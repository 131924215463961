import React, { Fragment, useEffect, useState } from "react";
import Header from "../header/Header";
import CSS from "../userManagement/userManagementPage.module.scss";
import "./userManagement.css";
import { connect } from "react-redux";
import { Icon, Table, Input, Dropdown } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Modal from "@material-ui/core/Modal";
import "../../components/ModalPopup.scss";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { getOrganizations } from "../../redux/organization/actions";
import { getFuneralHomes } from "../../redux/funeralHome/actions";
import { inviteUser, userDelete, updateUser } from "../../redux/user/actions";
import { useTranslation } from "react-i18next";
import CSS1 from "../../components/Table.module.css";
import validator from 'validator';
import {
	useTable,
	usePagination,
	useSortBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
} from "react-table";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
	selectRoot: {
		'&:focus':{
		backgroundColor:'white'
		}
	},
	selected:{
		background: 'linear-gradient(left, #00667E 0%, #00667E 2%, #E1EAEC 2%, #E1EAEC 100%)',
	},
	rootMenuItem: {
	  "&$selected": {
		backgroundColor: "white",
		  "&:hover": {
			backgroundColor: "white"
		   }
		},
	  '&:hover':{
		backgroundColor:'#C4D5D9'
	  },
	},
	icon: {
		fill: '#00667E',
	 },
  }));
const UserPanel = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { organizations, getOrganizations, user, inviteUser, getFuneralHomes, userDelete, updateUser, funeralHomes } = props;
	const [currentOrgData, setCurrentOrgData] = useState(null);
	const [modalState, setModalState] = useState({
		state: "close",
	});
	const [organizationName, setOrganizationName] = useState("");
	const [nameQuery, setNameQuery] = useState("");
	const [currentOrganization, setCurrentOrganization] = useState("");
	const [currentHomeList, setCurrentHomeList] = useState([]);
	const [roleType, setRoleType] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userValid, setUserValid] = useState(false);
	const [checkUserValid, setCheckUserValid] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [deleteUserConfirmationModal,setUserDeleteConfirmationModal ] = useState({
        state: "close",
    });
	const [userRole, setUserRole] = useState([]);
	const [userArray, setUserArray] = useState([]);
	const [errorStates, setErrorStates] = useState({
		userEmail: ""
	});
	useEffect(() => {
	  
	}, [userRole]);
	useEffect(() => {
	  
	}, [checkUserValid]);
	useEffect(() => {
		if(validator.isEmail(userEmail) || !userEmail){
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail:""
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail: t("pages.admin_create_org.add_org.email_validation")
				}
			})
		}
		if(userArray.length>0){
			userArray.map(arr => {
				if(arr.email.toLocaleLowerCase() === userEmail && modalState.state!=="edit"){
					setErrorStates(prev=>{
						return {
							...prev,
							userEmail: t("pages.admin_create_org.add_org.email_exist_validation")
						}
					})
				}
			})
		}
	},[userEmail]);
	useEffect(() => {
		if(user.user?.role === "super_admin" || user.user?.role === "admin" || user.user?.role === "editor"){
			const roleList = [
				{
					id: 1,
					label: t("pages.admin_create_org.user_mgmt.add_modal.roles.editor_r"),
				},
				{
					id: 2,
					label: t("pages.admin_create_org.user_mgmt.add_modal.roles.editor"),
				}
			];
			setUserRole(roleList);
		}
		else{
			const roleList = [
				{
					id: 1,
					label: t("pages.admin_create_org.user_mgmt.add_modal.roles.editor_r"),
				},
			];
			setUserRole(roleList);
		}
	}, [user]);
	useEffect(() => {
		getOrganizations();
		getFuneralHomes();
	}, []);
	useEffect(() => {
		if (organizations.length>0) {
			setCurrentOrgData({ ...organizations });
			var userData = [];
			userData = organizations?.map(org => org.funeralHomes.filter((home)=>(home.users.length>0)));
			var x1 = userData.filter(x1=>x1.length>0);
			var flattened = [].concat.apply([],x1);
			var modifiedData = [];
			modifiedData.push(
				flattened.map((home) => home.name),
				flattened.map((home) =>
					home.users.map(function (user) {
						return user.email;
					})
				),
				flattened.map((home) => home.id),
				flattened.map((home) =>
					home.users.map(function (user) {
						return user.id;
					})
				),
				flattened.map((home) =>
					home.users.map(function (user) {
						return user.role;
					})
				),
				flattened.map((home) =>
					home.users.map(function (user) {
						return user.organizationId;
					})
				),
			);
			var x = [];
			for (var i = 0; i < modifiedData[0]?.length; i++) {
				x.push(modifiedData[0][i], modifiedData[1][i], modifiedData[2][i], modifiedData[3][i], modifiedData[4][i], modifiedData[5][i]);
			}
			var y = [];
			for (var j = 0; j < x?.length; j += 6) {
				for (var k = 0; k < x[j + 1].length; k++) {
					y.push({ email: x[j + 1][k], homeName: x[j], homeId: x[j+2], userId: x[j + 3][k], role: x[j+4][k], organId: x[j+5][k] });
				}
			}
			var outputArray = [];
			y?.forEach(function (item) {
				var existing = outputArray.filter(function (v, i) {
					return v.email === item.email;
				});
				if (existing.length) {
					var existingIndex = outputArray.indexOf(existing[0]);
					outputArray[existingIndex].homeName = outputArray[
						existingIndex
					].homeName.concat(item.homeName);
					outputArray[existingIndex].homeId = outputArray[
						existingIndex
					].homeId.concat(item.homeId);
					outputArray[existingIndex].role = outputArray[
						existingIndex
					].role.concat(item.role);
					outputArray[existingIndex].organId = outputArray[
						existingIndex
					].organId.concat(item.organId);
				} else {
					if (typeof item.homeName === "string"){
						item.homeName = [item.homeName];
					}
					if (typeof item.homeId === "string"){
						item.homeId = [item.homeId];
					}
					if (typeof item.role === "string"){
						item.role = [item.role];
					}
					if (typeof item.organId === "string"){
						item.organId = [item.organId];
					}
					outputArray?.push(item);
				}
			});
			setUserArray(outputArray);
		}
	}, [organizations]);
	useEffect(() => {
	}, [modalState]);
	useEffect(() => {
		if(user.user?.role==="editor" || user.user?.role==="editor_restricted"){
			setCurrentHomeList([...funeralHomes])
		}
	}, [funeralHomes]);	
	const handleRoleType = (e) => {
		setRoleType(e.target.value);
	};
	useEffect(() => {
	}, [userArray]);
	const addUser = () => {
		var roleLabel ="";
		if(roleType === "Editor"){
			roleLabel="editor";
		}
		else{
			roleLabel="editor_restricted";
		}
		if (checkValidation()) {
			const userInvitePayload = {
				userInvite: {
					email: userEmail,
					role: roleLabel,
					funeralHomeIds: [
						...currentHomeList
							.map((x) => {
								if (x.isChecked) {
									return x.id;
								} else {
									return null;
								}
							})
							.filter((x) => x != null),
					],
				},
			};
			inviteUser(userInvitePayload);
			setModalState({
				state: "close",
			});
		}
	};
	const userUpdate = async (userId) => {
		var roleLabel ="";
		if(roleType === "Editor"){
			roleLabel="editor";
		}
		else{
			roleLabel="editor_restricted";
		}
		if (checkValidation()) {
			const userUpdatePayload = {
				user: {
					email: userEmail,
					role: roleLabel,
				},
				funeralHomeIds: [
					...currentHomeList
						.map((x) => {
							if (x.isChecked) {
								return x.id;
							} else {
								return null;
							}
						})
						.filter((x) => x != null),
				],
			};
			await updateUser(userUpdatePayload, userId).then(()=>{
				getOrganizations();
			});
			setModalState({
				state: "close",
			});
		}
	};
	const editUser = (userId) => {
		setModalState({
			state: "edit",
			id: userId,
		});
		const user1 = userArray?.find((x) => x.userId === userId);
		setUserEmail(user1.email);
		if(user1.role[0] === "editor"){
			setRoleType("Editor");
		}
		else{
			setRoleType("Editor-Restricted");
		}
		for (var i = 0; i < organizations.length; i++) {
			if (organizations[i].id === user1.organId[0]) {
				return (
					setOrganizationName(organizations[i].name),
					setCurrentHomeList(
						organizations[i].funeralHomes
							.map((x) => {
								return {
									id: x.id,
									name: x.name,
									isChecked: user1.homeId?.find(
										(home) => home === x.id
									),
								};
							})
							.filter((x) => x != null)
					)
				);
			}
		  }
	};
	const deleteUser = (userId) => {
		const orgUser = userArray?.find(x => x.userId === userId);
        if(orgUser){
            setUserDeleteConfirmationModal({
                state: "open",
				userId: orgUser.userId,
				email: orgUser.email
            });
        }
	};
	const deleteUserConfirmation = async(userId) => {
		userDelete(userId).then(() => {
			getOrganizations();
		})
		setUserDeleteConfirmationModal({
            state: 'close'
        });
	}
	const handleOpen = () => {
		setModalState({
			state: "add",
		});
		resetUserModal();
	};
	const handleClose = () => {
		setModalState({
			state: "close",
		});
		resetUserModal();
	};
	const handleUserEmail = (e) => {
		setUserEmail(e.target.value);
	};
	useEffect(() => {
		checkValidation();
		checkUserValidation();
	}, [userEmail, currentHomeList, roleType, errorStates, user, modalState]);
	const checkValidation = () => {
		if (
			userEmail &&
			currentHomeList.filter((x) => x.isChecked).length > 0 &&
			!errorStates.userEmail &&
			roleType
		) {
			setUserValid(true);
			return true;
		} else {
			setUserValid(false);
			return false;
		}
	};
	const checkUserValidation = () => {
		if((organizationName && userEmail && !errorStates.userEmail) || user.user?.role==="editor" || user.user?.role==="editor_restricted"){
			setCheckUserValid(true);
		}
		else{
			setCheckUserValid(false);
		}
		if(user.user?.role==="editor_restricted" && modalState.state === "edit"){
			setCheckUserValid(false);
		}
	};
	const resetUserModal = () => {
		setUserEmail("");
		setRoleType("");
		setOrganizationName("");
		setCurrentHomeList(
			currentHomeList.map((x) => {
				return {
					id: x.id,
					name: x.name,
					isChecked: false,
				};
			})
		);
	};
	const funeralHomeToggled = (id) => {
		setCurrentHomeList((prev) => {
			const home = prev.find((x) => x.id === id);
			if (home) {
				home.isChecked = !home.isChecked;
			}
			return [...prev];
		});
		if(currentHomeList?.filter((x) => x.isChecked).length === currentHomeList?.length){
			if(currentHomeList?.length === 1){
				setRoleType(userRole[0].label);
			}
			else{
				if(roleType === "" || roleType === "Editor-Restricted"){
					setRoleType(userRole[1].label);
				}
			}
		}else{
			setRoleType("");
		}
	};
	const getOrgName = (organId) => {
		for (var i = 0; i < organizations.length; i++) {
			if (organizations[i].id === organId[0]) {
				return (<div>{organizations[i].name}</div>)
			}
		  }
	}
	const columns = React.useMemo(() => [
		{
			id: "editUser",
			Header: "",
			accessor: (org, row) => {
				if(user.user?.role === "editor_restricted"){
					if (org?.role[0] === "editor_restricted") {
						const id = row;
						return <EditIcon cursor="pointer" color="primary" onClick={()=>{editUser && editUser(userArray[id].userId)}}/>;
					}
				}
				else if(user.user?.role === "editor"){
					if (org?.role[0] === "editor_restricted" || org?.role[0] === "editor") {
						const id = row;
						return <EditIcon cursor="pointer" color="primary" onClick={()=>{editUser && editUser(userArray[id].userId)}}/>;
					}
				}
				else{
					if (org) {
						const id = row;
						return <EditIcon cursor="pointer" color="primary" onClick={()=>{editUser && editUser(userArray[id].userId)}}/>;
					}
				}
			},
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			Header: t("pages.admin_create_org.view_org.add_modal.email"),
			accessor: (org) => {
				return (<div className={CSS.topHeaderData}>{org.email}</div>);
			},
			disableSortBy: true,
		},
		{
			Header: t("pages.admin_create_org.user_mgmt.add_modal.role"),
			accessor: (org) => {
				return (<div className={CSS.topHeaderData}>{org.role[0]}</div>);
			},
			sortType: (rowA, rowB) => {
				if (
					rowA.original.name.toLocaleLowerCase() <
					rowB.original.name.toLocaleLowerCase()
				)
					return -1;
				if (
					rowA.original.name.toLocaleLowerCase() >
					rowB.original.name.toLocaleLowerCase()
				)
					return 1;
				return 0;
			},
		},
		{
			Header: t("pages.admin_create_org.table.org_column"),
			accessor: (org) => {
				return (<div className={CSS.topHeaderData}>{getOrgName(org.organId)}</div>);
			},
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			Header: t("pages.admin_create_org.view_org.add_modal.site_access"),
			accessor: (org) => {
				return (
					org &&
					org.homeName.map((home) => {
						return <div className={CSS.topHeaderData} key={Math.random()}>{home}</div>;
					})
				);
			},
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			id: "deleteUser",
			Header: "",
			accessor: (org, row) => {
				if(((user.user && user.user?.role === "super_admin") ||
					(user.user && user.user?.role === "admin") || (user.user && user.user?.role === "editor")) && org){
					const id = row;
					return (
						<DeleteForeverIcon
							color="primary"
							cursor= "pointer"
							className={CSS.primaryIcon}
							onClick={() => {
								deleteUser && deleteUser(userArray[id].userId);
							}}
						/>
					);
				}
			},
			disableSortBy: true,
			disableGlobalFilter: true,
		},
	]);
	const userModal = ({ state, id }) => (
		<div className={"modalPopup2Wrapper"}>
			<div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
				<div className={"modalPopupTitle"}>
					{modalState.state === "add" ? t("pages.admin_create_org.view_org.add_modal.title") : t("pages.admin_create_org.view_org.user_modal.update_user")}
				</div>
				<div className={"modalPopupCloseBtn"}>
					<CloseIcon
						className={"modalPopupCloseIcon"}
						onClick={handleClose}
					/>
				</div>
			</div>
			<div className={"modalPopupBody"} style={{padding: "0px 16px 24px"}}>
				<div className={CSS.elementWrapper}>
					<label className={CSS.elementLabel}>{t("pages.admin_create_org.user_mgmt.add_modal.email")}</label>
					<TextField
						className={errorStates.userEmail? `${CSS.inputEleError} ${CSS.size_l}` :`${CSS.inputEle} ${CSS.size_l}`}
						style={{ width: 365 }}
						variant="outlined"
						value={userEmail}
						onChange={(e) => {
							handleUserEmail(e);
						}}
					/>
					{modalState.state === "edit" && !errorStates.userEmail ? <div className={CSS.hintMsg}>Note: You must log in with the new email.</div> :
					<div className={CSS.errorText}>{errorStates.userEmail}</div>}
				</div>
				{(user.user?.role === "admin") || (user.user?.role === "super_admin") &&
					<div className={CSS.elementWrapper}>
						<label className={CSS.elementLabel}>
						{t("pages.admin_create_org.user_mgmt.add_modal.select_org")}
						</label>
						<div className="dropdown">
							<div
								className="control"
								onClick={() => setDropdownOpen((prev) => !prev)}>
								<input
									type="text"
									className="selectedValue"
									value={organizationName || nameQuery}
									onChange={(event) => {
										setOrganizationName("");
										setNameQuery(event.target.value);
									}}
								/>
								<div
									className={`arrow ${
										dropdownOpen ? "open" : null
									}`}
								/>
							</div>
							<div
								className={`options ${
									dropdownOpen ? "open" : null
								}`}>
								{organizations
									.filter((val) => {
										if (nameQuery === "") {
											return val;
										} else if (
											val.name
												.toLocaleLowerCase()
												.includes(
													nameQuery.toLocaleLowerCase()
												)
										) {
											return val;
										}
									})
									.map((org) => (
										<div
											key={org.id}
											onClick={() => {
												setCurrentOrganization(org);
												setCurrentHomeList(org.funeralHomes);
												setOrganizationName(org.name);
												setDropdownOpen(false);
											}}
											className="option">
											{org.name}
										</div>
									))}
							</div>
						</div>
					</div>}
				{checkUserValid &&
					<div>
						<div className={CSS.elementWrapper}>
							<label className={CSS.elementLabel}>
								{t("pages.admin_create_org.user_mgmt.table.site_col")}
							</label>
							<div className={CSS.inputSet}>
								{currentHomeList &&
									currentHomeList.map(
										(home) => (
											<FormControlLabel
												key={home.id}
												control={
													<Checkbox
														checked={home.isChecked}
														onChange={() => {
															funeralHomeToggled(
																home.id
															);
														}}
														style ={{
															color: "#00667E",
														  }}
													/>
												}
												label={home.name}
											/>
										)
									)}
							</div>
						</div>
						<div className={CSS.elementWrapper}>
							<label className={CSS.elementLabel}>{t("pages.admin_create_org.user_mgmt.add_modal.role")}</label>
							<FormControl
								variant="outlined"
								className={`${CSS.inputEle} ${CSS.size_l}`}>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={roleType}
									onChange={handleRoleType}
									MenuProps={{
										disableScrollLock: true,
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										  },
										getContentAnchorEl: null,
										MenuListProps: {
											disablePadding: true
										}
									  }}
									classes={{ root: classes.selectRoot }}										  
									inputProps={{
										classes: {
											root: classes.border,
											icon: classes.icon,
										},
									}}
									IconComponent={ExpandMoreOutlinedIcon}>
									{userRole.length>0 && 
									userRole?.map((role) => {
										return (
											<MenuItem
												key={role.id}
												value={role.label}
												classes={{ selected: classes.selected, root: classes.rootMenuItem }}
												disabled={(currentHomeList.filter((x) => x.isChecked).length === currentHomeList.length && role.label === "Editor-Restricted" && currentHomeList?.length>1)
												|| (organizations?.length===0 && role.label === "Editor-Restricted") || (organizations?.length===0 && role.label === "Editor")}>
												{role.label}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					</div> }
				<div style={{ float: "right", marginTop: "32px", paddingBottom: "32px" }}>
					{state === "edit" ? (
						<button className={`${CSS.actionBtn}  ${CSS.warning} ${userValid ? "" : CSS.disabled}`} onClick={() => {userUpdate(id);}}>
							{t("pages.admin_create_org.view_org.user_modal.update_user")}
						</button>
					) : (
						<button className={`${CSS.actionBtn}  ${CSS.warning} ${userValid ? "" : CSS.disabled}`}
							onClick={addUser}>
							{t("pages.admin_create_org.user_mgmt.add_modal.title")}
						</button>
					)}
				</div>
			</div>
		</div>
	);
	return (
		<div>
			<Header />
			<div className={CSS.bodyWrapper}>
				<span className={CSS.navPath}>{t("pages.admin_create_org.user_mgmt.title")}</span>
				<section className={CSS.sectionWrapper}>
					<div className={CSS.usersPanel} style={{padding: "0px", border: "1px solid #C4D5D9", borderRadius: "8px", overflow: "hidden"}}>
						<UserTable
							editUser={editUser}
							deleteUser={deleteUser}
							usersList={userArray}
							columns={columns}
							t={t}
							handleOpen={handleOpen}
						/>
					</div>
				</section>
				<Modal
					open={modalState.state !== "close"}
					onClose={handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description">
					{userModal(modalState)}
				</Modal>
				<Modal
					open={deleteUserConfirmationModal.state !== 'close'}>
						<div className={"modalPopup2Wrapper"} style={{width: "420px"}}>
							<div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
								<div className={"modalPopupTitle"}>
									Delete {deleteUserConfirmationModal.email}?
								</div>
								<div className={"modalPopupCloseBtn"}>
									<CloseIcon
									className={"modalPopupCloseIcon"}
									onClick={()=>{ setUserDeleteConfirmationModal({
										state: 'close'
									})}}
									/>
								</div>
							</div>
							<div className={"modalPopupBody"} style={{padding: "0px 24px 16px"}}>
								<div className={"modalBody"}>
									{deleteUserConfirmationModal.email} {t("pages.admin_create_org.add_org.user_no_longer_exist")}
									<div style={{marginTop: "32px"}}><span>{t("pages.admin_create_org.add_org.deleting_user")}{" "} <span style={{fontWeight: "800"}}>{t("pages.admin_create_org.add_org.cannot_undone")}</span></span></div>
								</div>
								<div style={{marginTop: 16, display:'flex',justifyContent: 'space-between'}}>
									<button  className={`${CSS.actionBtn} ${CSS.primary}`} onClick={()=>{ setUserDeleteConfirmationModal({
										state: 'close'
									})}}>{t('pages.admin_create_org.view_org.delete_home_modal.cancel')}</button>                     
									<button  className={`${CSS.actionBtn} ${CSS.error}`}  onClick={()=> { deleteUserConfirmation(deleteUserConfirmationModal.userId) }}>{t("pages.admin_create_org.add_org.delete_user")}</button>
								</div>
							</div>
						</div>
				</Modal>
			</div>
		</div>
	);
};

const OrgFilter = ({ globalFilter, setGlobalFilter, handleOpen }) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(globalFilter);
	const [filterData, setFilterData] = useState({
		filterValue: "",
	});
	const onChange = (value) => {
		setFilterData((prev) => {
			const newFilter = {
				...prev,
				filterValue: value,
			};
			setGlobalFilter(newFilter);
			return newFilter;
		});
	};

	return (
		<div className={CSS.headerRow} style={{margin: "16px 16px 0px 16px"}}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div className={CSS.headerName}>{t("pages.admin_create_org.table.users_column")}</div>
				<Input
					className={CSS.filterWrapper}
					iconPosition="left"
					placeholder={t("pages.events_calendar.search_placeholder")}>
					<Icon name="search" style={{ color: "#00667E" }} />
					<input
						className={CSS.filterInput}
						value={value || ""}
						onChange={(e) => {
							setValue(e.target.value);
							onChange(e.target.value);
						}}
					/>
				</Input>
			</div>
			<button className={`${CSS.actionBtn} ${CSS.warning}`} onClick={handleOpen}>
				{t("pages.admin_create_org.view_org.add_modal.title")}
			</button>
		</div>
	);
};

const UserTable = ({ columns, editUser, deleteUser, usersList, setModalState, resetUserModal, handleOpen, t }) => {
	const [hoveredRow, setHoveredRow] = useState(null);
	useEffect(() => {
	}, [hoveredRow]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		setPageSize,
		state,
		setGlobalFilter,
		setFilter,
	} = useTable(
		{
			columns,
			data: usersList,
			globalFilter: (rows, columnIds, globalFilterData) => {
				return rows.filter((row) => {
					const event = row.original;
					let { filterValue } = globalFilterData;
					if (!(filterValue && filterValue.trim())) {
						return true;
					}
					filterValue = filterValue.trim().toLowerCase();
					return (
						event.email.toLowerCase().includes(filterValue) ||
						`${event.email.toLowerCase()}`.includes(filterValue)
					);
				});
			},
			initialState: { pageIndex: 0 },
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);
	return (
        <Fragment>
			<OrgFilter
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
				setModalState={setModalState}
				resetUserModal={resetUserModal}
				handleOpen={handleOpen}
			/>
            <Table className={CSS.table} structured {...getTableProps()} style={{border : "none"}}>
                <Table.Header>
                    {headerGroups.map((headerGroup) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Table.HeaderCell
                                    // width={2}
                                    className={CSS1.headerCell}
                                    {...column.getHeaderProps({
                                        ...column.getSortByToggleProps(),
                                        style: { ...column.style },
                                    })}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <Icon name="caret down" />
                                            ) : (
                                                <Icon name="caret up" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.length === 0 &&
                        (state.filters.length > 0 || state.globalFilter) && (
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell
                                    style={{
                                        padding: "50px 0 50px 0",
                                        fontStyle: "italic",
                                    }}>
                                    {t("pages.admin_create_org.table.filter_no_results")}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    {page.map((row, i) => {
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        return (
                            <React.Fragment key={rowProps.key}>
                                <Table.Row {...rowProps}
								onMouseEnter={()=> setHoveredRow(rowProps.key)}
								onMouseLeave={() => setHoveredRow(null)}
								style={{ background: rowProps.key === hoveredRow ? '#D6FFD6' : 'white', }}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <Table.Cell {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </Table.Cell>
                                        );
                                    })}
                                </Table.Row>
                            </React.Fragment>
                        );
                    })}
                </Table.Body>
            </Table>
        <div className={CSS.pagination} style={{borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
				<div className={CSS.pageDropdown}>
				{t('pages.admin_events_table.footer_rows')}:
					<Dropdown
						value={state.pageSize}
						options={[
							{ key: 1, text: "10", value: 10 },
							{ key: 2, text: "20", value: 20 },
							{ key: 3, text: "30", value: 30 },
							{ key: 4, text: "40", value: 40 },
							{ key: 5, text: "50", value: 50 },
						]}
						onChange={(e, data) => {
							setPageSize(Number(data.value));
						}}
					/>
				</div>
				<div className={CSS.pageMover}>
					<Icon
						name="arrow left"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					/>{" "}
					<span>
					{t("pages.admin_create_org.table.page")}{" "}
						<strong>
							{state.pageIndex + 1} of {pageOptions.length}
						</strong>{" "}
					</span>{" "}
					<Icon
						name="arrow right"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					/>
				</div>
			</div>
        </Fragment>
	);
};

const mapStateToProps = (state) => ({
	organizations: state.organization.organizations,
	funeralHomes: state.funeralHome.funeralHomes,
	user: state.user.currentUser,
	userUpdated: state.user.userUpdated
});

const mapDispatchToProps = {
	getOrganizations,
	getFuneralHomes,
	inviteUser,
	userDelete,
	updateUser
};
export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
