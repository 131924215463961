import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { init as initPretender } from './pretender';
import './i18n';
import ContextWrapper from "./pages/calender view/context/ContextWrapper";

// Remove this line to not use pretender locally
// if (process.env.NODE_ENV === 'development') {
//   initPretender();
// }

ReactDOM.render(
  <ContextWrapper>
    <App />
  </ContextWrapper>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
