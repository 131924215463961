import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
	getFuneralStreams,
	getFuneralDetails,
	postFuneralComment,
	getFuneralComments,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess
} from "../../redux/funeral/actions";
import { getFuneralHome } from "../../redux/funeralHome/actions";
import "../../components/ModalPopup.scss";
import { useTranslation } from 'react-i18next';
import VideoStream from "./VideoStream";
import EmbeddedVideoStreamLiveStream from './EmbeddedVideoStreamLiveStream';

const FuneralStreamsPage = (props) => {
	const {
		match: {
			params: { id: funeralId },
		},
	} = props;
	const { funeralDetails, funeralHomeInfo, visitorDetails, postFuneralComment, postFuneralReply } =
		props;
	const { t } = useTranslation();
	useEffect(() => {
		props.getFuneralDetails(funeralId, true);
		props.getFuneralComments(funeralId);
		props.getFuneralStreams(funeralId, true);
	}, []);
	useEffect(() => {
		if(funeralDetails.funeralHomeId){
			props.getFuneralHome(funeralDetails.funeralHomeId, true);
		}
	}, [funeralDetails.funeralHomeId]);

	return (
		<div>
			{funeralDetails.streamProvider === 'livestream_provider' && (<EmbeddedVideoStreamLiveStream funeralDetails={funeralDetails} funeralHomeInfo={funeralHomeInfo} funeralId={funeralId} />)}
			{funeralDetails.streamProvider === 'aws_provider' && <VideoStream funeralDetails={funeralDetails} funeralHomeInfo={funeralHomeInfo} funeralId={funeralId} />}
			{funeralDetails.streamProvider === 'dacast_provider' && (<EmbeddedVideoStreamLiveStream funeralDetails={funeralDetails} funeralHomeInfo={funeralHomeInfo} funeralId={funeralId} />)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralStreams: state.funeral.funeralStreams,
	funeralDetails: state.funeral.funeralDetails,
	funeralComments: state.funeral.funeralComments,
	funeralHomeInfo: state.funeralHome.funeralHomeViewing,
	visitorDetails: state.funeral.visitorData,
	commentDetails: state.funeral.commentData
});

const mapDispatchToProps = {
	getFuneralStreams,
	getFuneralDetails,
	getFuneralComments,
	getFuneralHome,
	postFuneralComment,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess
};
export default connect(mapStateToProps, mapDispatchToProps)(FuneralStreamsPage);
