export const col = {
    border: '#e0e0e0 1px solid',
};
export const weekDays = {
    minHeight: 72,
    width: '12.5%',
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 16,
    backgroundColor: "#FFFFFF",
    boxShadow: "inset -1px -1px 0px #E0E0E0"
};

export const weekDayName = {
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: '#667679',
    marginLeft: 10,
    fontWeight: 700,
    marginTop: 4,
    fontStyle: "normal",
    fontFamily: "Open Sans"
};

export const container = {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    overflowX: 'auto',
    overflowY: "scroll",
    marginLeft: 72
};

export const weekDates = {
    fontSize: 22,
    lineHeight: '32px',
    color: '#001A20',
    marginLeft: 10,
    fontWeight: 400,
    marginTop: 16,
    fontStyle: "normal",
    fontFamily: "Open Sans"
};

export const slot = {
    height: 72,
    width: '12.5%',
};
export const timeCol = {
    fontSize: 12,
    color: '#667679',
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    textAlign: 'center',
    marginLeft: 16,
    justifyContent: 'center',
};
export const timeCol1 = {
    fontSize: 12,
    color: '#667679',
    display: 'flex',
    alignItems: 'center',
    marginRight: 21,
    textAlign: 'center',
    marginLeft: 16,
    justifyContent: 'center',
};

export const timeString = {
};

export const row = {
    position: 'relative',
};

export const lightHighlighter = {
    backgroundColor: '#EFF6FF',
};

export const eventHighlighter = {
    position: 'absolute',
    borderRadius: '4px',
    color: 'white',
    padding: '2px 16px',
    zIndex: 1,
    cursor: 'pointer',
};