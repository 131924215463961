import React, { useState, useCallback, useEffect } from "react";
import { Input, Icon } from "semantic-ui-react";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import CSS from "./GlobalFilter.module.scss";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { debounce } from "lodash";


const useStyles = makeStyles((theme) => ({
	selectRoot: {
		'&:focus':{
		backgroundColor:'white'
		},
	},
	selected:{
		background: 'linear-gradient(left, #00667E 0%, #00667E 5%, #E1EAEC 5%, #E1EAEC 100%)',
	},
	rootMenuItem: {
	  "&$selected": {
		backgroundColor: "white",
		  "&:hover": {
			backgroundColor: "white",
			border: "none"
		   }
		},
	  '&:hover':{
		backgroundColor:'#C4D5D9'
	  },
	},
	icon: {
		fill: '#00667E',
	 }
  }));

const GlobalFilter = ({ globalFilter, setGlobalFilter, user, xyzGlobalFilter }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [value, setValue] = useState(globalFilter);
	const [filterData, setFilterData] = useState({
		filterName: "Name",
		filterValue: "",
	});

	useEffect(() => {
	  for(var key in xyzGlobalFilter) {
        if(xyzGlobalFilter[key] === "") {
					setValue("");
        }
    }
	}, [xyzGlobalFilter]);

	const onChange = (value) => {
		setFilterData((prev) => {
			const newFilter = {
				...prev,
				filterValue: value,
			};
			setGlobalFilter(newFilter);
			return newFilter;
		});
	};

	const handleFilterName = (e) => {
		setFilterData((prev) => {
			const newFilter = {
				...prev,
				filterName: e.target.value,
			};
			setGlobalFilter(newFilter);
			return newFilter;
		});
	};
	
	const searchStaticList = [
		{
			id: 1,
			label: t('pages.admin_events_table.filter_client_name'),
		},
		{
			id: 2,
			label: t('pages.admin_events_table.filter_client_email'),
		},
	];

	const handleFilterChange = (e) => {
		onChange(e.target.value);
	}
	const handler = useCallback(debounce(((handleFilterChange)), 500), []);

	return (
		<Input style={{marginLeft: "16px", marginTop: "16px"}}
			className={CSS.filterWrapper}
			iconPosition="left"
			placeholder={((user.user && user.user?.role === "super_admin") || (user.user && user.user?.role === "admin"))?
				t('pages.admin_events_table.search_hint'): t('pages.admin_events_table.search_hint_2')
				}>
			<Icon name="search" style={{color: "#00667E"}}/>
			<input
				className={CSS.filterInput}
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					handler(e);
				}}
			/>
			{((user.user && user.user?.role === "super_admin") || (user.user && user.user?.role === "admin")) &&
				<Select
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					style={{ width: "120px", height: "32px", borderLeft: "1px solid black", textAlign: "center" }}
					value={filterData.filterName}
					onChange={handleFilterName}
					MenuProps={{
						disableScrollLock: true,
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						  },
						getContentAnchorEl: null,
						MenuListProps: {
							disablePadding: true
						}
					  }}
					inputProps={{
						classes: {
							root: classes.border,
							icon: classes.icon,
						},
					}}
					classes={{ root: classes.selectRoot }}			  
					IconComponent={ExpandMoreOutlinedIcon}>
					{searchStaticList.map((list) => {
						return (
							<MenuItem key={list.id} value={list.label} classes={{ selected: classes.selected, root: classes.rootMenuItem }}>
								{list.label}
							</MenuItem>
						);
					})}
				</Select>
			}
		</Input>
	);
};

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(GlobalFilter);
