import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logo from "../../../src/assets/svgs/funeral-live-logo.png";
import CSS from "./VideoRequestPage.module.scss";
import { useTranslation } from 'react-i18next';

const PaymentSuccess = (props) => {
    const location = useLocation();
	const { t } = useTranslation();
    useEffect(() => {
    }, [location]);
    const openFuneralLive = () => {
        window.open("https://funerallive.ca/");
    };
    return (
        <div>
            <div className={CSS.navWrapper}>
                <div className={CSS.logoWrapper}>
                    <img src={logo} alt="Logo" onClick={openFuneralLive} />
                </div>
            </div>
            <div className={CSS.sectionWrapper}>
                <section className={CSS.formWrapper} style={{marginTop: "88px"}}>
                    <span className={CSS.formTitlte}>{t('pages.request_video.thank_you_msg')}, <span style={{textTransform: "capitalize"}}>{location.state.payload.first_name}</span></span>
                    <div>
                        <span className={CSS.formHint}>{t('pages.request_video.payment_processed')} <b style={{fontWeight: "700"}}>{location.state.payload.email}</b>. {t('pages.request_video.delayed_res')}.</span>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default PaymentSuccess;