import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import "../../components/ModalPopup.scss"
import NavHeader from "../header/Header";
import Spinner from "../../components/Spinner";
import {
	getFuneralStreams,
	getFuneralDetails,
	postFuneralComment,
	getFuneralComments,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess,
	startStream,
	stopStream,
	resetStream,
	resendStartSignal,
	sendStopSignal
} from "../../redux/funeral/actions";
import { getFuneralHome } from "../../redux/funeralHome/actions";
import "../../components/ModalPopup.scss";
import { Grid, Segment, Button, Embed, Header, List } from 'semantic-ui-react';

const convertDateToDateOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("YYYY-MM-DD");
	}
	return "";
};

const convertDateToTimeOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("h:mm A");
	}
	return "";
};

const FuneralInfo = ({ funeral }) => {
  return (
    <Segment>
      <Header as="h2">Funeral Information</Header>
      <p>First Name: {funeral.firstName}</p>
      <p>Last Name: {funeral.lastName}</p>
      <p>Date of Birth: {funeral.dateOfBirth}</p>
      <p>Date of Death: {funeral.dateOfDeath}</p>
      {/* Add more properties here */}
    </Segment>
  );
};

const StreamControl = ({ stream, startStream, stopStream, updateFuneralStreams, resendStartSignal, sendStopSignal, cameraError }) => {
	console.log('cameraError ', cameraError);
	const [isStreaming, setIsStreaming] = useState(stream.state === 'started' || stream.state === 'paused');
	const [modalOpen, setModalOpen] = useState(false);
	const canStream = stream.livestreamEvent;

	const closeModal = () => setModalOpen(false);

  	return (
    <Segment>
		<h3>{stream.name}</h3>
				<p>State: {stream.state}</p>
		<p>Start Time: {convertDateToDateOnly(stream.streamStart)} at {convertDateToTimeOnly(stream.streamStart)}</p>
		<p>End Time: {convertDateToDateOnly(stream.streamEnd)} at {convertDateToTimeOnly(stream.streamEnd)}</p>
		<p>Room: {stream.room?.name}</p>
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '20px' }}>
			{stream.livestreamEvent && (
				<iframe id="ls_embed_1641182855" 
					src={`https://livestream.com/accounts/29883600/events/${stream.livestreamEvent.eventId}/player?width=400&height=200&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false`}
					width="400" 
					height="200"
				/>
			)}
			{!stream.livestreamEvent && (
				<Segment textAlign="center">
					<Spinner />
					<Header as="h3" style={{ fontWeight: 'bold' }}>
						Creating event on the backend. This could take up to 5 minutes. Please refresh the page to try again.
					</Header>
				</Segment>			
			)}
		</div>
		<div>
			<Segment textAlign="center">
				<Button
					onClick={() => {
						startStream(stream.funeralId, stream.id);
						updateFuneralStreams();
						// setIsStreaming(true);
					}}
					disabled={isStreaming || stream.location !== 'On-Site' || !canStream || stream.state === 'ended'}
					color="green"
				>
					Start Stream
				</Button>
				<Button
					onClick={() => {
						resendStartSignal(stream.funeralId, stream.id);
						updateFuneralStreams();
						// setIsStreaming(true);
					}}
					disabled={!isStreaming}
					color="grey"
				>
					Start Camera
				</Button>
				<Button
					onClick={() => {
						sendStopSignal(stream.funeralId, stream.id);
						updateFuneralStreams();
						// setIsStreaming(true);
					}}
					disabled={!isStreaming}
					color="grey"
				>
					Stop Camera
				</Button>
				<Button 
					onClick={() => {
						setModalOpen(true);
					}} 
					disabled={!isStreaming || stream.location !== 'On-Site' || stream.state === 'ended'}
					color="red"
				>
					Stop Stream
				</Button>
			</Segment>
			<Segment>
				<Header as="h4">Useful Tips</Header>
				<List ordered>
					<List.Item>If you're experiencing bad video quality try "Stop Camera" then "Start Camera".</List.Item>
					<List.Item>If you would like to pause the stream click "Stop Camera" and then "Start Camera" when you're ready to stream again.</List.Item>
					<List.Item>If the stream is started, but no video is being streamed, try "Start Camera" to start the stream again.</List.Item>
				</List>
			</Segment>
		</div>
		<Modal open={modalOpen} onClose={closeModal}>
			<div className={"modalPopup2Wrapper"}>
				<div className={"modalPopupHeaderWrapper"}>
					<div className={"modalPopupTitle"} style={{paddingRight: "16px", wordBreak: "break-word"}}>
						Are you sure you want to end the stream? 
					</div>
					<div className={"modalPopupCloseBtn"}>
						<CloseIcon
							className={"modalPopupCloseIcon"}
							onClick={closeModal}
						/>
					</div>
				</div>
				<div className={"modalPopupBody"} style={{padding: "0px 24px 16px", overflow: "hidden"}}>
					<div className="modalHint">
						The stream cannot be started again once ended.
					</div>
					<div className="btns">
						<button
							className={"cancelBtn"}
							onClick={closeModal}>
							Cancel
						</button>
						<button
							className={"commitBtn"}
							onClick={(e) => {
								stopStream(stream.funeralId, stream.id);
								setTimeout(() => {
									updateFuneralStreams();
								}, 1000);
								setIsStreaming(false);
								closeModal()
							}}>
							Yes, stop stream
						</button>
					</div>
				</div>
			</div>
		</Modal>
	</Segment>
  );
};


const FuneralViewPage = (props) => {
	const {
		match: {
			params: { id: funeralId },
		},
	} = props;
	const { funeralDetails, funeralStreams, startStream, stopStream, cameraError } =
		props;

	useEffect(() => {
		props.getFuneralDetails(funeralId, true);
		props.getFuneralComments(funeralId);
		props.getFuneralStreams(funeralId, true);
	}, []);

	useEffect(() => {
		if(funeralDetails.funeralHomeId){
			props.getFuneralHome(funeralDetails.funeralHomeId, true);
		}
	}, [funeralDetails.funeralHomeId]);

	const updateFuneralStreams = () => {
		props.getFuneralStreams(funeralId, true);
	}
	
	return (
		<div>
			<NavHeader />
			<div style={{ padding: '20px' }}>
				<Grid columns={2} divided>
					<Grid.Column width={16}>
						<FuneralInfo funeral={funeralDetails} />
					</Grid.Column>
					{funeralStreams.map((stream) => (
						<Grid.Column key={stream.id}>
							<StreamControl
								stream={stream}
								startStream={startStream}
								stopStream={stopStream}
								updateFuneralStreams={updateFuneralStreams} 
								resendStartSignal={props.resendStartSignal}
								sendStopSignal={props.sendStopSignal}
								cameraError={cameraError}
							/>
						</Grid.Column>
					))}
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralStreams: state.funeral.funeralStreams,
	funeralDetails: state.funeral.funeralDetails,
	funeralComments: state.funeral.funeralComments,
	funeralHomeInfo: state.funeralHome.funeralHomeViewing,
	visitorDetails: state.funeral.visitorData,
	commentDetails: state.funeral.commentData,
	cameraError: state.error
});

const mapDispatchToProps = {
	getFuneralStreams,
	getFuneralDetails,
	getFuneralComments,
	getFuneralHome,
	postFuneralComment,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess,
	startStream,
	stopStream,
	resendStartSignal,
	sendStopSignal
};
export default connect(mapStateToProps, mapDispatchToProps)(FuneralViewPage);
