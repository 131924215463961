import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import PrivateRoute from './components/PrivateRoute';
import FuneralsPage from './pages/adminTablePage/FuneralsPage';
import LoginPage from './pages/userLoginPage/LoginPage';
import RegistrationPage from './pages/registrationPage/RegistrationPage';
import PasswordResetPage from './pages/passwordResetPage/PasswordResetPage';
import EnterPasswordResetPage from './pages/enterPasswordResetPage/EnterPasswordResetPage';
import FuneralHomesPage from './pages/FuneralHomesPage';
import FuneralHomeViewPage from './pages/FuneralHomeViewPage';
import OrganizationManagementAddAndEditPage from './pages/orgManagement/OrganizationManagementAddAndEditPage';
import FuneralViewPage from './pages/deceasedPage/FuneralViewPage';
import FuneralAdminPage from './pages/deceasedPage/FuneralAdminPage';
import FuneralHomeAdminPage from './pages/funeralHomeAdminPage/FuneralHomeAdminPage';
import FuneralAdminPageClone from './pages/deceasedPage/FuneralAdminPageClone';
import FuneralStreamsPage from './pages/deceasedPage/FuneralStreamsPage';
import 'semantic-ui-css/semantic.min.css';
import configureStore from './store/configureStore';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CreateEventPage from './pages/createEvent/CreateEventPage';
import CreateEventClone from './pages/createEvent/CreateEventClone';
import CondolenceMessagesViewPage from './pages/condolenceMsgViewPage/CondolenceMessagesViewPage';
import EventSuccessPage from './pages/createEvent/EventSuccessPage';
import OrganizationManagementPage from './pages/orgManagement/OrganizationManagementPage';
import OrganizationManagementView from './pages/orgManagement/OrganizationManagementView';
import userManagementPage from './pages/userManagement/userManagementPage';
import VideoRequestPage from './pages/video request/VideoRequestPage';
import CalenderViewPage from "./pages/calender view/CalenderViewPage";
import ConfigurationManagementPage from './pages/configurationManagement/ConfigurationManagementPage';
import PaymentSuccess from './pages/video request/PaymentSuccess';
import ClipDownloadPage from './pages/video request/ClipDownloadPage';
import StreamingCredits from './pages/streamingCredits/StreamingCredits';
import CheckoutPage from './pages/streamingCredits/CheckoutPage';
import LandingPage from './pages/landingPage/LandingPage';
import FuneralManagementConsole from './pages/FuneralManagementConsole';
import BillingPage from './pages/billing/BillingPage';
import FuneralPaymentCheckoutPage from './pages/video request/FuneralPaymentCheckoutPage';
import Policy from './pages/policy&Purchase/Policy';
import PurchaseAndReturn from './pages/policy&Purchase/PurchaseAndReturn';
import NotFoundImage from "./assets/svgs/404page.png";
import FuneralHomeDetailsPage from './pages/funeralHomeDetailsPage/FuneralHomeDetailsPage';
const store = configureStore();

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#00667E",
    },
    secondary: {
      main: "#AE0000",
    }
  },
  props: {
    MuiButton:{
      variant: "contained"
    }
  },
  overrides:{
    MuiButton:{
      contained:{
        backgroundColor: "#C4D5D9"
      }
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <Provider store={store}>
      <Router>
        {/* <Menu /> */}
        <Switch>
          {/* <PrivateRoute exact path="/" component={() => <div style={{ padding: 50}}><h2>Welcome to Funeral Live Admin</h2></div>} /> */}
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/register/:token" component={RegistrationPage} />
          <Route exact path="/resetPassword" component={PasswordResetPage} />
          <Route exact path="/resetPassword/:token" component={EnterPasswordResetPage} />
          <Route exact path="/about" component={LandingPage} />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/purchase" component={PurchaseAndReturn} />
          <PrivateRoute exact path="/createEvent" component={CreateEventClone} />
          <PrivateRoute exact path="/createEvent/:id/:state" component={CreateEventClone} />
          <PrivateRoute exact path="/createEventWithCredits" component={CreateEventClone} />
          <PrivateRoute exact path="/createEventWithCredits/:id/:state" component={CreateEventClone} />
          <PrivateRoute exact path="/EventSuccess" component={EventSuccessPage} />
          <PrivateRoute exact path="/funerals" component={FuneralsPage} />
          <PrivateRoute exact path="/funerals/:id/admin" component={FuneralAdminPageClone} />
          {/* <PrivateRoute exact path="/funerals/:id/admin_new" component={FuneralAdminPage} /> */}
          <PrivateRoute exact path="/orgmanagement" component={OrganizationManagementPage} />
          <PrivateRoute exact path="/orgmanagement/:id/view" component={OrganizationManagementView} />
          <PrivateRoute exact path="/usermanagement" component={userManagementPage} />
          <PrivateRoute exact path="/calenderView" component={CalenderViewPage} />
          <PrivateRoute exact path="/funeralhomes" component={FuneralHomesPage} />
          <PrivateRoute path="/funeralhomes/:id" component={FuneralHomeViewPage} />
          <PrivateRoute path="/funeralhome/:id/admin" component={FuneralHomeDetailsPage} />
          <PrivateRoute path="/funeralhomeadmin" component={FuneralHomeAdminPage} />
          <PrivateRoute exact path="/organization/add" component={OrganizationManagementAddAndEditPage} />
          <PrivateRoute exact path="/organization/:id/edit" component={OrganizationManagementAddAndEditPage} />
          <PrivateRoute exact path="/configmanagement" component={ConfigurationManagementPage} />
          <PrivateRoute exact path="/streamcredits" component={StreamingCredits} />
          <PrivateRoute exact path="/billing" component={BillingPage} />
          <PrivateRoute exact path="/checkout/:id" component={CheckoutPage} />
          <Route exact path="/funeralPayment" component={FuneralPaymentCheckoutPage} />
          <Route path="/funerals/:id" component={FuneralViewPage} />
          <Route path="/funeral_embed/:id" component={FuneralStreamsPage} />
          <Route path="/videoRequest/:video_request_id" component={VideoRequestPage} />
          <Route path="/downloadClip/:download_clip_id" component={ClipDownloadPage} />
          <Route path="/paymentSuccess" component={PaymentSuccess} />
          <PrivateRoute exact path="/viewcondolensemessages/:id" component={CondolenceMessagesViewPage} />
          <Route path="/funeral_management_console/:id" component={FuneralManagementConsole} />
          <Route component={() => 
             <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: `url(${NotFoundImage}) no-repeat center center fixed`,
                backgroundSize: 'cover',
              }}
            />
          } />
        </Switch>
      </Router>
    </Provider>
    </ThemeProvider>
  );
}

export default App;
