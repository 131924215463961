import { CONFIG_LIST_REQUEST_SUCCESS } from './actions';

const initialState = {
  configurations: [],
  configuration: undefined
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case CONFIG_LIST_REQUEST_SUCCESS:
      return { ...state, configurations: action.configurations };
    default:
      return state;
  }
}

export default reducer;
