import React, {useState, useEffect, Fragment, useRef} from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { fileChecksum } from "../../utils/checksum";
import {
	postFuneralComment,
	getFuneralComments,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess,
	postVideoWithSignedUrl,
} from "../../redux/funeral/actions";
import { loadingAction } from "../../redux/funeral/actions";
import Modal from "@material-ui/core/Modal";
import Webcam from "react-webcam";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from 'react-player/lazy';
import CSS from "./FuneralViewPage.module.scss";
import Spinner from "../../components/Spinner";
import Timer from "../../components/Timer";
import CSS1 from '../../components/Table.module.css'

function CondolenceMessagePage(props) {
	const {funeralComments, visitorDetails, getFuneralComments, loadingAction, showLoading, funeralId, userSavedData, postFuneralComment, postFuneralReply, postVideoWithSignedUrl} = props;
    useEffect(()=>{
		if(funeralComments && funeralComments.length > 0){
			setCommentsMap(funeralComments.map((x)=>{
				return {
					...x,
					rplyOpen: false
				}
			}))
		}
	},[funeralComments]);
	const [browserLang, setBrowserLang] = useState("");
    useEffect(() => {
		var userLang = navigator.language || navigator.userLanguage;
		setBrowserLang(userLang);
	    getFuneralComments(funeralId);
	}, []);
	useEffect(() => {
		
	}, [browserLang])
    const [commentsMap, setCommentsMap] = useState([]);
	const [visitorId, setVisitorId] = useState(null);
	const [postMsgValues, setPostMsgValues] = useState({
		postMsgFullName: "",
		postMsgEmail: "",
		postMsgTxt: "",
	});
    const [postRplyValues, setPostRplyValues] = useState({
		postMsgTxt: "",
	});
	const { t } = useTranslation();
	useEffect(() => {
		if(visitorDetails){
			setVisitorId(visitorDetails.id);
			}
	}, [visitorDetails]);
    const [isActive, setIsActive] = useState(true);
	const [open, setOpen] = useState(false);
	const webcamRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const [capturing, setCapturing] = useState(false);
	const [recordedChunks, setRecordedChunks] = useState([]);
	const [Url, setUrl] = useState(null);
	const [videoBlob, setVideoBlob] = useState(null);
	const [showVideo, setShowVideo] = useState(false);
	const [playing, setPlaying] = useState(false);
	const imgSrc = React.useRef(null);
	const [videoCheck, setVideoCheck] = useState(false);
	const [replyBtnId, setReplyBtnId] = useState([]);
	const [videoFilePath, setVideoFilePath] = useState(null);
	const [videoUploadError, setVideoUploadError] = useState("");
	const [stillRecording, setStillRecording] = useState(true);
	const [ timerId, setTimerId ] = useState("");
    useEffect(() => {
	}, [replyBtnId, videoUploadError, videoFilePath]);
	useEffect(() => {
		setStillRecording(false);
	}, [stillRecording]);
	const expandMessages = () => {
		setIsActive(!isActive);
	};
    const commentCreatedAtDate = (time) => {
		return moment(new Date(time)).format("LL");
	};
	const commentCreatedAtTime = (time) => {
		return moment(new Date(time)).format("h:mm a");
	};
	const handlePostMsgTxt = (e) => {
		setPostMsgValues((previous) => {
			return {
				...previous,
				postMsgTxt: e.target.value,
			};
		});
	};
    const handlePostRplyTxt = (e) => {
		setPostRplyValues((previous) => {
			return {
				...previous,
				postMsgTxt: e.target.value,
			};
		});
	};
	const postMessage = async () => {
		let clip = null;
		if (videoBlob) {
			const checksum = await fileChecksum(videoBlob);
			loadingAction(true);
			const blobSignedId = await postVideoWithSignedUrl(videoBlob, checksum);
			if(blobSignedId){
				setVideoUploadError("");
				clip = blobSignedId;
			}
			else{
				setVideoUploadError(t('pages.deceased.condolences_upload_error'));
				return;
			}
		}
		const messagePayload = {
			comment: {
				visitor_id: visitorId,
				value: postMsgValues.postMsgTxt,
				clip
			}
		}
		postFuneralComment(funeralId, messagePayload).then(()=>{
			clearCommentData();
		  	getFuneralComments(funeralId);
		});		
	};
	const postReply = (index)=>{
		const commentId = commentsMap[index].id;
		postFuneralReply(commentId,{
			reply: {
				visitor_id: visitorId,
				value: postRplyValues.postMsgTxt
			}
		}).then(()=>{
			clearCommentData();
			getFuneralComments(funeralId);
		});
		setReplyBtnId([...replyBtnId,commentId]);
	}
	const clearCommentData = () => {
		setPostRplyValues({
			postMsgTxt: "",
		});
    	setPostMsgValues({
			postMsgTxt: "",
		});
		setCapturing(false);
		setRecordedChunks([]);
		setVideoFilePath(null);
		setUrl(null);
		setVideoBlob(null);
	};
	const openRply = (index) => {
		setCommentsMap((prev)=>{
			prev[index].rplyOpen = true;
			return [...prev];
		})
	}
	const cancelRply = (index) => {
		setCommentsMap((prev)=>{
			prev[index].rplyOpen = false;
			return [...prev];
		})
	}
	const handleVideoOpen = () => {
		setOpen(true);
		if(navigator.mediaDevices.getUserMedia){
			navigator.mediaDevices.getUserMedia({video:true})
			.then(()=>{
				setVideoCheck(true);
			}).catch((err)=>{
				setVideoCheck(false);
			})
		}
	};
	useEffect(() => {
	},[videoCheck]);
	useEffect(() => {
		if (!capturing) {
			if (recordedChunks.length) {
				const blob = new Blob(recordedChunks, {
				  type: "video/webm"
				});
				const url = URL.createObjectURL(blob);
				setUrl(url);
				setVideoBlob(blob);
			}
		}
	}, [recordedChunks]);
	useEffect(() => {
		if(capturing){
			const timer = () => setTimeout(() => {
				handleStopCaptureClick();
			}, 120000);
			const timerIdValue = timer();
			setTimerId(timerIdValue);
			// return () => {
			// 	clearTimeout(timerId);
			// };
		}
	}, [capturing]);
	useEffect(() => {
		if(timerId && !capturing){
			clearTimeout(timerId);
		}
	}, [timerId, capturing]);
	const handleStartCaptureClick = React.useCallback(() => {
		setCapturing(true);
		imgSrc.current = webcamRef.current.getScreenshot({
			width: 190,
			height: 120
		  });
		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
		  mimeType: "video/webm"
		});
		mediaRecorderRef.current.addEventListener(
		  "dataavailable",
		  handleDataAvailable
		);
		mediaRecorderRef.current.start();
	  }, [webcamRef, capturing, mediaRecorderRef]);
	const handleDataAvailable = React.useCallback(
		({ data }) => {
		  if (data.size > 0) {
			setRecordedChunks((prev) => prev.concat(data));
		  }
		},
		[setRecordedChunks]
	  );
	const handleStopCaptureClick = React.useCallback(() => {
		mediaRecorderRef.current.stop();
		setCapturing(false)
	  }, [mediaRecorderRef, webcamRef, capturing]);
	const handlePreview = React.useCallback(async () => {
		setShowVideo(true);
		setOpen(false);
		if (recordedChunks.length) {
			const blob = new Blob(recordedChunks, {
				type: "video/webm"
				});
			const url = URL.createObjectURL(blob);
			setUrl(url);
			setVideoBlob(blob);
		}
	}, [recordedChunks, Url]);
	const handleRecordClip = () =>{
		setCapturing(false);
		setRecordedChunks([]);
		setVideoFilePath(null);
		setUrl(null);
		setVideoBlob(null);
	};	
	const handleClose = () => {
		setVideoFilePath(null);
		setCapturing(false);
		setUrl(null);
		setVideoBlob(null);
		setRecordedChunks([]);
		setOpen(false);
	};
	const handleVideoUpload = (event) => {
		setUrl(null);
		setVideoBlob(null);
		setRecordedChunks([]);
		if(event.target.files[0]){
			setVideoFilePath(URL.createObjectURL(event.target.files[0]));
		}
		setVideoBlob(event.target.files[0]);
	};
    const videoModal = () =>{
		return(
			<>
				<div className={"modalPopupWrapper3"}>
					<div className={"modalPopupHeaderWrapper"}>
						<div className={"modalPopupTitle"} style={{paddingRight: "16px", wordBreak: "break-word"}}>
							{t('pages.deceased.condolences_modal_title')}
						</div>
						<div className={"modalPopupCloseBtn"}>
							<CloseIcon
								className={"modalPopupCloseIcon"}
								onClick={handleClose}
							/>
						</div>
					</div>
					{videoCheck ?
						<div className={CSS.modalPopupBody} style={{padding: "0px 24px 16px", overflow: "hidden"}}>
							{(Url && recordedChunks.length>0) || videoFilePath ?
								<ReactPlayer
									className={Url ? CSS1.reactPlayer : CSS1.player}
									url={Url ? Url : videoFilePath}
									playing={playing}
									controls={true}
									volume={1.0}
									// light={`${imgSrc.current}`}
									// height={"auto"}
									onReady={() => setPlaying(true)}
									height='100%'
									width='100%'
								/>
								:
								<Webcam
									audio={true}
									muted={true}
									className={CSS1.webcam}
									style={{transform: "scaleX(-1)"}}
									ref={webcamRef}
									width='100%'
									height="100%"
								/>}
						</div> : 
						<div className = {CSS.withoutVideo} style={{padding: "16px 24px"}}>
							{!videoFilePath ?
								<div className={CSS.noCamera}>
									<div className={CSS.cameraBlock}>
										{t('pages.deceased.condolences_nocameramic_title')}
									</div>
									<div className={CSS.blockHint}>
										{t('pages.deceased.condolences_nocameramic_text1')}
										<br />
										{t('pages.deceased.condolences_nocameramic_text2')}
										<br />
										{t('pages.deceased.condolences_nocameramic_text3')}
									</div>
								</div>
								: 
								<ReactPlayer
									url={videoFilePath}
									// playing={playing}
									className={CSS1.player}
									controls={true}
									volume={1.0}
									onReady={() => setPlaying(true)}
									height='100%'
									width='100%'
								/>
							}
						</div>}
						<div className={CSS.exportBar} style={{padding: "16px 24px"}}>
							<label className={CSS.fromDevice} htmlFor="files">{t('pages.deceased.condolences_upload_from_device')}</label>
							<input type="file" id="files" onChange={handleVideoUpload} onClick={(event)=> { event.target.value = null }}style={{display: "none"}} disabled={capturing}/>
							{capturing ? (
								<>
									<button className={`${CSS.actionBtn} ${CSS.primary}`} style={{ marginRight: "4px" }}
									type="button" onClick={handleStopCaptureClick}>{browserLang === "en" || "en-US" ? "Stop recording": "Arrêter"}</button>
									<Timer />
								</>
							) : (
								Url?
									<>
										<button className={`${CSS.actionBtn} ${CSS.primary}`}
										type="button" onClick={handleRecordClip}>{t('pages.deceased.condolences_rerecord')}</button>
										<span className={CSS.reRecordHint} style={{paddingLeft: "4px"}}>{t('pages.deceased.condolences_rerecord_help')}</span>
									</>
									:
									(!videoFilePath?
										<>
											<button className={`${CSS.actionBtn} ${CSS.primary} ${videoCheck? "": CSS.disabled}`}
											type="button" onClick={handleStartCaptureClick}>{t('pages.deceased.condolences_begin_recording')}</button>
											<span className={CSS.reRecordHint} style={{paddingLeft: "4px"}}>{t('pages.deceased.condolences_recording_help1')}</span>
										</>
									:
									<>
										<button className={`${CSS.actionBtn} ${CSS.primary}`}
										type="button" onClick={handleRecordClip}>{t('pages.deceased.discard')}</button>
										<span className={CSS.reRecordHint} style={{paddingLeft: "4px"}}>{t('pages.deceased.delete_warning')}</span>
									</>)		
							)}
							<button
								className={`${CSS.actionBtn} ${CSS.warning} ${
									recordedChunks.length>0 || videoFilePath ? "" : CSS.disabled}`}
								onClick={handlePreview}
								type="button">
								{t('pages.deceased.condolences_upload_video')}
							</button>
						</div>
				</div>
			</>
		)
	}
    const chartForm = (userSavedData, forRply, index) => {
		return (
			<Fragment>
				<div className={CSS.formElements}>
					<div className={CSS.inputWrapperRow}>
						<div className={CSS.inputWrapper}>
							{showLoading && 
								<div style={{position: "absolute", display: "flex", zIndex: "10", minWidth:"760px", justifyContent:"center", alignItems: "center"}}>
									<Spinner />
								</div>
							}
							<label className={CSS.inputHeader}>
								{forRply ? t('pages.deceased.condolences_reply') : t('pages.deceased.condolences_messaging_title')}
							</label>
							{(!forRply && Url && showVideo) || (!forRply && videoFilePath && showVideo) ?(
								<div className={CSS.previewVideo}>
									<textarea style={{padding: "8px 16px"}}
										className={CSS.inputElementAfter}
										rows="8"
										value={postMsgValues.postMsgTxt}
										onChange={handlePostMsgTxt}>
									</textarea>
									<div className={CSS.videoPreview}>
										<ReactPlayer
											className={CSS.reactPlayer}
											url={Url ? Url : videoFilePath}
											// playing={playing}
											controls={true}
											volume={1.0}
											// light={`${imgSrc.current}`}
											height={140}
											onReady={() => setPlaying(true)}
											width='100%'
										/>
									</div>
							  	</div>
							):(
                                !forRply?
								<textarea style={{padding: "8px 16px"}}
								className={CSS.inputElement}
								rows="8"
								value={postMsgValues.postMsgTxt}
								onChange={handlePostMsgTxt}>
								</textarea>
                                :
                                <textarea style={{padding: "8px 16px"}}
								className={CSS.inputElement}
								rows="8"
								value={postRplyValues.postMsgTxt}
								onChange={handlePostRplyTxt}>
								</textarea>
							)}
						</div>
					</div>
				</div>
				<div>
					<div className={CSS.submitWrapper}>
						<div className={CSS.hintMsg}>
							{userSavedData.isAuthenticated ? (
								forRply ? (
									<span>
										{t('pages.deceased.condolences_messaging_help3')}{" "}
										<b className={CSS.postBY}>{userSavedData.fullName}</b>
									</span>
								) : (
									<span>
										{t('pages.deceased.condolences_messaging_help2')}{" "}
										<b className={CSS.postBY}>{userSavedData.fullName}</b>
									</span>
								)
							) : forRply ? (
								""
							) : (
								<span>
									{t('pages.deceased.condolences_messaging_help1')}
								</span>
							)}
						</div>
						<div>
							{forRply || (
								<Fragment>
									{!Url && !videoFilePath ? (
										<button
											className={`${CSS.actionBtn} ${CSS.primary}`}
											onClick={handleVideoOpen}
											type="button">
											{t('pages.deceased.condolences_upload_video')}
										</button>
										) : (
											<button
											className={`${CSS.actionBtn} ${CSS.primary}`}
											style={{marginRight: "8px"}}
											onClick={()=>{
												setUrl(null);
												setVideoBlob(null);
												setRecordedChunks([]);
												setVideoFilePath(null);
												setVideoUploadError("");
											}}
											type="button">
											{t('pages.deceased.condolences_remove_video')}
											</button>
										)}
									<Modal open={open} onClose={handleClose}>
										{videoModal()}
									</Modal>
									<button className={`${CSS.actionBtn} ${CSS.warning} ${postMsgValues.postMsgTxt? "": CSS.disabled}`}
										type="button"
										onClick={postMessage}>
										{t('pages.deceased.condolences_messaging_title')}
									</button>
								</Fragment>
							)}
							{forRply && (
								<Fragment>
									<button className={`${CSS.actionBtn} ${CSS.primary}`}
										type="button" style={{marginRight: "8px"}} onClick={()=>{cancelRply(index)}}>
										{t('pages.edit_event.cancel')}
									</button>
									<button className={`${CSS.actionBtn} ${CSS.warning} ${postRplyValues.postMsgTxt? "": CSS.disabled}`}
										type="button"
										onClick={() => {postReply(index)}}>
										{t('pages.deceased.condolences_reply_title')}
									</button>
								</Fragment>
							)}
						</div>
					</div>
					{videoUploadError ? 
						<div className={CSS.errorText}>
							{videoUploadError}
						</div> : ""}
				</div>
			</Fragment>
		);
	};
	return (
		visitorDetails && userSavedData.isAuthenticated && (
				<Fragment>
					<section className={CSS.postMessageSection}>
						<div className={CSS.sectionContainer}>
							<div className={CSS.formWrappper}>
								<span className={CSS.formHeading}>
									{t("pages.deceased.condolences_messaging_title")}
								</span>
								{chartForm(userSavedData)}
							</div>
						</div>
					</section>
					<section className={CSS.condolanceSection}>
						<div className={CSS.sectionContainer}>
							<div className={CSS.sectionHeaderWrapper}>
								<div>
									<div className={CSS.sectionHeader}>
										{t("pages.deceased.condolences_title")}
									</div>
									<div className={CSS.sectionDescription}>
										{t("pages.deceased.condolences_help")}
									</div>
								</div>
								<div>
									<div
										className={CSS.expand}
										onClick={expandMessages}>
										{!isActive ? (
											<ExpandMoreOutlinedIcon className={CSS.expandIcon}/>
										) : (
											<ExpandLessIcon className={CSS.expandIcon}/>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
					{isActive ? (
						<section className={CSS.postsSection}>
							<div className={CSS.sectionContainer}>
								{commentsMap.map((comment, index) => {
									return (
										<div key={index}>
											{!comment.clipUrl?
											<div className={CSS.postMessageWarraper}>
												<div className={CSS.message}>
													{comment.value}
												</div>
												<div className={CSS.messageInfo_action}>
													<div className={CSS.message_info}>
														<div className={CSS.postBY}>
															{comment.visitor.name}
														</div>
														<div className={CSS.postTimestamp}>
															{commentCreatedAtDate(comment.visitor.createdAt)}
														</div>
														<div className={CSS.postTimestamp}>
															{commentCreatedAtTime(comment.visitor.createdAt)}
														</div>
													</div>
													{comment.rplyOpen || (
														<div>
															{!replyBtnId.includes(comment.id) && (
																<button className={`${CSS.actionBtn} ${CSS.primary}`} onClick={() => {openRply(index);}}>
																	{t("pages.deceased.condolences_reply")}
																</button>
															)}
														</div>
													)}
												</div>
												{comment.rplyOpen && (
													<div className={CSS.formWrappper}>
														{chartForm(userSavedData,true,index)}
													</div>
												)}
											</div>:
												<div className={CSS.postMessageWithVideo}>
													<div className={CSS.reactPlayer}>
														<ReactPlayer
															url={comment.clipUrl}
															playing={false}
															controls={true}
															volume={1.0}
															// light={`${imgSrc.current}`}
															onReady={() => setPlaying(true)}
															height='100%'
															width='100%'
														/>
													</div>
													<div className={CSS.postMessageWarraper}>
														<div className={CSS.message}>
															{comment.value}
														</div>
														<div className={CSS.messageInfo_action}>
															<div className={CSS.message_info}>
																<div className={CSS.postBY}>
																	{
																		comment.visitor.name
																	}
																</div>
																<div className={CSS.postTimestamp}>
																	{commentCreatedAtDate(
																		comment.visitor.createdAt
																	)}
																</div>
																<div className={CSS.postTimestamp}>
																	{commentCreatedAtTime(
																		comment.visitor.createdAt
																	)}
																</div>
															</div>
															{comment.rplyOpen || (
																<div>
																	{!replyBtnId.includes(comment.id) && (
																		<button className={`${CSS.actionBtn} ${CSS.primary}`} onClick={() => {openRply(index);}}>
																			{t("pages.deceased.condolences_reply")}
																		</button>
																	)}
																</div>
															)}
														</div>
														{comment.rplyOpen && (
															<div className={CSS.formWrappper}>
																{chartForm(userSavedData,true,index)}
															</div>
														)}
													</div>
												</div>
											}
											{comment.replies.length > 0 &&
												comment.replies.map((reply) => {
													return (
														<div key={reply.id}
															className={CSS.postReplyWrraper}>
															<div className={CSS.message}>
																{reply.value}
															</div>
															<div className={CSS.messageInfo_action}>
																<div className={CSS.message_info}>
																	<div className={CSS.postBY}>
																		{
																			reply.visitor.name
																		}
																	</div>
																	<div className={CSS.postTimestamp}>
																		{commentCreatedAtDate(
																			reply.visitor.createdAt
																		)}
																	</div>
																	<div className={CSS.postTimestamp}>
																		{commentCreatedAtTime(
																			reply.visitor.createdAt
																		)}
																	</div>
																</div>
															</div>
														</div>
													);
												})}
										</div>
									);
								})}
							</div>
						</section>
					) : null}
				</Fragment>
			)
	);
}
const mapStateToProps = (state) => ({
	funeralComments: state.funeral.funeralComments,
	visitorDetails: state.funeral.visitorData,
	commentDetails: state.funeral.commentData,
	showLoading: state.funeral.showLoading
});

const mapDispatchToProps = {
	getFuneralComments,
	postFuneralComment,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess,
	postVideoWithSignedUrl,
	loadingAction
};
export default connect(mapStateToProps, mapDispatchToProps)(CondolenceMessagePage);
