import axios from '../../utils/axios';
import camelcaseKeys from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";

const CONFIG_LIST_REQUEST = 'CONFIG_LIST_REQUEST';
export const CONFIG_LIST_REQUEST_SUCCESS = 'CONFIG_LIST_REQUEST_SUCCESS';
export const CONFIG_LIST_REQUEST_FAILURE = 'CONFIG_LIST_REQUEST_FAILURE';

const ADD_CONFIG_REQUEST = 'ADD_CONFIG_REQUEST';
export const ADD_CONFIG_REQUEST_SUCCESS = 'ADD_CONFIG_REQUEST_SUCCESS';
export const ADD_CONFIG_REQUEST_FAILURE = 'ADD_CONFIG_REQUEST_FAILURE';

const UPDATE_CONFIG_REQUEST = 'UPDATE_CONFIG_REQUEST';
export const UPDATE_CONFIG_REQUEST_SUCCESS = 'UPDATE_CONFIG_REQUEST_SUCCESS';
export const UPDATE_CONFIG_REQUEST_FAILURE = 'UPDATE_CONFIG_REQUEST_FAILURE';


const DELETE_CONFIG_REQUEST = 'DELETE_CONFIG_REQUEST';
export const DELETE_CONFIG_SUCCESS = 'DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'DELETE_CONFIG_FAILURE';

export const getConfigurations = () => {
  return dispatch => {
    dispatch({ type: CONFIG_LIST_REQUEST });
    return axios.get('api/configurations', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const configurations = camelcaseKeys(response.data).configurations;
      dispatch(getConfigsSuccessful(configurations));
      return response;
    })
    .catch(error => {
        dispatch(getConfigsFailed(error));
      return error;
    });
  }
};

const getConfigsSuccessful = (configurations)=>{
  return {
    type: CONFIG_LIST_REQUEST_SUCCESS,
    configurations: configurations
  }
}

const getConfigsFailed = (error)=>{
  return {
    type: CONFIG_LIST_REQUEST_FAILURE,
    error
  }
}

export const createConfig = (payload) => {
	return (dispatch) => {
		dispatch({ type: ADD_CONFIG_REQUEST });
		return axios
			.post('api/configurations', snakeCaseKeys(payload), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const configData = camelcaseKeys(response.data);
				dispatch(createConfigDataSuccess(configData));
        return configData;
			})
			.catch((error) => dispatch(createConfigDataFailure(error)));
	};
};


const createConfigDataSuccess = (createConfigResponse) => {
	return {
		type: ADD_CONFIG_REQUEST_SUCCESS,
		createConfigResponse,
	};
};

const createConfigDataFailure = (error) => {
	return {
		type: ADD_CONFIG_REQUEST_FAILURE,
		error,
	};
};

export const updateConfig = (configId, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_CONFIG_REQUEST });
		return axios
			.put(`api/configurations/${configId}`, snakeCaseKeys(payload), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const configData = camelcaseKeys(response.data);
				dispatch(updateConfigDataSuccess(configData));
        return configData;
			})
			.catch((error) => dispatch(updateConfigDataFailure(error)));
	};
};


const updateConfigDataSuccess = (updateConfigResponse) => {
	return {
		type: UPDATE_CONFIG_REQUEST_SUCCESS,
		updateConfigResponse,
	};
};

const updateConfigDataFailure = (error) => {
	return {
		type: UPDATE_CONFIG_REQUEST_FAILURE,
		error,
	};
};


export const deleteConfiguration = (configId) => {
  return dispatch => {
    dispatch({ type: DELETE_CONFIG_REQUEST });
    return axios.delete(`api/configurations/${configId}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      dispatch(deleteConfigurationSuccess(configId));
      return response;
    })
    .catch(error => {
      if( error.response ){
        dispatch(deleteConfigurationFailure(error.response.data.message));
      } else {
        dispatch(deleteConfigurationFailure("something went wrong"));
      }
      return error;
    });
  }
};

const deleteConfigurationSuccess = (configId) => {
  return {
    type: DELETE_CONFIG_SUCCESS,
    configId,
  }
};

const deleteConfigurationFailure = (message) => {
  return {
    type: DELETE_CONFIG_FAILURE,
    message,
  }
};
