import React, { Fragment } from 'react';
import Day from './Day';
import CSS from "./CalenderView.module.scss";

const MonthView=({month, funerals, roomId, roomsSelected, colors, colors2, funeralHomes, updatedRoomsList}) =>{
    return (
        <div className={CSS.monthView}>
            {month.map((row, i) => (
                <Fragment key={i}>
                    {row.map((day, idx) => (
                        <Day day={day} key={idx} rowIdx={i} funerals={funerals} roomId={roomId} roomsSelected={roomsSelected} colors={colors}
                        colors2={colors2} funeralHomes={funeralHomes} updatedRoomsList={updatedRoomsList}/>
                    ))}
                </Fragment>
            ))}
        </div>
    )
}

export default MonthView;
