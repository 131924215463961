import React from "react";
import CSS from "./StreamingCredits.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const StreamCreditCard = ({ STATE, funeralHomeId }) => {
	const history = useHistory();
	const { t, i18n } = useTranslation();
    const handleCreditsPayment = (data) => {
        history.push({
            pathname:
                `/checkout/${funeralHomeId}`,
            state: {
                creditData: data,
            },
        });
    }
	const cardData = [
		{
			id: 1,
			credits: 1,
			price: 21.0,
			discount: null,
			total: 21
		},
		{
			id: 2,
			credits: 25,
			price: 20.0,
			discount: "4.25%",
      		total: 500
		},
		{
			id: 3,
			credits: 100,
			price: 19.0,
			discount: "9.5%",
      		total: 1900
		},
		{
			id: 4,
			credits: 500,
			price: 18.0,
			discount: "14%",
      		total: 9000
		},
		{
			id: 5,
			credits: 1000,
			price: 17.0,
			discount: "19%",
      		total: 17000
		},
	];
	return (
		<div className={CSS.creditPackageCards}>
			{cardData.map((data) => {
				return (
					<div key={data.id} className={CSS.eachCard}>
						<div className={CSS.creditName}>
							<div className={CSS.creditNumber}>
								{data.credits}
							</div>
							<div
								className={CSS.creditNumberHint}
								style={{ marginLeft: 4 }}>
								{" "}
								{data.credits === 1 ? t('pages.streaming_credits.credit') : t('pages.streaming_credits.credits')}
							</div>
						</div>
						<div className={CSS.creditHint}>
						{t('pages.streaming_credits.get')} {data.credits} {t('pages.streaming_credits.stream')}
						</div>
						<div className={CSS.creditHint}>
							{t('pages.streaming_credits.for')} {"$"}
							{data.price}{" "}
							{t('pages.streaming_credits.per_stream')}
						</div>
						<div className={CSS.discountPrice} style={(data.credits===25 || data.credits===100) ? {backgroundColor : "#E1EAEC" } : data.credits===1 ? {backgroundColor : "none"} : {backgroundColor : "#D6FFD6"}}>
							{data.discount? 
								<>
									{t('pages.streaming_credits.save')}{" "}
									{data.discount}
								</>
								: <div style={{visibility: 'hidden'}}>{"u"}</div> }
						</div>
						{!STATE ?
							<>
								<div className={CSS.creditPrice}>
									{"$"}
									{data.total}
								</div>
								<button className={`${CSS.actionBtn} ${CSS.warning}`}
									onClick={() => handleCreditsPayment(data)}>
									{t('pages.streaming_credits.Buy')} {data.credits}{" "}
									{data.credits === 1 ? t('pages.streaming_credits.credit') : t('pages.streaming_credits.credits')}
								</button>
							</> : "" }
					</div>
				);
			})}
		</div>
	);
};

export default StreamCreditCard;
