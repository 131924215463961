import React, { Fragment, useEffect, useState } from "react";
import Header from "../header/Header";
import CSS from "./OrganizationManagementView.module.scss";
import { connect } from "react-redux";
import { Icon, Table, Input } from "semantic-ui-react";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import { getOrganization } from "../../redux/organization/actions";
import { getFuneralHomes } from "../../redux/funeralHome/actions";
import { inviteUser, updateUser, userDelete, initialStateVal, resendUserInvite } from "../../redux/user/actions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import validator from 'validator';
import CSS1 from "../../components/Table.module.css";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
	useTable,
	usePagination,
	useSortBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
} from "react-table";
import { deleteOrganization } from "../../redux/organization/actions";
import { deleteFuneralHome } from "../../redux/funeralHome/actions";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import FormControl from "@material-ui/core/FormControl";
import Spinner from '../../components/Spinner';
import moment from "moment";
const useStyles = makeStyles((theme) => ({
	selectRoot: {
		'&:focus':{
		backgroundColor:'white'
		}
	},
	selected:{
		background: 'linear-gradient(left, #00667E 0%, #00667E 2%, #E1EAEC 2%, #E1EAEC 100%)',
	},
	rootMenuItem: {
	  "&$selected": {
		backgroundColor: "white",
		  "&:hover": {
			backgroundColor: "white"
		   }
		},
	  '&:hover':{
		backgroundColor:'#C4D5D9'
	  },
	},
	icon: {
		fill: '#00667E',
	 },
  }));
const OrganizationManagementEdit = (props) => {
	const {
		match: {
			params: { id: orgId },
		},
	} = props;
	const {
		organization,
		funeralHomes,
		getOrganization,
		getFuneralHomes,
		deleteOrganization,
		deleteFuneralHome,
		history,
		user,
		inviteUser,
		updateUser,
		userDelete,
		resendUserInvite, resendInviteResponse,
		userInviteResponseData, initialStateVal
	} = props;
	const { t } = useTranslation();
	const [tabIndex, setTabIndex] = useState("0");
	const [currentOrgData, setCurrentOrgData] = useState(null);
	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
		state: "close",
	});
	useEffect(() => {
		getOrganization(orgId);
		getFuneralHomes();
	}, []);
	useEffect(() => {
		if (organization) {
			setCurrentOrgData({ ...organization });
		}
	}, [organization]);
	const HandleTabIndexChange = (evt, index) => {
		setTabIndex(index + "");
	};
	const removeFuneralHome = (homeId) => {
		const home = currentOrgData.funeralHomes.find((x) => x.id === homeId);
		if (home) {
			setDeleteConfirmationModal({
				state: "open",
				homeId: home.id,
				name: home.name,
				deleteOrg: currentOrgData.funeralHomes.length === 1,
			});
		}
	};
	const deleteConfirmation = async (homeId) => {
		deleteFuneralHome(homeId).then(() => {
			if (currentOrgData.funeralHomes.length === 1) {
				deleteOrganization(orgId);
				history.push(`/orgmanagement`);
			} else {
				setCurrentOrgData((prev) => {
					const index = prev.funeralHomes.findIndex((x) => x.id === homeId);
					if (index !== -1) {
						prev.funeralHomes.splice(index, 1);
					}
					setTabIndex("0");
					return { ...prev };
				});
			}
		});
		setDeleteConfirmationModal({
			state: "close",
		});
	};

	return (
		<div>
			<Header />
			<div className={CSS.bodyWrapper}>
				<span className={CSS.navPath}>
					<a href="/orgmanagement" className={CSS.moveTo}>
						{t("pages.admin_create_org.orgManagement_title")}
					</a>{" "}
					{">"} {t("pages.admin_create_org.view_org.viewOrg_title")}
				</span>
				<section className={CSS.sectionWrapper}>
					{currentOrgData && (
						<span className={CSS.pageTitle}>
							{currentOrgData.name}
						</span>
					)}
					<div
						className={`${CSS.segment} ${CSS.noPadding} ${CSS.tabsContainer}`}>
						<TabContext value={tabIndex}>
							<TabList
								onChange={HandleTabIndexChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto"
								aria-label="scrollable auto tabs example">
								{user.user?.role === "admin" || user.user?.role === "super_admin" ?
								currentOrgData &&
									currentOrgData.funeralHomes.map(
										(home, index) => (
											<Tab
												key={home.id}
												label={home.name}
												value={index + ""}
											/>
										)
									): 
									funeralHomes &&
									funeralHomes.map(
										(home, index) => (
											<Tab
												key={home.id}
												label={home.name}
												value={index + ""}
											/>
										)
									)}
							</TabList>
							{user.user?.role === "admin" || user.user?.role === "super_admin" ?
							currentOrgData &&
								currentOrgData.funeralHomes.map(
									(home, index) => (
										<TabPanel
											key={home.id}
											value={index + ""}>
											<div className={CSS.homeDetailsPanel}>
												<div className={CSS.homeDetails}>
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_email")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.contactEmail}&nbsp;
														</span>
													</div>
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_phone")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.phone}&nbsp;
														</span>
													</div>
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_url")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.baseUrl}&nbsp;
														</span>
													</div>
													{home.flowerShopLink &&
														<div className={CSS.homeDetailWrapper}>
															<span className={CSS.homeDetailTitle}>
																{t("pages.admin_create_org.view_org.org_flower_link")}:&nbsp;
															</span>
															<span className={CSS.homeDetailValue}>
																{home.flowerShopLink}&nbsp;
															</span>
														</div>}
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
														{t("pages.admin_create_org.view_org.org_rooms")}:&nbsp;
														</span>
														{home.rooms.map(room =>
															(<span key={room.id} className={CSS.homeDetailValue}>
																{room.name},&nbsp;
															</span>)
														)}
													</div>
													<br />
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_mailing")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.mailingAddress?.street},{" "}
															{home.mailingAddress?.unit !== "" ? `${home.mailingAddress?.unit}, ` : null}
															{home.mailingAddress?.city},{" "}
															{home.mailingAddress?.province},{" "}
															{home.mailingAddress?.country},{" "}
															{home.mailingAddress?.postalCode}
														</span>
													</div>
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_billing")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.billingAddress?.street},{" "}
															{home.billingAddress?.unit !== "" ? `${home.billingAddress?.unit}, ` : null}
															{home.billingAddress?.city},{" "}
															{home.billingAddress?.province},{" "}
															{home.billingAddress?.country},{" "}
															{home.billingAddress?.postalCode}
														</span>
													</div>
												</div>
												<div
													className={CSS.homeActions}>
													{user.user &&
														user.user?.role !=="editor_restricted" && (
															<EditIcon
																cursor="pointer"
																color="primary"
																onClick={() =>
																history.push({
																	pathname:
																		`/organization/${orgId}/edit`,
																	state: {
																		detail: home.id,
																	},
																})
															}
															/>
														)}
													{((user.user &&
														user.user?.role ==="super_admin") ||
														(user.user &&
															user.user?.role ==="admin")) && (
														<DeleteForeverIcon
															cursor="pointer"
															color="primary"
															onClick={() =>removeFuneralHome(home.id)
															}
														/>
													)}
												</div>
											</div>
										</TabPanel>
									)
								):
								funeralHomes &&
								funeralHomes.map(
									(home, index) => (
										<TabPanel
											key={home.id}
											value={index + ""}>
											<div className={CSS.homeDetailsPanel}>
												<div className={CSS.homeDetails}>
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_email")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.contactEmail}&nbsp;
														</span>
													</div>
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_phone")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.phone}&nbsp;
														</span>
													</div>
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_url")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.baseUrl}&nbsp;
														</span>
													</div>
													{home.flowerShopLink &&
														<div className={CSS.homeDetailWrapper}>
															<span className={CSS.homeDetailTitle}>
																{t("pages.admin_create_org.view_org.org_flower_link")}:&nbsp;
															</span>
															<span className={CSS.homeDetailValue}>
																{home.flowerShopLink}&nbsp;
															</span>
														</div>}
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
														{t("pages.admin_create_org.view_org.org_rooms")}:&nbsp;
														</span>
														{home.rooms.map(room =>
															(<span key={room.id} className={CSS.homeDetailValue}>
																{room.name},&nbsp;
															</span>)
														)}
													</div>
													<br />
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_mailing")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.mailingAddress?.street},{" "}
															{home.mailingAddress?.unit !== "" ? `${home.mailingAddress?.unit}, ` : null}
															{home.mailingAddress?.city},{" "}
															{home.mailingAddress?.province},{" "}
															{home.mailingAddress?.country},{" "}
															{home.mailingAddress?.postalCode}
														</span>
													</div>
													<br />
													<div className={CSS.homeDetailWrapper}>
														<span className={CSS.homeDetailTitle}>
															{t("pages.admin_create_org.view_org.org_billing")}:&nbsp;
														</span>
														<span className={CSS.homeDetailValue}>
															{home.billingAddress?.street},{" "}
															{home.billingAddress?.unit !== "" ? `${home.billingAddress?.unit}, ` : null}
															{home.billingAddress?.city},{" "}
															{home.billingAddress?.province},{" "}
															{home.billingAddress?.country},{" "}
															{home.billingAddress?.postalCode}
														</span>
													</div>
												</div>
												<div
													className={CSS.homeActions}>
													{user.user &&
														user.user?.role !=="editor_restricted" && (
															<EditIcon
																cursor="pointer"
																color="primary"
																onClick={() =>
																history.push({
																	pathname:
																		`/organization/${orgId}/edit`,
																	state: {
																		detail: home.id,
																	},
																})
															}
															/>
														)}
													{((user.user &&
														user.user?.role ==="super_admin") ||
														(user.user &&
															user.user?.role ==="admin")) && (
														<DeleteForeverIcon
															cursor="pointer"
															color="primary"
															onClick={() =>removeFuneralHome(home.id)
															}
														/>
													)}
												</div>
											</div>
										</TabPanel>
									)
								)}
						</TabContext>
					</div>
					<div className={`${CSS.segment}`} style={{padding: "0px", border: "1px solid #C4D5D9", borderRadius: "8px", overflow: "hidden"}}>
						{currentOrgData && currentOrgData.funeralHomes && (
							<UserPanel
								funeralHomes={currentOrgData.funeralHomes}
								user={user}
								inviteUser={inviteUser}
								userDelete={userDelete}
								updateUser={updateUser}
								resendUserInvite={resendUserInvite}
								resendInviteResponse={resendInviteResponse}
								orgData={organization}
								orgId={orgId}
								getOrganization={getOrganization}
								getFuneralHomes={getFuneralHomes}
								userInviteResponseData={userInviteResponseData}
								initialStateVal={initialStateVal}
							/>
						)}
					</div>
				</section>
			</div>
			{currentOrgData && (
				<Modal open={deleteConfirmationModal.state !== "close"}>
					<div className={"modalPopup2Wrapper"}>
						<div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
							<div className={"modalPopupTitle"}>
								{t("pages.admin_create_org.view_org.delete_org_modal.title")}{" "}
								{deleteConfirmationModal.name}
							</div>
							<div className={"modalPopupCloseBtn"}>
								<CloseIcon
									className={"modalPopupCloseIcon"}
									onClick={() => {
										setDeleteConfirmationModal({
											state: "close",
										});
									}}
								/>
							</div>
						</div>
						<div className={"modalPopupBody"} style={{padding: "0px 24px 16px"}}>
							{deleteConfirmationModal.deleteOrg ? (
								<div className={"modalBody"}>
									{t("pages.admin_create_org.view_org.delete_org_modal.body1")}{" "}
									<span style={{ fontWeight: "bold" }}>
										{currentOrgData.name}
									</span>
									.{" "}
									{t("pages.admin_create_org.view_org.delete_org_modal.body2")}
									<div>
										<span style={{ color: "#AE0000" }}>
											{t("pages.admin_create_org.view_org.delete_org_modal.body_warn")}
										</span>
									</div>
								</div>
							) : (
								<div className={"modalBody"}>
									{t("pages.admin_create_org.view_org.delete_home_modal.body1")}
									<div>
										<span style={{ color: "#AE0000" }}>
											{t("pages.admin_create_org.view_org.delete_org_modal.body_warn")}
										</span>
									</div>
								</div>
							)}
							<div
								style={{
									marginTop: 16,
									display: "flex",
									justifyContent: "space-between",
								}}>
								<button className={`${CSS.actionBtn} ${CSS.primary}`}
									onClick={() => {setDeleteConfirmationModal({state: "close"})}}>
									{t("pages.admin_create_org.view_org.delete_home_modal.cancel")}
								</button>
								<button className={`${CSS.actionBtn} ${CSS.error}`}
									onClick={() => {deleteConfirmation(deleteConfirmationModal.homeId)}}>
									{deleteConfirmationModal.deleteOrg? "Delete Organization": "Delete Funeral Home"}
								</button>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};

const UserPanel = ({ funeralHomes, user, inviteUser, orgData, updateUser, resendUserInvite, resendInviteResponse, 
	userDelete, orgId, getOrganization, userInviteResponseData, initialStateVal }) => {
	const { t } = useTranslation();
	const [modalState, setModalState] = useState({
		state: "close",
	});
	const [userFuneralHomes, setUserFuneralHomes] = useState([]);
	const [userEmail, setUserEmail] = useState("");
	const [userValid, setUserValid] = useState(false);
	const [errorStates, setErrorStates] = useState({
		userEmail: ""
	});
	const [userArray, setUserArray] = useState([]);
	const [deleteUserConfirmationModal,setUserDeleteConfirmationModal ] = useState({
        state: "close",
    });
	const [roleType, setRoleType] = useState("");
	const [userRole, setUserRole] = useState([]);
	const [checkUserValid, setCheckUserValid] = useState(false);
	const [resendInviteUserId, setResendInviteUserId] = useState("");
	const classes = useStyles();
	useEffect(() => {
	  
	}, [userRole, modalState, checkUserValid, orgData, resendInviteUserId]);
	useEffect(() => {
		if(user.user?.role === "super_admin" || user.user?.role === "admin" || user.user?.role === "editor"){
			const roleList = [
				{
					id: 1,
					label: t("pages.admin_create_org.user_mgmt.add_modal.roles.editor_r"),
				},
				{
					id: 2,
					label: t("pages.admin_create_org.user_mgmt.add_modal.roles.editor"),
				}
			];
			setUserRole(roleList);
		}
		else{
			const roleList = [
				{
					id: 1,
					label: t("pages.admin_create_org.user_mgmt.add_modal.roles.editor_r"),
				},
			];
			setUserRole(roleList);
		}
	}, [user]);
	useEffect(() => {
		
	}, [userInviteResponseData]);
	const handleRoleType = (e) => {
		setRoleType(e.target.value);
	};
	const handleUserEmail = (e) => {
		setUserEmail(e.target.value);
	};
	const handleOpen = () => {
		setModalState({
			state: "add",
		});
		resetUserModal();
	};
	const handleClose = async () => {
		setModalState({
			state: "close",
		});
		resetUserModal();
		await initialStateVal();
	};
	useEffect(() => {
		var userData = [];
		let tempInvitedUserData = [];
		userData = orgData.funeralHomes.filter((home) => home.users.length > 0);
		var tempUserData = [];
		tempUserData.push(
			userData.map((home) => home.name),
			userData.map((home) =>
				home.users.map(function (user) {
					return user.email;
				})
			),
			userData.map((home) => home.id),
			userData.map((home) =>
				home.users.map(function (user) {
					return user.id;
				})
			),
			userData.map((home) =>
			home.users.map(function (user) {
				return user.role;
			})
			)
		);
		var x = [];
		for (var i = 0; i < tempUserData[0].length; i++) {
			x.push(tempUserData[0][i], tempUserData[1][i], tempUserData[2][i], tempUserData[3][i], tempUserData[4][i]);
		}
		var y = [];
		for (var j = 0; j < x.length; j += 5) {
			for (var k = 0; k < x[j + 1].length; k++) {
				y.push({ email: x[j + 1][k], homeName: x[j], homeId: x[j+2], userId: x[j + 3][k], role: x[j+4][k] });
			}
		}
		var outputArray = [];
		y.forEach(function (item) {
			var existing = outputArray.filter(function (v, i) {
				return v.email === item.email;
			});
			if (existing.length) {
				var existingIndex = outputArray.indexOf(existing[0]);
				outputArray[existingIndex].homeName = outputArray[
					existingIndex
				].homeName.concat(item.homeName);
				outputArray[existingIndex].homeId = outputArray[
					existingIndex
				].homeId.concat(item.homeId);
				outputArray[existingIndex].role = outputArray[
					existingIndex
				].role.concat(item.role);
			} else {
				if (typeof item.homeName === "string"){
					item.homeName = [item.homeName];
				}
				if (typeof item.homeId === "string"){
					item.homeId = [item.homeId];
				}
				outputArray.push(item);
			}
		});
		
		//invited users array
		var invitedUsersData = [];
		invitedUsersData = orgData.funeralHomes.filter((home) => home.openUserInvites?.length > 0);
		tempInvitedUserData.push(
			invitedUsersData.map((home) => home.name),
			invitedUsersData.map((home) =>
				home.openUserInvites.map(function (user) {
					return user.email;
				})
			),
			invitedUsersData.map((home) => home.id),
			invitedUsersData.map((home) =>
				home.openUserInvites.map(function (user) {
					return user.id;
				})
			),
			invitedUsersData.map((home) =>
				home.openUserInvites.map(function (userInvited) {
					return userInvited.invitationToken;
				})
			),
			invitedUsersData.map((home) =>
				home.openUserInvites.map(function (userInvited) {
					return userInvited.updatedAt;
				})
			),
		);
		var temp = [];
		for (var i = 0; i < tempInvitedUserData[0].length; i++) {
			temp.push(tempInvitedUserData[0][i], tempInvitedUserData[1][i], tempInvitedUserData[2][i], tempInvitedUserData[3][i], tempInvitedUserData[4][i], tempInvitedUserData[5][i]);
		}
		var temp1 = [];
		for (var j = 0; j < temp.length; j += 6) {
			for (var k = 0; k < temp[j + 1].length; k++) {
				temp1.push({ email: temp[j + 1][k], homeName: temp[j], homeId: temp[j+2], userId: temp[j + 3][k], inviteToken: temp[j+4][k], updatedTime: temp[j+5][k] });
			}
		}
		var invitedUsersOutputArray = [];
		temp1.forEach(function (item) {
			var existing = invitedUsersOutputArray.filter(function (v, i) {
				return v.email === item.email;
			});
			if (existing.length) {
				var existingIndex = invitedUsersOutputArray.indexOf(existing[0]);
				invitedUsersOutputArray[existingIndex].homeName = invitedUsersOutputArray[
					existingIndex
				].homeName.concat(item.homeName);
				invitedUsersOutputArray[existingIndex].homeId = invitedUsersOutputArray[
					existingIndex
				].homeId.concat(item.homeId);
				invitedUsersOutputArray[existingIndex].invitationToken = invitedUsersOutputArray[
					existingIndex
				].inviteToken.concat(item.inviteToken);
				invitedUsersOutputArray[existingIndex].updatedAt = invitedUsersOutputArray[
					existingIndex
				].inviteToken.concat(item.updatedTime);
			} else {
				if (typeof item.homeName === "string"){
					item.homeName = [item.homeName];
				}
				if (typeof item.homeId === "string"){
					item.homeId = [item.homeId];
				}
				invitedUsersOutputArray.push(item);
			}
		});
		outputArray = outputArray.concat(invitedUsersOutputArray)
		setUserArray(outputArray);
	}, []);	
	useEffect(() => {
	  
	}, [userArray]);
	useEffect(() => {
		if(user.user?.role==="editor" || user.user?.role==="editor_restricted"){
			setUserFuneralHomes([...funeralHomes]);
		}
	}, [funeralHomes]);	
	useEffect(() => {
		if(validator.isEmail(userEmail) || !userEmail){
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail:""
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail: t("pages.admin_create_org.add_org.email_validation")
				}
			})
		}
		if(userArray.length>0){
			userArray.map(arr => {
				if(arr.email.toLocaleLowerCase() === userEmail && modalState.state!=="edit"){
					setErrorStates(prev=>{
						return {
							...prev,
							userEmail: t("pages.admin_create_org.add_org.email_exist_validation")

						}
					})
				}
			})
		}
	},[userEmail]);
	const convertDateToDateOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("YYYY-MM-DD");
		}
		return "";
	};

	const convertDateToTimeOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("hh:mm A");
		}
		return "";
	};
	const addUser = async () => {
		var roleLabel ="";
		if(roleType === "Editor"){
			roleLabel="editor";
		}
		else{
			roleLabel="editor_restricted";
		}
		if (checkValidation()) {
			const userInvitePayload = {
				userInvite: {
					email: userEmail,
					role: roleLabel,
					funeralHomeIds: [
						...userFuneralHomes
							.map((x) => {
								if (x.isChecked) {
									return x.id;
								} else {
									return null;
								}
							})
							.filter((x) => x != null),
					],
				},
			};
			await inviteUser(userInvitePayload);
			await getOrganization(orgId);
		}
	};
	const userUpdate = async (userId) => {
		var roleLabel ="";
		if(roleType === "Editor"){
			roleLabel="editor";
		}
		else{
			roleLabel="editor_restricted";
		}
		if (checkValidation()) {
			const userUpdatePayload = {
				user: {
					email: userEmail,
					role: roleLabel,
				},
				funeralHomeIds: [
					...userFuneralHomes
						.map((x) => {
							if (x.isChecked) {
								return x.id;
							} else {
								return null;
							}
						})
						.filter((x) => x != null),
				],
			};
			await updateUser(userUpdatePayload, userId).then(()=>{
				getOrganization(orgId);
			});
		}
	};
	const editUser = (userId) => {
		initialStateVal();
		setModalState({
			state: "edit",
			id: userId,
		});
		const user1 = userArray?.find((x) => x.userId === userId);
		setUserEmail(user1.email);
		if(user1.role === "editor"){
			setRoleType("Editor");
		}
		else{
			setRoleType("Editor-Restricted");
		}
		setUserFuneralHomes(
			funeralHomes
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						isChecked: user1.homeId?.find(
							(home) => home === x.id
						),
					};
				})
				.filter((x) => x != null)
		);
	};
	const resendUserInvitation = async (id) => {
		setResendInviteUserId(id);
		await resendUserInvite(id);
	}
	useEffect(() => {
		checkValidation();
		checkUserValidation();
	}, [userEmail, userFuneralHomes, errorStates, roleType, modalState, user]);
	const checkValidation = () => {
		if (
			userEmail &&
			userFuneralHomes.filter((x) => x.isChecked).length > 0 &&
			!errorStates.userEmail &&
			roleType
		) {
			setUserValid(true);
			return true;
		} else {
			setUserValid(false);
			return false;
		}
	};
	const checkUserValidation = () => {
		if(user.user?.role==="editor_restricted" && modalState.state === "edit"){
			setCheckUserValid(false);
		}
		else{
			setCheckUserValid(true);
		}
	};
	const resetUserModal = () => {
		setUserEmail("");
		setUserFuneralHomes(
			funeralHomes.map((x) => {
				return {
					id: x.id,
					name: x.name,
					isChecked: false,
				};
			})
		);
		setRoleType("");
	};
	const funeralHomeToggled = (id) => {
		setUserFuneralHomes((prev) => {
			const home = prev.find((x) => x.id === id);
			if (home) {
				home.isChecked = !home.isChecked;
			}
			return [...prev];
		});
		if(userFuneralHomes?.filter((x) => x.isChecked).length === userFuneralHomes?.length){
			if(userFuneralHomes?.length === 1){
				setRoleType(userRole[0].label);
			}
			else{
				if(roleType === "" || roleType === "Editor-Restricted"){
					setRoleType(userRole[1].label);
				}
			}
		}else{
			setRoleType("");
		}
	};
	const removeUser = (userId) => {
        const orgUser = userArray?.find(x => x.userId === userId);
        if(orgUser){
            setUserDeleteConfirmationModal({
                state: "open",
				userId: orgUser.userId,
				email: orgUser.email
            });
        }
    }
	const deleteUserConfirmation = async(userId) => {
		userDelete(userId).then(() => {
			getOrganization(orgId);
		})
		setUserDeleteConfirmationModal({
            state: 'close'
        });
	}
	const columns = React.useMemo(() => [
		{
			id: "editUser",
			Header: "",
			accessor: (org, row) => {
				if(!org.inviteToken){
					if(user.user?.role === "editor_restricted"){
						if (org?.role === "editor_restricted") {
							const id = row;
							return <EditIcon cursor="pointer" color="primary" onClick={()=>{editUser && editUser(userArray[id].userId)}}/>;
						}
					}
					else if(user.user?.role === "editor"){
						if (org?.role === "editor_restricted" || org?.role === "editor") {
							const id = row;
							return <EditIcon cursor="pointer" color="primary" onClick={()=>{editUser && editUser(userArray[id].userId)}}/>;
						}
					}
					else{
						if (org) {
							const id = row;
							return <EditIcon cursor="pointer" color="primary" onClick={()=>{editUser && editUser(userArray[id].userId)}}/>;
						}
					}
				}
			},
			disableSortBy: true,
			disableGlobalFilter: true,
			minWidth: 48,
			width: 48,
			borderRadius: 0,
		},
		{
			Header: t("pages.admin_create_org.view_org.add_modal.email"),
			accessor: (org) => {
				return (<div className={CSS.topHeaderData}>{org.email}</div>);
			},
			minWidth: 200,
			width: 200,
			disableSortBy: true,
			disableGlobalFilter: true,
			sortType: (rowA, rowB) => {
				if (
					rowA.original.name.toLocaleLowerCase() <
					rowB.original.name.toLocaleLowerCase()
				)
					return -1;
				if (
					rowA.original.name.toLocaleLowerCase() >
					rowB.original.name.toLocaleLowerCase()
				)
					return 1;
				return 0;
			},
		},
		{
			Header: t("pages.admin_create_org.view_org.add_modal.site_access"),
			accessor: (org) => {
				return (
					org &&
					org.homeName.map((home) => {
						return <div className={CSS.topHeaderData} key={Math.random()}>{home}</div>;
					})
				);
			},
			minWidth: 200,
			width: 200,
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			Header: "status",
			accessor: (org) => {
				if(!org.inviteToken){
					return <div className={CSS.topHeaderDataActiveStatus}>Active</div>
				}
				else{
					return <div className={CSS.topHeaderDataInactiveStatus}>Inactive</div>
				}
			},
			minWidth: 200,
			width: 200,
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			Header: t("pages.admin_create_org.view_org.add_modal.resend_email"),
			accessor: (org) => {
				if(org.inviteToken){
					return(
						<div>
							<button className={`${CSS.actionBtn}  ${CSS.primary}`} onClick={() => resendUserInvitation(org.userId)}>{t("pages.admin_create_org.view_org.add_modal.resend_email")}</button>
							{(resendInviteResponse === "ERROR" && resendInviteUserId === org.userId) ?
									<div className={CSS.errorText} style={{marginTop : "0px", position: "relative", marginLeft: "0px"}}>
										{t("pages.admin_create_org.view_org.add_modal.email_sent_error")}
									</div>
								:
								(resendInviteResponse === "REQUEST" && resendInviteUserId === org.userId) ?
									<div className={CSS.errorText} style={{marginTop : "0px", position: "relative", marginLeft: "0px", color: "#167434"}}>
										{t("pages.admin_create_org.view_org.add_modal.sending_invite")}...
									</div>
								:
								<div>{t("pages.admin_create_org.view_org.add_modal.invited_on")}{" "} {convertDateToDateOnly(org.updatedTime)} at {convertDateToTimeOnly(org.updatedTime)}</div>
							}
						</div>
					)
				}
			},
			minWidth: 200,
			width: 200,
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			id: "deleteUser",
			Header: "",
			accessor: (org,row) => {
				if (
					((user.user && user.user?.role === "super_admin") ||
						(user.user && user.user?.role === "admin") || (user.user && user.user?.role === "editor")) &&
					org && !org.inviteToken
				) {
					const id = row;
					return (
						<DeleteForeverIcon
							cursor="pointer"
							color="primary"
							className={CSS.primaryIcon}
							onClick={()=> {
								removeUser && removeUser(userArray[id]?.userId)
							}}
						/>
					);
				}
			},
			minWidth: 48,
			width: 48,
			borderRadius: 0,
			disableSortBy: true,
			disableGlobalFilter: true,
		},
	]);
	const userModal = ({ state, id }) => (
		<div className={"modalPopup2Wrapper"}>
			<div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
				<div className={"modalPopupTitle"}>
					{modalState.state === "add" ? t("pages.admin_create_org.view_org.add_modal.title") : t("pages.admin_create_org.view_org.user_modal.update_user")}
				</div>
				<div className={"modalPopupCloseBtn"} style={{paddingLeft: "16px"}}>
					<CloseIcon
						className={"modalPopupCloseIcon"}
						onClick={handleClose}
					/>
				</div>
			</div>
			<div className={"modalPopupBody"} style={{padding: "16px 24px"}}>
				<div className={CSS.elementWrapper}>
					<label className={CSS.elementLabel}>
						{t("pages.admin_create_org.view_org.add_modal.email")}
					</label>
					<TextField
						className={errorStates.userEmail? `${CSS.inputEleError} ${CSS.size_l}` :`${CSS.inputEle} ${CSS.size_l}`}
						style={{ width: "365px" }}
						variant="outlined"
						value={userEmail}
						onChange={(e) => {
							handleUserEmail(e);
						}}
					/>
					{modalState.state === "edit" && !errorStates.userEmail ? <div className={CSS.hintMsg}>{t("pages.admin_create_org.view_org.add_modal.new_email_hint")}</div> :
					<div className={CSS.errorText}>{errorStates.userEmail}</div>}
				</div>
				{checkUserValid &&
					<div>
						<div className={CSS.elementWrapper}>
							<label className={CSS.elementLabel}>
								{t(
									"pages.admin_create_org.view_org.add_modal.site_access"
								)}
							</label>
							<div className={CSS.inputSet}>
								{userFuneralHomes &&
									userFuneralHomes.map((home) => (
										<FormControlLabel
											key={home.id}
											control={
												<Checkbox
													checked={home.isChecked}
													onChange={() => {
														funeralHomeToggled(home.id);
													}}
													color="primary"
												/>
											}
											label={home.name}
										/>
									))}
							</div>
						</div>
						<div className={CSS.elementWrapper}>
							<label className={CSS.elementLabel}>{t("pages.admin_create_org.user_mgmt.add_modal.role")}</label>
							<FormControl
								variant="outlined"
								className={`${CSS.inputEle} ${CSS.size_l}`}>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={roleType}
									onChange={handleRoleType}
									MenuProps={{
										disableScrollLock: true,
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
											},
										getContentAnchorEl: null,
										MenuListProps: {
											disablePadding: true
										}
										}}
									classes={{ root: classes.selectRoot }}										  
									inputProps={{
										classes: {
											root: classes.border,
											icon: classes.icon,
										},
									}}
									IconComponent={ExpandMoreOutlinedIcon}>
									{userRole.length>0 && 
									userRole?.map((role) => {
										return (
											<MenuItem
												key={role.id}
												value={role.label}
												classes={{ selected: classes.selected, root: classes.rootMenuItem }}
												disabled={userFuneralHomes.filter((x) => x.isChecked).length === userFuneralHomes.length && role.label === "Editor-Restricted" && userFuneralHomes?.length>1}>
												{role.label}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					</div>}
				<div style={{ float: "right", marginTop: "32px", paddingBottom: "32px" }}>
					{state === "edit" ? (
						<button className={`${CSS.actionBtn}  ${CSS.warning} ${userValid ? "" : CSS.disabled}`}
							onClick={() => {userUpdate(id);}}>
							{t("pages.admin_create_org.view_org.user_modal.update_user")}
						</button>
					) : (
						<button className={`${CSS.actionBtn}  ${CSS.warning} ${userValid ? "" : CSS.disabled}`}
							onClick={addUser}>
							{t("pages.admin_create_org.view_org.add_modal.title")}
						</button>
					)}
				</div>
				{
					userInviteResponseData === "ERROR" ?
						<div className={CSS.errorText}>{t("pages.admin_create_org.view_org.add_modal.user_invite_issue")}</div>
					: userInviteResponseData === "REQUEST" ? 
						<Spinner invitedUsr = {true} />
					: userInviteResponseData === "SUCCESS" ?
						<div className={CSS.successText}>{t("pages.admin_create_org.view_org.add_modal.email_sent_success")}</div>
					: ""
				}
			</div>
		</div>
	);
	return (
		<div className={CSS.usersPanel}>
			<UserTable
				editUser={editUser}
				columns={columns}
				data={userArray}
				setModalState={setModalState}
				resetUserModal={resetUserModal}
				handleOpen={handleOpen}
				resendInviteResponse={resendInviteResponse}
			/>
			<Modal
				open={modalState.state !== "close"}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description">
				{userModal(modalState)}
			</Modal>
			<Modal
            open={deleteUserConfirmationModal.state !== 'close'}>
                <div className={"modalPopup2Wrapper"} style={{width: "420px"}}>
                    <div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
                        <div className={"modalPopupTitle"}>
							Delete {deleteUserConfirmationModal.email}?
                        </div>
                        <div className={"modalPopupCloseBtn"}>
                            <CloseIcon
                            className={"modalPopupCloseIcon"}
                            onClick={()=>{ setUserDeleteConfirmationModal({
                                state: 'close'
                            })}}
                            />
                        </div>
                    </div>
                    <div className={"modalPopupBody"} style={{padding: "0px 24px 16px"}}>
						<div className={"modalBody"}>
							{deleteUserConfirmationModal.email} {t("pages.admin_create_org.add_org.user_no_longer_exist")}
							<div style={{marginTop: "32px"}}><span>{t("pages.admin_create_org.add_org.deleting_user")}{" "} <span style={{fontWeight: "800"}}>{t("pages.admin_create_org.add_org.cannot_undone")}</span></span></div>
						</div>
                        <div style={{marginTop: 16, display:'flex',justifyContent: 'space-between'}}>
                            <button  className={`${CSS.actionBtn} ${CSS.primary}`} onClick={()=>{ setUserDeleteConfirmationModal({
                                state: 'close'
                            })}}>{t('pages.admin_create_org.view_org.delete_home_modal.cancel')}</button>                     
                            <button  className={`${CSS.actionBtn} ${CSS.error}`}  onClick={()=> { deleteUserConfirmation(deleteUserConfirmationModal.userId) }}>{t("pages.admin_create_org.add_org.delete_user")}</button>
                        </div>
                    </div>
                </div>
            </Modal>
		</div>
	);
};
const OrgFilter = ({ globalFilter, setGlobalFilter, handleOpen }) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(globalFilter);
	const [filterData, setFilterData] = useState({
		filterValue: "",
	});
	const onChange = (value) => {
		setFilterData((prev) => {
			const newFilter = {
				...prev,
				filterValue: value,
			};
			setGlobalFilter(newFilter);
			return newFilter;
		});
	};

	return (
		<div className={CSS.headerRow} style={{margin: "16px 16px 0px 16px"}}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div className={CSS.headerName}>{t("pages.admin_create_org.table.users_column")}</div>
				<Input
					className={CSS.filterWrapper}
					iconPosition="left"
					placeholder={t("pages.events_calendar.search_placeholder")}>
					<Icon name="search" style={{ color: "#00667E" }} />
					<input
						className={CSS.filterInput}
						value={value || ""}
						onChange={(e) => {
							setValue(e.target.value);
							onChange(e.target.value);
						}}
					/>
				</Input>
			</div>
			<button
				className={`${CSS.actionBtn} ${CSS.warning}`}
				onClick={handleOpen}>
				{t("pages.admin_create_org.view_org.add_modal.title")}
			</button>
		</div>
	);
};

const UserTable = ({
	columns,
	editUser,
	setModalState,
	data,
	resetUserModal,
	filterInputValue,
	handleOpen,
	resendInviteResponse
}) => {
	const { t } = useTranslation();
	const [hoveredRow, setHoveredRow] = useState(null);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		state,
		setGlobalFilter,
		setFilter,
	} = useTable(
		{
			columns,
			data,
			globalFilter: (rows, columnIds, globalFilterData) => {
				return rows.filter((row) => {
					const event = row.original;
					let { filterValue } = globalFilterData;
					if (!(filterValue && filterValue.trim())) {
						return true;
					}
					filterValue = filterValue.trim().toLowerCase();
					return (
						event.email.toLowerCase().includes(filterValue) ||
						`${event.email.toLowerCase()}`.includes(filterValue)
					);
				});
			},
			initialState: { pageIndex: 0 },
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded
	);
	return (
		<Fragment>
			<OrgFilter
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
				setModalState={setModalState}
				resetUserModal={resetUserModal}
				handleOpen={handleOpen}
			/>
			<Table className={CSS.table} structured {...getTableProps()} style={{border : "none"}}>
				<Table.Header>
					{headerGroups.map((headerGroup) => (
						<Table.Row {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Table.HeaderCell
									// width={2}
									className={CSS1.headerCell}
									{...column.getHeaderProps({
										...column.getSortByToggleProps(),
										style: {
											minWidth: column.minWidth,
											width: column.width,
											borderRadius: column.borderRadius
										},
									})}>
									{column.render("Header")}
									<span>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<Icon name="caret down" />) : (
												<Icon name="caret up" />)) : ("")}
									</span>
								</Table.HeaderCell>
							))}
						</Table.Row>
					))}
				</Table.Header>
				<Table.Body {...getTableBodyProps()}>
					{rows.length === 0 &&
						((state.filters.length > 0 || state.globalFilter) && data.length>0) && (
							<Table.Row>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell
									style={{
										padding: "50px 0 50px 0",
										fontStyle: "italic",
									}}>
									{t("pages.admin_create_org.table.filter_no_results")}
								</Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
						)}
					{rows.map((row, i) => {
						prepareRow(row);
						const rowProps = row.getRowProps();
						return (
							<React.Fragment key={rowProps.key}>
								<Table.Row {...rowProps}
								onMouseEnter={()=> setHoveredRow(rowProps.key)}
								onMouseLeave={() => setHoveredRow(null)}
								style={{ background: rowProps.key === hoveredRow ? '#D6FFD6' : 'white', }}>
									{row.cells.map((cell) => {
										return (
											<Table.Cell
												{...cell.getCellProps()}>
												{cell.render("Cell")}
											</Table.Cell>
										);
									})}
								</Table.Row>
							</React.Fragment>
						);
					})}
				</Table.Body>
			</Table>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	organization: state.organization.organization,
	funeralHomes: state.funeralHome.funeralHomes,
	user: state.user.currentUser,
	userInviteResponseData: state.user.userInviteResponse,
	userUpdated: state.user.userUpdated,
	resendInviteResponse: state.user.resendUserInviteResponse
});

const mapDispatchToProps = {
	getOrganization,
	getFuneralHomes,
	deleteFuneralHome,
	deleteOrganization,
	inviteUser,
	updateUser,
	userDelete,
	initialStateVal,
	resendUserInvite
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrganizationManagementEdit);
