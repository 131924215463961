import axiosNonConfigured from 'axios';
import axios from '../../utils/axios';
import camelcaseKeys from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";

const GET_FUNERAL_HOMES_REQUEST = 'GET_FUNERAL_HOMES_REQUEST';
export const GET_FUNERAL_HOMES_SUCCESS = 'GET_FUNERAL_HOMES_SUCCESS';
export const GET_FUNERAL_HOMES_FAILURE = 'GET_FUNERAL_HOMES_FAILURE';

const GET_FUNERAL_HOME_REQUEST = 'GET_FUNERAL_HOME_REQUEST';
export const GET_FUNERAL_HOME_SUCCESS = 'GET_FUNERAL_HOME_SUCCESS';
export const GET_FUNERAL_HOME_FAILURE = 'GET_FUNERAL_HOME_FAILURE';

const CREATE_FUNERAL_HOME_REQUEST = 'CREATE_FUNERAL_HOME_REQUEST';
export const CREATE_FUNERAL_HOME_SUCCESS = 'CREATE_FUNERAL_HOME_SUCCESS';
export const CREATE_FUNERAL_HOME_FAILURE = 'CREATE_FUNERAL_HOME_FAILURE';

const UPDATE_FUNERAL_HOME_REQUEST = 'UPDATE_FUNERAL_HOME_REQUEST';
export const UPDATE_FUNERAL_HOME_SUCCESS = 'UPDATE_FUNERAL_HOME_SUCCESS';
export const UPDATE_FUNERAL_HOME_FAILURE = 'UPDATE_FUNERAL_HOME_FAILURE';

const DELETE_FUNERAL_HOME_REQUEST = 'DELETE_FUNERAL_HOME_REQUEST';
export const DELETE_FUNERAL_HOME_SUCCESS = 'DELETE_FUNERAL_HOME_SUCCESS';
export const DELETE_FUNERAL_HOME_FAILURE = 'DELETE_FUNERAL_HOME_FAILURE';

const PRESIGN_IMAGE_REQUEST = 'PRESIGN_IMAGE_REQUEST';
export const PRESIGN_IMAGE_SUCCESS = 'PRESIGN_IMAGE_SUCCESS';
export const PRESIGN_IMAGE_FAILURE = 'PRESIGN_IMAGE_FAILURE';

const POST_REDEEM_POINTS = 'POST_REDEEM_POINTS';
export const POST_REDEEM_POINTS_SUCCESS = 'POST_REDEEM_POINTS_SUCCESS';
export const POST_REDEEM_POINTS_FAILURE = 'POST_REDEEM_POINTS_FAILURE';

const GET_TEST_STREAM_REQUEST = 'GET_TEST_STREAM_REQUEST';
export const GET_TEST_STREAM_SUCCESS = 'GET_TEST_STREAM_SUCCESS';
export const GET_TEST_STREAM_FAILURE = 'GET_TEST_STREAM_FAILURE';

const POST_TEST_STREAM_REQUEST = 'POST_TEST_STREAM_REQUEST';
export const POST_TEST_STREAM_SUCCESS = 'POST_TEST_STREAM_SUCCESS';
export const POST_TEST_STREAM_FAILURE = 'POST_TEST_STREAM_FAILURE';

const REMOVE_HOME_IMAGE_REQUEST = "REMOVE_HOME_IMAGE_REQUEST";
export const REMOVE_HOME_IMAGE_SUCCESS = "REMOVE_HOME_IMAGE_SUCCESS";
export const REMOVE_HOME_IMAGE_FAILURE = "REMOVE_HOME_IMAGE_FAILURE";

export const getFuneralHomes = () => {
  return dispatch => {
    dispatch({ type: GET_FUNERAL_HOMES_REQUEST });
    return axios.get('api/funeral_homes', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const funeralHomes = camelcaseKeys(response.data).funeralHomes;
        dispatch(getFuneralHomesSuccess(funeralHomes));
      })
      .catch(error => dispatch(getFuneralHomesFailure(error)));
  }
};

const getFuneralHomesSuccess = (funeralHomes) => {
  return {
    type: GET_FUNERAL_HOMES_SUCCESS,
    funeralHomes,
  }
};

const getFuneralHomesFailure = (error) => {
  return {
    type: GET_FUNERAL_HOMES_FAILURE,
    error,
  }
};

export const getFuneralTestStream = (id) => {
  return dispatch => {
    dispatch({ type: GET_TEST_STREAM_REQUEST });
    
    return axios.get(`api/camera_tests/${id}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const streamSuccessData = camelcaseKeys(response.data).testDetails;
        dispatch(getFuneralTestStreamSuccess(streamSuccessData));
      })
      .catch(error => dispatch(getFuneralTestStreamFailure(error)));
  }
};

const getFuneralTestStreamSuccess = (streams) => {
  return {
    type: GET_TEST_STREAM_SUCCESS,
    streams,
  }
};

const getFuneralTestStreamFailure = (error) => {
  return {
    type: GET_TEST_STREAM_FAILURE,
    error,
  }
};

export const getFuneralHome = (id, useInternalApiKey) => {
  const token = useInternalApiKey ? (process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc') : localStorage.getItem("token");
  return dispatch => {
    dispatch({ type: GET_FUNERAL_HOME_REQUEST });
    
    return axios.get(`api/funeral_homes/${id}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        const funeralHome = camelcaseKeys(response.data).funeralHome;
        dispatch(getFuneralHomeSuccess(funeralHome));
      })
      .catch(error => dispatch(getFuneralHomeFailure(error)));
  }
};

const getFuneralHomeSuccess = (funeralHome) => {
  return {
    type: GET_FUNERAL_HOME_SUCCESS,
    funeralHome,
  }
};

const getFuneralHomeFailure = (error) => {
  return {
    type: GET_FUNERAL_HOME_FAILURE,
    error,
  }
};

export const postImageWithSignedUrl = (file, checksum) => {
  return dispatch => {
    dispatch({ type: PRESIGN_IMAGE_REQUEST });
    const data = {
      file: {
        filename: file.name,
        byte_size: file.size,
        checksum: checksum,
        content_type: 'application/image',
        metadata: {
          'message': 'Funeral Image'
        }
      }
    };

    return axios.post('api/presigned_url', data, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const responseData = response.data;
      return axiosNonConfigured.put(responseData.direct_upload.url, file, {
        headers: {
          ...responseData.direct_upload.headers
        }
      }).then((s3UploadResponse) => {
        dispatch(uploadFuneralHomeImageSuccess(responseData.blob_signed_id));
      }).catch(error => {
        console.log("failed to upload image");
      });
    })
    .catch(error => {
      console.log("error response::: ", error);
      // dispatch(createFuneralHomeFailure(error.response.data.message));
    });
  }
}

const uploadFuneralHomeImageSuccess = (blobSignedId) => {
  return {
    type: PRESIGN_IMAGE_SUCCESS,
    blobSignedId,
  }
};

export const createFuneralHome = (funeralHome) => {
  return dispatch => {
    dispatch({ type: CREATE_FUNERAL_HOME_REQUEST });
    const data = snakeCaseKeys(funeralHome);
    return axios.post('api/funeral_homes', data, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const funeralHome = camelcaseKeys(response.data).funeralHome;
      dispatch(createFuneralHomeSuccess(funeralHome));
    })
    .catch(error => {
      if( error.response ){
        dispatch(createFuneralHomeFailure(error.response.data.message));
      } else {
        dispatch(createFuneralHomeFailure("something went wrong"));
      }
    });
  }
};

const createFuneralHomeSuccess = (funeralHome) => {
  return {
    type: CREATE_FUNERAL_HOME_SUCCESS,
    funeralHome,
  }
};

const createFuneralHomeFailure = (message) => {
  return {
    type: CREATE_FUNERAL_HOME_FAILURE,
    message,
  }
};

export const updateFuneralHome = (funeralHome, funeralHomeId) => {
  return dispatch => {
    dispatch({ type: UPDATE_FUNERAL_HOME_REQUEST });
    const data = snakeCaseKeys(funeralHome);
    return axios.put(`api/funeral_homes/${funeralHomeId}`, data, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const funeralHome = camelcaseKeys(response.data).funeralHome;
      dispatch(updateFuneralHomeSuccess(funeralHome));
    })
    .catch(error => {
      if( error.response ){
        dispatch(updateFuneralHomeFailure(error.response.data.message));
      } else {
        dispatch(updateFuneralHomeFailure("something went wrong"));
      }
    });
  }
};

const updateFuneralHomeSuccess = (funeralHome) => {
  return {
    type: UPDATE_FUNERAL_HOME_SUCCESS,
    funeralHome,
  }
};

const updateFuneralHomeFailure = (message) => {
  return {
    type: UPDATE_FUNERAL_HOME_FAILURE,
    message,
  }
};

export const deleteFuneralHome = (funeralHomeId) => {
  return dispatch => {
    dispatch({ type: DELETE_FUNERAL_HOME_REQUEST });

    return axios.delete(`api/funeral_homes/${funeralHomeId}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      dispatch(deleteFuneralHomeSuccess(funeralHomeId));
      return response;
    })
    .catch(error => {
      if( error.response ){
        dispatch(deleteFuneralHomeFailure(error.response.data.message));
      } else {
        dispatch(deleteFuneralHomeFailure("something went wrong"));
      }
      return error;
    });
  }
};

const deleteFuneralHomeSuccess = (funeralHomeId) => {
  return {
    type: DELETE_FUNERAL_HOME_SUCCESS,
    funeralHomeId,
  }
};

const deleteFuneralHomeFailure = (message) => {
  return {
    type: DELETE_FUNERAL_HOME_FAILURE,
    message,
  }
};

export const postTestStreamData = (payload) => {
  return dispatch => {
    dispatch({ type: POST_TEST_STREAM_REQUEST });
    return axios.post(`api/camera_tests`, snakeCaseKeys(payload), {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const streamData = camelcaseKeys(response.data).testDetails;
        dispatch(postTestStreamDataSuccess(streamData));
        return response;
      })
      .catch(error => {
				if( error.response ){
				  dispatch(postTestStreamDataFailure(error.response.data));
				} else {
				  dispatch(postTestStreamDataFailure("something went wrong"));
				}
			  });
  }
}
export const postTestStreamDataSuccess = (newStream) => {
  return {
    type: POST_TEST_STREAM_SUCCESS,
    newStream
  }
}
const postTestStreamDataFailure = (error) => {
  return {
    type: POST_TEST_STREAM_FAILURE,
    error,
  }
};

export const postRedeemPoints = (homeId, redeemPoints) => {
  return dispatch => {
    dispatch({ type: POST_REDEEM_POINTS });
    return axios.post(`api/funeral_homes/${homeId}/redeem_points/${redeemPoints}`, null, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const pointsData = camelcaseKeys(response.data);
        dispatch(postRedeemPointsSuccess(pointsData));
        return response;
      })
      .catch(error => {
				if( error.response ){
				  dispatch(postRedeemPointsFailure(error.response.data));
				} else {
				  dispatch(postRedeemPointsFailure("something went wrong"));
				}
			  });
  }
}
export const postRedeemPointsSuccess = (updatedPoints) => {
  return {
    type: POST_REDEEM_POINTS_SUCCESS,
    updatedPoints
  }
}
const postRedeemPointsFailure = (error) => {
  return {
    type: POST_REDEEM_POINTS_FAILURE,
    error,
  }
};

export const removeLogo = (id) => {
	return (dispatch) => {
		dispatch({ type: REMOVE_HOME_IMAGE_REQUEST });
		return axios
			.post(`api/funeral_homes/${id}/remove_logo`, null, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const funeralData = camelcaseKeys(response.data);
        console.log(funeralData)
				dispatch(removeLogoSuccess(funeralData));
			})
			.catch(error => {
				if( error.response ){
				  dispatch(removeLogoFailure(error.response.data.message));
				} else {
				  dispatch(removeLogoFailure("something went wrong"));
				}
			  });
	};
};

const removeLogoSuccess = (updateEventResponse) => {
	return {
		type: REMOVE_HOME_IMAGE_SUCCESS,
		updateEventResponse,
	};
};

const removeLogoFailure = (message) => {
	return {
		type: REMOVE_HOME_IMAGE_FAILURE,
		message,
	};
};