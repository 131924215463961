import React, {useState, useEffect, Fragment, useRef} from "react";
import { fileChecksum } from "../../utils/checksum";
import axios from '../../utils/axios';
import {
	postVideoWithSignedUrl,
  getFuneralStreams,
} from "../../redux/funeral/actions";
import Spinner from "../../components/Spinner";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import CSS from "./FuneralAdminPage.module.scss";

function UploadClipsToStream(props) {
  const { t } = useTranslation();
  const { postVideoWithSignedUrl, stream, clipsForStream, getFuneralStreams, percentCompleted } = props;

  const [isUploading, setIsUploading] = useState(false);
  const [isDeletingId, setIsDeletingId] = useState("");
  const [error, setError] = useState("");
  const [Url, setUrl] = useState(null);
  const [copiedUrl, setCopiedUrl] = useState("");
  const [videoBlob, setVideoBlob] = useState(null);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const handleVideoUpload = (event) => {    
    setError("");
		setUrl(null);
		setVideoBlob(null);
		setRecordedChunks([]);
		if(event.target.files[0]){
			setVideoFilePath(URL.createObjectURL(event.target.files[0]));
		}
		setVideoBlob(event.target.files[0]);
	};

  const handleUploadClipToStream = async () => {
    let clip = null;
		if (videoBlob) {
      try {
        setIsUploading(true);
      
        // const checksum = await fileChecksum(videoBlob);
        // const blobSignedId = await postVideoWithSignedUrl(videoBlob, checksum);
        // Step 1: Upload the file to the server and get the checksum
        const formData = new FormData();
        formData.append('file', videoBlob);
        const response = await axios.post('/api/checksums', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
          }
        });

        const checksum = response.data.checksum;

        // Step 2: Post video with signed URL
        const blobSignedId = await postVideoWithSignedUrl(videoBlob, checksum);

        if(blobSignedId){
          clip = blobSignedId;
          const data = {
            clip: {
              clip_url: clip,
            }
          }

          const result = await axios.post(`api/streams/${stream.id}/upload_clip`, data, {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
          });

          await getFuneralStreams(stream.funeralId, false)
        }

        setError("");
        setUrl(null);
        setVideoBlob(null);
        setIsUploading(false);
      } catch (error) {
        console.log(' error ', error);
        setIsUploading(false);
        setError("Something went wrong. Please try again.");
      }
    }
  }

  const handleDeleteStreamClip = async (clipId) => {
    try {
      setIsDeletingId(clipId);
      await axios.delete(`api/clips/${clipId}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });
  
      await getFuneralStreams(stream.funeralId, false);

      setIsDeletingId("");
    } catch (error) {
      setIsDeletingId("");
    }
  }

  return (
    <>
      <div style={{marginTop: 5}}>
        <label className={CSS.fromDevice} htmlFor={`${stream.id}-files`}>{t('pages.admin_funeral_page.link_with_url')}</label>
        <input type="text" id={`${stream.id}-url`} onChange={(event) => setCopiedUrl(event.target.value)} style={{marginRight: 10, height: 30}} />
        <button
          className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
          onClick={async () => {
            const data = {
              clip: {
                clip_url: copiedUrl,
                manual_link: true
              }
            }
  
            const result = await axios.post(`api/streams/${stream.id}/upload_clip`, data, {
              headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
              }
            });
  
            await getFuneralStreams(stream.funeralId, false);
            await setCopiedUrl("");
          }}
        >
          Upload Clip From URL
        </button>
      </div>
      <div style={{marginTop: 5}}>
        <label className={CSS.fromDevice} htmlFor={`${stream.id}-files`}>{t('pages.admin_funeral_page.choose_from_device')}</label>
        <input type="file" accept="video/*" id={`${stream.id}-files`} onChange={handleVideoUpload} onClick={(event)=> { event.target.value = null }} style={{display: "none"}} />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <p className={CSS.errorText}>{error}</p>
          {videoBlob && !error && (
            <>
              <p style={{ marginRight: 10 }}>{videoBlob?.name}</p>
              {isUploading ? (
                <div style={{ width: '100%', position: 'relative' }}>
                  <div
                    style={{
                      width: `${percentCompleted}%`,
                      height: 20,
                      backgroundColor: '#007bff',
                      position: 'absolute',
                      top: 0,
                      left: 0
                    }}
                  />
                  <span style={{ position: 'absolute', top: 25, left: '50%', transform: 'translateX(-50%)' }}>{`${percentCompleted}%`}</span>
                </div>
              ) : (
                <button
                  className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}
                  onClick={handleUploadClipToStream}
                >
                  {t('pages.admin_funeral_page.upload_clip')}
                </button>
              )}
            </>
          )}
        </div>
        <div>
          {clipsForStream.map((clip) => {
            return (
              <div key={clip.id} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <video height="150" controls>
                  <source src={clip.uploadedClipUrl} type="video/mp4" />
                  {t('pages.admin_funeral_page.browser_support_error')}
                </video>
                {isDeletingId !== clip.id && <button onClick={() => handleDeleteStreamClip(clip.id)} className={`${CSS.actionBtn} ${CSS.deleteBtn}`}>{t('pages.admin_funeral_page.delete')}</button>}
                {isDeletingId === clip.id && <Spinner spinnerSize={10} />}
              </div>
            )
          })}
          </div>
        </div>
    </>
  );
}

const mapStateToProps = (state) => ({
	percentCompleted: state.funeral.percentCompleted,
});

const mapDispatchToProps = {
	postVideoWithSignedUrl,
  getFuneralStreams
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadClipsToStream);
