import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import logo1 from "../../../src/assets/svgs/funeral-live-logo.png";
import CSS from "../streamingCredits/CheckoutPage.module.scss";
import CSS1 from "./VideoRequestPage.module.scss";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from '../../assets/svgs/favicon.ico';
import { useHistory } from 'react-router-dom';
import defaultImage from "../../assets/svgs/default_image.jpg";
import CheckoutFormData from "../../components/CheckoutFormData";
import moment from "moment";
import { useTranslation } from 'react-i18next';
const FuneralPaymentCheckoutPage = () => {
	const location = useLocation();
    const history = useHistory();
	const { t } = useTranslation();
    useEffect(() => {
	}, [location]);
    const videoInfo = location.state.deceasedFuneralData;
    const STATE=true;
    const convertDateToDateOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("MMM DD, YYYY");
		}
		return "";
	};
    const openFuneralLive = () => {
        window.open("https://funerallive.ca/");
    };
	return(
        <div>
            <div className={CSS1.navWrapper}>
                <div className={CSS1.logoWrapper}>
                    <img src={logo1} alt="Logo" onClick={openFuneralLive} />
                </div>
            </div>
            <div className={CSS.paymentForm}>
                <div className={CSS.productData}>
                    <div className={CSS.toBack}>
                        <div>
                            <ArrowBackIcon 
                                style={{
                                    color: "#00667E",
                                    marginRight: 12,
                                    marginTop: 4,
                                    cursor: "pointer"
                                }}
                                onClick={() => history.goBack()}
                            />
                        </div>
                        <div className={CSS.logo}>
                            <img src={logo} alt="logo" />
                        </div>
                        <div className={CSS.backText}>
                        {t('pages.request_video.back')}
                        </div>
                    </div>
                    <div className={CSS.payment}>
                        <div className={CSS.headerData}>
                            <div className={CSS.videoPaymentHeader} style={{marginTop: 24}}>
                            {t('pages.request_video.digital_file_title')}
                            </div>
                            <div className={CSS.deceasedLastName}>
                                {videoInfo.funeral.lastName}{" - "}{convertDateToDateOnly(videoInfo.funeral.dateOfDeath)}
                            </div>
                        </div>
                        <div className={CSS.imagePriceContainer}>
                            <div className={CSS.deceasedImg}>
                                <img src={videoInfo.funeral.deceasedImageUrl ? videoInfo.funeral.deceasedImageUrl: defaultImage } alt="img"/>
                            </div>
                            <div className={CSS.headerData}>
                                <table>
                                    <tr>
                                        <div className={CSS.deceasedLastName}>
                                        {t('pages.request_video.summary')}
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>Subtotal:</td>
                                        <td>$50</td>
                                    </tr>
                                    <tr>
                                        <td>HST (13%):</td>
                                        <td>$6.50</td>
                                    </tr>
                                    <tr>
                                        <td>Total:</td>
                                        <td>$56.50</td>
                                    </tr>
                                </table>
                                <div className={CSS.price}>
                                    $56.50 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={CSS.checkoutForm}>
                    <CheckoutFormData STATE={STATE} userPayload={location.state.visitorPayload}/>
                </div>
            </div>
        </div>
        );
};

export default FuneralPaymentCheckoutPage;
