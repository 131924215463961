import axios from 'axios';

const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return "http://localhost:4000";
  }

  return "https://api.funeralliveapp.ca";
}

const axiosInstnace = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
});

export default axiosInstnace;
