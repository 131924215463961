import React, { useState, useEffect } from "react";
import moment from "moment";
import { generateWeekViewCoordinates } from "../../utils";
import { eventHighlighter } from "../styles";
import UpdateEventModal from "./UpdateEventModal";

function EventHighlighter({ startDate, event, events, funeralHomes }) {
	const [modalId, setModalId] = useState("");
	useEffect(() => {}, [modalId]);
	const showDeceasedStreamInfo = (value) => {
		setModalId(value);
	};
	const deceasedLastName = (id) => {
		return events.map((funeral) => {
			if (funeral.id === id) {
				return funeral.lastName;
			}
		});
	};
	return (
		<>
			<React.Fragment>
				<div
					style={{
						...generateWeekViewCoordinates(event, startDate),
						...eventHighlighter,
						backgroundImage: `linear-gradient(to right, ${event.color1} 0%, ${event.color1} 3%, ${event.color2} 3%, ${event.color2} 100%)`,
					}}
					onClick={() => showDeceasedStreamInfo(event.id)}>
					<div
						style={{
							fontSize: 10,
							fontStyle: "normal",
							fontWeight: 400,
							color: "#001A20",
						}}>
						{moment(event.streamStart).format("hh:mm a")} -{" "}
						{moment(event.streamEnd).format("hh:mm a")}
					</div>
					<div
						style={{
							fontSize: 10,
							fontStyle: "normal",
							fontWeight: 600,
							color: "#001A20",
						}}>
						{deceasedLastName(event.funeralId)}-{event.name}
					</div>
				</div>
			</React.Fragment>
			{modalId ? (
				<UpdateEventModal
					stream={event}
					funerals={events}
					modalId={modalId}
					setModalId={setModalId}
					funeralHomes={funeralHomes}
				/>
			) : (
				""
			)}
		</>
	);
}

export default EventHighlighter;
