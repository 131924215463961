import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Message, Grid, Input, Form } from 'semantic-ui-react';
import { getFuneralHome, updateFuneralHome, deleteFuneralHome } from '../redux/funeralHome/actions';
import { inviteUser } from '../redux/user/actions';
import { filter, some } from 'lodash';


const renderUsers = (users, userInvites) => {
  const userList = users.map((user, index) =>
    <Table.Row key={index}>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{user.role === 'admin' ? "Admin" : "User"}</Table.Cell>
      <Table.Cell>Created</Table.Cell>
    </Table.Row>
  );

  const filteredInvites = filter(userInvites, (userInvite) => {
    return !some(users, (user) => {
      return user.email === userInvite.email;
    });
  });

  const userInviteList = filteredInvites.map((userInvite) =>
    <Table.Row key={userInvite.invitationToken}>
      <Table.Cell>{userInvite.email}</Table.Cell>
      <Table.Cell>{userInvite.role === 'admin' ? "Admin" : "User"}</Table.Cell>
      <Table.Cell>Invitation sent. <a target="_incognito" href={`https://funeral-live-admin.herokuapp.com/#/register/${userInvite.invitationToken}`}>Complete Registration here</a></Table.Cell>
    </Table.Row>
  );

  return [...userList, ...userInviteList];
}

const FuneralHomeView = (props) => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [updatedFuneralHome, setUpdatedFuneralHome] = useState({
    name: "",
    billingAddress: {
      street: "",
      city: "",
      postalCode: "",
      province: "",
      country: ""
    },
    phone: "",
    contactEmail: "",
    flowerShopLink: "",
    googleCalendarId: "",
    logo: "",
    livestreamEventImage: "",
    baseUrl: ""
  });

  useEffect(() => {
    if (props.funeralHome) {
      setUpdatedFuneralHome({
        name: props.funeralHome.name || "",
        billingAddress: {
          street: (props.funeralHome.billingAddress ? props.funeralHome.billingAddress.street : "") || "",
          city: (props.funeralHome.billingAddress ? props.funeralHome.billingAddress.city : "") || "",
          postalCode: (props.funeralHome.billingAddress ? props.funeralHome.billingAddress.postalCode : "") || "",
          province: (props.funeralHome.billingAddress ? props.funeralHome.billingAddress.province : "") || "",
          country: (props.funeralHome.billingAddress ? props.funeralHome.billingAddress.country : "") || ""
        },
        phone: props.funeralHome.phone || "",
        contactEmail: props.funeralHome.contactEmail || "",
        flowerShopLink: props.funeralHome.flowerShopLink || "",
        googleCalendarId: props.funeralHome.googleCalendarId || "",
        baseUrl: props.funeralHome.baseUrl || "",
        logo: props.funeralHome.logo,
        livestreamEventImage: props.funeralHome.livestreamEventImage,
      });
    }
  }, [props.funeralHome]);

  useEffect(() => {
    props.getFuneralHome(props.match.params.id);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateFuneralHome(updatedFuneralHome, props.match.params.id);
  };

  const handleDelete = async () => {
    const result = await props.deleteFuneralHome(props.match.params.id);
    if (result.status === 200) {
      props.history.push('/funeralhomes');
    }
  }

  if (updatedFuneralHome)
  return (
    <div style={{ padding: 50 }}>
      <h2>Update Funeral Home</h2>
      <div style={{ paddingTop: 20}}>
        <Form onSubmit={handleSubmit}>
          <Form.Input fluid label='Funeral Home Name' placeholder='Name' value={updatedFuneralHome.name} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, name: e.target.value })} required />
          <Form.Group widths='equal'>
            <Form.Input type='email' fluid label='Contact Email' placeholder='Email' value={updatedFuneralHome.contactEmail} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, contactEmail: e.target.value })} />
            <Form.Input type='phone' fluid label='Contact Phone' placeholder='Phone #' value={updatedFuneralHome.phone} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, phone: e.target.value })} />
            <Form.Input type='url' fluid label='Wordpress Site URL' placeholder='Wordpress Site URL' value={updatedFuneralHome.baseUrl} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, baseUrl: e.target.value })} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input type='url' fluid label='Flowershop Link' placeholder='Enter Url' value={updatedFuneralHome.flowerShopLink} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, flowerShopLink: e.target.value })} />
            <Form.Input fluid label='Google Calendar ID' placeholder='Copy paste from your Google account' value={updatedFuneralHome.googleCalendarId} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, googleCalendarId: e.target.value })} />
          </Form.Group>
          <Form.Input fluid label='Billing Address' placeholder='Street' value={updatedFuneralHome.billingAddress.street} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, billingAddress: { ...updatedFuneralHome.billingAddress, street: e.target.value} })} />
          <Form.Group widths='equal'>
            <Form.Input fluid placeholder='City' value={updatedFuneralHome.billingAddress.city} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, billingAddress: { ...updatedFuneralHome.billingAddress, city: e.target.value} })} />
            <Form.Input fluid placeholder='Postal Code' value={updatedFuneralHome.billingAddress.postalCode} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, billingAddress: { ...updatedFuneralHome.billingAddress, postalCode: e.target.value} })} />
            <Form.Input fluid placeholder='Province' value={updatedFuneralHome.billingAddress.province} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, billingAddress: { ...updatedFuneralHome.billingAddress, province: e.target.value} })} />
            <Form.Input fluid placeholder='Country' value={updatedFuneralHome.billingAddress.country} onChange={(e) => setUpdatedFuneralHome({ ...updatedFuneralHome, billingAddress: { ...updatedFuneralHome.billingAddress, country: e.target.value} })} />
          </Form.Group>
          <Form.Button style={{ float: 'right' }} color="black">Update</Form.Button>
        </Form>
        <div style={{ float: 'right' }}>
          <Button color="red" onClick={handleDelete}>Delete</Button>
        </div>
        <div style={{ paddingTop: 30 }}>
          <h2>Send User Invites</h2>
          { 
            ((props.funeralHome.users && props.funeralHome.users.length > 0) ||
            (props.funeralHome.userInvites && props.funeralHome.userInvites.length > 0)) &&
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {renderUsers(props.funeralHome.users, props.funeralHome.userInvites)}
                </Table.Body>
              </Table>
          }
          <Grid columns={2} style={{ paddingTop: 20 }}>
            <Grid.Row>
              <Grid.Column>
                <Input style={{ width: "100%" }} type="email" size='large' placeholder='Add user email...' value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
              </Grid.Column>
              <Grid.Column>
              <Button color="green" size='large' onClick={() => props.inviteUser(inviteEmail, props.match.params.id)}>Send Invite</Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {props.userInvite.error && 
                  <Message negative>
                    <Message.Header>{props.userInvite.message}</Message.Header>
                  </Message>
                }
                {props.userInvite.success && 
                  <Message positive>
                    <Message.Header>Invitation sent</Message.Header>
                  </Message>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </div>
  );

  return <div>Loading...</div>
};

const mapStateToProps = state => ({
  funeralHome: state.funeralHome.funeralHomeViewing,
  userInvite: state.user.userInvite
});

const mapDispatchToProps = {
  getFuneralHome,
  inviteUser,
  updateFuneralHome,
  deleteFuneralHome
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralHomeView);