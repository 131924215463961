import {applyMiddleware, compose, createStore, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import userReducer from '../redux/user/reducer';
import funeralReducer from '../redux/funeral/reducer';
import funeralHomeReducer from '../redux/funeralHome/reducer';
import createEventReducer from '../redux/createEvent/reducer';
import organizationReducer from '../redux/organization/reducer';
import configurationReducer from '../redux/configuration/reducer';
import paymentReducer from '../redux/payment/reducer';
import funeralInsightsReducer from '../redux/funeralInsights/reducer';

const reducers = combineReducers({
  user: userReducer,
  funeral: funeralReducer,
  funeralHome: funeralHomeReducer,
  createEvent: createEventReducer,
  organization: organizationReducer,
  configuration: configurationReducer,
  payment: paymentReducer,
  funeralInsights: funeralInsightsReducer,
});

let allStoreEnhancers = compose(
  applyMiddleware(thunk)
);

if (window.devToolsExtension) {
  allStoreEnhancers = compose(allStoreEnhancers, window.devToolsExtension());
}

export default function configureStore() {
  const store = createStore(
    reducers, 
    undefined,
    allStoreEnhancers
  );
  return store;
}