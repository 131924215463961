import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import Select from "@material-ui/core/Select";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import MiniCalenderView from "./MiniCalenderView";
import MonthView from "./MonthView";
import { getMonth } from "./DateUtil";
import CSS from "./CalenderView.module.scss";
import Header from "../header/Header";
import GlobalContext from "./context/GlobalContext";
import { connect } from "react-redux";
import { getFunerals } from "../../redux/funeral/actions";
import dayjs from "dayjs";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import "../userManagement/userManagement.css";
import { getOnsiteData } from "../../redux/createEvent/actions";
import { getOrganizations } from "../../redux/organization/actions";
import { getFuneralHome, getFuneralHomes } from "../../redux/funeralHome/actions";
import Spinner from "../../components/Spinner";
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from "moment";
import { getAllDaysInTheWeek } from './calendarWeekView/utils';
import GoogleCalendar from "./calendarWeekView";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchPopup from "./SearchPopup";
import { useTranslation } from "react-i18next";
import TruncateMarkup from "react-truncate-markup";
const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSvgIcon-root": {
			// fill: "red"
		},
	},
	checkbox: {
		"&:hover": {
			backgroundColor: "transparent",
		}
	},
	checkedbox: {
		backgroundColor: "transparent",
	},
	selectRoot: {
		'&:focus':{
		backgroundColor:'white'
		},
	},
	selected:{
		background: 'linear-gradient(left, #00667E 0%, #00667E 5%, #E1EAEC 5%, #E1EAEC 100%)',
	},
	rootMenuItem: {
	  "&$selected": {
		backgroundColor: "white",
		  "&:hover": {
			backgroundColor: "white",
			border: "none"
		   }
		},
	  '&:hover':{
		backgroundColor:'#C4D5D9'
	  },
	},
	icon: {
		fill: '#00667E',
	 }
}));
const CalenderViewPage = (props) => {
	const { loading, funerals, getFunerals, user, organizations, funeralHomes, history, onsiteLocationList, getOrganizations, getFuneralHomes, getOnsiteData, isLoading } = props;
	const classes = useStyles();
	const [currentMonth, setCurrentMonth] = useState(getMonth());
	const { monthIndex, setMonthIndex } = useContext(GlobalContext);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [viewId, setViewId] = useState(2);
	const [viewName, setViewName] = useState("month")
	const [dropdownRoomOpen, setDropdownRoomOpen] = useState(false);
	const [nameQuery, setNameQuery] = useState("");
	const [homeId, setHomeId] = useState(null);
	const [funeralHomeName, setFuneralHomeName] = useState("");
	const [roomId, setRoomId] = useState(null);
	const [roomName, setRoomName] = useState([]);
	const [roomsSelected, setRoomsSelected] = useState([]);
	const [updatedRoomsList, setupdatedRoomsList] = useState([]);
	const [weekDetails, setWeekDetails] = useState({
		startDate: +moment(),
		weekDays: getAllDaysInTheWeek(),
	});
	const colors = [
		"#2F80ED",
		"#9B51E0",
		"#219653",
		"#EB5757",
		"#F2994A",
		"#F2C94C",
		"#56CCF2",
		"#6FCF97",
		"#828282",
	  ];
	const colors2 = [
		"#EBF3FE",
		"#F6EEFC",
		"#E9F5EE",
		"#FEEFEF",
		"#FEF5ED",
		"#FEFAEE",
		"#EFFAFE",
		"#F1FBF5",
		"#F3F3F3",
	  ];
	var from = "";
	var to = "";
	useEffect(() => {
	}, [funerals]);
	useEffect(async () => {
		await getOrganizations();
		await getFuneralHomes();
	}, []);
	useEffect(() => {
		if(organizations.length>0){
			setFuneralHomeName(organizations[0].funeralHomes[0].name);
			setHomeId(organizations[0]?.funeralHomes[0]?.id)
			getOnsiteData(organizations[0]?.funeralHomes[0]?.id);
			setRoomsSelected(onsiteLocationList?.map(room => room.id));
			setRoomName(["All"]);
			getFunerals(1, 100, null, null, [organizations[0]?.funeralHomes[0]?.id], from, to);
		}
	}, [organizations]);
	useEffect(() => {
		if(viewId){
			setCurrentMonth(getMonth(monthIndex));
			setWeekDetails({
				startDate: +moment(currentMonth[0][0]),
				weekDays: getAllDaysInTheWeek(),
			});
		}
	}, [viewId]);
	useEffect(() => {
		if (viewId === 1) {
			setCurrentMonth(getMonth(monthIndex));
			from = dayjs(new Date(dayjs().year(), monthIndex, 1)).format("YYYY-MM-DD");
			to = dayjs(new Date(dayjs().year(), monthIndex + 1, 0)).format("YYYY-MM-DD");
		}
		else{
			from = moment(weekDetails.weekDays[0].dateStamp).format("YYYY-MM-DD");
			to = moment(weekDetails.weekDays[6].dateStamp).format("YYYY-MM-DD");
		}
		if(homeId){
			const selectedHome = [homeId];
			if(roomsSelected.length>0){
				getFunerals(1, 100, null, null, selectedHome , from, to, roomsSelected);
			}
			else{
				getFunerals(1, 100, null, null, selectedHome , from, to);
			}
		}
	}, [monthIndex, weekDetails, viewId]);
	useEffect(() => {
		const xyz = onsiteLocationList.map((obj, index) =>({
			...obj,
			color: colors[index % 9],
			color1: colors2[index % 9],
		}))
		setupdatedRoomsList(xyz);
		setRoomsSelected(xyz?.map(room => room.id));
	}, [onsiteLocationList]);
	useEffect(() => {

	}, [currentMonth, roomsSelected, currentMonth]);
	useEffect(() => {
	}, [roomId]);
	let menuRef = useRef(null);
	let roomRef = useRef(null);
	useEffect(() => {
		let handler = (e)=>{
			if(!menuRef.current.contains(e.target)){
				setDropdownOpen(false);
			}
			if(!roomRef.current.contains(e.target)){
				setDropdownRoomOpen(false);
			}      
		};
		document.addEventListener("mousedown", handler);
		return() =>{
			document.removeEventListener("mousedown", handler);
		}
	},[]);
	const viewArray = [
		{ id: 1, name: "Month" },
		{ id: 2, name: "Week" }
	]
	const { t } = useTranslation();
	const handlePrevMonth = () => {
		if (viewId === 1) {
			setMonthIndex(monthIndex - 1);
		}
		else {
			const dateBefore7Days = moment(weekDetails.startDate).subtract(7, 'days');
			setWeekDetails({
				startDate: +dateBefore7Days,
				weekDays: getAllDaysInTheWeek(dateBefore7Days),
			});
		}
	};
	const handleNextMonth = () => {
		if (viewId === 1) {
			setMonthIndex(monthIndex + 1);
		}
		else {
			const dateAfter7Days = moment(weekDetails.startDate).add(7, 'days');
			setWeekDetails({
				startDate: +dateAfter7Days,
				weekDays: getAllDaysInTheWeek(dateAfter7Days),
			});
		}
	};
	const goToCreateEvent = () => {
		history.push("/createEvent");
	};
	const roomssLeftEllipsis = node => {
		const displayedRoles = node.props.children;
		const originalRolesCount = roomName.length;
		const displayedRolesCount = displayedRoles
		  ? displayedRoles.split(', ').filter(Boolean).length
		  : 0;
		if((originalRolesCount - displayedRolesCount === 0)){
			return '...'
		}
		else{
			return `... (+${originalRolesCount - displayedRolesCount} more)`;
		}
	  };
	const updateSelectedRooms = (e) => {
		const { id, checked } = e.target;
		if (checked) {
			setRoomsSelected([...roomsSelected, id]);
			updatedRoomsList.map(list => {
				if (list.id === id) {
					if (!roomName.includes(list.name)) {
						if(roomName.length+1 === updatedRoomsList.length) {
							setRoomName(["All"]);
						}
						else{
							setRoomName([...roomName, list.name]);
						}
					}
				}
			})
		}
		if (!checked) {
			setRoomsSelected(roomsSelected.filter(item => item !== id));
			updatedRoomsList.map(list => {
				if (list.id === id) {
					const index = roomName.indexOf(list.name);
					if (index > -1) {
						roomName.splice(index, 1);
					}
				}
			})
		}
	};
	const updateSelectedAllRooms = (e) => {
		const { id, checked } = e.target;
		if (checked) {
			setRoomsSelected(updatedRoomsList.map(room => room.id));
			setRoomName(["All"]);
		}
		else {
			setRoomsSelected([]);
			setRoomName([]);
		}
	}
	const onClickMiniCalendar = (day, currentMonthIdx) => {
        const start = day.startOf('week').toDate();
		const selectedWeek = moment(start);
		setWeekDetails({
			startDate: +selectedWeek,
			weekDays: getAllDaysInTheWeek(selectedWeek),
		});
	}
	return (
		funerals && !isLoading ?
			<>
				<Header />
				<Fragment>
					{loading && <Spinner funeralSpinner = {true}/>}
					<div className={CSS.calenderView}>
						<MiniCalenderView funerals={funerals} funeralHomes={funeralHomes} roomsSelected={roomsSelected} colors={colors}
							colors2={colors2} updatedRoomsList={updatedRoomsList} onClickMiniCalendar={onClickMiniCalendar}/>
						<div className={CSS.calenderDisplay}>
							<header className={CSS.CalenderHeader}>
								<div className={CSS.showYear}>
									<button
										onClick={handlePrevMonth}
										className={CSS.actionBtnLeft}>
										<ChevronLeft />
									</button>
									{viewId === 1 ?
										<button className={CSS.actionBtn}>
											{dayjs(
												new Date(dayjs().year(), monthIndex)
											).format("MMMM YYYY")}
										</button>
										:
										<button className={CSS.actionBtn}>
											{moment(weekDetails.weekDays[0].dateStamp).format("MMM")}{" "}{moment(weekDetails.weekDays[0].dateStamp).format("DD")}{"-"}{moment(weekDetails.weekDays[6].dateStamp).format("DD")}
										</button>
									}
									<button
										onClick={handleNextMonth}
										className={CSS.actionBtnRight}>
										<ChevronRight />
									</button>
								</div>
								<div className={CSS.searchCriteria}>
									<FormControl
										variant="outlined"
										style={{ width: 125 }}
										className={`${CSS.inputEle} ${CSS.size_sm}`}>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											style={{ width: "100px", height: "32px",  borderRadius: 24, fontFamily: 'Bitter, serif', fontStyle: 'normal', fontWeight: 500, fontSize: 16 }}
											value={viewId}
											onChange={(e) => {
												setViewId(e.target.value);
												setViewName(e.target.name);
											}}
											MenuProps={{
												disableScrollLock: true,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left"
												},
												getContentAnchorEl: null,
												MenuListProps: {
													disablePadding: true
												}
											}}
											inputProps={{
												classes: {
													root: classes.border,
													icon: classes.icon,
												},
											}}
											classes={{ root: classes.selectRoot }}			  
											IconComponent={ExpandMoreOutlinedIcon}>
											{viewArray.map((list) => {
												return (
													<MenuItem key={list.id} value={list.id} classes={{ selected: classes.selected, root: classes.rootMenuItem }}
													style={{ fontFamily: 'Open Sans', fontStyle: 'normal', fontWeight: 400, fontSize: 12 }}>
														{list.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<div className={CSS.siteBar}>
										<label
											className={CSS.elementLabel}
											style={{ marginRight: "4px" }}>
											{t("pages.events_calendar.filter_site_label")}
										</label>
										<div className="dropdown" style={{ width: "240px" }} ref={menuRef}>
											<div className="control"
												onClick={() => setDropdownOpen((prev) => !prev)}>
												<input
													type="text"
													style={{ textOverflow: "ellipsis", display: "inline-block"}}
													className={"selectedValue"}
													value={funeralHomeName || nameQuery}
													onChange={(event) => {
														setFuneralHomeName("");
														setNameQuery(event.target.value);
													}}
												/>
												<div className={`arrow ${dropdownOpen ? "open" : null}`}
												/>
											</div>
											<div className={`options ${dropdownOpen ? "open" : null}`} ref={menuRef}>
												{user.user?.role === "admin" || user.user?.role === "super_admin" ?
													organizations
														.filter((val) => {
															if (nameQuery === "" || nameQuery.length < 3) 
															{
																return val;
															} 
															else if (val.name.toLocaleLowerCase().includes(nameQuery.toLocaleLowerCase()))
															{
																return val;
															}
														})
														.map((org) => (
															<div key={org.id} className="option">
																{(user.user?.role === "super_admin" || user.user?.role === "admin") && (
																	<div disabled className="first-option">
																		{org.name}
																	</div>
																)}
																{org.funeralHomes.map(
																	(hom) => (
																		<div className="sub-option"
																			key={hom.id}
																			onClick={() => {
																				getOnsiteData(hom.id);
																				setHomeId(hom.id);
																				setDropdownOpen(false);
																				setFuneralHomeName(hom.name);
																				if (hom.id) {
																					setRoomName(["All"]);
																					from = moment(weekDetails.weekDays[0].dateStamp).format("YYYY-MM-DD");
																					to = moment(weekDetails.weekDays[6].dateStamp).format("YYYY-MM-DD");
																					getFunerals(1, 100, null, null, [hom.id] , from, to);
																				}
																			}}>
																			{hom.name}
																		</div>
																	)
																)}
															</div>
														)) 
													:
													funeralHomes?.filter((val) => {
														if (nameQuery === "" || nameQuery.length < 3) 
														{
															return val;
														} 
														else 
														{
															if (val.name.toLocaleLowerCase().includes(nameQuery.toLocaleLowerCase())){
																return val;
															}
														}
													})
													.map((home) => (
														<div className="sub-option" key={home.id}
															onClick={() => {
																getOnsiteData(home.id);
																setHomeId(home.id);
																setFuneralHomeName(home.name);
																setDropdownOpen(false);
																if (home.id) {
																	setRoomName(["All"]);
																	from = moment(weekDetails.weekDays[0].dateStamp).format("YYYY-MM-DD");
																	to = moment(weekDetails.weekDays[6].dateStamp).format("YYYY-MM-DD");
																	getFunerals(1, 100, null, null, [home.id] , from, to);
																}
															}}>
															{home.name}
														</div>
													))
												}
											</div>
										</div>
									</div>
									<div className={CSS.siteBar}>
										<label
											className={CSS.elementLabel}
											style={{ marginRight: "4px" }}>
											{t("pages.events_calendar.filter_room_label")}
										</label>
										<div className="dropdown" style={{ width: "240px" }} ref={roomRef}>
											<div className="control"
												onClick={() =>
													setDropdownRoomOpen((prev) => !prev)}>
														<TruncateMarkup lines={1} ellipsis={roomssLeftEllipsis}>
															<div style={{ height: roomName.length===0 ? "32px" : "" , fontSize: "1rem", backgroundColor: "#fff",
																border: "1px solid black",
																borderRadius: "24px",
																cursor: "pointer",
																outline: "none",
																padding: "4px 16px",
																transition: "all 200ms ease",
																width: "100%" }}>
																{roomName.join(', ')}
															</div>
														</TruncateMarkup>
												{homeId ?
													<div className={`arrow ${dropdownRoomOpen ? "open" : null}`} /> : ""}
											</div>
											{homeId ?
												<div className={`options ${dropdownRoomOpen ? "open" : null}`} ref={roomRef}>
													<div style={{ paddingLeft: "24px" }}>
														<FormControlLabel
															control={<Checkbox checked={roomsSelected.length === onsiteLocationList.length && roomsSelected.length !== 0} id="selectAll"
																onChange={updateSelectedAllRooms}
																style={{
																	color: "#00667E",
																}}
																classes={{ root: classes.checkbox, checked: classes.checkedbox }}
																indeterminate={roomsSelected.length !== onsiteLocationList.length && roomsSelected.length !== 0}
																className={classes.root} />}
															label={<span style={{ fontSize: '16px', fontWeight: "300", fontFamily: "Bitter,serif", wordWrap: "break-word", color: "#001A20", lineHeight: "16px" }}>All</span>} />
													</div>
													{updatedRoomsList.map(
														(location) => {
															return (
																<div key={location.id} style={{ paddingLeft: "24px", height: "24px", whiteSpace: "nowrap", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																	<FormControlLabel
																		key={location.id}
																		className={classes.label}
																		control={<Checkbox checked={roomsSelected.includes(location.id)}
																			id={location.id}
																			classes={{ root: classes.checkbox, checked: classes.checkedbox }}
																			disabled={roomsSelected.length === onsiteLocationList.length && roomsSelected.length !== 0}
																			onChange={updateSelectedRooms}
																			style={{ color: "#00667E" }} />}
																		label={<span style={{ fontSize: '16px', fontWeight: "300", fontFamily: "Bitter,serif", wordWrap: "break-word", color: "#001A20", lineHeight: "16px" }}>{location.name}</span>}
																	/>
																	<div style={{height: "16px", width: "16px", backgroundColor: location.color, marginRight: "16px"}}></div>
																</div>
															);
														}

													)}
												</div> : ""}
										</div>
									</div>
									<SearchPopup funerals={funerals}/>
									<button
										className={`${CSS.actionBtn} ${CSS.warning}`}
										type="button"
										onClick={goToCreateEvent}>
										{t("pages.events_calendar.create_button")}
									</button>
								</div>
							</header>
							{viewId === 1 ?
								<MonthView month={currentMonth} funerals={funerals} roomId={roomId} roomsSelected={roomsSelected} colors={colors}
								colors2={colors2} funeralHomes={funeralHomes} updatedRoomsList={updatedRoomsList}/>
								: <GoogleCalendar weekDetails={weekDetails} funerals={funerals} roomId={roomId} roomsSelected={roomsSelected} colors={colors}
								colors2={colors2} funeralHomes={funeralHomes} updatedRoomsList={updatedRoomsList}/>}
						</div>
					</div>
				</Fragment>
			</>
			: <div style={{ position: "fixed", left: "35%", top: "25%", zIndex: "1000" }}><Spinner /></div>
	);
};

const mapStateToProps = (state) => ({
	funerals: state.funeral.funerals.funerals,
	isLoading: state.funeral.funerals.isLoading,
	onsiteLocationList: state.createEvent.onsiteRes,
	funeralHomes: state.funeralHome.funeralHomes,
	organizations: state.organization.organizations,
	funeralHomeInfo: state.funeralHome.funeralHomeViewing,
	user: state.user.currentUser,
	loading: state.funeral.showLoading,
});

const mapDispatchToProps = {
	getFunerals,
	getOnsiteData,
	getOrganizations,
	getFuneralHomes,
	getFuneralHome,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalenderViewPage);
