import { 
  GET_FUNERALS_SUCCESS,
  UPDATE_STREAM_SUCCESS,
  STOP_STREAM_SUCCESS,
  OVERWRITE_STATE_SUCCESS,
  GET_FUNERAL_STREAMS_SUCCESS,
  GET_FUNERAL_CLIPS_SUCCESS,
  GET_FUNERAL_SUCCESS,
  GET_FUNERAL_FAILURE,
  GET_FUNERAL_URL_SUCCESS,
  POST_FUNERALS_COMMENT_REQUEST,
  POST_FUNERALS_COMMENT_SUCCESS,
  GET_FUNERAL_COMMENTS_SUCCESS,
  GET_FUNERAL_VISITORS_SUCCESS,
  POST_FUNERALS_REPLIES_REQUEST,
  POST_FUNERALS_REPLY_SUCCESS,
  POST_VISITOR_DETAILS_SUCCESS,
  POST_DOWNLOAD_LINK_SUCCESS,
  GET_DOWNLOAD_PAGE,
  GET_DOWNLOAD_PAGE_SUCCESS,
  GET_DOWNLOAD_PAGE_FAILURE,
  POST_STRIPE_PAYMENT_SUCCESS,
  EXPORT_MESSAGES_VIA_EMAIL,
  EXPORT_MESSAGES_VIA_EMAIL_SUCCESS,
  EXPORT_MESSAGES_VIA_EMAIL_FAILURE,
  EXPORT_BUY_FUNERAL_VIA_EMAIL_SUCCESS,
  EXPORT_BUY_FUNERAL_VIA_EMAIL_FAILURE,
  GET_UPCOMING_FUNERALS_REQUEST_SUCCESS,
  LOADING_ACTION,
  INITIAL_ACTION,
  RESEND_START_SIGNAL_REQUEST,
  RESEND_START_SIGNAL_SUCCESS,
  RESEND_START_SIGNAL_FAILURE,
  FILE_PROGRESS_UPDATE
} from './actions';

const AJAX_STATE = {      
  IDEAL: "IDEAL",
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
}

const initialState = {
  funerals: [],
  funeralStreams: [],
  funeralClips: [],
  funeralDetails: [],
  getFuneralError: false,
  funeralVisitors: [],
  funeralComments: [],
  funeralReplies: [],
  funeralUrlData : {},
  visitorData: {},
  commentData: {},
  funeralPostMessage: AJAX_STATE.IDEAL,
  funeralReplyMessage: AJAX_STATE.IDEAL,
  funeralVisitorData: AJAX_STATE.IDEAL,
  showLoading: false,
  downloadLink: {},
  videoDownloadData: {},
  videoDownloadDataLoading: false,
  paymentData: {},
  exportResData: AJAX_STATE.IDEAL,
  upcomingFunerals: [],
  cameraPreviewRes: AJAX_STATE.IDEAL,
  cameraError: false,
  percentCompleted: 1,
};

function reducer(state = initialState, action) {
  switch(action.type) {
    case GET_FUNERALS_SUCCESS:
      return { ...state, funerals: action.funerals }
    case GET_FUNERAL_URL_SUCCESS:
      return { ...state, funeralUrlData: action.urlData }
    case GET_FUNERAL_STREAMS_SUCCESS:
      return { ...state, funeralStreams: action.funeralStreams }
    case GET_FUNERAL_CLIPS_SUCCESS:
        return { ...state, funeralClips: action.clips }
    case GET_FUNERAL_SUCCESS:
      return { ...state, funeralDetails: action.funeral }
    case GET_FUNERAL_FAILURE:
      return { ...state, getFuneralErrorCode: action.errorCode }
    case POST_FUNERALS_COMMENT_REQUEST:
      return { ...state, funeralPostMessage: AJAX_STATE.REQUEST}
    case POST_FUNERALS_COMMENT_SUCCESS:
      if(action.newComment){
        return { ...state, funeralPostMessage: AJAX_STATE.SUCCESS, funeralComments : [...state.funeralComments, action.newComment], ...state, commentData: action.newComment }
      }else{
        return { ...state, funeralPostMessage: AJAX_STATE.SUCCESS }
      }
    case GET_FUNERAL_COMMENTS_SUCCESS:
        return { ...state, funeralComments : action.comments }
    case GET_FUNERAL_VISITORS_SUCCESS:
        return { ...state, funeralVisitors : action.visitors }
    case POST_FUNERALS_REPLIES_REQUEST:
        return { ...state, funeralReplyMessage: AJAX_STATE.REQUEST}
    case POST_FUNERALS_REPLY_SUCCESS:
        if(action.newReply){
          return { ...state, funeralReplyMessage: AJAX_STATE.SUCCESS, funeralReplies : [...state.funeralReplies, action.newReply] }
        }else{
          return { ...state, funeralReplyMessage: AJAX_STATE.SUCCESS }
        }
    case POST_VISITOR_DETAILS_SUCCESS:
          return { ...state, visitorData : action.newVisitor }
    case POST_DOWNLOAD_LINK_SUCCESS:
      return { ...state, downloadRequest: action.downloadRequest  }
    case GET_DOWNLOAD_PAGE:
      return { ...state, videoDownloadDataLoading: true }
    case GET_DOWNLOAD_PAGE_SUCCESS:
      return { ...state, videoDownloadData: action.download, videoDownloadDataLoading: false  }
    case GET_DOWNLOAD_PAGE_FAILURE:
      return { ...state, videoDownloadDataLoading: false  }
    case POST_STRIPE_PAYMENT_SUCCESS:
      return { ...state, paymentData: action.payment  }
    case UPDATE_STREAM_SUCCESS: 
        return { ...state, funerals: { ...state.funerals, funerals: updateFuneral(state.funerals.funerals, action.funeral) }, cameraError: null }
    case STOP_STREAM_SUCCESS:
        return { ...state, funerals: { ...state.funerals, funerals: updateFuneral(state.funerals.funerals, action.funeral) }, cameraError: null }
    case OVERWRITE_STATE_SUCCESS:
        return { ...state, funerals: { ...state.funerals, funerals: updateFuneral(state.funerals.funerals, action.funeral) }, cameraError: null }
    case EXPORT_MESSAGES_VIA_EMAIL:
      return { ...state, exportResData: AJAX_STATE.REQUEST };
    case EXPORT_MESSAGES_VIA_EMAIL_SUCCESS:
      return { ...state, exportResData: AJAX_STATE.SUCCESS };
    case EXPORT_MESSAGES_VIA_EMAIL_FAILURE:
      return { ...state, exportResData: AJAX_STATE.ERROR };
    case EXPORT_BUY_FUNERAL_VIA_EMAIL_SUCCESS:
      return { ...state, exportResData: AJAX_STATE.SUCCESS };
    case EXPORT_BUY_FUNERAL_VIA_EMAIL_FAILURE:
      return { ...state, exportResData: AJAX_STATE.ERROR };
    case GET_UPCOMING_FUNERALS_REQUEST_SUCCESS:
      return { ...state, upcomingFunerals: action.upFunerals }
    case RESEND_START_SIGNAL_REQUEST:
      return { ...state, cameraPreviewRes: AJAX_STATE.REQUEST}
    case RESEND_START_SIGNAL_SUCCESS:
      return { ...state, cameraPreviewRes: AJAX_STATE.SUCCESS, cameraError: null}
    case RESEND_START_SIGNAL_FAILURE:
      return { ...state, cameraPreviewRes: AJAX_STATE.ERROR, cameraError: action.error}
    case LOADING_ACTION:
      return { ...state, showLoading: action.payload }
    case INITIAL_ACTION:
      return { ...state, exportResData: AJAX_STATE.IDEAL, cameraPreviewRes: AJAX_STATE.IDEAL }
    case FILE_PROGRESS_UPDATE:
      return { ...state, percentCompleted: action.percentCompleted }
    default:
      return state;
  }
}

const updateFuneral = (funerals, updatedFuneral) => {
  let newFunerals = [];
  funerals.forEach((funeral) => {
    if (funeral.id === updatedFuneral.id) {
      newFunerals.push(updatedFuneral);
    } else {
      newFunerals.push(funeral);
    }
  });
  return newFunerals;
}

export default reducer;
