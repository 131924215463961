import React, {useState} from 'react';
import CSS from './EventSuccessPage.module.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../pages/header/Header";
import { useTranslation } from 'react-i18next';

const EventSuccessPage = (props) => {
	const location = useLocation();
	const { t } = useTranslation();
    useEffect(() => {
	}, [location]);
    const [isCopied, setCopied] = useState(false);
    const linkURL = location.state.detail;
    const copyHandler = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }
    const createNewEvent = () =>{
		history.push("/createEvent");
    }
    const goToDeceasedPage = () =>{
        window.open(`${window.location.origin}/funerals/${linkURL}`, "_blank");
    }
	const history = useHistory();
    return (
        <div>
            <Header />
            <section className={CSS.topHeader}>
                <div className={CSS.sectionContianer}>
                    <div className={CSS.pageTitle}>{t('pages.create_event.create_event_title')}</div>
                </div>
            </section>
            <section className={CSS.successMsgWrapper}>
                <div className={CSS.elementalRow}>
                    <div className={CSS.elementalCol}>
                        <CheckCircleIcon className={CSS.checkMark}/>
                    </div>
                </div>
                <div className={CSS.elementalRow} style={{marginTop: '24px'}}>
                    <div className={CSS.elementalCol}>
                        <div className={CSS.sectionTitle}>{t('pages.create_event.event_created_title')}</div>
                    </div>
                </div>
                <div className={CSS.elementalRow}>
                    <div className={CSS.elementalCol}>
                        <div className={CSS.sectionSubTitle}>{t('pages.create_event.event_created_message')}</div>
                    </div>
                </div>
                <div className={CSS.elementalRow}>
                    <div className={CSS.elementalCol}>
                        <div className={CSS.linkText}><a href={`${window.location.origin}/funerals/${linkURL}`} target="_blank" style={{color: "#00667E"}}>{linkURL}</a></div>
                    </div>
                    <CopyToClipboard text={`${window.location.origin}/funerals/${linkURL}`} onCopy={copyHandler}>
                        <div className={CSS.elementalCol}>
                            <div className={CSS.actionBtn} onClick={copyHandler}>{
                                isCopied ? t('pages.create_event.copy_stream_link_copied') : t('pages.create_event.copy_stream_link_button')
                            }</div>
                        </div>
                    </CopyToClipboard>
                </div>
                <div className={CSS.elementalRow} style={{marginTop: '24px'}}>
                    <div className={CSS.elementalCol}>
                        <button className={`${CSS.actionBtn} ${CSS.warning}`} onClick={createNewEvent}>{t('pages.create_event.create_another_event_button')}</button>
                    </div>
                </div>
            </section>
        </div>  
    )
}
export default EventSuccessPage;
