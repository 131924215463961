import React, { useState, useEffect,Fragment } from "react";
import { find } from "lodash";
import useInterval from '../../hooks/useInterval';
import CSS from "./FuneralViewPage.module.scss";
import ReactPlayer from 'react-player/lazy';
import { useTranslation } from 'react-i18next';
// import { getFuneralStreams, getFuneralPassword } from "../redux/funeral/actions";
import { getFuneralStreams, getFuneralPassword } from "../../redux/funeral/actions";
import { connect } from "react-redux";
import moment from "moment";
import validator from 'validator';
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
	postVisitorData,
} from "../../redux/funeral/actions";
import CondolenceMessagePage from "./CondolenceMessagePage";
import axios from "axios";

const NOT_STARTED_STATE = 'not_started';
const STARTING_STATE = 'starting';
const STARTED_STATE = 'started';
const STOPPING_STATE = 'stopping';
const ENDED_STATE = 'ended';


const determineStreamToShow = (streams) => {
	let chosenStream;
	chosenStream = find(streams, (stream) => {
		return stream.state === STARTED_STATE;
	});
	if (!chosenStream) {
		chosenStream = find(streams, (stream) => {
			return stream.state === STARTING_STATE;
		});
	}
	if(!chosenStream) {
		chosenStream = streams[0];
	}
	return chosenStream;
}
function VideoStream({ funeralDetails, funeralStreams, funeralHomeInfo, funeralId, postVisitorData, getFuneralStreams, funeralComments, visitorDetails, getFuneralPassword, passwordDigest }) {
    // const { funeralDetails, funeralStreams, funeralHomeInfo, funeralId, postVisitorData, login } =props;
    const [errorStates, setErrorStates] = useState({
		userEmail: "",
	});
	const [playing, setPlaying] = useState(false);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const [userFullName, setUserFullName] = useState(()=>{
		const savedName = localStorage.getItem("fullName");
		const initialName = JSON.parse(savedName);
		return initialName || "";
	});
	const [userEmail, setUserEmail] = useState(()=>{
		const savedEmail = localStorage.getItem("email");
		const initialEmail = JSON.parse(savedEmail);
		return initialEmail || "";
	});
	const [funeralPassword, setFuneralPassword] = useState("");
	const [userSavedData, setUserData] = useState({
		email: "",
		fullName: "",
		password: "",
		isAuthenticated: false,
	});
	const [dropdownVideoOpen, setVideoDropdown] = useState(false);
	const [passwordFlag, setPasswordFlag] = useState(false);
	const [accessStreamChecker, setAccessStreamChecker] = useState(false);
	const toggleVideosDropdown = () => setVideoDropdown(!dropdownVideoOpen);
	useEffect(() => {
		getFuneralStreams(funeralId, true);
	}, []);
	useInterval(() => {
		const endedStreamsCount = funeralStreams.filter((s) => s.state === ENDED_STATE).length;
		const endedStreamsClipCount = funeralStreams.filter((s) => s.state === ENDED_STATE && s.clips.length > 0).length;
		// check if all streams have ended. If not and no video is playing currently, then keep polling
		if (!isVideoPlaying && (endedStreamsClipCount !== funeralStreams.length)) {
			getFuneralStreams(funeralId, true);
		}
  }, 30000);
	useEffect(() => {
		if(validator.isEmail(userEmail) || !userEmail){
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail:""
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail:t("pages.create_event.email_validation")
				}
			})
		}
	}, [userEmail]);
	useEffect(() => {
		if (funeralStreams.length > 0) {
			const videoToShow = determineStreamToShow(funeralStreams);
			setVideoInformation(videoToShow);
			if (videoToShow.clips[0]?.isLive) {
				setstate(false);
			} else {
				setstate(true);
			}
		}
	}, [funeralStreams]);
	useEffect(() => {
		checkDetailsValid();
	}, [funeralPassword, errorStates, userEmail, userFullName]);
	useEffect(() => {
	}, [accessStreamChecker]);
	const checkDetailsValid = () => {
		if(funeralDetails.passwordDigest){
			if (
				userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				funeralPassword &&
				!errorStates.userEmail
			) {
				setAccessStreamChecker(true);
				return true;
			}
			else{
				setAccessStreamChecker(false);
				return false;
			}
		}
		else{
			if (
				userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				!errorStates.userEmail
			) {
				setAccessStreamChecker(true);
				return true;
			}
			else{
				setAccessStreamChecker(false);
				return false;
			}
		}	
	}
	useEffect(() => {
		// if(passwordDigest){
		// 	setPasswordFlag(true);
		// }
		// else{
		// 	setPasswordFlag(false);
		// }
	}, [passwordDigest]);
	useEffect(() => {
	}, [passwordFlag]);
	const getBaseUrl = () => {
		if (process.env.NODE_ENV === 'development') {
		  return "http://localhost:4000";
		}
	  
		return "https://api.funeralliveapp.ca";
	  }
	const [videoInformation, setVideoInformation] = useState(null);
	const [state, setstate] = useState(false);
    const convertDateToTimeOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("h:mm A");
		}
		return "";
	};
	const convertDateToOnlyDate = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("LL");
		}
		return "";
	};
    const saveCurrentUserDetails = () => {
		if (funeralDetails.passwordDigest) {
			var passwordResponse = {};
			if(userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				funeralPassword &&
				!errorStates.userEmail){
				axios.get(`api/funerals/${funeralId}/validate_password?password=${funeralPassword}`, {
					baseURL: getBaseUrl(),
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						"Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
					}
				  })
				  .then(function (response) {
					passwordResponse=response;
					if(passwordResponse.data.valid_password){
						setUserData((prev) => {
							return {
								...prev,
								email: userEmail,
								fullName: userFullName,
								isAuthenticated: true,
							};
						});
						const visitorPayload = {
							visitor: {
								name: userFullName,
								email: userEmail
							}
						}
						postVisitorData(funeralId, visitorPayload);
						localStorage.setItem("fullName", JSON.stringify(userFullName));
						localStorage.setItem("email", JSON.stringify(userEmail));
						setPasswordFlag(false);
					}
					else{
						setPasswordFlag(true);
					}
				  })
			}
		}
		else{
			if(userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				!errorStates.userEmail){
					setUserData((prev) => {
						return {
							...prev,
							email: userEmail,
							fullName: userFullName,
							isAuthenticated: true,
						};
					});
					const visitorPayload = {
						visitor: {
							name: userFullName,
							email: userEmail
						}
					}
					postVisitorData(funeralId, visitorPayload);
					localStorage.setItem("fullName", JSON.stringify(userFullName));
					localStorage.setItem("email", JSON.stringify(userEmail));
			}
		}
	};
    const openClipData = (stream) =>{
		setVideoInformation(stream);
		setstate(true);
	}
    const watchAgain = () =>{
		setstate(false);
	}
	const { t } = useTranslation();
	return (
        <>
		<section className={CSS.userForm}>
			<div className={CSS.userContainer}>
				<div className={CSS.sectionContainer}>
					{userSavedData.isAuthenticated || (
						<div className={CSS.formWrappper}>
							<div className={CSS.innerWrappper}>
								<span className={CSS.formHeading}>
									{t("pages.deceased.stream_access_title")}
								</span>
								<div className={CSS.formElements}>
									<div className={CSS.inputWrapperRow}>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t(
													"pages.deceased.stream_access_name"
												)}
											</label>
											<input
												className={CSS.inputElement}
												type="text"
												value={userFullName}
												onChange={(e) =>
													setUserFullName(
														e.target.value
													)
												}></input>
										</div>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t(
													"pages.deceased.stream_access_email"
												)}
											</label>
											<input
												className={
													errorStates.userEmail
														? CSS.inputError
														: CSS.inputElement
												}
												type="text"
												value={userEmail}
												onChange={(e) =>
													setUserEmail(e.target.value)
												}></input>
											<div className={CSS.errorText}>
												{errorStates.userEmail}
											</div>
										</div>
									</div>
									{funeralDetails.passwordDigest ?
									<div className={CSS.inputWrapperRow}>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t(
													"pages.deceased.stream_access_password"
												)}
											</label>
											<input
												className={passwordFlag ? CSS.inputError : CSS.inputElement}
												type="password"
												value={funeralPassword}
												placeholder="Enter password"
												onChange={(e) =>
													setFuneralPassword(e.target.value)
												}></input>
											{passwordFlag ? 
												<div className={CSS.errorText}>
													Incorrect Password
												</div>: ""}
										</div>
									</div>: ""}
								</div>
								<div>
									<div className={CSS.submitWrapper}>
										<div className={CSS.hintMsg}></div>
										<button className={`${CSS.actionBtn} ${CSS.warning} ${accessStreamChecker? "": CSS.disabled}`}
											type="button"
											onClick={saveCurrentUserDetails}>
											{t("pages.deceased.access_stream")}
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{userSavedData.isAuthenticated && funeralStreams.length > 0 && (
				<Fragment>
					<div className={CSS.userform}>
						<div className={CSS.dropdownBtn}>
							<div className={CSS.dropdownHeader}>
								<span className={CSS.burgerMenuTitle}>
									{funeralStreams.length} {t("pages.deceased.streams")}
								</span>
								{dropdownVideoOpen ? (
									<ExpandLessIcon
										className={CSS.dropdownIcon}
										onClick={toggleVideosDropdown}
									/>
								) : (
									<ExpandMoreOutlinedIcon
										className={CSS.dropdownIcon}
										onClick={toggleVideosDropdown}
									/>
								)}
							</div>
							<div className={CSS.menu}>
								{dropdownVideoOpen &&
									funeralDetails &&
									funeralStreams.map((stream) => (
										<div
											key={stream.id}
											className={CSS.dropdownMenuItems}
											onClick={() =>
												openClipData(stream)
											}>
											{stream.state === ENDED_STATE ? (
												<div className={CSS.otherClips}>
													<div className={CSS.videoFrame}>
														<ReactPlayer
															url={stream.clips[0]?.clipUrl}
															playing={false}
															controls={true}
															volume={0.0}
															// muted={true}
															// light={`${imgSrc.current}`}
															onReady={() =>
																setPlaying(true)
															}
															height={100}
															width={200}
															onPlay={() => setIsVideoPlaying(true)}
															onEnded={() => setIsVideoPlaying(false)}
															onError={() => setIsVideoPlaying(false)}
															onPause={() => setIsVideoPlaying(false)}
														/>
													</div>
													<div className={CSS.streamName}>
														{stream.name} <br />
														{
															funeralDetails.firstName
														}{" "}
														{
															funeralDetails.lastName
														}
													</div>
													<div
														className={CSS.endTime}>
														{t("pages.deceased.ended")}{" "}
														{convertDateToOnlyDate(
															stream.streamEnd
														)}{" "}
														at <br />
														{convertDateToTimeOnly(
															stream.streamEnd
														)}{" "}
														{
															funeralHomeInfo.timeZone
														}
													</div>
												</div>
											) : stream.state === NOT_STARTED_STATE ? 
												<div className={CSS.streamNotStartedDropdownItem}>
													<div className={CSS.streamName}>
														{stream.name} •{" "}
														{
															funeralDetails.firstName
														}{" "}
														{
															funeralDetails.lastName
														}
													</div>
													<div
														className={CSS.endTime}>
														{t("pages.deceased.starts")}{" "}
														{convertDateToOnlyDate(
															stream.streamStart
														)}{" "}
														at <br />
														{convertDateToTimeOnly(
															stream.streamStart
														)}{" "}
														{
															funeralHomeInfo.timeZone
														}
													</div>
												</div>
												:""
											}
										</div>
									))}
							</div>
						</div>
						{/* <ReactPlayer
                            className={CSS.reactPlayer}
                            url={"https://dzbjiafeu8bzr.cloudfront.net/out/v1/cb2c70e2a4f64c7fa910738c3a24e4f2/index.m3u8"}
                            playing={true}
                            controls={true}
                            volume={1.0}
                            // muted={true}
                            // light={`${imgSrc.current}`}
                            onReady={() => setPlaying(true)}
                            width='100%'
                            height='100%'
                        /> */}
						{videoInformation?.clips.length > 0 ? (
							!state ? (
								<div>
									<ReactPlayer
										className={CSS.reactPlayer}
										url={videoInformation.clips[0]?.clipUrl}
										playing={true}
										controls={true}
										volume={1.0}
										// muted={true}
										// light={`${imgSrc.current}`}
										onReady={() => setPlaying(true)}
										width="100%"
										height="100%"
										onPlay={() => setIsVideoPlaying(true)}
										onEnded={() => setIsVideoPlaying(false)}
										onError={() => setIsVideoPlaying(false)}
										onPause={() => setIsVideoPlaying(false)}
									/>
								</div>
							) : (
								<div className={CSS.videoStreamInfo}>
									<div className={CSS.videoDetailInfo}>
										<div
											className={
												CSS.videoStreamDetailInfo
											}>
											{t("pages.deceased.ended")}{" "}
											{convertDateToOnlyDate(
												videoInformation.streamStart
											)}{" "}
											at {""}
											{convertDateToTimeOnly(
												videoInformation.streamEnd
											)}{" "}
											{funeralHomeInfo.timeZone}
										</div>
										<div
											className={
												CSS.videoDeceasedNameInfo
											}>
											{videoInformation.name} <br />
											{funeralDetails.firstName}{" "}
											{funeralDetails.lastName}
										</div>
										<button
											className={`${CSS.actionBtn} ${CSS.warning}`}
											type="button"
											onClick={watchAgain}>
											{!videoInformation.livestreamEvent && !videoInformation.dacastEvent ? t("pages.deceased.expired_stream") : t("pages.deceased.watch_again")}
										</button>
									</div>
								</div>
							)
						) : (
							<div className={CSS.videoStreamInfo}>
								<div className={CSS.videoDetailInfo}>
									<div className={CSS.videoStreamDetailInfo}>
										{videoInformation.state === ENDED_STATE
											? t("pages.deceased.ended")
											: "Starting "}
										{convertDateToOnlyDate(
											videoInformation.streamStart
										)}{" "}
										at {""}
										{videoInformation.state === ENDED_STATE
											? convertDateToTimeOnly(
													videoInformation.streamEnd
											  )
											: convertDateToTimeOnly(
													videoInformation.streamStart
											  )}{" "}
										{funeralHomeInfo.timeZone}
									</div>
									<div className={CSS.videoDeceasedNameInfo}>
										{videoInformation.name} <br />
										{funeralDetails.firstName}{" "}
										{funeralDetails.lastName}
									</div>
								</div>
							</div>
						)}
					</div>
				</Fragment>
			)}
		</section>
        <CondolenceMessagePage  funeralComments={funeralComments} visitorDetails={visitorDetails} funeralId={funeralId} userSavedData={userSavedData}/>
        </>
	);
}
const mapStateToProps = (state) => ({
	funeralStreams: state.funeral.funeralStreams,
	passwordDigest: state.funeral.passwordCheck
});

const mapDispatchToProps = {
	getFuneralStreams,
    postVisitorData,
		// getFuneralPassword
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoStream);
