import React from 'react'
import WeekView from './weekView';

const GoogleCalendar = ({ weekDetails, funerals, roomsSelected, colors, colors2, funeralHomes, updatedRoomsList }) => {
  return (
    <WeekView
      weekDetails={weekDetails}
      events={funerals}
      roomsSelected={roomsSelected}
      colors={colors}
      colors2={colors2}
      funeralHomes={funeralHomes}
      updatedRoomsList={updatedRoomsList}
    />
  )
}

export default GoogleCalendar