import axios from '../../utils/axios';
import axiosNonConfigured from "axios";
import createId from '../../utils/uuid';
import camelcaseKeys from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";

export const FILE_PROGRESS_UPDATE = 'FILE_PROGRESS_UPDATE';

export const GET_FUNERALS_REQUEST = 'GET_FUNERALS_REQUEST';
export const GET_FUNERALS_SUCCESS = 'GET_FUNERALS_SUCCESS';
export const GET_FUNERALS_FAILURE = 'GET_FUNERALS_FAILURE';

const GET_FUNERAL_STREAMS_REQUEST = 'GET_FUNERAL_STREAMS_REQUEST';
export const GET_FUNERAL_STREAMS_SUCCESS = 'GET_FUNERAL_STREAMS_SUCCESS';
export const GET_FUNERAL_CLIPS_SUCCESS = 'GET_FUNERAL_CLIPS_SUCCESS';
export const GET_FUNERAL_STREAMS_FAILURE = 'GET_FUNERAL_STREAMS_FAILURE';

const STOP_STREAM_REQUEST = 'STOP_STREAM_REQUEST';
export const STOP_STREAM_SUCCESS = 'STOP_STREAM_SUCCESS';
export const STOP_STREAM_FAILURE = 'STOP_STREAM_FAILURE';

const OVERWRITE_STATE_REQUEST = 'OVERWRITE_STATE_REQUEST';
export const OVERWRITE_STATE_SUCCESS = 'OVERWRITE_STATE_SUCCESS';
export const OVERWRITE_STATE_FAILURE = 'OVERWRITE_STATE_FAILURE';

const START_STREAM_REQUEST = 'START_STREAM_REQUEST';
export const UPDATE_STREAM_SUCCESS = 'UPDATE_STREAM_SUCCESS';
export const UPDATE_STREAM_FAILURE = 'UPDATE_STREAM_FAILURE';

const RESET_STREAM_REQUEST = 'RESET_STREAM_REQUEST';
export const RESET_STREAM_SUCCESS = 'RESET_STREAM_SUCCESS';
export const RESET_STREAM_FAILURE = 'RESET_STREAM_FAILURE';

export const RESEND_START_SIGNAL_REQUEST = 'RESEND_START_SIGNAL_REQUEST';
export const RESEND_START_SIGNAL_SUCCESS = 'RESEND_START_SIGNAL_SUCCESS';
export const RESEND_START_SIGNAL_FAILURE = 'RESEND_START_SIGNAL_FAILURE';

const SEND_STOP_SIGNAL_REQUEST = 'SEND_STOP_SIGNAL_REQUEST';
export const SEND_STOP_SIGNAL_SUCCESS = 'SEND_STOP_SIGNAL_SUCCESS';
export const SEND_STOP_SIGNAL_FAILURE = 'SEND_STOP_SIGNAL_FAILURE';

const GET_STREAM_CLIPS_REQUEST = 'GET_STREAM_CLIPS_REQUEST';
export const GET_STREAM_CLIPS_SUCCESS = 'GET_STREAM_CLIPS_SUCCESS';
export const GET_STREAM_CLIPS_FAILURE = 'GET_STREAM_CLIPS_FAILURE';

const GET_FUNERAL_REQUEST = 'GET_FUNERAL_REQUEST';
export const GET_FUNERAL_SUCCESS = 'GET_FUNERAL_SUCCESS';
export const GET_FUNERAL_FAILURE = 'GET_FUNERAL_FAILURE';

export const POST_FUNERALS_COMMENT_REQUEST = 'POST_FUNERALS_COMMENT_REQUEST';
export const POST_FUNERALS_COMMENT_SUCCESS = 'POST_FUNERALS_COMMENT_SUCCESS';
export const POST_FUNERALS_COMMENT_FAILURE = 'POST_FUNERALS_COMMENT_FAILURE';

const GET_FUNERAL_COMMENTS_REQUEST = 'GET_FUNERAL_COMMENTS_REQUEST';
export const GET_FUNERAL_COMMENTS_SUCCESS = 'GET_FUNERAL_COMMENTS_SUCCESS';
export const GET_FUNERAL_COMMENTS_FAILURE = 'GET_FUNERAL_COMMENTS_FAILURE';

export const POST_FUNERALS_REPLIES_REQUEST = 'POST_FUNERALS_REPLIES_REQUEST';
export const POST_FUNERALS_REPLY_SUCCESS = 'POST_FUNERALS_REPLY_SUCCESS';
export const POST_FUNERALS_REPLY_FAILURE = 'POST_FUNERALS_REPLY_FAILURE';

export const POST_VISITOR_DETAILS_REQUEST = 'POST_VISITOR_DETAILS_REQUEST';
export const POST_VISITOR_DETAILS_SUCCESS = 'POST_VISITOR_DETAILS_SUCCESS';
export const POST_VISITOR_DETAILS_FAILURE = 'POST_VISITOR_DETAILS_FAILURE';

const GET_FUNERAL_VISITORS_REQUEST = 'GET_FUNERAL_VISITORS_REQUEST';
export const GET_FUNERAL_VISITORS_SUCCESS = 'GET_FUNERAL_VISITORS_SUCCESS';
export const GET_FUNERAL_VISITORS_FAILURE = 'GET_FUNERAL_VISITORS_FAILURE';

const GET_FUNERAL_URL_REQUEST = 'GET_FUNERAL_URL_REQUEST';
export const GET_FUNERAL_URL_SUCCESS = 'GET_FUNERAL_URL_SUCCESS';
export const GET_FUNERAL_URL_FAILURE = 'GET_FUNERAL_URL_FAILURE';

const PRESIGN_VIDEO_REQUEST = 'PRESIGN_VIDEO_REQUEST';
export const PRESIGN_VIDEO_SUCCESS = 'PRESIGN_VIDEO_SUCCESS';
export const PRESIGN_VIDEO_FAILURE = 'PRESIGN_VIDEO_FAILURE';

export const POST_DOWNLOAD_LINK = 'POST_DOWNLOAD_LINK';
export const POST_DOWNLOAD_LINK_SUCCESS = 'POST_DOWNLOAD_LINK_SUCCESS';
export const POST_DOWNLOAD_LINK_FAILURE = 'POST_DOWNLOAD_LINK_FAILURE';

export const GET_DOWNLOAD_PAGE = 'GET_DOWNLOAD_PAGE';
export const GET_DOWNLOAD_PAGE_SUCCESS = 'GET_DOWNLOAD_PAGE_SUCCESS';
export const GET_DOWNLOAD_PAGE_FAILURE = 'GET_DOWNLOAD_PAGE_FAILURE';

export const EXPORT_MESSAGES_VIA_EMAIL = 'EXPORT_MESSAGES_VIA_EMAIL';
export const EXPORT_MESSAGES_VIA_EMAIL_SUCCESS = 'EXPORT_MESSAGES_VIA_EMAIL_SUCCESS';
export const EXPORT_MESSAGES_VIA_EMAIL_FAILURE = 'EXPORT_MESSAGES_VIA_EMAIL_FAILURE';

export const EXPORT_BUY_FUNERAL_VIA_EMAIL = 'EXPORT_BUY_FUNERAL_VIA_EMAIL';
export const EXPORT_BUY_FUNERAL_VIA_EMAIL_SUCCESS = 'EXPORT_BUY_FUNERAL_VIA_EMAIL_SUCCESS';
export const EXPORT_BUY_FUNERAL_VIA_EMAIL_FAILURE = 'EXPORT_BUY_FUNERAL_VIA_EMAIL_FAILURE';

export const POST_STRIPE_PAYMENT = 'POST_STRIPE_PAYMENT';
export const POST_STRIPE_PAYMENT_SUCCESS = 'POST_STRIPE_PAYMENT_SUCCESS';
export const POST_STRIPE_PAYMENT_FAILURE = 'POST_STRIPE_PAYMENT_FAILURE';

const GET_UPCOMING_FUNERALS_REQUEST = 'GET_UPCOMING_FUNERALS_REQUEST';
export const GET_UPCOMING_FUNERALS_REQUEST_SUCCESS = 'GET_UPCOMING_FUNERALS_REQUEST_SUCCESS';
export const GET_UPCOMING_FUNERALS_REQUEST_FAILURE = 'GET_UPCOMING_FUNERALS_REQUEST_FAILURE';

export const DELETE_FUNERAL_REQUEST = 'DELETE_FUNERAL_REQUEST';
export const DELETE_FUNERAL_SUCCESS = 'DELETE_FUNERAL_SUCCESS';
export const DELETE_FUNERAL_FAILURE = 'DELETE_FUNERAL_FAILURE';

export const EXTEND_STREAM_REQUEST = 'EXTEND_STREAM_REQUEST';

export const LOADING_ACTION = "LOADING_ACTION";

export const INITIAL_ACTION = "INITIAL_ACTION";

export const getFunerals = (page, limit, sortProps, globalFilterProps, funeralHomesSelected, from, to, rooms) => {
  return dispatch => {
    dispatch({ type: GET_FUNERALS_REQUEST });
    dispatch(loadingAction(true));
    let requestEndpoint = `api/funerals?page=${page}&limit=${limit}`
    if (sortProps && sortProps.length > 0) {
      const sort = sortProps[0].id.toLowerCase().split(' ').join('_');
      const desc = sortProps[0].desc;
      requestEndpoint = requestEndpoint + `&sort=${sort}&desc=${desc}`;
    } else {
      requestEndpoint = requestEndpoint + "&sort=streams&desc=true";
    }
    if (globalFilterProps) {
      requestEndpoint = requestEndpoint + `&filter_name=${globalFilterProps.filterName}&filter_value=${globalFilterProps.filterValue}`;
    }
    if (funeralHomesSelected) {
      requestEndpoint = requestEndpoint + `&funeral_homes=${JSON.stringify(funeralHomesSelected)}`;
    }
    if (rooms) {
      requestEndpoint = requestEndpoint + `&rooms=${JSON.stringify(rooms)}`;
    }
    if (from && to) {
      requestEndpoint = requestEndpoint + `&from=${from}&to=${to}`;
    }  
    return axios.get(requestEndpoint, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const funerals = camelcaseKeys(response.data);
        dispatch(loadingAction(false));
        dispatch(getFuneralsSuccess(funerals));
        return response;
      })
      .catch(error => {
        dispatch(loadingAction(false));
        getFuneralsFailure(error)
      });
  }
};

const getFuneralsSuccess = (funerals) => {
  return {
    type: GET_FUNERALS_SUCCESS,
    funerals,
  }
};

const getFuneralsFailure = (error) => {
  return {
    type: GET_FUNERALS_FAILURE,
    error,
  }
};

export const getFuneralStreams = (funeralId, useInternalApiKey = false) => {
  console.log('useInternalApiKey ', useInternalApiKey);
  const token = useInternalApiKey ? (process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc') : localStorage.getItem("token");
  return dispatch => {
    dispatch({ type: GET_FUNERAL_STREAMS_REQUEST });
    
    return axios.get(`api/funerals/${funeralId}/streams`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        const streams = camelcaseKeys(response.data).streams;
        const clips = camelcaseKeys(response.data).uploadedClips;
        dispatch(getFuneralStreamsSuccess(streams));
        dispatch(getFuneralClipsSuccess(clips));
      })
      .catch(error => getFuneralStreamsFailure(error));
  }
};

const getFuneralStreamsSuccess = (funeralStreams) => {
  return {
    type: GET_FUNERAL_STREAMS_SUCCESS,
    funeralStreams,
  }
};

const getFuneralClipsSuccess = (clips) => {
  return {
    type: GET_FUNERAL_CLIPS_SUCCESS,
    clips,
  }
};

const getFuneralStreamsFailure = (error) => {
  return {
    type: GET_FUNERAL_STREAMS_FAILURE,
    error,
  }
};

export const getFuneralUrl = (url) => {
  return dispatch => {
    dispatch({ type: GET_FUNERAL_URL_REQUEST });   
    return axios.get(`api/funerals/${url}/find_by_url`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const funeralURL = camelcaseKeys(response.data).funeral;
        dispatch(getFuneralUrlSuccess(funeralURL));
      })
      .catch(error => getFuneralUrlFailure(error));
  }
};

const getFuneralUrlSuccess = (urlData) => {
  return {
    type: GET_FUNERAL_URL_SUCCESS,
    urlData,
  }
};

const getFuneralUrlFailure = (error) => {
  return {
    type: GET_FUNERAL_URL_FAILURE,
    error,
  }
};

export const startStream = (funeralId, streamId) => {
  return dispatch => {
    dispatch({ type: START_STREAM_REQUEST });
    const token = localStorage.getItem("token");

    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/start`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    .then(() => {
      // get the funeral again
        axios.get(`api/funerals/${funeralId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then(response => {
          const funeral = camelcaseKeys(response.data).funeral;
          dispatch(updateStreamSuccess(funeral));
        })
        .catch(error => getFuneralFailure(error));
    })
    .catch(error => updateStreamFailure(error));
  }
};

const updateStreamSuccess = (funeral) => {
  return {
    type: UPDATE_STREAM_SUCCESS,
    funeral,
  }
};

const updateStreamFailure = (error) => {
  return {
    type: UPDATE_STREAM_FAILURE,
    error,
  }
};

// Extend Stream

export const extendStream = (funeralId, streamId, minutes) => {
  return dispatch => {
    dispatch({ type: EXTEND_STREAM_REQUEST });
    const token = localStorage.getItem("token");

    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/extend_stream`, { minutes }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    .then(() => {
      dispatch(getFuneralStreams(funeralId));
    })
    .catch(error => updateStreamFailure(error));
  }
};

export const resetStream = (funeralId, streamId) => {
  return dispatch => {
    dispatch(loadingAction(true));
    dispatch({ type: RESET_STREAM_REQUEST });
    
    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/reset_stream`, {}, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(() => {
        dispatch(loadingAction(false));
        dispatch(resetStreamSuccess());
    })
    .catch(error => {
      dispatch(loadingAction(false));
      resetStreamFailure(error)}
    );
  }
};

const resetStreamSuccess = () => {
  return {
    type: RESET_STREAM_SUCCESS,
  }
};

const resetStreamFailure = (error) => {
  return {
    type: RESET_STREAM_FAILURE,
    error,
  }
};

export const resendStartSignal = (funeralId, streamId) => {
  return dispatch => {
    dispatch(loadingAction(true));
    dispatch({ type: RESEND_START_SIGNAL_REQUEST });
    
    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/resend_start_signal`, {}, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(() => {
        dispatch(loadingAction(false));
        dispatch(resendStartSignalSuccess());
    })
    .catch(error => {
      dispatch(loadingAction(false));
      dispatch(resendStartSignalFailure(error))});
  }
};

const resendStartSignalSuccess = () => {
  return {
    type: RESEND_START_SIGNAL_SUCCESS,
  }
};

const resendStartSignalFailure = (error) => {
  console.log('the error in action ', error)
  return {
    type: RESEND_START_SIGNAL_FAILURE,
    error: error.response.data.message,
  }
};

export const sendStopSignal = (funeralId, streamId) => {
  return dispatch => {
    dispatch(loadingAction(true));
    dispatch({ type: SEND_STOP_SIGNAL_REQUEST });
    
    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/send_stop_signal`, {}, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(() => {
        dispatch(loadingAction(false));
        dispatch(sendStopSignalSuccess());
    })
    .catch(error => {
      dispatch(loadingAction(false));
      sendStopSignalFailure(error)}
    );
  }
};

const sendStopSignalSuccess = () => {
  return {
    type: SEND_STOP_SIGNAL_SUCCESS,
  }
};

const sendStopSignalFailure = (error) => {
  return {
    type: SEND_STOP_SIGNAL_FAILURE,
    error,
  }
};

export const stopStream = (funeralId, streamId) => {
  return dispatch => {
    dispatch({ type: STOP_STREAM_REQUEST });
    const token = localStorage.getItem("token");
    
    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/stop`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(() => {
        // get the funeral again
        axios.get(`api/funerals/${funeralId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then(response => {
          const funeral = camelcaseKeys(response.data).funeral;
          dispatch(stopStreamSuccess(funeral));
        })
        .catch(error => getFuneralFailure(error));
      })
      .catch(error => stopStreamFailure(error));
  }
};

const stopStreamSuccess = (funeral) => {
  return {
    type: STOP_STREAM_SUCCESS,
    funeral
  }
};

const stopStreamFailure = (error) => {
  return {
    type: STOP_STREAM_FAILURE,
    error,
  }
};

export const changeStateToEnded = (funeralId, streamId) => {
  return dispatch => {
    dispatch({ type: OVERWRITE_STATE_REQUEST });
    const token = localStorage.getItem("token");
    
    return axios.post(`api/funerals/${funeralId}/streams/${streamId}/overwrite_state`, {
      state: "ended"
    }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(() => {
        // get the funeral again
        axios.get(`api/funerals/${funeralId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then(response => {
          const funeral = camelcaseKeys(response.data).funeral;
          dispatch(changeStateToEndedSuccess(funeral));
        })
        .catch(error => getFuneralFailure(error));
      })
      .catch(error => changeStateToEndedFailure(error));
  }
};

const changeStateToEndedSuccess = (funeral) => {
  return {
    type: OVERWRITE_STATE_SUCCESS,
    funeral
  }
};

const changeStateToEndedFailure = (error) => {
  return {
    type: OVERWRITE_STATE_FAILURE,
    error,
  }
};

export const getStreamClips = (streamId) => {
  return dispatch => {
    dispatch({ type: GET_STREAM_CLIPS_REQUEST });
    
    return axios.post(`api/streams/${streamId}/update_clips`, {}, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(() => {
        dispatch(getStreamClipsSuccess());
      })
      .catch(error => getStreamClipsFailure(error));
  }
};

const getStreamClipsSuccess = () => {
  return {
    type: GET_STREAM_CLIPS_SUCCESS
  }
};

const getStreamClipsFailure = (error) => {
  return {
    type: GET_STREAM_CLIPS_FAILURE,
    error,
  }
};

export const getFuneralDetails = (funeralId, useInternalApiKey = false, includeVisitors = false) => {
  const token = useInternalApiKey ? (process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc') : localStorage.getItem("token");
  return dispatch => {
    dispatch({ type: GET_FUNERAL_REQUEST });
    
    return axios.get(`api/funerals/${funeralId}?include_visitors=${includeVisitors ? true : false}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        const funeral = camelcaseKeys(response.data).funeral;
        dispatch(getFuneralSuccess(funeral));
        return response;
      })
      .catch(error => dispatch(getFuneralFailure(error?.response?.status)));
  }
};

const getFuneralSuccess = (funeral) => {
  return {
    type: GET_FUNERAL_SUCCESS,
    funeral,
  }
};

const getFuneralFailure = (error) => {
  return {
    type: GET_FUNERAL_FAILURE,
    errorCode: error,
  }
};

export const postVisitorData = (funeralId, payload) => {
  return dispatch => {
    dispatch({ type: POST_VISITOR_DETAILS_REQUEST });
    
    return axios.post(`api/funerals/${funeralId}/visitors`, snakeCaseKeys(payload), {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
      }
    })
      .then(response => {
        const visitorData = camelcaseKeys(response.data).visitor;
        dispatch(postVisitorDataSuccess(visitorData));
        return response;
      })
      .catch(error => postVisitorDataFailure(error));
  }
}
export const postVisitorDataSuccess = (newVisitor) => {
  if(newVisitor){
    return {
      type: POST_VISITOR_DETAILS_SUCCESS,
      newVisitor
    }
  }else{
    return {
      type: POST_VISITOR_DETAILS_SUCCESS
    }
  }
}
const postVisitorDataFailure = (error) => {
  return {
    type: POST_VISITOR_DETAILS_FAILURE,
    error,
  }
};

export const postVideoWithSignedUrl = (file, checksum) => {
  return dispatch => {
    dispatch({ type: PRESIGN_VIDEO_REQUEST });
    const data = {
      file: {
        filename: createId(),
        byte_size: file.size,
        checksum: checksum,
        content_type: 'application/video',
        metadata: {
          'message': 'Uploaded Clip'
        }
      }
    };

    return axios.post('api/presigned_url', data, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
      }
    })
    .then(response => {
      const responseData = response.data;
      // Axios instance configured to not automatically set content type
      const axiosInstance = axiosNonConfigured.create();

      // Request configuration with progress tracking
      const config = {
        headers: {
          ...responseData.direct_upload.headers
        },
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          dispatch({ type: FILE_PROGRESS_UPDATE, percentCompleted });
        }
      };

      // Make the PUT request with progress tracking
      return axiosInstance.put(responseData.direct_upload.url, file, config)
        .then((s3UploadResponse) => {
          dispatch(loadingAction(false));
          dispatch(uploadVideoPresignedUrlSuccess(responseData.blob_signed_id));
          return responseData.blob_signed_id;
        })
        .catch(error => {
          dispatch(loadingAction(false));
          console.log("failed to upload video");
        });

      // return axiosNonConfigured.put(responseData.direct_upload.url, file, {
      //   headers: {
      //     ...responseData.direct_upload.headers
      //   }
      // }).then((s3UploadResponse) => {
      //   dispatch(loadingAction(false));
      //   dispatch(uploadVideoPresignedUrlSuccess(responseData.blob_signed_id));
      //   return responseData.blob_signed_id;
      // }).catch(error => {
      //   dispatch(loadingAction(false));
      //   console.log("failed to upload video");
      // });
    })
    .catch(error => {
      dispatch(loadingAction(false));
      console.log("error response::: ", error);
      // dispatch(createFuneralHomeFailure(error.response.data.message));
    });
  }
}

const uploadVideoPresignedUrlSuccess = (blobSignedId) => {
  return {
    type: PRESIGN_VIDEO_SUCCESS,
    blobSignedId,
  }
};

export const postFuneralComment = (funeralId, data) => {
  return dispatch => {
    dispatch({ type: POST_FUNERALS_COMMENT_REQUEST });
    
    return axios.post(`api/funerals/${funeralId}/comments`, snakeCaseKeys(data), {
      // params: params,
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
      }
    })
      .then(response => {
        const commentResponse = camelcaseKeys(response.data).comment;
        dispatch(postFuneralCommentSuccess(commentResponse));
        return response;
      })
      .catch(error => postFuneralCommentFailure(error));
  }
}

export const postFuneralCommentSuccess = (newComment) => {
  if(newComment){
    return {
      type: POST_FUNERALS_COMMENT_SUCCESS,
      newComment
    }
  }else{
    return {
      type: POST_FUNERALS_COMMENT_SUCCESS
    }
  }
}

const postFuneralCommentFailure = (error) => {
  return {
    type: POST_FUNERALS_COMMENT_FAILURE,
    error,
  }
};


export const getFuneralComments = (funeralId) => {
  return dispatch => {
    dispatch({ type: GET_FUNERAL_COMMENTS_REQUEST });
    
    return axios.get(`api/funerals/${funeralId}/comments`, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
      }
    })
      .then(response => {
        const comments = camelcaseKeys(response.data).comments;
        dispatch(getFuneralCommentsSuccess(comments));
        return response;
      })
      .catch(error => getFuneralCommentsFailure(error));
  }
};

const getFuneralCommentsSuccess = (comments) => {
  return {
    type: GET_FUNERAL_COMMENTS_SUCCESS,
    comments,
  }
};

const getFuneralCommentsFailure = (error) => {
  return {
    type: GET_FUNERAL_COMMENTS_FAILURE,
    error,
  }
};

export const postDownloadLink = (downloadId) => {
  return dispatch => {
    dispatch({ type: POST_DOWNLOAD_LINK });
    return axios.post(`api/checkout/payment_successful?download_request_id=${downloadId}`, null, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const downloadRequest = camelcaseKeys(response.data).downloadRequest;
        dispatch(postDownloadLinkSuccess(downloadRequest));
      })
      .catch(error => postDownloadLinkFailure(error));
  }
}

export const postDownloadLinkSuccess = (downloadRequest) => {
    return {
      type: POST_DOWNLOAD_LINK_SUCCESS,
      downloadRequest
    }
}

const postDownloadLinkFailure = (error) => {
  return {
    type: POST_DOWNLOAD_LINK_FAILURE,
    error,
  }
};

export const getFuneralVisitors = (funeralId) => {
  return dispatch => {
    dispatch({ type: GET_FUNERAL_VISITORS_REQUEST });
    
    return axios.get(`api/funerals/${funeralId}/visitors`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const visitors = camelcaseKeys(response.data).visitors;
        dispatch(getFuneralVisitorsSuccess(visitors));
        return response;
      })
      .catch(error => getFuneralVisitorsFailure(error));
  }
};

const getFuneralVisitorsSuccess = (visitors) => {
  return {
    type: GET_FUNERAL_VISITORS_SUCCESS,
    visitors,
  }
};

const getFuneralVisitorsFailure = (error) => {
  return {
    type: GET_FUNERAL_VISITORS_FAILURE,
    error,
  }
};

export const postFuneralReply = (commentId, data) => {
  return dispatch => {
    dispatch({ type: POST_FUNERALS_REPLIES_REQUEST });
    
    return axios.post(`api/comments/${commentId}/replies`, snakeCaseKeys(data), {
      // parameters: parameters,
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
      }
    })
      .then(response => {
        dispatch(postFuneralReplySuccess());
        return response;
      })
      .catch(error => postFuneralReplyFailure(error));
  }
}

export const postFuneralReplySuccess = (newReply) => {
  if(newReply){
    return {
      type: POST_FUNERALS_REPLY_SUCCESS,
      newReply: newReply
    }
  }else{
    return {
      type: POST_FUNERALS_REPLY_SUCCESS
    }
  }
}

const postFuneralReplyFailure = (error) => {
  return {
    type: POST_FUNERALS_REPLY_FAILURE,
    error,
  }
};

export const getDowloadPage = ( videoId , useInternalApiKey = false) => {
  const token = useInternalApiKey ? (process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc') : localStorage.getItem("token");
  return dispatch => {
    dispatch({ type: GET_DOWNLOAD_PAGE });
    
    return axios.get(`/api/video_request/${videoId}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        const videoPageData = camelcaseKeys(response.data);
        dispatch(getDowloadPageSuccess(videoPageData));
      })
      .catch(error => dispatch(getDowloadPageFailure(error)));
  }
};

const getDowloadPageSuccess = (download) => {
  return {
    type: GET_DOWNLOAD_PAGE_SUCCESS,
    download,
  }
};

const getDowloadPageFailure = (error) => {
  return {
    type: GET_DOWNLOAD_PAGE_FAILURE,
    error,
  }
};

export const postStripePayment = (payload) => {
  return dispatch => {
    dispatch({ type: POST_STRIPE_PAYMENT });
    
    return axios.post(`api/checkout/create_session`, snakeCaseKeys(payload), {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
      }
    })
      .then(response => {
        const paymentResponse = camelcaseKeys(response.data);
        dispatch(postStripePaymentSuccess(paymentResponse));
        return response;
      })
      .catch(error => postStripePaymentFailure(error));
  }
}
export const postStripePaymentSuccess = (payment) => {
    return {
      type: POST_STRIPE_PAYMENT_SUCCESS,
      payment
    }
}
const postStripePaymentFailure = (error) => {
  return {
    type: POST_STRIPE_PAYMENT_FAILURE,
    error,
  }
};

export const postMessagesToEmail = (funeralId, payload) => {
  return dispatch => {
    dispatch({ type: EXPORT_MESSAGES_VIA_EMAIL });
    return axios.post(`api/funerals/${funeralId}/comments/export`, snakeCaseKeys(payload), {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const exportResponse = camelcaseKeys(response.data).visitor;
        dispatch(postMessagesToEmailSuccess(exportResponse));
        return response;
      })
      .catch(error => postMessagesToEmailFailure(error));
  }
}
export const postMessagesToEmailSuccess = (response) => {
  return {
    type: EXPORT_MESSAGES_VIA_EMAIL_SUCCESS,
    response
  }
}
const postMessagesToEmailFailure = (error) => {
  return {
    type: EXPORT_MESSAGES_VIA_EMAIL_FAILURE,
    error,
  }
};

export const postBuyFuneralLinksToEmail = (funeralId, payload) => {
  return dispatch => {
    dispatch({ type: EXPORT_BUY_FUNERAL_VIA_EMAIL });
    return axios.post(`api/funerals/${funeralId}/buy_funeral_links`, snakeCaseKeys(payload), {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const exportResponse = camelcaseKeys(response.data).visitor;
        dispatch(postBuyFuneralLinksToEmailSuccess(exportResponse));
        return response;
      })
      .catch(error => postBuyFuneralLinksToEmailFailure(error));
  }
}
export const postBuyFuneralLinksToEmailSuccess = (response) => {
  return {
    type: EXPORT_BUY_FUNERAL_VIA_EMAIL_SUCCESS,
    response
  }
}
const postBuyFuneralLinksToEmailFailure = (error) => {
  return {
    type: EXPORT_BUY_FUNERAL_VIA_EMAIL_FAILURE,
    error,
  }
};

export const getUpcomingFunerals = () => {
  return dispatch => {
    dispatch({ type: GET_UPCOMING_FUNERALS_REQUEST });
    return axios.get(`api/upcoming_funerals`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        const upcomingFunerals = camelcaseKeys(response.data).funerals;
        dispatch(getUpcomingFuneralsSuccess(upcomingFunerals));
        return response;
      })
      .catch(error => getUpcomingFuneralsFailure(error));
  }
};
const getUpcomingFuneralsSuccess = (upFunerals) => {
  return {
    type: GET_UPCOMING_FUNERALS_REQUEST_SUCCESS,
    upFunerals,
  }
};
const getUpcomingFuneralsFailure = (error) => {
  return {
    type: GET_UPCOMING_FUNERALS_REQUEST_FAILURE,
    error,
  }
};

export const deleteFuneral = (funeralId) => {
  return dispatch => {
    dispatch({ type: DELETE_FUNERAL_REQUEST });
    return axios.delete(`api/funerals/${funeralId}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      dispatch(deleteFuneralSuccess(funeralId));
      return response;
    })
    .catch(error => {
      if( error.response ){
        dispatch(deleteFuneralFailure(error.response.data.message));
      } else {
        dispatch(deleteFuneralFailure("something went wrong"));
      }
      return error;
    });
  }
};
const deleteFuneralSuccess = (funeralId) => {
  return {
    type: DELETE_FUNERAL_SUCCESS,
    funeralId,
  }
};
const deleteFuneralFailure = (message) => {
  return {
    type: DELETE_FUNERAL_FAILURE,
    message,
  }
};

export function loadingAction(value){
	return{
		type: LOADING_ACTION,
		payload: value,
	}
}

export function initialStateVal(){
	return{
		type: INITIAL_ACTION,
	}
}