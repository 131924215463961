import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Image } from 'semantic-ui-react';
import { getFuneralHomes } from '../redux/funeralHome/actions';
import logo from '../assets/svgs/funeral-live-logo.png';


const createCard = (funeralHome, index, history) => {
  return (
    <Card
      key={index}
      image={<Image src={funeralHome.imageUrl || logo} wrapped ui={false} size='large' />}
      header={funeralHome.name}
      meta={funeralHome.billingAddress && `${funeralHome.billingAddress.street}, ${funeralHome.billingAddress.city}, ${funeralHome.billingAddress.province}`}
      description={
        <div>
          <p>Phone: {funeralHome.phone}</p>
          <p>Contact email: {funeralHome.contactEmail}</p>
          <p>flower shop link: {funeralHome.flowerShopLink}</p>
        </div>
      }
      extra={<Button color='black' onClick={() => history.push(`/funeralhomes/${funeralHome.id}`)}>View</Button>}
    />
  );
}

const renderFuneralHomes = (funeralHomes, history) => {
  return funeralHomes.map((funeralHome, index) => {
    return createCard(funeralHome, index, history);
  });
}

const FuneralHomesPage = (props) => {
  useEffect(() => {
    props.getFuneralHomes();
  }, []);

  if (props.funeralHomes) {
    return (
      <Card.Group itemsPerRow={3} style={{ padding: 50, paddingTop: 100 }} stackable doubling>
        {renderFuneralHomes(props.funeralHomes, props.history)}
      </Card.Group>
    );
  }
};

const mapStateToProps = state => ({
  funeralHomes: state.funeralHome.funeralHomes,
});

const mapDispatchToProps = {
  getFuneralHomes,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralHomesPage);