import React, { useState, useEffect } from "react";
import moment from "moment";
import WeekHeader from "./WeekHeader";
import TimeSlotGroup from "./TimeSlotGroup";
import EventHighlighter from "./EventHighlighter";
import { times } from "../../utils";
import { container } from "../styles";

const WeekView = ({ weekDetails, events, roomsSelected, colors, colors2, funeralHomes, updatedRoomsList }) => {
	const [dayEvents, setDayEvents] = useState([]);
	const getDayEvents = (y) => {
		const groups = y.reduce((groups, stream) => {
		let streamId
		if(stream.location === "On-Site"){
			streamId = stream.roomId.split("T")[0];
		}
		else{
			streamId = 1;
		}
		if (!groups[streamId]) {
			groups[streamId] = [];
		}
		groups[streamId].push(stream);
		return groups;
		}, {});
		const groupArrayByCommonStreamDate = Object.keys(groups).map(
		(date) => {
			return {
			streams: groups[date],
			};
		}
		);
		const xyz = groupArrayByCommonStreamDate.map((s1, index) =>
		s1.streams.map((obj) => ({
			...obj,
		}))
		);
		const y1 = xyz.flat();
		updatedRoomsList.forEach((obj, index) => {
			y1.forEach((list) => {
				if (obj.id === list.roomId) {
					list["color1"] = obj.color;
					list["color2"] = obj.color1;
				}
				else if(list.roomId === 1){
                    list["color1"] = "#001A20";
					list["color2"] = "#E6E9E9";
                }
		  });
		});
		setDayEvents(y1);
	}
	useEffect(() => {
  	}, [dayEvents]);
	useEffect(() => {
		if (roomsSelected.length > 0) {
			const x = events.map((f) =>
				f.streams.filter((s) => roomsSelected.includes(s.roomId))
			);
			const onsiteStreams = x.flat();
      		getDayEvents(onsiteStreams);
		}
		else{
			const x = events.map((f) => f.streams.map((s) => s));
			const y = x.flat();
			const onsiteStreams = y.map(obj =>
				obj.location === "Off-Site" ? { ...obj, roomId: 1 } : obj
			);
			getDayEvents(onsiteStreams);
    	}
	}, [events, roomsSelected]);
	return (
		<div style={container}>
			<WeekHeader weekDays={weekDetails.weekDays} />
			{times.map((time) => (
				<TimeSlotGroup
					key={time}
					time={time}
					weekDays={weekDetails.weekDays}>
					{dayEvents &&
						dayEvents.map(
							(stream) =>
								moment(stream.streamStart).week() <=
									moment(weekDetails.startDate).week() &&
								moment(stream.streamEnd).week() >=
									moment(weekDetails.startDate).week() &&
								parseInt(
									moment(stream.streamStart).format("H")
								) === time && (
									<EventHighlighter
										key={stream.id}
										startDate={weekDetails.startDate}
										event={stream}
										events={events}
										funeralHomes={funeralHomes}
									/>
								)
						)}
				</TimeSlotGroup>
			))}
		</div>
	);
};

export default WeekView;
