import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const CSwitch = withStyles((theme) => ({
    root: {
      width: 44,
      height: 24,
      margin: 2,
      padding: 0,
      position: 'relative'
    },
    switchBase: {
      padding: 4,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '&$checked': {
        transform: 'translate(20px, -50%)',
        color: '#ffffff',
        '& + $track': {
          backgroundColor: '#00667E',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#00667E',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 30 / 2,
      border: `1px solid #C4C4C4`,
      backgroundColor: '#C4C4C4',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  export default CSwitch;
// export default function CustomizedSwitches(){
//     return (
//         <CSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />
//     )
// }