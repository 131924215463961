import moment from "moment";
export const getAllDaysInTheWeek = (currentDate = moment()) => {
	const weekStart = currentDate.clone().startOf("week");
	const days = Array.from(Array(7))
		.map((day, index) => index)
		.map((day) =>
			moment(weekStart)
				.add(day, "days")
				.set("minutes", 0)
				.set("seconds", 0)
		)
		.map((momentObj) => ({
			date: momentObj.date(),
			dateStamp: +momentObj,
			weekDayName: momentObj.format("ddd"),
		}));
	return days;
};
export const times = [
	7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
];
export const generateWeekViewCoordinates = (event, startDate) => {
	const start = moment(event.streamStart);
	const end = moment(event.streamEnd);
	const duration = moment.duration(end.diff(start));
	const weekStart = moment(startDate);
	const top =
		start.minutes() === 30 ? "50%" : start.minutes() === 15 ? "25%" : "0%";
	const marginTop =
		start.minutes() === 30
			? "36px"
			: start.minutes() === 15
			? "18px"
			: start.minutes() === 45
			? "54px"
			: "0px";
	const timeFactor = duration.hours() + duration.minutes() / 60;
	const height = timeFactor * 72;
	let left, width;
	if (weekStart.week() === start.week()) {
		const weekDay = start.weekday();
		left = weekDay * 12.5;
		// let count = 0;
		// const currentEventDate = moment(event.streamStart, "YYYY-MM-DD");
		// eventss.map((data) => {
		// 	if (currentEventDate.isSame(moment(data.streamStart, "YYYY-MM-DD"))){
		// 		var format = 'HH:mm:ss'
		// 		var eventStartTime = moment(moment(event.streamStart).format("HH:mm:ss"),format);
		// 		var eventEndTime = moment(moment(event.streamEnd).format("HH:mm:ss"),format);
		// 		var beforeTime = moment(moment(data.streamStart).format("HH:mm:ss"), format);
		// 		var afterTime = moment(moment(data.streamEnd).format("HH:mm:ss"), format);
		// 		if (eventEndTime.isBetween(beforeTime, afterTime) || eventStartTime.isBetween(beforeTime, afterTime)) {
		// 			count=count+1;
		// 		}
		// 	}
		// });
		// left = weekDay * 144 + 96 + (count!== 0 ? (Math.ceil(144/(count+1))) : 0);
		// console.log(left)
	}

	if (weekStart.week() === start.week() && weekStart.week() === end.week()) {
		const daysDiff = duration.days();
		width = 12.5;
	}

	// if (weekStart.week() > start.week() && weekStart.week() === end.week()) {
	// 	const daysDiff = moment
	// 		.duration(
	// 			end.diff(
	// 				weekStart
	// 					.startOf("week")
	// 					.set("hours", start.hours())
	// 					.set("minutes", start.minutes())
	// 			)
	// 		)
	// 		.days();
	// 	width = (daysDiff + 1) * 12.5 - 2;
	// }

	// if (weekStart.week() > start.week()) {
	// 	left = 12.5;
	// }

	// if (weekStart.week() < end.week()) {
	// 	width = 100 - left;
	// }

	return {
		top: top + "%",
		left: left + "%",
		height: height + "px",
		width: width + "%",
		marginTop: marginTop,
	};
};
export const isTodaysDate = (dateStamp) => {
	const today = moment();
	dateStamp = moment(dateStamp);
	return (
		moment.duration(dateStamp.diff(today)).days() === 0 &&
		today.day() === dateStamp.day()
	);
};
