import {POST_CREDITS_PAYMENT, POST_CREDITS_PAYMENT_SUCCESS, 
  POST_CREDITS_PAYMENT_FAILURE, LOADING_ACTION,  
  GET_CREDIT_CARDS_SUCCESS, POST_CREDIT_CARD,
  POST_CREDIT_CARD_SUCCESS, POST_CREDIT_CARD_FAILURE,
  POST_EXISTING_CREDITCARD_PAYMENT,
  POST_EXISTING_CREDITCARD_PAYMENT_SUCCESS,
  POST_EXISTING_CREDITCARD_PAYMENT_FAILURE,
  UPDATE_EXISTING_CARD_SUCCESS,
  INITIAL_ACTION, UPDATE_EXISTING_CARD,
  UPDATE_EXISTING_CARD_FAILURE,
  GET_TRANSACTIONS_SUCCESS,
  INITIAL_BILLING_ACTION,
  POST_REQUEST_DEMO,
  POST_REQUEST_DEMO_SUCCESS,
  POST_REQUEST_DEMO_FAILURE,
  POST_FUNERAL_VIDEO_PAYMENT, 
  POST_FUNERAL_VIDEO_PAYMENT_SUCCESS,
  POST_FUNERAL_VIDEO_PAYMENT_FAILURE} from './actions';
const AJAX_STATE = {      
  IDEAL: "IDEAL",
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
}
const initialState = {
  creditPaymentRes: AJAX_STATE.IDEAL,
  demoDataRes: AJAX_STATE.IDEAL,
  availableCreditCards: [],
  loader: true,
  creditcardRes: AJAX_STATE.IDEAL,
  existingCardPaymentRes: AJAX_STATE.IDEAL,
  updateCardRes: AJAX_STATE.IDEAL,
  funeralVideoPayment: AJAX_STATE.IDEAL,
  transactionsRes: []
}
function reducer(state = initialState, action) {
    switch(action.type) {
      case POST_CREDITS_PAYMENT:
        return { ...state, creditPaymentRes: AJAX_STATE.REQUEST }
      case POST_CREDITS_PAYMENT_SUCCESS:
        return { ...state, creditPaymentRes: AJAX_STATE.SUCCESS }
      case POST_CREDITS_PAYMENT_FAILURE:
        return { ...state, creditPaymentRes: AJAX_STATE.ERROR }
      case POST_REQUEST_DEMO:
        return { ...state, demoDataRes: AJAX_STATE.REQUEST }
      case POST_REQUEST_DEMO_SUCCESS:
        return { ...state, demoDataRes: AJAX_STATE.SUCCESS }
      case POST_REQUEST_DEMO_FAILURE:
        return { ...state, demoDataRes: AJAX_STATE.ERROR }
      case POST_CREDIT_CARD:
        return { ...state, creditcardRes: AJAX_STATE.REQUEST }
      case POST_CREDIT_CARD_SUCCESS:
        return { ...state, creditcardRes: AJAX_STATE.SUCCESS }
      case POST_CREDIT_CARD_FAILURE:
        return { ...state, creditcardRes: AJAX_STATE.ERROR }
      case GET_CREDIT_CARDS_SUCCESS:
        return { ...state, availableCreditCards: action.cardInfo }
      case UPDATE_EXISTING_CARD:
        return { ...state, updateCardRes: AJAX_STATE.REQUEST }
      case UPDATE_EXISTING_CARD_SUCCESS:
        return { ...state, updateCardRes: AJAX_STATE.SUCCESS }
      case UPDATE_EXISTING_CARD_FAILURE:
        return { ...state, updateCardRes: AJAX_STATE.ERROR }
      case POST_EXISTING_CREDITCARD_PAYMENT:
        return { ...state, existingCardPaymentRes: AJAX_STATE.REQUEST }
      case POST_EXISTING_CREDITCARD_PAYMENT_SUCCESS:
        return { ...state, existingCardPaymentRes: AJAX_STATE.SUCCESS }
      case POST_EXISTING_CREDITCARD_PAYMENT_FAILURE:
        return { ...state, existingCardPaymentRes: AJAX_STATE.ERROR }
      case POST_FUNERAL_VIDEO_PAYMENT:
        return { ...state, funeralVideoPayment: AJAX_STATE.REQUEST }
      case POST_FUNERAL_VIDEO_PAYMENT_SUCCESS:
        return { ...state, funeralVideoPayment: AJAX_STATE.SUCCESS }
      case POST_FUNERAL_VIDEO_PAYMENT_FAILURE:
        return { ...state, funeralVideoPayment: AJAX_STATE.ERROR }
      case GET_TRANSACTIONS_SUCCESS:
        return { ...state, transactionsRes: [...state.transactionsRes, action.transactionInfo] }
      case LOADING_ACTION:
        return { ...state, loader: action.payload }
      case INITIAL_ACTION:
        return { ...state, creditPaymentRes: AJAX_STATE.IDEAL, creditcardRes: AJAX_STATE.IDEAL, 
          existingCardPaymentRes: AJAX_STATE.IDEAL, updateCardRes: AJAX_STATE.IDEAL, funeralVideoPayment: AJAX_STATE.IDEAL, demoDataRes: AJAX_STATE.IDEAL}
      case INITIAL_BILLING_ACTION:
        return { ...state, transactionsRes: []}
      default:
        return state
    }
}  

export default reducer;