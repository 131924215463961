import React, { useState } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/svgs/funeral-live-logo.png';
import CSS from './LoginPage.module.scss';
import { loginUser } from '../../redux/user/actions';
import { Redirect, NavLink as Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from 'react-i18next';

const LoginPage = (props) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dropdownOpen, setDropdown] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
    () => {
			const savedLanguage = localStorage.getItem("language");
			i18n.changeLanguage(savedLanguage);
			return savedLanguage;
		}
  );

  // Redirect if user is logged in
  if (props.user?.loggedIn) {
    return <Redirect to="/funerals" />;
  }

	const toggleDropdown = () => setDropdown(!dropdownOpen);
  const handleLogin = async () => {
  const result = await props.loginUser(email, password);
    if (result.status === 200) {
      props.history.push('/funerals');
    }
  }
  const handleContact = () => {
    window.open("https://funerallive.ca/contact-us/");
  };
  const openFuneralLive = () => {
    window.open("https://funerallive.ca/");
  };
  const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		if(event.target.value === "en"){
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
		}
		else if(event.target.value === "fr"){
			i18n.changeLanguage("fr");
			localStorage.setItem("language", "fr");
		}
	};
  return (
    <div>
      <section className={CSS.navWrapper}>
        <div className={CSS.logoWrapper}>
          <img src={logo} alt="Logo" onClick={openFuneralLive}/>
        </div>
        <div className={CSS.navTitles}>
          <Link to="/resetPassword" activeStyle={{ color: '#167434' }}>
            {t('pages.sign_in.forgot_password')}
          </Link>
          <Link to="/#" onClick={handleContact}>{t('pages.sign_in.contact')}</Link>
          <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
            <option value="en">En</option>
            <option value="fr">Fr</option>
          </select>
        </div>
        <div className={CSS.burgerMenu}>
          {dropdownOpen ? (
            <CloseIcon onClick={toggleDropdown} />
          ) : (
            <MenuIcon onClick={toggleDropdown} />
          )}
          {dropdownOpen && (
            <div className={CSS.dropdownMenu}>
              <Link to="/resetPassword" activeStyle={{ color: '#167434' }}>
              `{t('pages.sign_in.forgot_password')}
              </Link>
              <Link to="/#" onClick={handleContact}>`{t('pages.sign_in.contact')}</Link>
            </div>
          )}
          <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
            <option value="en">En</option>
            <option value="fr">Fr</option>
          </select>
        </div>
      </section>
      <section className={CSS.loginForm}>
        <div className={CSS.sectionContainer}>
        <div className={CSS.formWrappper}>
						<span className={CSS.formHeading}>
            {t('pages.sign_in.sign_in_title')}
						</span>
						<div className={CSS.formElements}>
							<div className={CSS.inputWrapperRow}>
								<div className={CSS.inputWrapper}>
									<label className={CSS.inputHeader}>
                    {t('pages.sign_in.email')}
									</label>
									<input
										className={CSS.inputElement}
										type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}></input>
								</div>
							</div>
              <div className={CSS.inputWrapperRow}>
                <div className={CSS.inputWrapper}>
                  <label className={CSS.inputHeader}>
                    {t('pages.sign_in.password')}
                  </label>
                  <input
                    className={CSS.inputElement}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    ></input>
                </div>
              </div>
						</div>
						<div>
            {props.user.error && 
            <div className={CSS.userMsg}>
              <div className={CSS.userMessageContainer}>
                {t('pages.sign_in.password_error')}
              </div>
            </div>
          }
							<div className={CSS.submitWrapper}>
                <Link to="/resetPassword" className={CSS.linkText}>
                  {t('pages.sign_in.forgot_password')}
                </Link>
								<button className={`${CSS.actionBtn} ${CSS.warning}`} type="button" disabled={!email || !password} onClick={handleLogin}>
                  {t('pages.sign_in.sign_in_button')}
								</button>
							</div>
						</div>
					</div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user.currentUser,
});

const mapDispatchToProps = {
  loginUser
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
