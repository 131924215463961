import { ORG_LIST_REQUEST_SUCCESS, GET_ORG_REQUEST_SUCCESS } from './actions';

const initialState = {
  organizations: [],
  organization: undefined
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case ORG_LIST_REQUEST_SUCCESS:
      return { ...state, organizations: action.organizations };
    case GET_ORG_REQUEST_SUCCESS:
      return { ...state, organization: action.organization };
    default:
      return state;
  }
}

export default reducer;
