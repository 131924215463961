import React, {useState,useEffect} from "react";
import CSS from "../genericStyles/checkoutForm.module.scss";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import VISACARDICON from '../assets/svgs/visa.png';
import MASTERCARDICON from '../assets/svgs/masterCard.png';
import Spinner from "./Spinner";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { getCreditCards, loadingAction, postExistingCredicardPayment, deleteCreditCard, 
    initialStateVal, postCreditsPayment,} from "../redux/payment/actions";
import AddIcon from '@mui/icons-material/Add';
import PaymentSuccess from "./PaymentSuccess";
import CheckoutFormData from "./CheckoutFormData";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from 'react-i18next';
import "../components/ModalPopup.scss";
const StripeCheckoutForm = (props) => {
    const {data, getCreditCards, loadingAction, creditCards, showLoading, postExistingCredicardPayment, 
        paymentRes ,paymentResponse, deleteCreditCard, initialStateVal, updateCardResponse, addCreditCardres} = props;
    const { id } = useParams();
	const { t } = useTranslation();
    const [pageState, setPageState] = useState("");
    const [cardId, setCardId] = useState("");
    const [cardsList, setCardsList] = useState([]);
    const [editCardId, setEditCardId] = useState("");
    const [currentCardData, setCurrentCardData] = useState("");
	const [open, setOpen] = useState(false);
    useEffect(() => {
        initialStateVal();
		getCreditCards(id, false);
		loadingAction(true);
	}, []);
    useEffect(() => {
        if(creditCards?.creditCards?.length>0){
            setCardsList(creditCards.creditCards);
        }
	}, [creditCards, showLoading, pageState, cardId]);
    useEffect(() => {
    }, [cardsList, editCardId, currentCardData]);
	useEffect(() => {
	}, [paymentRes, paymentResponse]);
    const handleClose = () => {
		setOpen(false);
        setCurrentCardData("");
	};
    const deleteCreditCardModal = () =>{
		return(
			<>
				<div className={"modalPopup2Wrapper"}>
					<div className={"modalPopupHeaderWrapper"}>
						<div className={"modalPopupTitle"} style={{paddingRight: "16px", wordBreak: "break-word"}}>
                            {t('pages.checkout_form.delete_card_hint')}
						</div>
						<div className={"modalPopupCloseBtn"}>
							<CloseIcon
								className={"modalPopupCloseIcon"}
								onClick={handleClose}
							/>
						</div>
					</div>
                    {currentCardData &&
						<div className={"modalPopupBody"} style={{padding: "0px 24px 16px", overflow: "hidden"}}>
                            <div className="modalHint">
                            {t('pages.checkout_form.permanent_card_removal_hint')}
                            </div>
                            <div className="cardData">
                                <img src={currentCardData.brand==="Visa"? VISACARDICON : MASTERCARDICON} height={40} width={62}/>
                                <div className="cardBody">
                                    {currentCardData.brand}{" ...."}{currentCardData.last4}
                                </div>   
                            </div>
                            <div className="btns">
                                <button
                                    className={"cancelBtn"}
                                    onClick={() => {
                                        handleClose()
                                    }}>
                                    {t('pages.checkout_form.cancel')}
                                </button>
                                <button
                                    className={"commitBtn"}
                                    onClick={(e) => {
                                        handleDeleteCard(e,currentCardData.id);
                                        handleClose();
                                    }}>
                                    {t('pages.checkout_form.delete')}
                                </button>
                            </div>
                        </div>
                    }
				</div>
			</>
		)
	}
    const handleExistingCardId = (cardid) => {
        setCardId(cardid);
    };
    const handleExistingCardPayment = async (e) => {
        initialStateVal();
        e.preventDefault();
        const paymentPayload = {
            credit_card_id: cardId,
            credits: data.credits,
            amount: data.total,
        }
        await postExistingCredicardPayment(paymentPayload, id, false);
    };
    const handleDeleteCard = async (e,cardid) => {
        e.preventDefault();
		loadingAction(true);
        await deleteCreditCard(id, cardid, false);
        await getCreditCards(id,false);
    }
    const handleEditCard = (e,cardid) => {
        e.preventDefault();
        initialStateVal();
        setEditCardId(cardid);
        setPageState("Edit");
    }
    const handleCanel = async (e) => {
        e.preventDefault(); 
        await initialStateVal();
        setPageState("");
    }
	return(
        <div>
            {paymentResponse === "IDEAL" || paymentResponse === "ERROR"?
                <>
                    {paymentRes !== "SUCCESS" ?
                        pageState === "Add" || pageState === "Edit"?
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <div className={CSS.checkoutFormHeader}>
                                    {pageState === "Add" ? t('pages.checkout_form.new_card') : t('pages.checkout_form.edit_card')}
                                 </div>
                                <button className={`${CSS.cancelButton} ${
									(updateCardResponse==="REQUEST" || addCreditCardres==="REQUEST") 
                                    ? CSS.disabled : ""}`} onClick={(e) => {handleCanel(e)}}>
                                    {t('pages.checkout_form.cancel')}
                                </button>
                            </div>
                            : 
                            <div className={CSS.checkoutFormHeader}>
                                {t('pages.checkout_form.pay_with_card_title')}
                            </div>
                        : ""}
                    {showLoading?
                        <Spinner/>
                        :
                        (creditCards?.creditCards?.length === 0 || pageState==="Add" || pageState==="Edit") ? (
                            <CheckoutFormData data={data} homeId={id} pageState={pageState} setPageState={setPageState} 
                            cardsAvailable={creditCards?.creditCards} editCardId={editCardId}/>
                        ) 
                        : (
                            <div className={CSS.CheckoutFormData}>
                                <div className={CSS.paymentHint} style={{ marginTop: 16 }}>
                                    {t('pages.checkout_form.choose_payment')}
                                </div>
                                {creditCards?.creditCards?.map((card) => {
                                    return(
                                        <div key={card.id}>
                                            <div className={CSS.availableCreditCards} style={{border: card.id===cardId ? "1px solid #00667E": "1px solid #C4D5D9"}}>
                                                <div className={CSS.eachCard}>
                                                    <input
                                                        id="credit"
                                                        value="credit"
                                                        name="check"
                                                        type="radio"
                                                        className={CSS.radioBtn}
                                                        onChange={()=>{handleExistingCardId(card.id)}}
                                                    />
                                                    <img src={card.brand==="Visa"? VISACARDICON : MASTERCARDICON} height={40} width={62}/>
                                                </div>
                                                <div className={CSS.editDelete}>
                                                    <div className={CSS.last4}>
                                                        {card.brand}{" ...."}{card.last4}
                                                    </div>
                                                    <div className={CSS.icons}>
                                                        <EditIcon className={CSS.EditIcon} onClick={(e) => handleEditCard(e,card.id)}/>
                                                        <DeleteForeverIcon className={CSS.EditIcon} onClick={(e) => {
                                                            setCurrentCardData(card);
                                                            setOpen(true);
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {paymentResponse === "ERROR" ?
                                    <div style={{color: "#AE0000", fontFamily: 'Bitter,serif', fontStyle: 'normal', fontWeight: 300,
                                        fontSize: '14px', lineHeight: '21px'}}>
                                        {t('pages.checkout_form.authenticate_payment_error')} 
                                        {t('pages.checkout_form.different_payment_method_hint')} 
                                    </div> : ""
                                }
                                <div className={CSS.addCard} onClick={(e) => {e.preventDefault(); setPageState("Add")}}>
                                    <AddIcon />
                                    {t('pages.checkout_form.new_card')}
                                </div>
                                <button className={`${CSS.orderButton} ${ cardId ? "" : CSS.disabled }`} onClick={(e) => handleExistingCardPayment(e)}>
                                    {t('pages.checkout_form.pay_btn')}
                                </button>
                            </div>
                        )
                    }
                </>
            : paymentResponse === "REQUEST" ?
				<Spinner style={{position: 'absolute', height: '100px', width: '100px', top: '50%', left: '50%',
					marginLeft: '-50px', marginTop: '-50px'}}/>
			: paymentResponse === "SUCCESS" ?
				<PaymentSuccess />
			: ""
            }
            <Modal open={open} onClose={handleClose}>
                {deleteCreditCardModal()}
            </Modal>
        </div>
    )
};

const mapStateToProps = (state) => ({
	creditCards: state.payment.availableCreditCards,
	showLoading: state.payment.loader,
	paymentRes: state.payment.creditPaymentRes,
	paymentResponse: state.payment.existingCardPaymentRes,
    updateCardResponse: state.payment.updateCardRes,
	addCreditCardres: state.payment.creditcardRes,
});
const mapDispatchToProps = {
	getCreditCards,
	loadingAction,
    postExistingCredicardPayment,
    deleteCreditCard,
    postCreditsPayment,
	initialStateVal
};
export default connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutForm);
