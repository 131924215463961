import React from 'react';
import logo from "../../../src/assets/svgs/funeral-live-logo.png";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useState, useEffect } from "react";
import CSS from "./VideoRequestPage.module.scss";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { connect } from "react-redux";
import { getFuneralHome } from "../../redux/funeralHome/actions";
import defaultImage from "../../assets/svgs/default_image.jpg";
import validator from 'validator';
import { Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import Spinner from "../../components/Spinner";
import { postDownloadLink, getDowloadPage, postStripePayment } from '../../redux/funeral/actions';

function VideoRequestPage(props) {
    const {
		match: {
			params: { video_request_id: videoReqId },
		},
	} = props;
    const { getDowloadPage, videoReqData, videoDownloadDataLoading } = props;
    // console.log('videoDownloadDataLoading ', videoDownloadDataLoading);
	const { t } = useTranslation();
    const history = useHistory();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phoneContact, setPhoneContact] = useState("");
    const [errorStates, setErrorStates] = useState({
		email: ""
	});
    const [checkoutValid, setCheckoutValid] = useState(false);
    const openFuneralLive = () => {
		window.open("https://funerallive.ca/");
	};
	const toggleDropdown = () => setDropdown(!dropdownOpen);
    useEffect(() => {
        async function fetchData() {
            await getDowloadPage(videoReqId, true);
        }
        fetchData();
    }, []);
    useEffect(() => {
    }, [videoReqData]);
    useEffect(() => {
		if(validator.isEmail(email) || !email){
			setErrorStates(prev=>{
				return {
					...prev,
					email:""
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					email: t("pages.admin_create_org.add_org.email_validation")
				}
			})
		}
    }, [email]);
    useEffect(() => {
        checkDetailsValid();
    }, [fname, lname, email, phoneContact, errorStates]);
    const checkDetailsValid = () => {
        if(fname &&
            lname &&
            phoneContact &&
            email &&
            !errorStates.email){
                setCheckoutValid(true);
                return true;
            }
            else{
                setCheckoutValid(false);
                return false;
            }
    };
	const [dropdownOpen, setDropdown] = useState(false);
    const convertDateToDateOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("LL");
		}
		return "";
	};
    const downloadVideoClip = async (e) => {
        e.preventDefault();
        const payload ={
            funeral_id : videoReqData.funeral ? videoReqData.funeral.id : '',
            email: email,
            first_name: fname,
            last_name: lname,
            telephone_number: phoneContact
        }
        history.push({
            pathname:
                "/funeralPayment",
            state: {
                visitorPayload: payload,
                deceasedFuneralData: videoReqData
            },
        });
    }

    if (videoDownloadDataLoading) {
        return (
            <div style={{width: '100%', textAlign: 'center' }}>
                <Spinner style={{width: '50%', height: '50%', margin: '0 auto'}}/>
            </div>
        );
    }

    return (
        <div>
            {videoReqData && (
				<div className={CSS.navWrapper}>
					<div className={CSS.logoWrapper}>
						<img src={logo} alt="Logo" onClick={openFuneralLive} />
					</div>
					<div className={CSS.navTitles}>
                    {videoReqData?.funeralHome?
						<Link
							to={{ pathname: videoReqData.funeralHome.flowerShopLink }}
							target="_blank">
							{t('navigation.viewer_send_flowers')}
						</Link>: ""}
                        {videoReqData?.funeralHome?
						<Link to={{ pathname: videoReqData.funeralHome.baseUrl }}
							target="_blank">{videoReqData.funeralHome.name}</Link>:""}
					</div>
					<div className={CSS.burgerMenu}>
						{dropdownOpen ? (
							<CloseIcon onClick={toggleDropdown} />
						) : (
							<MenuIcon onClick={toggleDropdown} />
						)}
						{dropdownOpen && (
							<div className={CSS.dropdownMenu}>
                                {videoReqData.funeralHome? 
								<Link
									to={{
										pathname:
											videoReqData.funeralHome.flowerShopLink,
									}}
									target="_blank">
									{t('navigation.viewer_send_flowers')}
								</Link>: ""}
                                {videoReqData?.funeralHome?
								<Link to={{ pathname: videoReqData.funeralHome.baseUrl }}
							        target="_blank">{videoReqData.funeralHome.name}</Link>:""}
							</div>
						)}
					</div>
				</div>
			)}
            {Object.keys(videoReqData).length > 0 && videoReqData.clipsToDownload?.length > 0  && (
                <>
                <div className={CSS.sectionWrapper}>
                    <section className={CSS.formWrapper}>
                        <span className={CSS.formTitlte}>{t('pages.request_video.request_page_title')}</span>
                        <div>
                            <span className={CSS.formHint}>{t('pages.request_video.request_page_message')}</span>
                        </div>
                    </section>
                    <section className={CSS.formWrapper}>
                        <span className={CSS.formTitlte}>{t('pages.request_video.details')}</span>
                        <div>
                            <span className={CSS.formHint}>{t('pages.request_video.message')}</span>
                        </div>
                    </section>
                </div>
                <section className={CSS.formWrapper}>
                    <div className={CSS.elementRows}>
                        <div className={CSS.formElementsRow}>
                            <div className={CSS.elementWrapper}>
                                <label className={CSS.elementLabel}>
                                {t('pages.request_video.fname_label')}
                                </label>
                                <TextField
                                    className={`${CSS.inputEle} ${CSS.size_m}`}
                                    variant="outlined"
                                    value={fname}
                                    onChange={(e) => {
                                        setFname(e.target.value);
                                    }}
                                />
                            </div>
                            <div className={CSS.elementWrapper}>
                                <label className={CSS.elementLabel}>
                                {t('pages.request_video.lname_label')}
                                </label>
                                <TextField
                                    className={`${CSS.inputEle} ${CSS.size_m}`}
                                    variant="outlined"
                                    value={lname}
                                    onChange={(e) => {
                                        setLname(e.target.value);
                                    }}
                                />
                            </div>
                            <div className={CSS.elementWrapper}>
                                <label className={CSS.elementLabel}>
                                {t('pages.request_video.email_label')}
                                </label>
                                <TextField
                                    className={errorStates.email? `${CSS.inputEleError} ${CSS.size_m}` :`${CSS.inputEle} ${CSS.size_m}`}
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                                <div className={CSS.errorText}>{errorStates.email}</div>
                            </div>
                            <div className={CSS.elementWrapper}>
                                <label className={CSS.elementLabel}>
                                {t('pages.request_video.phone')}
                                </label>
                                <TextField
                                    className={`${CSS.inputEle} ${CSS.size_m}`}
                                    variant="outlined"
                                    value={phoneContact}
                                    onChange={(e) => {
                                        setPhoneContact(e.target.value);;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <div className={CSS.sectionWrapper}>
                    <section className={CSS.formWrapper}>
                        <div className={CSS.deceasedView}>
                            <div className={CSS.imageView}>
                                <div className={CSS.img}>
                                    <img className={CSS.imageData}
                                        src={defaultImage}
                                        alt="Profile"
                                    />
                                </div>
                                {videoReqData.funeral ?
                                <div className={CSS.deceasedName}>
                                    {videoReqData.funeral.firstName}{" - "}{convertDateToDateOnly(videoReqData.funeral.dateOfDeath)}
                                </div>: ""}
                            </div>
                            <Grid columns={3}>
                                {videoReqData.clipsToDownload?.map((clip, index) =>
                                    <Grid.Row className={CSS.thumbnailWrapper} key={index}>
                                        <Grid.Column><img className={CSS.thumbnail} alt="thumbnail" src={clip.thumbnail} key={clip.eventId} /></Grid.Column>
                                        <Grid.Column>
                                            <p>{clip.name} {t('pages.request_video.streamed_on')} {convertDateToDateOnly(clip.streamStart)}</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>
                            <div className={CSS.submitWrapper}>
                                <button className={`${CSS.submitBtn}  ${CSS.warning} ${checkoutValid ? "" : CSS.disabled}`} 
                                onClick={downloadVideoClip}>
                                    {t('pages.request_video.checkout')} {"$50 CAD + taxes"}
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </>
            )}
            {Object.keys(videoReqData).length === 0 && (
                <div className={CSS.sectionWrapper}>
                    <section className={CSS.formWrapper}>
                        <span className={CSS.formTitlte}>{t('pages.request_video.no_funeral_title')}</span>
                        <div>
                            <span className={CSS.formHint}>{t('pages.request_video.no_funeral_message')}</span>
                        </div>
                    </section>
                </div>
            )}
            {Object.keys(videoReqData).length > 0 && videoReqData.clipsToDownload?.length === 0  && (
                <div className={CSS.sectionWrapper}>
                    <section className={CSS.formWrapper}>
                        <span className={CSS.formTitlte}>{t('pages.request_video.no_clips_title')}</span>
                        <div>
                            <span className={CSS.formHint}>{t('pages.request_video.no_clips_message')}</span>
                        </div>
                    </section>
                </div>
            )}
        </div>
    )
}
const mapStateToProps = (state) => ({
	funeralHomeInformation: state.funeralHome.funeralHomeViewing,
    downloadStatus: state.funeral.downloadLink,
    videoReqData: state.funeral.videoDownloadData,
    videoDownloadDataLoading: state.funeral.videoDownloadDataLoading
});

const mapDispatchToProps = {
	getFuneralHome,
    postDownloadLink,
    getDowloadPage,
    postStripePayment
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoRequestPage);
