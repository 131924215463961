import React, { useEffect, useState } from "react";
import CSS from "./CalenderView.module.scss";
import dayjs from "dayjs";
import moment from "moment";
import "../../components/ModalPopup.scss";
import CloseIcon from "@material-ui/icons/Close";
import UpdateEventModal from "./calendarWeekView/weekView/components/UpdateEventModal";

const Day = ({ day, rowIdx, roomId, funerals, roomsSelected, colors, colors2, funeralHomes, updatedRoomsList}) => {
    const [dayEvents, setDayEvents] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
	const [modalId, setModalId] = useState("");
	useEffect(() => {}, [modalId]);
    useEffect(() => {
		if (funerals.length > 0) {
			const x = funerals.map((f) => f.streams.map((s) => s));
			const y = x.flat();
            const onsiteStreams = y.map(obj =>
                obj.location === "Off-Site" ? { ...obj, roomId: 1 } : obj
            );
            getDayEvents(onsiteStreams);
		}
	}, [funerals]);
    const getDayEvents = (y) => {
        const groups = y.reduce((groups, stream) => {
          let streamId
          if(stream.location === "On-Site"){
            streamId = stream.roomId.split("T")[0];
          }
          else{
            streamId = "1";
          }
          if (!groups[streamId]) {
            groups[streamId] = [];
          }
          groups[streamId].push(stream);
          return groups;
        }, {});
        const groupArrayByCommonStreamDate = Object.keys(groups).map(
          (date) => {
            return {
              streams: groups[date],
            };
          }
        );
        const xyz = groupArrayByCommonStreamDate.map((s1, index) =>
          s1.streams.map((obj) => ({
            ...obj,
          }))
        );
        const y1 = xyz.flat();
        updatedRoomsList.forEach((obj, index) => {
			y1.forEach((list) => {
				if (obj.id === list.roomId) {
					list["color1"] = obj.color;
					list["color2"] = obj.color1;
				}
                else if(list.roomId === 1){
                    list["color1"] = "#001A20";
					list["color2"] = "#E6E9E9";
                }
		  });
		});
        const groups1 = y1.reduce((groups1, stream) => {
            const date = stream.streamStart.split('T')[0];
            if (!groups1[date]) {
                groups1[date] = [];
            }
            groups1[date].push(stream);
            return groups1;
        }, {});
        const groupArrayByCommonStreamDate1 = Object.keys(groups1).map((date) => {
            return {
                date,
                streams: groups1[date]
            };
        });
        setDayEvents(groupArrayByCommonStreamDate1);
      }
    useEffect(() => {
    }, [dayEvents]);
    useEffect(() => {
        if (roomsSelected.length > 0) {
            const x = funerals.map((f) => f.streams.filter(s => roomsSelected.includes(s.roomId)));
            const y = x.flat();
            getDayEvents(y)
        }
    }, [roomsSelected]);
    const deceasedLastName = (id) => {
        return funerals.map(funeral => {
            if (funeral.id === id) {
                return funeral.lastName;
            }
        })
    }
    const convertDateToOnlyDate = (unformattedDate) => {
        if (unformattedDate) {
            return moment(unformattedDate).format("LL");
        }
        return "";
    };
    const convertDateToTimeOnly = (unformattedDate) => {
        if (unformattedDate) {
            return moment(unformattedDate).format("h:mm A");
        }
        return "";
    };
    const getCurrentDayClass = () => {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? `${CSS.roundedValue}` : "";
    }
    const showDeceasedStreamInfo = (value) => {
		setModalId(value);
	};
    const handleDrawerOpenEvents = (events) => {
        setDrawerOpen(!drawerOpen);
    }
    const handleDrawerClose = (events) => {
        setDrawerOpen(false);
    }

    return (
        <div className={CSS.eachday}>
            <header className={CSS.dayHeader}>
                {rowIdx === 0 && (
                    <p className={CSS.dateText}>
                        {day.format("ddd").toUpperCase()}
                    </p>
                )}
                <p className={`${CSS.dateValue}  ${getCurrentDayClass()}`}>
                    {day.format("DD")}
                </p>
            </header>
            {dayEvents.length > 0 &&
                dayEvents.map((evt, idx) => (
                    <div className={CSS.eventData} key={idx}>
                        {convertDateToOnlyDate(evt.date) === convertDateToOnlyDate(day.$d) ?
                            <>
                                {evt.streams.slice(0, 2).map((stream) => {
                                    return (
                                        <>
                                            <div className={CSS.eventDataText}>
                                                <div className={CSS.event}
                                                    style={{ 
                                                        // backgroundImage: `linear-gradient(to right, #00667E 0%, #00667E 3%, rgba(0,102,126,0.4) 3%, rgba(0,102,126,0.4) 100%)`,
						                                backgroundImage: `linear-gradient(to right, ${stream.color1} 0%, ${stream.color1} 3%, ${stream.color2} 3%, ${stream.color2} 100%)`,         
                                                        borderRadius: "4px" }}
                                                    onClick={() => showDeceasedStreamInfo(stream.id)}>{convertDateToTimeOnly(stream.streamStart)} {" - "} {convertDateToTimeOnly(stream.streamEnd)}
                                                    <div className={CSS.eventDetails}>{deceasedLastName(stream.funeralId)} {" - "} {stream.name}</div>
                                                </div>
                                            </div>
                                            {modalId ? (
                                                <UpdateEventModal
                                                    stream={stream}
                                                    funerals={funerals}
                                                    modalId={modalId}
                                                    setModalId={setModalId}
                                                    funeralHomes={funeralHomes}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )
                                })}
                                {evt.streams.length > 2 ?
                                    <>
                                        <SlideDrawer show={drawerOpen} events={evt.streams} handleDrawerClose={handleDrawerClose} day={day.$d} convertDateToTimeOnly={convertDateToTimeOnly}
                                            showDeceasedStreamInfo={showDeceasedStreamInfo} deceasedLastName={deceasedLastName} convertDateToOnlyDate={convertDateToOnlyDate} />
                                        {drawerOpen && <BackDrop closeDrawer={handleDrawerClose} />}
                                        <div onClick={() => handleDrawerOpenEvents(evt.streams)} style={{ cursor: 'pointer' }}>{evt.streams.length - 2}{" more"}</div>
                                    </>
                                    : ""}
                            </>
                            : ""}
                    </div>
                ))
            }
        </div>
    );
};

const SlideDrawer = ({ show, events, handleDrawerClose, day, deceasedLastName, convertDateToTimeOnly, showDeceasedStreamInfo, convertDateToOnlyDate }) => {
    return (
        <div className={show ? CSS.sideDrawerOpen : CSS.sideDrawerClose}>
            {show ?
                <div style={{ marginTop: "100px", marginLeft: '20px', marginRight: "20px", marginBottom: "20px" }}>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginBottom: "20px", lineHeight: '39px', fontSize: '26px', color: '#001A20' }}>
                        <div>Streams</div>
                        <CloseIcon style={{ cursor: "pointer", color: "black" }}
                            onClick={() => handleDrawerClose()}
                        />
                    </div>
                    <div style={{ marginBottom: "20px", lineHeight: '24px', fontSize: '18px', color: '#001A20' }}>
                        {convertDateToOnlyDate(day)}
                    </div>
                    {
                        events.map(stream => {
                            return (
                                <div className={CSS.eventDataText}>
                                    <div className={CSS.event}
                                        style={{ 
                                            backgroundImage: `linear-gradient(to right, ${stream.color1} 0%, ${stream.color1} 3%, ${stream.color2} 3%, ${stream.color2} 100%)`,
                                            borderRadius: "4px" }}
                                        onClick={() => showDeceasedStreamInfo(stream.id)}>{convertDateToTimeOnly(stream.streamStart)} {" - "} {convertDateToTimeOnly(stream.streamEnd)}
                                        <div className={CSS.eventDetails}>{deceasedLastName(stream.funeralId)} {" - "} {stream.name}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                : ""}
        </div>
    );
};

const BackDrop = (props) => {
    return <div className={CSS.backdrop} onClick={props.closeDrawer}></div>;
};

export default Day;
