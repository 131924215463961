import React, { useRef, useEffect } from "react";
import { Row, Col } from "antd";
import TimeSlot from "./TimeSlot";
import { row, timeCol, timeString } from "../styles";
import moment from "moment";

function TimeSlotGroup(props) {
	const myRef = useRef(null);
	const formattedTime = moment().set("hours", props.time).format("h a");
	useEffect(() => {
		if (formattedTime === "9 am") {
			const executeScroll = () => myRef.current.scrollIntoView();
			executeScroll();
		}
	}, []);
	return (
		<Row
			key={props.time}
			style={{
				row,
				display: "flex",
				alignItems: "stretch",
				position: "relative",
			}}>
				{props.weekDays.map((day) => (
					<TimeSlot
						key={day.dateStamp}
						dateStamp={day.dateStamp}
						time={props.time}
					/>
				))}
				{props.children}
				<Col style={timeCol}>
					<span ref={myRef} style={timeString}>
						{formattedTime}
					</span>
				</Col>
		</Row>
	);
}

export default TimeSlotGroup;
