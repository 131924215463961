import React, { Fragment, useEffect, useState } from 'react';
import moment from "moment";
import CSS from "./CalenderView.module.scss";

const EventsView = ({ funerals, currentMonthIdx, funeralHomes }) => {
    const [dayEvents, setDayEvents] = useState([]);
    const [funeralData, setFuneralData] = useState([]);
    useEffect(() => {
        if (funerals) {
            setFuneralData(funerals);
            const x = funerals.map((f) => f.streams.map(s => s));
            const y = x.flat();
            const groups = y.reduce((groups, stream) => {
                const date = stream.streamStart.split('T')[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(stream);
                return groups;
            }, {});
            const groupArrayByCommonStreamDate = Object.keys(groups).map((date) => {
                return {
                    date,
                    streams: groups[date]
                };
            });
            setDayEvents(groupArrayByCommonStreamDate);
        }
    }, []);
    useEffect(() => {
    }, [dayEvents, funeralData])
    const convertDateToOnlyDate = (unformattedDate) => {
        if (unformattedDate) {
            return moment(unformattedDate).format("LL");
        }
        return "";
    };
    const convertDateToDateOnly = (unformattedDate) => {
        if (unformattedDate) {
            return moment(unformattedDate).format("L");
        }
        return "";
    };
    const convertDateToDayOnly = (unformattedDate) => {
        if (unformattedDate) {
            return moment(unformattedDate).format("dddd");
        }
        return "";
    };
    const convertDateToTimeOnly = (unformattedDate) => {
        if (unformattedDate) {
            return moment(unformattedDate).format("h:mm A");
        }
        return "";
    };
    const deceasedLastName = (id) => {
        return funeralData.map(funeral => {
            if (funeral.id === id) {
                return funeral.lastName;
            }
        })
    };
    let eventsForTheWeek = [];
    const getAllDates = () => {
        const start = moment().toDate();
        const end = moment().endOf('week').toDate();
        let loop = new Date(start);
        while (loop <= end) {
            dayEvents.map((events) => {
                if (convertDateToOnlyDate(loop) === convertDateToOnlyDate(events.date)) {
                    eventsForTheWeek.push(events);
                }
            })
            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        }
    }
    const getHomeName = (id) => {
        return funeralHomes.map(home => {
			if (home.id === id) {
                return <div key={home.id}>{home.name}</div>
			}
		})
    }
    return (
        <Fragment>
            {getAllDates()}
            {
                eventsForTheWeek.length > 0 ?
                    eventsForTheWeek.map(eve => {
                        return (
                            <div className={CSS.eventsList} key={eve.id}>
                                {convertDateToOnlyDate(eve.date) === convertDateToOnlyDate(moment()) ?
                                    <>
                                        <div className={CSS.dayEventHeaderToday}>TODAY {" "}{convertDateToDateOnly(eve.date)}</div>
                                        {eve.streams.map(e1 => {
                                            return (
                                                <div className={CSS.dayEvent} key={e1.id}>
                                                    {e1.roomId &&
                                                        <div className={CSS.dayEventHomeDetails}>{getHomeName(e1.room.funeralHomeId)} {" - "} 
                                                            {e1.room.name}
                                                        </div> }
                                                    <div className={CSS.dayEventTime}>{convertDateToTimeOnly(e1.streamStart)} {" - "} {convertDateToTimeOnly(e1.streamEnd)}</div>
                                                    <div className={CSS.dayEventDetails}>{deceasedLastName(e1.funeralId)} {" - "} {e1.name}</div>
                                                </div>
                                            )
                                        })}
                                    </> 
                                    :
                                    convertDateToOnlyDate(eve.date) === convertDateToOnlyDate(moment().add(1, 'days')) ?
                                        <>
                                            <div className={CSS.dayEventHeader}>TOMORROW {" "}{convertDateToDateOnly(eve.date)}</div>
                                            {eve.streams.map(e1 => {
                                            return (
                                                <div className={CSS.dayEvent} key={e1.id}>
                                                    {e1.roomId &&
                                                        <div className={CSS.dayEventHomeDetails}>{getHomeName(e1.room.funeralHomeId)} {" - "} 
                                                            {e1.room.name}
                                                        </div> }
                                                    <div className={CSS.dayEventTime}>{convertDateToTimeOnly(e1.streamStart)} {" - "} {convertDateToTimeOnly(e1.streamEnd)}</div>
                                                    <div className={CSS.dayEventDetails}>{deceasedLastName(e1.funeralId)} {" - "} {e1.name}</div>
                                                </div>
                                            )
                                            })}
                                        </>
                                        : 
                                        eve.streams.map(e1 => {
                                            return (
                                                <div className={CSS.dayEvent} key={e1.id}>
                                                    <div className={CSS.dayEventHeader} style={{ textTransform: "uppercase" }}>{convertDateToDayOnly(e1.streamStart)} {" "}{convertDateToDateOnly(e1.streamStart)}</div>
                                                    {e1.roomId &&
                                                        <div className={CSS.dayEventHomeDetails}>{getHomeName(e1.room.funeralHomeId)} {" - "} 
                                                            {e1.room.name}
                                                        </div> }
                                                    <div className={CSS.dayEventTime}>{convertDateToTimeOnly(e1.streamStart)} {" - "} {convertDateToTimeOnly(e1.streamEnd)}</div>
                                                    <div className={CSS.dayEventDetails}>{deceasedLastName(e1.funeralId)} {" - "} {e1.name}</div>
                                                </div>
                                            )
                                            })
                                        }
                            </div>)
                    })
                    :
                    <div className={CSS.noEvents}>No Events for this Week</div>
                }
        </Fragment>
    )
}

export default EventsView;
