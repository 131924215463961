import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CSS from "../genericStyles/checkoutForm.module.scss";
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { initialStateVal } from "../redux/payment/actions";
import { useTranslation } from 'react-i18next';
function PaymentSuccess({initialStateVal}) {
  const history = useHistory();
	const { t } = useTranslation();
  const goToCreditsPage = () => {
    initialStateVal();
    history.push('/streamcredits');
  }
  return (
    <div className={CSS.successRes}>
        <div className={CSS.successHeader}>
            {t('pages.checkout_form.payment_success')}
        </div>
        <div className={CSS.successIcon}>
            <CheckCircleOutlineIcon style={{height: '100px', width: '100px', marginTop: "16px", marginBottom: "16px"}}/>
        </div>
        <div className={CSS.successHint}>
          {t('pages.checkout_form.credits_applied_hint')}
        </div>
        <button className={CSS.orderButton} onClick={goToCreditsPage}>{t('pages.checkout_form.back_to_account')}</button>
    </div>
  )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
	initialStateVal
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);