import axios from '../../utils/axios';
import camelcaseKeys from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";

const FUNERAL_VISITORS_REQ = 'FUNERAL_VISITORS_REQ';
export const FUNERAL_VISITORS_SUCCESS = 'FUNERAL_VISITORS_SUCCESS';
export const FUNERAL_VISITORS_FAILURE = 'FUNERAL_VISITORS_FAILURE';

const DOWNLOAD_REQUESTS_REQ = 'DOWNLOAD_REQUESTS_REQ';
export const DOWNLOAD_REQUESTS_SUCCESS = 'DOWNLOAD_REQUESTS_SUCCESS';
export const DOWNLOAD_REQUESTS_FAILURE = 'DOWNLOAD_REQUESTS_FAILURE';

const SEND_DOWNLOAD_REQ = 'SEND_DOWNLOAD_REQ';
export const SEND_DOWNLOAD_SUCCESS = 'SEND_DOWNLOAD_SUCCESS';
export const SEND_DOWNLOAD_FAILURE = 'SEND_DOWNLOAD_FAILURE';

export const getFuneralVisitors = (funeralId) => {
  return dispatch => {
    dispatch({ type: FUNERAL_VISITORS_REQ });
    return axios.get(`api/funerals/${funeralId}/visitors`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const visitors = camelcaseKeys(response.data).visitors;
      dispatch(getFuneralVisitorsSuccessful(visitors));
      return response;
    })
    .catch(error => {
        dispatch(getFuneralVisitorsFailed(error));
      return error;
    });
  }
};

const getFuneralVisitorsSuccessful = (visitors)=>{
  return {
    type: FUNERAL_VISITORS_SUCCESS,
    visitors
  }
}

const getFuneralVisitorsFailed = (error)=>{
  return {
    type: FUNERAL_VISITORS_FAILURE,
    error
  }
}

export const getFuneralDownloadInformation = (funeralId) => {
  return dispatch => {
    dispatch({ type: DOWNLOAD_REQUESTS_REQ });
    return axios.get(`api/funerals/${funeralId}/download_requests`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const downloadRequests = camelcaseKeys(response.data).downloadRequests;
      const cronJobs = camelcaseKeys(response.data).cronJobs;
      dispatch(getFuneralDownloadInformationSuccessful(downloadRequests, cronJobs));
      return response;
    })
    .catch(error => {
        dispatch(getFuneralDownloadInformationFailed(error));
      return error;
    });
  }
};

const getFuneralDownloadInformationSuccessful = (downloadRequests, cronJobs)=>{
  return {
    type: DOWNLOAD_REQUESTS_SUCCESS,
    downloadRequests,
    cronJobs
  }
}

const getFuneralDownloadInformationFailed = (error)=>{
  return {
    type: DOWNLOAD_REQUESTS_FAILURE,
    error
  }
}

export const sendDownloadRequest = (funeralId, email) => {
  return dispatch => {
    dispatch({ type: DOWNLOAD_REQUESTS_REQ });
    return axios.post(`api/funerals/${funeralId}/download_requests?email=${email}`, null, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const data = response.data;
      dispatch(sendDownloadRequestSuccessful(data));
      return response;
    })
    .catch(error => {
        dispatch(sendDownloadRequestFailed(error));
      return error;
    });
  }
};

const sendDownloadRequestSuccessful = (data)=>{
  return {
    type: DOWNLOAD_REQUESTS_SUCCESS,
    data
  }
}

const sendDownloadRequestFailed = (error)=>{
  return {
    type: DOWNLOAD_REQUESTS_FAILURE,
    error
  }
}

