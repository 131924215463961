import dayjs from "dayjs";
import React, { useContext, useEffect, useState, Fragment } from "react";
import GlobalContext from "./context/GlobalContext";
import { getMonth } from "./DateUtil";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CSS from "./CalenderView.module.scss";
import EventsView from "./EventsView";

const MiniCalenderView = ({ funerals, funeralHomes, onClickMiniCalendar }) => {
	const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
	const [currentMonth, setCurrentMonth] = useState(getMonth());
	useEffect(() => {
		setCurrentMonth(getMonth(currentMonthIdx));
	}, [currentMonthIdx]);	
	const { monthIndex, setSmallCalendarMonth, setDaySelected, daySelected } =	useContext(GlobalContext);
	useEffect(() => {
		setCurrentMonthIdx(monthIndex);
	}, [monthIndex]);
	function handlePrevMonth() {
		setCurrentMonthIdx(currentMonthIdx - 1);
	}
	function handleNextMonth() {
		setCurrentMonthIdx(currentMonthIdx + 1);
	}
	function getDayClass(day) {
		const format = "DD-MM-YY";
		const nowDay = dayjs().format(format);
		const currDay = day.format(format);
		const slcDay = daySelected && daySelected.format(format);
		if (nowDay === currDay) {
			return `${CSS.sideCalenderCurrDay}`;
		} else if (currDay === slcDay) {
			return `${CSS.sideCalenderSlcDay}`;
		} else {
			return "";
		}
	}
	return (
		<aside className={CSS.sideCalender}>
			<div className={CSS.sideCalenderData}>
				<header className={CSS.sideCalenderHeader}>
					<div className={CSS.monthYear}>
						<p className={CSS.sideCalenderYear}>
							{dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM")}
						</p>
						<p className={CSS.sideCalenderValue}>
							{dayjs(new Date(dayjs().year(), currentMonthIdx)).format("YYYY")}
						</p>
					</div>
					<div>
						<button
							onClick={handlePrevMonth}
							className={CSS.monthBtn}>
							<ChevronLeft />
						</button>
						<button
							onClick={handleNextMonth}
							className={CSS.monthBtn}>
							<ChevronRight />
						</button>
					</div>
				</header>
				<div className={CSS.sideCalenderMonth}>
					{currentMonth[0].map((day, i) => (
						<span key={i} className={CSS.sideCalenderDay}>
							{day.format("ddd")}
						</span>
					))}
					{currentMonth.map((row, i) => (
						<Fragment key={i}>
							{row.map((day, idx) => (
								<button
									key={idx}
									onClick={() => {
										setSmallCalendarMonth(currentMonthIdx);
										setDaySelected(day);
										onClickMiniCalendar(day, currentMonthIdx);
									}}
									className={`${CSS.sideCalenderDateSelect} ${getDayClass(day)}`}>
									<span className={CSS.sideCalenderDateSelected}>
										{day.format("D")}
									</span>
								</button>
							))}
						</Fragment>
					))}
				</div>
			</div>
			<EventsView funerals={funerals} currentMonthIdx={currentMonthIdx} funeralHomes={funeralHomes}/>
		</aside>
	);
};

export default MiniCalenderView;