import React, { useRef, useEffect } from 'react';
import CSS from "./LandingPage.module.scss";
import Header from '../header/Header';
import StreamCreditCard from '../streamingCredits/StreamingCreditCard';
import ApiImage from '../../assets/svgs/home-API.svg';
import CloudImage from '../../assets/svgs/home-Cloud.svg';
import CostImage from '../../assets/svgs/home-cost effective.svg';
import PlugImage from '../../assets/svgs/home-plug and play.svg';
import MessagesImage from '../../assets/svgs/home-messages.svg';
import AudioImage from '../../assets/svgs/home-audio.svg';
import VideoImage from '../../assets/svgs/home-video.svg';
import LandingImage1 from '../../assets/svgs/Landing-01.png';
import LandingImage2 from '../../assets/svgs/Landing-02.png';
import LandingImage3 from '../../assets/svgs/Landing-03.png';
import LandingImage4 from '../../assets/svgs/Landing-04.png';
import logo from "../../assets/svgs/favicon.ico";
import { Link } from "react-router-dom";
import { postRequestDemo } from '../../redux/payment/actions';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const LandingPage = ({ postRequestDemo, demoResponse }) => {
    const myRef = useRef(null);
    const { t } = useTranslation();
    const executeScroll = () => myRef.current.scrollIntoView();
    const openFuneralLive = () => {
		window.open("https://funerallive.ca/");
	};
    const [userDetails, setUserDetails] = React.useState({
        firstName: "",
        lastName: "",
        orgName: "",
        email: "",
        phnNo: ""
    });
    const [valid, setValid] = React.useState(false);
    useEffect(() => {
        if(userDetails.firstName && userDetails.firstName.trim() &&
            userDetails.lastName && userDetails.lastName.trim() && 
            userDetails.orgName && userDetails.orgName.trim() &&
			userDetails.email && userDetails.email.trim() &&
			userDetails.phnNo && userDetails.phnNo.trim()
        ){
            setValid(true);
            return true;
        }
        else{
            setValid(false);
            return false;
        }
    }, [userDetails]);
    useEffect(() => {
      
    }, [valid]);
    function handleChange(evt) {
        const value = evt.target.value;
        setUserDetails({
          ...userDetails,
          [evt.target.name]: value
        });
    }
    const sendDemoReq = async (e) => {
        e.preventDefault();
        const payload = {
            email: userDetails.email,
            phone: userDetails.phnNo,
            first_name: userDetails.firstName,
            last_name: userDetails.lastName,
            organization_name: userDetails.orgName
        }
        await postRequestDemo(payload);
        clearInputFields();
    }
    const clearInputFields = () => {
        setUserDetails({
            firstName: "",
            lastName: "",
            orgName: "",
            email: "",
            phnNo: ""
        })
    }
    return (
        <div className={CSS.main}>
            <Header Landing="landing"/>
            <div className={CSS.landingPage}>
                <div className={CSS.landingHeader}>
                    <div className={CSS.landingHeaderDesc}>
                        <div className={CSS.landingHeaderDesc1}>
                        {t('pages.landing.live_stream_header')}
                        </div>
                        <div className={CSS.landingHeaderDesc2}>
                        {t('pages.landing.live_stream_subheader')}
                        </div>
                        <div className={CSS.landingHeaderDesc3}>
                        {t('pages.landing.live_stream_hint')}
                        </div>
                        <button className={`${CSS.actionBtn} ${CSS.warning}`} onClick={executeScroll}>
                        {t('pages.landing.request_demo_btn')}
                        </button>
                    </div>
                    <img src={LandingImage1} alt="Landing1"/>
                </div>
                <div className={CSS.landingInfo}>
                    <div className={CSS.landingInfoImage}>
                        <img src={LandingImage2} alt="landing2"/>
                    </div>
                    <div className={CSS.landingInfoDesc}>
                        <div className={CSS.landingInfoDescHeader}>
                        {t('pages.landing.connect_header')}
                        </div>
                        <div className={CSS.landingInfoDescHeaderHint}>
                        {t('pages.landing.connect_subheader')}
                        </div>
                        <div className={CSS.landingInfoDescHeaderCaption}>
                        {t('pages.landing.connect_hint')}
                        </div>
                    </div>
                    <div className={CSS.landingInfoIcons}>
                        <div className={CSS.landingIconText}>
                            <img src={MessagesImage} alt="messages"/>
                            <div className={CSS.iconHint}>
                            {t('pages.landing.subhint1')}
                            </div>
                        </div>
                        <div className={CSS.landingIconText}>
                            <img src={AudioImage} alt="audio"/>
                            <div className={CSS.iconHint}>
                            {t('pages.landing.subhint2')}
                            </div>
                        </div>
                        <div className={CSS.landingIconText}>
                            <img src={VideoImage} alt="video"/>
                            <div className={CSS.iconHint}>
                            {t('pages.landing.subhint3')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={CSS.landingDetails}>
                    <div className={CSS.landingDetailsLeft}>
                        <div className={CSS.landingDetailsLeftHeader}>{t('pages.landing.how_header')}</div>
                        <div className={CSS.landingDetailsLeftHint}>{t('pages.landing.how_subheader')}</div>
                    </div>
                    <div className={CSS.landingDetailsRight}>
                        <div className={CSS.landingDetailsRightHeader}>
                        {t('pages.landing.dynamic_event_header')}
                        </div>
                        <div className={CSS.landingDetailsEachPoint}>
                            <div className={CSS.landingDetailsSno}>01.</div>
                            <div className={CSS.landingDetailsPoint}>
                            {t('pages.landing.dynamic_event_hint1')}
                            </div>
                        </div>
                        <div className={CSS.landingDetailsEachPoint}>
                            <div className={CSS.landingDetailsSno}>02.</div>
                            <div className={CSS.landingDetailsPoint}>
                            {t('pages.landing.dynamic_event_hint2')}
                            </div>
                        </div>
                        <div className={CSS.landingDetailsEachPoint}>
                            <div className={CSS.landingDetailsSno}>03.</div>
                            <div className={CSS.landingDetailsPoint}>
                            {t('pages.landing.dynamic_event_hint3')}
                            </div>
                        </div>
                        <div className={CSS.landingDetailsEachPoint}>
                            <div className={CSS.landingDetailsSno}>04.</div>
                            <div className={CSS.landingDetailsPoint}>
                            {t('pages.landing.dynamic_event_hint4')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={CSS.landingAdvantages}>
                    <div className={CSS.landingEachAdvantage}>
                        <div className={CSS.landingEachAdvantageHeader}>
                            <div className={CSS.landingEachAdvantageHeaderImg}>
                                <img src={PlugImage} alt="p&p"/>
                            </div>
                            <div className={CSS.landingEachAdvantageHeading}>
                            {t('pages.landing.time_saving_header')}
                            </div>
                        </div>
                        <div className={CSS.landingEachAdvantagePoints}>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.time_saving_hint1')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.time_saving_hint2')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.time_saving_hint3')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.time_saving_hint4')}
                            </div>
                        </div>
                    </div>
                    <div className={CSS.landingEachAdvantage}>
                        <div className={CSS.landingEachAdvantageHeader}>
                            <div className={CSS.landingEachAdvantageHeaderImg}>
                                <img src={ApiImage} alt="api"/>
                            </div>
                            <div className={CSS.landingEachAdvantageHeading}>
                            {t('pages.landing.api_integration_header')}
                            </div>
                        </div>
                        <div className={CSS.landingEachAdvantagePoints}>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.api_integration_hint1')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.api_integration_hint2')}
                            </div>
                        </div>
                    </div>
                    <div className={CSS.landingEachAdvantage}>
                        <div className={CSS.landingEachAdvantageHeader}>
                            <div className={CSS.landingEachAdvantageHeaderImg}>
                                <img src={CostImage} alt="cost"/>
                            </div>
                            <div className={CSS.landingEachAdvantageHeading}>
                            {t('pages.landing.profit_header')}
                            </div>
                        </div>
                        <div className={CSS.landingEachAdvantagePoints}>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.profit_hint1')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.profit_hint2')}
                            </div>
                        </div>
                    </div>
                    <div className={CSS.landingEachAdvantage}>
                        <div className={CSS.landingEachAdvantageHeader}>
                            <div className={CSS.landingEachAdvantageHeaderImg}>
                                <img src={CloudImage} alt="cloud"/>
                            </div>
                            <div className={CSS.landingEachAdvantageHeading}>
                            {t('pages.landing.cloud_steaming_header')}
                            </div>
                        </div>
                        <div className={CSS.landingEachAdvantagePoints}>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.cloud_steaming_hint1')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.cloud_steaming_hint2')}
                            </div>
                            <div className={CSS.landingEachAdvantageEachPoint}>
                            {t('pages.landing.cloud_steaming_hint3')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={CSS.requestDemo} ref={myRef}>
                    <div className={CSS.requestDemoHeader}>
                    {t('pages.landing.request_demo_header')}
                    </div>
                    <div className={CSS.requestDemoHint}>
                    {t('pages.landing.request_demo_subheader')}
                    </div>
                    <div className={CSS.requestDemoInputRow1}>
                        <div className={CSS.requestDemoInputEle}>
                            <label className={CSS.elementLabel}>
                            {t('pages.landing.first_name')}
                            </label>
                            <input value={userDetails.firstName} name="firstName" onChange={handleChange} className={CSS.inputBar}/>
                        </div>
                        <div className={CSS.requestDemoInputEle}>
                            <label className={CSS.elementLabel}>
                            {t('pages.landing.last_name')}
                            </label>
                            <input value={userDetails.lastName} name="lastName"  onChange={handleChange} className={CSS.inputBar}/>
                        </div>
                        <div className={CSS.requestDemoInputEle}>
                            <label className={CSS.elementLabel}>
                            {t('pages.landing.org_name')}
                            </label>
                            <input value={userDetails.orgName} name="orgName" onChange={handleChange} className={CSS.inputBar}/>
                        </div>
                        <div className={CSS.requestDemoInputEle}>
                        <label className={CSS.elementLabel}>
                        {t('pages.landing.email_add')}
                        </label>
                        <input value={userDetails.email} name="email"  onChange={handleChange} className={CSS.inputBar}/>
                    </div>
                    <div className={CSS.requestDemoInputEle}>
                        <label className={CSS.elementLabel}>
                        {t('pages.landing.phn_no')}
                        </label>
                        <input value={userDetails.phnNo} name="phnNo" onChange={handleChange} className={CSS.inputBar}/>
                    </div>
                    </div>
                    <button className={`${CSS.actionBtn} ${CSS.warning} ${valid ? "" : CSS.disabled}`} 
                        style={{marginTop: "24px"}} onClick={(e)=>{sendDemoReq(e)}}>
                        {t('pages.landing.submit_btn')}
                    </button>
                </div>
                <div className={CSS.landingBundles}>
                    <div className={CSS.landingBundlesHeader}>
                    {t('pages.landing.bundles_header')}
                    </div>
                    <div className={CSS.landingBundlesHint}>
                    {t('pages.landing.bundles_subheader')}
                    </div>
                    <StreamCreditCard STATE={true}/>
                </div>
                <div className={CSS.landingBundlesEnd}>
                    <div className={CSS.landingBundlesEndHeaderPart}>
                        <div>
                            <div className={CSS.landingBundlesEndHeader}>
                                ⭐️ {t('pages.landing.earn_stars_header')}

                            </div>
                            <div className={CSS.landingBundlesEndPoints}>
                                <div className={CSS.landingBundlesEachPoint}>
                                {t('pages.landing.earn_stars_subheader1')}
                                </div>
                                <div className={CSS.landingBundlesEachPoint}>
                                {t('pages.landing.earn_stars_subheader2')}
                                </div>
                                <div className={CSS.landingBundlesEachPoint}>
                                {t('pages.landing.earn_stars_subheader3')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={LandingImage3} alt="Landing3" className={CSS.image1}/>
                    <img src={LandingImage4} alt="Landing4" className={CSS.image2}/>
                </div>
            </div>
            <div className={CSS.footer}>
                <div className={CSS.footerLogo}>
				    <img src={logo} alt="Logo" onClick={openFuneralLive} />
                    <div className={CSS.footerLogoWord1}>
                        Funeral
                    </div>
                    <div className={CSS.footerLogoWord2}>
                        Live
                    </div>    
                </div>
                <div className={CSS.footerLinks}>
                    <Link to={"/policy"} className={CSS.url}>
                    {t('pages.landing.privacy_link')}
                    </Link>
                    <Link to={"/purchase"} className={CSS.url}>
                    {t('pages.landing.purchase_link')}
                    </Link>
                </div>
            </div>
            <div style={{height: "64px"}}>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
	demoResponse: state.payment.demoDataRes,
});

const mapDispatchToProps = {
    postRequestDemo
};
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);