import axios from "../../utils/axios";
import axiosNonConfigured from "axios";
import camelcaseKeys from "camelcase-keys-recursive";
import snakeCaseKeys from "snakecase-keys";

const GET_ONSITE_REQUEST = "GET_ONSITE_REQUEST";
export const GET_ONSITE_SUCCESS = "GET_ONSITE_SUCCESS";
export const GET_ONSITE_FAILURE = "GET_ONSITE_FAILURE";

const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

const PRESIGN_IMAGE_REQUEST = "PRESIGN_IMAGE_REQUEST";
export const PRESIGN_IMAGE_SUCCESS = "PRESIGN_IMAGE_SUCCESS";
export const PRESIGN_IMAGE_FAILURE = "PRESIGN_IMAGE_FAILURE";

const REMOVE_DECEASED_IMAGE_REQUEST = "REMOVE_DECEASED_IMAGE_REQUEST";
export const REMOVE_DECEASED_IMAGE_SUCCESS = "REMOVE_DECEASED_IMAGE_SUCCESS";
export const REMOVE_DECEASED_IMAGE_FAILURE = "REMOVE_DECEASED_IMAGE_FAILURE";

export const INITIAL_ACTION = "INITIAL_ACTION";

export const getOnsiteData = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_ONSITE_REQUEST });

		return axios
			.get(`api/funeral_homes/${id}/rooms`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const onsiteData = camelcaseKeys(response.data).rooms;
				dispatch(getOnsiteDataSuccess(onsiteData));
			})
			.catch((error) => getOnsiteDataFailure(error));
	};
};

const getOnsiteDataSuccess = (onsiteResponse) => {
	return {
		type: GET_ONSITE_SUCCESS,
		onsiteResponse,
	};
};

const getOnsiteDataFailure = (error) => {
	return {
		type: GET_ONSITE_FAILURE,
		error,
	};
};

export const postImageWithSignedUrl = (file, checksum) => {
	return (dispatch) => {
		dispatch({ type: PRESIGN_IMAGE_REQUEST });
		const data = {
			file: {
				filename: file.name,
				byte_size: file.size,
				checksum: checksum,
				content_type: "application/image",
				metadata: {
					message: "Profile Image",
				},
			},
		};

		return axios
			.post("api/presigned_url", data, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const responseData = response.data;
				return axiosNonConfigured
					.put(responseData.direct_upload.url, file, {
						headers: {
							...responseData.direct_upload.headers,
						},
					})
					.then((s3UploadResponse) => {
						dispatch(
							uploadFuneralHomeImageSuccess(
								responseData.blob_signed_id
							)
						);
						return responseData.blob_signed_id;
					})
					.catch((error) => {
						console.log("failed to upload image");
					});
			})
			.catch((error) => {
				console.log("error response::: ", error);
				// dispatch(createFuneralHomeFailure(error.response.data.message));
			});
	};
};

const uploadFuneralHomeImageSuccess = (blobSignedId) => {
	return {
		type: PRESIGN_IMAGE_SUCCESS,
		blobSignedId,
	};
};

export const createEventReq = (payload, useCredits = false) => {
	return (dispatch) => {
		dispatch({ type: CREATE_EVENT_REQUEST });
		return axios
			.post(`api/funerals?use_credits=${useCredits}`,snakeCaseKeys(payload), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const funeralData = camelcaseKeys(response.data).funeral;
				dispatch(createEventDataSuccess(funeralData));
        		return funeralData;
			})
			.catch(error => {
				if( error.response ){
				  dispatch(createEventDataFailure(error.response.data.message));
				} else {
				  dispatch(createEventDataFailure("something went wrong"));
				}
			  });
	};
};

const createEventDataSuccess = (createEventRes) => {
	return {
		type: CREATE_EVENT_SUCCESS,
		createEventRes,
	};
};

const createEventDataFailure = (message) => {
	return {
		type: CREATE_EVENT_FAILURE,
		message,
	};
};

export const updateEventReq = (payload, id) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_EVENT_REQUEST });
		return axios
			.put(`api/funerals/${id}`,snakeCaseKeys(payload), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const funeralData = camelcaseKeys(response.data).funeral;
				dispatch(updateEventDataSuccess(funeralData));
        		// return funeralData;
			})
			.catch(error => {
				if( error.response ){
				  dispatch(updateEventDataFailure(error.response.data.message));
				} else {
				  dispatch(updateEventDataFailure("something went wrong"));
				}
			  });
	};
};

const updateEventDataSuccess = (updateEventRes) => {
	return {
		type: UPDATE_EVENT_SUCCESS,
		updateEventRes,
	};
};

const updateEventDataFailure = (message) => {
	return {
		type: UPDATE_EVENT_FAILURE,
		message,
	};
};

export const removeDeceasedImage = (id) => {
	return (dispatch) => {
		dispatch({ type: REMOVE_DECEASED_IMAGE_REQUEST });
		return axios
			.post(`api/funerals/${id}/remove_deceased_image`, null, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const funeralData = camelcaseKeys(response.data).funeral;
				dispatch(removeDeceasedImageSuccess(funeralData));
			})
			.catch(error => {
				if( error.response ){
				  dispatch(removeDeceasedImageFailure(error.response.data.message));
				} else {
				  dispatch(removeDeceasedImageFailure("something went wrong"));
				}
			  });
	};
};

const removeDeceasedImageSuccess = (updateEventResponse) => {
	return {
		type: REMOVE_DECEASED_IMAGE_SUCCESS,
		updateEventResponse,
	};
};

const removeDeceasedImageFailure = (message) => {
	return {
		type: REMOVE_DECEASED_IMAGE_FAILURE,
		message,
	};
};

export function initialStateVal(){
	return{
		type: INITIAL_ACTION,
	}
}