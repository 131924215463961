import React, { useState } from "react";
import CSS from "../../..//CalenderView.module.scss";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import "../../../../../components/ModalPopup.scss";
import defaultImage from "../../../../../assets/svgs/default_image.jpg";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
function UpdateEventModal({ stream, funerals, modalId, setModalId, funeralHomes }) {
	const [showModal, setShowModal] = useState(modalId);
	const history = useHistory();
	const {t} = useTranslation();
	const hideModal = (value) => {
		setShowModal(0);
		setModalId("");
	};
	const deceasedLastName = (id) => {
		return funerals.map((funeral) => {
			if (funeral.id === id) {
				return funeral.lastName;
			}
		});
	};
	const deceasedFirstName = (id) => {
		return funerals.map((funeral) => {
			if (funeral.id === id) {
				return funeral.firstName;
			}
		});
	};
	const deceasedDateOfBirth = (id) => {
		return funerals.map((funeral) => {
			if (funeral.id === id) {
				return funeral.dateOfBirth;
			}
		});
	};
	const deceasedDateOfDeath = (id) => {
		return funerals.map((funeral) => {
			if (funeral.id === id) {
				return funeral.dateOfDeath;
			}
		});
	};
	const deceasedImg = (id) => {
		return funerals
			.map((funeral) => {
				if (funeral.id === id) {
					if (funeral.deceasedImageUrl) {
						return funeral.deceasedImageUrl;
					} else {
						return defaultImage;
					}
				}
			})
			.filter(Boolean);
	};
	const convertDateToOnlyDate = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("LL");
		}
		return "";
	};
	const convertDateToTimeOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("h:mm A");
		}
		return "";
	};
	const getHomeName = (id) => {
        return funeralHomes.map(home => {
			if (home.id === id) {
                return <div key={home.id}>{home.name}</div>
			}
		})
    }
	const eventDetailModal = (evtData) => {
		return (
			<div>
				<div
					className={"modalPopup2Wrapper"}
					style={{ padding: "32px", width: "540px" }}>
					<div className={CSS.modalPopupBody}>
						<div className={CSS.deceasedData}>
							<div
								className={CSS.deceasedInformation}
								style={{ width: "400px" }}>
								<div className={CSS.dataDeceased}>
									<div className={CSS.deceasedName}>
										{deceasedLastName(evtData.funeralId)} {" "} {deceasedFirstName(evtData.funeralId)}
									</div>
									<div className={CSS.deceasedLifeSpan}>
										{deceasedDateOfDeath(evtData.funeralId)} to {deceasedDateOfBirth(evtData.funeralId)}
									</div>
								</div>
								<img src={deceasedImg(evtData.funeralId)} style={{ width: "80px", height: '80px', float: "right", borderRadius: "50%" }} />
							</div>
							<div
								className={"modalPopupCloseBtn"}
								style={{
									marginLeft: "auto",
									marginRight: "0px",
								}}>
								<CloseIcon
									style={{ cursor: "pointer" }}
									className={"modalPopupCloseIcon"}
									onClick={() => hideModal(evtData.id)}
								/>
							</div>
						</div>
						<div style={{ paddingBottom: "4px" }}>
							<span
								style={{
									fontFamily: "Bitter, sansSerif",
									fontStyle: "normal",
									fontWeight: "600",
									fontSize: "18px",
									lineHeight: "24px",
									color: "#000000",
									wordBreak: "break-word",
								}}>
								{evtData.name}
								{console.log(evtData)}
							</span>
						</div>
						{evtData.location==="On-Site" &&
							<div className={CSS.dayEventHomeDetails}>{getHomeName(evtData.room.funeralHomeId)} {" - "} 
								{evtData.room.name}
							</div> }
						<div style={{ paddingBottom: "4px" }}>
							<span className={CSS.deceasedDetailTitle}>
							{t('pages.create_event.stream_date_label')}:&nbsp;
							</span>
							<span className={CSS.deceasedDetailvalue}>
								{convertDateToOnlyDate(evtData.streamStart)}
							</span>
						</div>
						<div style={{ paddingBottom: "4px" }}>
							<span className={CSS.deceasedDetailTitle}>
							{t('pages.create_event.stream_start_label')}:&nbsp;
							</span>
							<span className={CSS.deceasedDetailvalue}>
								{convertDateToTimeOnly(evtData.streamStart)}
							</span>
						</div>
						<div>
							<span className={CSS.deceasedDetailTitle}>
							{t('pages.create_event.stream_end_label')}:&nbsp;
							</span>
							<span className={CSS.deceasedDetailvalue}>
								{convertDateToTimeOnly(evtData.streamEnd)}
							</span>
						</div>
						<div>
							<span className={CSS.deceasedDetailTitle}>
								RTMP URL:&nbsp;
							</span>
							<span
								style={{
									cursor: "pointer",
									textDecorationStyle: "dotted",
								}}
								className={CSS.deceasedDetailvalue}
								onClick={() => {
									navigator.clipboard.writeText(
										evtData.livestreamEvent?.rtmpUrl
									);
								}}>
								{evtData.livestreamEvent?.rtmpUrl}
							</span>
						</div>
						<div>
							<span className={CSS.deceasedDetailTitle}>
								Stream Key:&nbsp;
							</span>
							<span
								style={{
									cursor: "pointer",
									textDecorationStyle: "dotted",
								}}
								className={CSS.deceasedDetailvalue}
								onClick={() => {
									navigator.clipboard.writeText(
										evtData.livestreamEvent?.streamKey
									);
								}}>
								{evtData.livestreamEvent?.streamKey}
							</span>
						</div>
						<div className={CSS.deceasedInfoDetail}>
							<span className={CSS.deceasedDetailTitle}>
							{t('pages.create_event.stream_location_label')}:&nbsp;
							</span>
							<span className={CSS.deceasedDetailvalue}>
								{evtData.location}
							</span>
							<div
								style={{
									marginLeft: "auto",
									marginRight: "0px",
								}}>
								<button
									className={`${CSS.actionBtn} ${CSS.warning}`}
									type="button"
									onClick={() =>
										history.push(
											`/createEvent/${evtData.funeralId}/edit`
										)
									}>
									Update Event
								</button>
							</div>
						</div>
						<div className={CSS.deceasedInfoDetail}>
							<div
								style={{
									marginLeft: "auto",
									marginRight: "0px",
									marginTop: "5px"
								}}>
								<button
									className={`${CSS.actionBtn} ${CSS.warning}`}
									type="button"
									onClick={() =>
										window.open(
											`/funerals/${evtData.funeralId}/admin`, '_blank'
										)
									}>
									Admin Page
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return (
		<Modal
			open={showModal === stream.id}
			onClose={() => hideModal(stream.id)}>
			{eventDetailModal(stream)}
		</Modal>
	);
}

export default UpdateEventModal;
