import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { some } from "lodash";
import debounce from 'lodash.debounce';
import {
	Icon,
	Table,
	Segment,
	Button,
	Popup,
	Label,
} from "semantic-ui-react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FuneralsTable from "../../components/Table";
import {
	getFunerals,
	startStream,
	stopStream,
	resetStream,
	getStreamClips,
	getFuneralComments,
	getFuneralVisitors,
	postMessagesToEmail,
	postBuyFuneralLinksToEmail,
	getUpcomingFunerals,
	initialStateVal,
	deleteFuneral
} from "../../redux/funeral/actions";
import { getFuneralHomes } from "../../redux/funeralHome/actions";
import CSS from "./FuneralsPage.module.scss";
import Header from "../header/Header";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import "../../components/ModalPopup.scss";
import { Link } from "react-router-dom";
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
	useTable,
	usePagination,
	useSortBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
} from "react-table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CSVLink } from "react-csv";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Spinner from "../../components/Spinner";
import UpcomingEvents from "./UpcomingEvents";
const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSvgIcon-root": {
			// fill: "red"
		},
	},
	checkbox: {
		"&:hover": {
			backgroundColor: "transparent",
		}
	},
	checkedbox: {
		backgroundColor: "transparent",
	}

}));
const convertDateToDateOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("YYYY-MM-DD");
	}
	return "";
};

const convertDateToTimeOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("h:mm A");
	}
	return "";
};

const renderClientEmails = (emails) => {
	return emails.map((email, index) => {
		return <div key={index}>{email}</div>;
	});
};

const streamHasNoClips = (stream) => {
	return !stream.clips || stream.clips.length === 0;
};

const handleExtend = (stream) => {
	// console.log('extend stream by 15 mins ', stream);
}

const getLocation = (stream) => {
	return stream.location;
}

const FuneralsPage = (props) => {
	const {
		funerals,
		getFunerals,
		funeralHomes,
		getFuneralHomes,
		user,
		history,
		startStream,
		resetStream,
		stopStream,
		getStreamClips,
		funeralComments,
		funeralVisitors,
		funeralsPagination,
		postMessagesToEmail,
		postBuyFuneralLinksToEmail,
		exportRes,
		getUpcomingFunerals,
		upcomingFuneralsRes,
		deleteFuneral,
		initialStateVal,
		loading
	} = props;
	const classes = useStyles();
	const [selectedFuneralHomes, setSelectedFuneralHomes] = useState([]);
	const [selectedDate, setSelectedDate] = useState('');
	const [dropdownOpen, setDropdown] = useState({});
	const [eachFuneralId, setEachFuneralId] = useState(null);
	const [exportType, setExportType] = useState("");
	const [recipientEmail, setRecipientEmail] = useState("");
	const [errorStates, setErrorStates] = useState({
		recipientEmail: "",
	});
	const [tablePageSize, setTablePageSize] = useState(0);
	const [tablePageIndex, setTablePageIndex] = useState(0);
	const [tableSortBy, setTableSortBy] = useState(null);
	const [tableGlobalFilter, setGlobalFilter] = useState({
		filterName: "",
		filterValue: "",
	});
	const fetchIdRef = useRef(0);
	const fetchData = debounce(useCallback(
		({ pageSize, pageIndex, sortBy, globalFilter }) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchId === fetchIdRef.current) {
				setTablePageSize(pageSize);
				setTablePageIndex(pageIndex + 1);
				setTableSortBy(sortBy);
				if(globalFilter!==undefined){
					setGlobalFilter(globalFilter);
				}
				getFunerals(pageIndex + 1, pageSize, sortBy, globalFilter, selectedFuneralHomes, selectedDate, selectedDate);
			}
		}
	), 1000);
	const { t } = useTranslation();
	const STYLE = 100;
	useEffect(() => {
		getFuneralHomes();
		getUpcomingFunerals();
	}, []);
	useEffect(() => {
		const emailList = recipientEmail.split(',');
		isEmailsValid(emailList);
	}, [recipientEmail]);
	useEffect(() => {
	}, [tableGlobalFilter]);
	const isEmailsValid = (emailList) => {
		const resultEmail = emailList.map(element => {
			return element.trim();
		});
		for (let i = 0; i < resultEmail.length; i += 1) {
			const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
			if (!regEx.test(resultEmail[i])) {
				setErrorStates(prev => {
					return {
						...prev,
						recipientEmail: t('pages.create_event.email_validation')
					}
				})
			} else {
				setErrorStates(prev => {
					return {
						...prev,
						recipientEmail: ""
					}
				})
			}
		}
		return true;
	}

	const updateSelectedFuneralHomes = async (e) => {
		const { id, checked } = e.target;
		let funeralHomesSelected = [];
		if (checked) {
			funeralHomesSelected = [...selectedFuneralHomes, id];
		} else if (!checked) {
			funeralHomesSelected = selectedFuneralHomes.filter(item => item !== id);
		}
		setSelectedFuneralHomes(funeralHomesSelected);
		await getFunerals(1, tablePageSize, tableSortBy, tableGlobalFilter, funeralHomesSelected, selectedDate, selectedDate);
	};
	const updateSelectedAllFuneralHomes = async (e) => {
		const { id, checked } = e.target;
		let funeralHomesSelected = [];
		if (checked) {
			funeralHomesSelected = funeralHomes.map(home => home.id);
		}
		setSelectedFuneralHomes(funeralHomesSelected);
		await getFunerals(1, tablePageSize, tableSortBy, tableGlobalFilter, funeralHomesSelected, selectedDate, selectedDate);
	}
	const renderFuneralHomes = (funeralHomes) => {
		return funeralHomes.map((funeralHome, index) => {
			return (
				<div key={funeralHome.id} style={{ paddingLeft: "25px", height: "24px", whiteSpace: "nowrap" }}>
					<FormControlLabel
						key={funeralHome.id}
						className={classes.label}
						control={<Checkbox checked={selectedFuneralHomes.includes(funeralHome.id)}
							id={funeralHome.id}
							classes={{ root: classes.checkbox, checked: classes.checkedbox }}
							disabled={selectedFuneralHomes.length === funeralHomes.length && selectedFuneralHomes.length !== 0}
							onChange={updateSelectedFuneralHomes}
							style={{ color: "#00667E" }} />}
						label={<span style={{ fontSize: '16px', fontWeight: "300", fontFamily: "Bitter,serif", wordWrap: "break-word", color: "#001A20", lineHeight: "16px" }}>{funeralHome.name}</span>}
					/>
				</div>
			);
		});
	};
	const goToCreateEvent = () => {
		history.push("/createEvent");
	};
	const [messagesOpen, setMessagesOpen] = useState(false);
	const [downloadLinksOpen, setDownloadLinksOpen] = useState(false);
	const [attendeesOpen, setAttendeesOpen] = useState(false);
	const [currentFuneral, setCurrentFuneral] = useState("");
	const [deleteFuneralModalOpen, setDeleteFuneralModalOpen] = useState(false);
	let white = "#FFFFFF";
	let grey = "#C4C4C4";
	let funeralSpinner = true;
	const [buttonColor, setButtonColor] = useState({});
	const [clearFilter, setClearFilter] = useState(false);
	const [isClearFilter, setIsClearFilter] = useState(false)
	const toggleDropdown = (funeralId) => {
		const currentColor = buttonColor[funeralId] || white;
		const newColor = currentColor === white ? grey : white;
		setButtonColor({ buttonColor, [funeralId]: newColor });
		const funeralDropDownOpen = dropdownOpen[funeralId] || false;
		setDropdown({ dropdownOpen, [funeralId]: !funeralDropDownOpen });
	};
	useEffect(() => {
	}, [clearFilter, messagesOpen, isClearFilter, currentFuneral]);
	const headers = [
		{ label: "Name", key: "name" },
		{ label: "Email", key: "email" }
	];
	const isDropdownOpen = (funeralId) => {
		const funeralDropDownOpen = dropdownOpen[funeralId] || false;
		return funeralDropDownOpen;
	}
	const handleMessagesOpen = async (funeralData) => {
		await props.getFuneralComments(funeralData.id);
		setEachFuneralId(funeralData.id);
		setCurrentFuneral(funeralData);
		setMessagesOpen(true);
	};

	const handleCopyEmbedCode = (funeralData) => {
    // Create the iframe embed code
    const iframeEmbedCode = `<iframe width="720" height="480" allowfullscreen="true" mozallowfull="true" webkitallowfullscreen="true" src="https://app.funerallive.ca/funeral_embed/${funeralData.id}" style="border: none;"></iframe>`;
    
    // Create a textarea element to hold the iframe code temporarily
    const textarea = document.createElement('textarea');
    textarea.value = iframeEmbedCode;
    
    // Make the textarea invisible and append it to the document body
    textarea.style.position = 'fixed';
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);
    
    // Select and copy the iframe code from the textarea
    textarea.select();
    document.execCommand('copy');
    
    // Remove the textarea from the document
    document.body.removeChild(textarea);

		toggleDropdown(funeralData.id);
  };

	const handleDeleteFuneralOpen = (funeralData) => {
		setEachFuneralId(funeralData.id);
		setCurrentFuneral(funeralData);
		setDeleteFuneralModalOpen(true);
	};
	const handleDownloadLinksOpen = (funeralData) => {
		setEachFuneralId(funeralData.id);
		setCurrentFuneral(funeralData);
		setDownloadLinksOpen(true);
	};
	const handleAttendeesOpen = async (funeralData) => {
		await props.getFuneralVisitors(funeralData.id);
		setCurrentFuneral(funeralData);
		setAttendeesOpen(true);
	};
	const handleClose = () => {
		setMessagesOpen(false);
		setAttendeesOpen(false);
		setDownloadLinksOpen(false);
		setDeleteFuneralModalOpen(false);
		setCurrentFuneral("");
		setEachFuneralId(null);
		initialStateVal();
	};
	const handleExport = (e) => {
		setExportType(e.target.value);
	};
	const handleRecipientEmails = (e) => {
		setRecipientEmail(e.target.value)
	};
	const commentCreatedAtDate = (time) => {
		return moment(new Date(time)).format("LL");
	};
	const commentCreatedAtTime = (time) => {
		return moment(new Date(time)).format("h:mm a");
	};
	const clearAllFilters = (e) => {
		e.preventDefault();
		setSelectedFuneralHomes([]);
		setSelectedDate('');
		setClearFilter(true);
		setIsClearFilter(true);
		getFunerals(1, tablePageSize, tableSortBy, "", [], '', '');
	};
	useEffect(() => {
	}, [eachFuneralId]);
	const updateSelectedDateAndGetFunerals = async (e) => {
		setSelectedDate(e.target.value);
		await getFunerals(1, tablePageSize, tableSortBy, tableGlobalFilter, selectedFuneralHomes, e.target.value, e.target.value);
	};
	const getEmailsToSend = () => {
		const str = recipientEmail.replace(/\s*,\s*/g, ",")
		const emailsArray = str.split(',');
		const payloadToSendMsgs = {
			emails: emailsArray
		}
		postMessagesToEmail(eachFuneralId, payloadToSendMsgs);
		setRecipientEmail("")
	}
	const getEmailsToSendForBuyFuneralLinks = async () => {
		const str = recipientEmail.replace(/\s*,\s*/g, ",")
		const emailsArray = str.split(',');
		const payloadToSendMsgs = {
			emails: emailsArray
		}
		await postBuyFuneralLinksToEmail(eachFuneralId, payloadToSendMsgs);
		setRecipientEmail("")
	}
	const deleteSelectedFuneral = async (funeralIdval) => {
		await deleteFuneral(funeralIdval);
		setDeleteFuneralModalOpen(false);
		await getFunerals(1, tablePageSize, tableSortBy, "", [], '', '');	
	}
	const columns = [
		{
			Header: "Funeral Home Id",
			accessor: "funeralHomeId",
			disableSortBy: true,
			disableGlobalFilter: true,
			filter: (rows, id, filterValue) => {
				return rows.filter((row) => {
					if (filterValue.length === 0) return false;
					if (filterValue.includes("All")) {
						return true;
					}
					const funeralHomeId = row.original.funeralHomeId;
					return filterValue.includes(funeralHomeId);
				});
			},
		},
		{
			id: "checker",
			Header: "Actions",
			accessor: (funeral) => {
				return (
					<div className={CSS.burgerMenu}>
						<MoreVertIcon
							className={buttonColor[funeral.id] === grey ? CSS.greyButton : CSS.whiteButton}
							style={{ color: "#00667E" }}
							cursor="pointer"
							onClick={() => toggleDropdown(funeral.id)}
						/>
						{isDropdownOpen(funeral.id) && (
							<div className={CSS.dropdownMenu}>
								<div className={CSS.eachLink}>
									<a href={`/createEvent/${funeral.id}/edit`}>
									{t("pages.edit_event.title")}
									</a>
								</div>
								{
									(
										user.user?.role === 'super_admin' || 
										user.user?.role === 'admin' || 
										(funeral.status === "not_started" || funeral.status === "invalid")
									) 
									&& 
									(
										<div className={CSS.eachLink} onClick={() => handleDeleteFuneralOpen(funeral)}>
											<a>
											⚠️ {t("pages.delete_event.title")}
											</a>
										</div>
									)
								}
									<div className={CSS.eachLink}>
										<span onClick={() => handleAttendeesOpen(funeral)}>
											{t("pages.admin_create_org.table.export_attendees")}
										</span>
									</div>
								<div className={CSS.eachLink}>
									<span onClick={() => handleMessagesOpen(funeral)}>
										{t("pages.admin_create_org.table.export_condolences")}
									</span>
								</div>
								<div className={CSS.eachLink}>
									<span onClick={() => handleCopyEmbedCode(funeral)}>
										{t("pages.embed_code.title")}
									</span>
								</div>
								{
									(
										funeral.streams?.length > 0 &&
										funeral.streams?.every((stream) => stream.state === "ended")
									) &&
									<div className={CSS.eachLink}>
										<span onClick={() => handleDownloadLinksOpen(funeral)}>
											{t("pages.admin_create_org.table.send_download_link")}
										</span>
									</div>
								}
							</div>
						)}
					</div>
				)
			},
			width: 80,
			borderRadius: 0,
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			Header: t("pages.admin_events_table.col_deceased_name"),
			accessor: (funeral) => (
				<div className={CSS.topHeaderData} style={{ color: "#001A20" }}>
					{funeral.lastName}, {funeral.firstName}
				</div>
			),
			maxWidth: 400,
			minWidth: 150,
			width: 150,
			sortType: (rowA, rowB) => {
				if (
					rowA.original.firstName.toLocaleLowerCase() <
					rowB.original.firstName.toLocaleLowerCase()
				)
					return -1;
				if (
					rowA.original.firstName.toLocaleLowerCase() >
					rowB.original.firstName.toLocaleLowerCase()
				)
					return 1;
				return 0;
			},
		},
		// {
		// 	Header: t("pages.admin_events_table.col_status"),
		// 	accessor: "funeralStatus",
		// 	Cell: ({ value }) => {
		// 		return(
		// 			renderStatusPills(value)
		// 		)},
		// 	disableSortBy: true,
		// 	disableGlobalFilter: true,
		// 	width: 100,
		// },
		{
			Header: t("pages.admin_events_table.col_client_email"),
			accessor: "clientEmails",
			Cell: ({ value }) => (
				<div className={CSS.topHeaderData} style={{ wordWrap: "break-word", color: "#001A20" }}>
					{value.emails &&
						renderClientEmails(JSON.parse(value.emails))}
				</div>
			),
			maxWidth: 400,
			minWidth: 150,
			width: 150,
			disableSortBy: true,
		},
		{
			Header: t("pages.admin_events_table.col_url"),
			accessor: (funeral) => (
				<>
					<Link to={`/funerals/${funeral.funeralUrl}`} target="_blank" className={CSS.url}>
						{t("pages.deceased.funeral_page_link")}
					</Link>
					&nbsp;&nbsp;
					<Link to={`/funerals/${funeral.funeralUrl}/admin`} target="_blank" className={CSS.url}>
						{t("pages.deceased.funeral_admin_page_link")}
					</Link>
				</>
			),
			disableSortBy: true,
			disableGlobalFilter: true,
			maxWidth: 400,
			minWidth: 200,
			width: 200,
		},
		{
			Header: t("pages.admin_events_table.col_streams"),
			accessor: "streams",
			disableSortBy: true,
			disableGlobalFilter: true,
			// minWidth: 80,
			width: 84,
			Cell: ({ value }) => (<div className={CSS.topHeaderData} style={{ color: "#001A20" }}>{value ? value.length : 0}</div>),
			disableSortBy: false,
			filter: (rows, id, filterValue) => {
				return rows.filter((row) => {
					if (!filterValue) {
						return true;
					}
					const streams = row.original.streams;
					if (!streams || streams.length < 1) {
						return false;
					}

					return some(streams, (stream) => {
						return moment(filterValue).isSame(
							stream.streamStart,
							"date"
						);
					});
				});
			},
			sortType: (rowA, rowB) => {
				if (
					rowA.original.streams.length > rowB.original.streams.length
				) {
					return 1;
				}
				return -1;
			},
		},
		{
			id: "expander",
			Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
				<span>
					<ArrowDropDownCircleOutlinedIcon />
				</span>
			),
			disableGlobalFilter: true,
			width: 48,
			borderRadius: 0,
			Cell: ({ row }) => (
				<span
					{...row.getToggleRowExpandedProps({
						style: {
							paddingLeft: `${row.depth * 2}rem`,
						},
					})}>
					{row.isExpanded ? (
						<ArrowDropDownCircleOutlinedIcon style={{ color: "#00667E", transform: "rotate(180deg)" }} />
					) : (
						<ArrowDropDownCircleOutlinedIcon style={{ color: "#00667E" }} />
					)}
				</span>
			),
		},
	];
	const getRoomName = (id, funeralId) => {
		return funerals.map(funeral => {
			if (funeral.id === funeralId) {
				return funeral.streams.map(stream => {
					if (stream.roomId === id) {
						return <div key={stream.id}>{stream.name}</div>
					}
				})
			}
		})
	}
	const streamColumns = (funeral) => [
		{
			id: 1,
			Header: () => (<div className={CSS.headerCellData}>{t("pages.admin_events_table.nested_col_stream_name")}</div>),
			accessor: (stream) => (
				<div className={CSS.streamData}>
					{stream.name}
					{stream.state === 'started' && <Label color='red' horizontal className={CSS.statusPill}>Live</Label>}
					{stream.state === 'ended' && <Label horizontal className={CSS.statusPill}>{t("pages.deceased.ended")}</Label>}
				</div>
			),
		},
		{
			id: 2,
			Header: () => (<div className={CSS.headerCellData}>{t("pages.admin_events_table.nested_col_stream_date")}</div>),
			accessor: (stream) => (
				<div className={CSS.streamData}>
					{convertDateToDateOnly(stream.streamStart)}
				</div>
			),
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			id: 3,
			Header: () => (<div className={CSS.headerCellData}>{t("pages.admin_events_table.nested_col_start_time")}</div>),
			accessor: (stream) => (
				<div className={CSS.streamData}>
					{convertDateToTimeOnly(stream.streamStart)}
					{stream.state === 'not_started' && stream.location === 'On-Site' && <Button onClick={() => startStream(stream.funeralId, stream.id)} className={CSS.startStopBtn} style={{ backgroundColor: "#00667E", color: "white", fontFamily: "Bitter", fontStyle: "normal", fontWeight: "normal", padding: "8px 8px", borderRadius: "4px" }}>{t("pages.admin_events_table.start_button")}</Button>}
					{stream.state === 'started' && stream.location === 'On-Site' && stream.room?.streamProvider !== 'self_managed_provider' && <Button onClick={() => resetStream(stream.funeralId, stream.id)} className={CSS.startStopBtn} style={{ backgroundColor: "#00667E", color: "white", fontFamily: "Bitter", fontStyle: "normal", fontWeight: "normal", padding: "8px 8px", borderRadius: "4px" }}>{t("pages.admin_events_table.reset_button")}</Button>}
				</div>
			),
			disableSortBy: true,
		},
		{
			id: 4,
			Header: () => (<div className={CSS.headerCellData}>{t("pages.admin_events_table.nested_col_end_time")}</div>),
			accessor: (stream) => (
				<div className={CSS.streamData}>
					{convertDateToTimeOnly(stream.streamEnd)}
					{stream.state === 'started' && stream.location === 'On-Site' && <Button onClick={() => stopStream(stream.funeralId, stream.id)} className={CSS.startStopBtn} style={{ backgroundColor: "#00667E", color: "white", fontFamily: "Bitter", fontStyle: "normal", fontWeight: "normal", padding: "8px 8px", borderRadius: "4px" }}>{t("pages.admin_events_table.end_button")}</Button>}
				</div>
			),
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			id: 5,
			Header: () => (<div className={CSS.headerCellData}>{t("pages.admin_events_table.nested_col_site")}</div>),
			accessor: (stream) => (
				<div className={CSS.streamData}>
					{stream.location}
				</div>
			),
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			id: 6,
			Header: () => (<div className={CSS.headerCellData}>{t("pages.admin_events_table.nested_col_location")}</div>),
			accessor: (stream) => (
				<div className={CSS.streamData}>
					{stream.roomId &&
						getRoomName(stream.roomId, stream.funeralId)
					}
					{stream.locationName &&
						<div>{stream.locationName}</div>}
					<b>RTMP Url</b>
					<p className={CSS.clickable} onClick={() => { navigator.clipboard.writeText(stream.livestreamEvent?.rtmpUrl) }}>{stream.livestreamEvent?.rtmpUrl}</p>
					<b>RTMP Key</b>
					<p className={CSS.clickable} onClick={() => { navigator.clipboard.writeText(stream.livestreamEvent?.streamKey) }}>{stream.livestreamEvent?.streamKey}</p>
				</div>
			),
			disableSortBy: true,
			disableGlobalFilter: true,
		},
	];
	const renderSubRow = ({
		row,
	}) => {
		return <React.Fragment>
			<Table.Row>
				<Table.Cell style={{ padding: "0px", borderTop: "none" }}>
					<ReactTable1
						data={row.original.streams}
						columns={streamColumns(row.original)}
					/>
				</Table.Cell>
			</Table.Row>
		</React.Fragment>;
	};
	return (
		<div>
			<Header />
			<div>
				{loading && <Spinner funeralSpinner={funeralSpinner}/>}
				<div className={CSS.header}>
					{upcomingFuneralsRes.length>0 && <UpcomingEvents funerals={upcomingFuneralsRes} funeralHomes={funeralHomes} 
					tablePageSize={tablePageSize} 
					tableSortBy={tableSortBy} isClearFilter={isClearFilter}
					STATE={tableGlobalFilter.filterValue!==""||selectedFuneralHomes.length>0||selectedDate ? false : true}
					setGlobalFilter={setGlobalFilter}
					setSelectedFuneralHomes={setSelectedFuneralHomes}
					setIsClearFilter={setIsClearFilter}
					setSelectedDate={setSelectedDate}/> }
					<div className={CSS.funeralHeader}>
						<h3 className={CSS.pageHeader}>
							{t("pages.admin_events_table.title")}
						</h3>
						<button
							className={`${CSS.actionBtn} ${CSS.warning}`}
							type="button"
							onClick={goToCreateEvent}>
							{t("pages.events_calendar.create_button")}
						</button>
					</div>
					<Segment style={{ padding: "0px", border: "1px solid #C4D5D9", borderRadius: "8px" }}>
						<Popup
							style={{ zIndex: "10" }}
							size="huge"
							position="bottom right"
							trigger={
								<Button
									className={CSS.filterDropDownButton}
									style={{ border: "1px solid #00667E", backgroundColor: "white", color: "black", marginTop: "16px", marginRight: "16px" }}
									icon="filter"
									content={t("pages.admin_events_table.filter_button")}
								/>
							}
							content={
								<div>
									{funeralHomes &&
										user.user && (
											<div style={{ paddingBottom: 30 }}>
												<div className={CSS.homeHeader}>
													{t("components.events_table_filters.title")}
												</div>
												<div style={{ height: "30px" }}>
													<FormControlLabel
														control={<Checkbox checked={selectedFuneralHomes.length === funeralHomes.length && selectedFuneralHomes.length !== 0} id="selectAll"
															onChange={updateSelectedAllFuneralHomes}
															style={{
																color: "#00667E",
															}}
															classes={{ root: classes.checkbox, checked: classes.checkedbox }}
															indeterminate={selectedFuneralHomes.length !== funeralHomes.length && selectedFuneralHomes.length !== 0}
															className={classes.root} />}
														label={<span style={{ fontSize: '16px', fontWeight: "300", fontFamily: "Bitter,serif", wordWrap: "break-word", color: "#001A20", lineHeight: "16px" }}>{t("components.events_table_filters.select_all")}</span>} />
												</div>
												{renderFuneralHomes(
													funeralHomes
												)}
											</div>
										)}
									<div>
										<h4 style={{ paddingLeft: "12px", fontFamily: "Bitter, serif", fontStyle: "normal", fontSize: "16px", fontWeight: "500" }}>
											{t("components.events_table_filters.date")}
										</h4>
										<input className={CSS.dateSearch}
											type="date"
											value={selectedDate}
											onChange={updateSelectedDateAndGetFunerals}
										/>
									</div>
									<div className={CSS.clearFilter}>
										<button className={`${CSS.actionBtn} ${CSS.primary}`} onClick={(e) => clearAllFilters(e)}>
											{t("components.events_table_filters.clear_filter")}
										</button>
									</div>
								</div>
							}
							on="click"
						/>
						<FuneralsTable
							data={funerals || []}
							columns={columns}
							xyzGlobalFilter={tableGlobalFilter}
							setGlobalFilter={setGlobalFilter}
							pageCount={funeralsPagination?.totalPages || 0}
							clearFilter={clearFilter}
							dateFilterValue={selectedDate}
							selectedFuneralHomes={selectedFuneralHomes}
							fetchData={fetchData}
							pageIndex={funeralsPagination?.currentPage - 1 || 0}
							SubComponent={({ row, rowProps }) =>
							(
								renderSubRow({
									row,
									rowProps,
									startStream,
									stopStream,
									resetStream,
									getStreamClips,
									t,
								}))
							}
						/>
					</Segment>
				</div>
			</div>
			<Fragment>
				<Modal open={messagesOpen} onClose={handleClose}>
					<div>
						{funerals && (
							<div className={"modalPopupWrapper"} style={{ overflow: "hidden" }}>
								<div className={"modalPopupHeaderWrapper"} style={{ position: "sticky", top: "0px", backgroundColor: "white", zIndex: "1000", boxShadow: "2px 2px 8px 2px rgba(0,26,32,0.2)" }}>
									<div className={"modalPopupTitle"}>
										{t("pages.admin_create_org.table.export_modal_btn")} •{" "}
										{currentFuneral.lastName} {currentFuneral.firstName}
									</div>
									<div className={"modalPopupCloseBtn"}>
										<CloseIcon
											className={"modalPopupCloseIcon"}
											onClick={handleClose}
										/>
									</div>
								</div>
									{exportRes === "IDEAL" ?
										funeralComments.length > 0 ?
											<>
												<div className={CSS.modalPopupBody}>
													{funeralComments.map((comment, index) => {
														return (
															<div key={index} className={CSS.condolenseMessages}>
																<div className={CSS.message}>
																	<div className={CSS.messageText}>
																		{comment.value}
																	</div>
																	<div className={CSS.messageAttribution}>
																		<span className={CSS.name}>
																			{comment.visitor.name}{" "}
																		</span>
																		<span className={CSS.date}>
																			{commentCreatedAtDate(comment.visitor.createdAt)}{" "}
																		</span>
																		<span className={CSS.time}>
																			{commentCreatedAtTime(comment.visitor.createdAt)}
																		</span>
																	</div>
																	{comment.replies.length > 0 &&
																		comment.replies.map((reply) => {
																			return (
																				<div key={reply.id} className={CSS.message}>
																					<div className={CSS.messageText}>
																						{reply.value}
																					</div>
																					<div className={CSS.messageAttribution}>
																						<span className={CSS.name}>
																							{reply.visitor.name}{" "}
																						</span>
																						<span className={CSS.date}>
																							{commentCreatedAtDate(reply.visitor.createdAt)}{" "}
																						</span>
																						<span className={CSS.time}>
																							{commentCreatedAtTime(reply.visitor.createdAt)}
																						</span>
																					</div>
																				</div>
																			);
																		})}
																</div>
															</div>
														);
													})}
												</div>
												<div className={CSS.exportBar} style={{ bottom: "0px", backgroundColor: "white", zIndex: "1000" }}>
													<div style={{ alignItems: "center", display: 'flex' }}>
														<input
															id="toHtml"
															value="toHtml"
															name="check"
															type="radio"
															onChange={handleExport}
														/>
														<label className={CSS.inputLabel}>
															{" "}{t("pages.admin_create_org.table.export_modal_radio1")}
														</label>
														<input
															id="toEmail"
															value="toEmail"
															name="check"
															type="radio"
															className={CSS.radioBtn}
															onChange={handleExport}
														/>
														<label className={CSS.inputLabel}>
															{" "}{t("pages.admin_create_org.table.export_modal_radio2")}
														</label>
														{exportType === "toEmail" && (
															<div>
																<TextField
																	className={errorStates.recipientEmail ? `${CSS.inputEleError} ${CSS.size_lg}` : `${CSS.inputEle} ${CSS.size_lg}`}
																	variant="outlined"
																	value={recipientEmail}
																	onChange={(e) => handleRecipientEmails(e)}
																/>
																{errorStates.recipientEmail ? <div className={CSS.errorText}>{errorStates.recipientEmail}</div> : <div className={CSS.inputHint}>
																	{t('pages.create_event.stream_email_list_hint')}
																</div>}
															</div>
														)}
													</div>
													<div className={CSS.btn}>
														<button
															className={`${CSS.actionBtn} ${CSS.warning}`}
															onClick={(e) => {
																e.preventDefault();
																if (exportType === "toHtml") {
																	window.open(`${window.location.origin}/viewcondolensemessages/${currentFuneral.id}`, "_blank");
																}
																else{
																	if(recipientEmail){
																		getEmailsToSend()
																	}
																}
															}}>
															{exportType === "toEmail" ? "Send" : t("pages.admin_create_org.table.export_modal_btn")}
														</button>
													</div>
												</div>
											</>
											:
											<div className={CSS.modalPopupBody}>
												<div className={CSS.noMessages}>
													{t("pages.admin_create_org.admin_export.no_messages")}
												</div>
											</div> 
									: exportRes === "REQUEST" ?
										<div className={CSS.modalPopupBody}>
											<div className={CSS.noMessages}>
												<Spinner spinnerSize={STYLE}/>
												<div className={CSS.successText}>{t("pages.admin_create_org.admin_export.sending_email_hint")}...</div>
											</div>
										</div>
									: exportRes === "SUCCESS" ?
									<div className={CSS.modalPopupBody}>
										<div className={CSS.noMessages}>
											<CheckCircleIcon style={{ color: '#167434', fontSize: 100}} />
											<div className={CSS.successText}>{t("pages.admin_create_org.admin_export.email_sent_success")}</div>
										</div>
									</div>
									: 
									<div className={CSS.modalPopupBody}>
										<div className={CSS.noMessages}>
											<ReportProblemIcon style={{ color: '#AE0000', fontSize: 100}} />
											<div className={CSS.successText}>{t("pages.admin_create_org.admin_export.email_sent_failure")}</div>
											<button
												className={`${CSS.actionBtn} ${CSS.warning}`}
												style={{marginTop: 16}}
												type="button"
												onClick={getEmailsToSend}>
												{t("pages.admin_create_org.admin_export.retry")}
											</button>
										</div>
									</div>
								}
							</div>
						)}
					</div>
				</Modal>
				<Modal open={attendeesOpen} onClose={handleClose}>
					<div>
						{funerals && (
							<div className={"modalPopup2Wrapper"} style={{ minWidth: "700px", overflow: "hidden" }}>
								<div className={"modalPopupHeaderWrapper"} style={{ position: "sticky", top: "0px", backgroundColor: "white", zIndex: "1000", boxShadow: "2px 2px 8px 2px rgba(0,26,32,0.2)" }}>
									<div className={"modalPopupTitle"} style={{ paddingRight: "16px", wordBreak: "break-word" }}>
										{t("pages.admin_create_org.table.export_attendees")} •{" "}
										{currentFuneral.lastName} {currentFuneral.firstName}
									</div>
									<div className={"modalPopupCloseBtn"}>
										<CloseIcon
											className={"modalPopupCloseIcon"}
											onClick={handleClose}
										/>
									</div>
								</div>
								{funeralVisitors && (
									<>
										<div className={CSS.modalPopupBody}>
											{funeralVisitors.map((visitor, index) => {
												return (
													<div key={index}>
														<div className={CSS.attendeesList}>
															<div className={CSS.attendeesAttribution}>
																<span className={CSS.name}>
																	{visitor.name}{" "}
																</span>
																<span className={CSS.email}>
																	{visitor.email}
																</span>
															</div>
														</div>
													</div>
												);
											})}
										</div>
										<div className={CSS.exportBar} style={{ display: "flex", flexDirection: "row-reverse", position: "sticky", bottom: "0px", backgroundColor: "white", zIndex: "1000" }}>
											<div className={CSS.btn}>
												<CSVLink data={funeralVisitors} headers={headers} filename={`${currentFuneral.lastName}${"_"}${currentFuneral.firstName}`} target="_blank">
													<button
														className={`${CSS.actionBtn} ${CSS.warning}`}>
														{t("pages.admin_create_org.table.export_modal_btn")} ({funeralVisitors.length})
													</button>
												</CSVLink>
											</div>
										</div>
									</>
								)}
							</div>
						)}
					</div>
				</Modal>
				<Modal open={downloadLinksOpen} onClose={handleClose}>
				 	<div>
						{funerals && (
							<div className={"modalPopupWrapper"} style={{ overflow: "hidden" }}>
								<div className={"modalPopupHeaderWrapper"} style={{ position: "sticky", top: "0px", backgroundColor: "white", zIndex: "1000", boxShadow: "2px 2px 8px 2px rgba(0,26,32,0.2)" }}>
									<div className={"modalPopupTitle"}>
										{t("pages.admin_create_org.table.download_modal_btn")} •{" "}
										{currentFuneral.lastName} {currentFuneral.firstName}
									</div>
									<div className={"modalPopupCloseBtn"}>
										<CloseIcon
											className={"modalPopupCloseIcon"}
											onClick={handleClose}
										/>
									</div>
								</div>
									{exportRes === "IDEAL" ?
											<>
												<div className={CSS.modalPopupBody}>
													<div className={CSS.noMessages}>
													{t("pages.admin_create_org.admin_export.send_buy_funeral_links")}
													</div>
												</div> 
												<div className={CSS.exportBar} style={{ bottom: "0px", backgroundColor: "white", zIndex: "1000" }}>
													<div style={{ alignItems: "center", display: 'flex' }}>
														<div>
															<TextField
																className={errorStates.recipientEmail ? `${CSS.inputEleError} ${CSS.size_lg}` : `${CSS.inputEle} ${CSS.size_lg}`}
																variant="outlined"
																value={recipientEmail}
																onChange={(e) => handleRecipientEmails(e)}
															/>
															{errorStates.recipientEmail ? <div className={CSS.errorText}>{errorStates.recipientEmail}</div> : <div className={CSS.inputHint}>
																{t('pages.create_event.stream_email_list_hint')}
															</div>}
														</div>
													</div>
													<div className={CSS.btn}>
														<button
															className={`${CSS.actionBtn} ${CSS.warning}`}
															onClick={(e) => {
																e.preventDefault();
																getEmailsToSendForBuyFuneralLinks(currentFuneral.id);
															}}>
															{t("pages.admin_create_org.admin_export.send")}
														</button>
													</div>
												</div>
											</>
									: exportRes === "REQUEST" ?
										<div className={CSS.modalPopupBody}>
											<div className={CSS.noMessages}>
												<Spinner spinnerSize={STYLE}/>
												<div className={CSS.successText}>{t("pages.admin_create_org.admin_export.sending_email_hint")}...</div>
											</div>
										</div>
									: exportRes === "SUCCESS" ?
									<div className={CSS.modalPopupBody}>
										<div className={CSS.noMessages}>
											<CheckCircleIcon style={{ color: '#167434', fontSize: 100}} />
											<div className={CSS.successText}>{t("pages.admin_create_org.admin_export.buy_funeral_sent_success")}</div>
										</div>
									</div>
									: 
									<div className={CSS.modalPopupBody}>
										<div className={CSS.noMessages}>
											<ReportProblemIcon style={{ color: '#AE0000', fontSize: 100}} />
											<div className={CSS.successText}>{t("pages.admin_create_org.admin_export.buy_funeral_sent_failure")}</div>
											<button
												className={`${CSS.actionBtn} ${CSS.warning}`}
												style={{marginTop: 16}}
												type="button"
												onClick={getEmailsToSend}>
												{t("pages.admin_create_org.admin_export.retry")}
											</button>
										</div>
									</div>
								}
							</div>
						)}
					</div>
				</Modal>
				<Modal open={deleteFuneralModalOpen} onClose={handleClose}>
					<div className={"modalPopup2Wrapper"}>
                    <div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
                        <div className={"modalPopupTitle"}>
						{t("pages.admin_create_org.view_org.delete_org_modal.delete_funeral")}
                        </div>
                        <div className={"modalPopupCloseBtn"}>
                            <CloseIcon
								className={"modalPopupCloseIcon"}
								onClick={handleClose}
                            />
                        </div>
                    </div>
                    <div className={"modalPopupBody"} style={{padding: "0px 24px 16px"}}>
						<div className={"modalBody"}>
							{t("pages.admin_create_org.view_org.delete_org_modal.delete_confirmation")}
							<div>
								<span style={{ color: "#AE0000" }}>
									{t("pages.admin_create_org.view_org.delete_org_modal.body_warn")}
								</span>
							</div>
						</div>
						<div
							style={{
								marginTop: 16,
								display: "flex",
								justifyContent: "space-between",
							}}>
							<button className={`${CSS.actionBtn} ${CSS.primary}`} onClick={handleClose}>
								{t("pages.admin_create_org.view_org.delete_home_modal.cancel")}
							</button>
							<button className={`${CSS.actionBtn} ${CSS.error}`} onClick={() => {deleteSelectedFuneral(eachFuneralId)}}>
							{t("pages.admin_create_org.view_org.delete_org_modal.delete_funeral")}
							</button>
						</div>
                    </div>
                </div>
				</Modal>
			</Fragment>
		</div>
	);
};

const ReactTable1 = ({
	columns,
	data,
	funeral
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
	} = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);
	const { height, minimumWidth } = useWindowDimensions();
	const { t } = useTranslation();
	return (
		<>
			<Table className={CSS.table} structured {...getTableProps()} style={{ border: "none", margin: "0px", width: (minimumWidth >= 768) ? "890%" : '100%', borderRadius: "none" }}>
				<Table.Header>
					{headerGroups.map((headerGroup) => (
						<Table.Row className={CSS.headerRow} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Table.HeaderCell
									className={CSS.innerHeaderCell}
									{...column.getHeaderProps(
										{
											...column.getSortByToggleProps(),
											style: { minWidth: column.minWidth, width: column.width },
										},
									)}>
									{column.render("Header")}
									<span>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<Icon name="caret down" />
											) : (
												<Icon name="caret up" />
											)
										) : (
											""
										)}
									</span>
								</Table.HeaderCell>
							))}
						</Table.Row>
					))}
				</Table.Header>
				<Table.Body {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						const rowProps = row.getRowProps();
						return (
							<React.Fragment key={rowProps.key}>
								<Table.Row {...rowProps}>
									{row.cells.map((cell) => {
										return (
											<Table.Cell
												{...cell.getCellProps()}>
												{cell.render("Cell")}
											</Table.Cell>
										);
									})}
								</Table.Row>
							</React.Fragment>
						);
					})}
				</Table.Body>
			</Table>
		</>
	);
};
const mapStateToProps = (state) => ({
	funerals: state.funeral.funerals.funerals,
	funeralsPagination: state.funeral?.funerals?.pagination || {},
	funeralHomes: state.funeralHome.funeralHomes,
	user: state.user.currentUser,
	funeralComments: state.funeral.funeralComments,
	funeralVisitors: state.funeral.funeralVisitors,
	exportRes: state.funeral.exportResData,
	upcomingFuneralsRes: state.funeral.upcomingFunerals,
	loading: state.funeral.showLoading,
});

const mapDispatchToProps = {
	getFunerals,
	getFuneralHomes,
	startStream,
	stopStream,
	resetStream,
	getStreamClips,
	getFuneralComments,
	getFuneralVisitors,
	postMessagesToEmail,
	postBuyFuneralLinksToEmail,
	getUpcomingFunerals,
	deleteFuneral,
	initialStateVal
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralsPage);
