import React from "react";
import { useEffect } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import CSS from "./CondolenceMessagesViewPage.module.scss";
import profileImage from "../../assets/svgs/default_image.jpg";
import { getFuneralComments, getFuneralDetails } from "../../redux/funeral/actions";
import { connect } from "react-redux";

const CondolenceMessagesViewPage = (props) => {
	const { getFuneralComments, getFuneralDetails, funeralDetails, funeralComments } = props;
	const {
		match: {
			params: { id: funeralId },
		},
	} = props;
	const { t } = useTranslation();
	useEffect(() => {
		getFuneralDetails(funeralId, true);
		getFuneralComments(funeralId);
	}, []);
	const commentCreatedAtDate = (time) => {
		return moment(new Date(time)).format("LL");
	};
	const commentCreatedAtTime = (time) => {
		return moment(new Date(time)).format("h:mm a");
	};

	return (
		<div className={CSS.printable}>
			<div className={CSS.condolenceHeader}>
			{t("pages.admin_create_org.admin_export.condolence_messages")}
			</div>
			<div className={CSS.deceasedDetails}>
				<div className={CSS.deceasedImage}>
					<img src= {funeralDetails.deceasedImageUrl ? funeralDetails.deceasedImageUrl : profileImage} alt="profile"/>
				</div>
				<div className={CSS.deceasedInfo}>
					<div className={CSS.deceasedName}>
						{funeralDetails.firstName} {funeralDetails.lastName}
					</div>
					<div className={CSS.deceasedDates}>
						{funeralDetails.dateOfBirth} - {funeralDetails.dateOfDeath}
					</div>
				</div>
			</div>
			<div className="modalPopupBody">
				{funeralComments.map((comment, index) => {
					return (
						<div key={index} className={CSS.condolenseMessages}>
							<div className={CSS.message}>
								<div className={CSS.messageText}>
									{comment.value}
								</div>
								<div className={CSS.messageAttribution}>
									<span className={CSS.name}>
										{comment.visitor.name}{" "}
									</span>
									<span className={CSS.date}>
										{commentCreatedAtDate(
											comment.visitor.createdAt
										)}{" "}
									</span>
									<span className={CSS.time}>
										{commentCreatedAtTime(
											comment.visitor.createdAt
										)}
									</span>
								</div>
								{comment.replies.length > 0 &&
									comment.replies.map((reply) => {
										return (
											<div
												key={reply.id}
												className={CSS.message}>
												<div className={CSS.messageText}>
													{reply.value}
												</div>
												<div className={CSS.messageAttribution}>
													<span className={CSS.name}>
														{reply.visitor.name}{" "}
													</span>
													<span className={CSS.date}>
														{commentCreatedAtDate(
															reply.visitor.createdAt
														)}{" "}
													</span>
													<span className={CSS.time}>
														{commentCreatedAtTime(
															reply.visitor.createdAt
														)}
													</span>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					);
				})}
			</div>
			<div className={CSS.divFooter}>{t("pages.admin_create_org.admin_export.condolence_messages")} • {funeralDetails.firstName} {funeralDetails.lastName}</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralDetails: state.funeral.funeralDetails,
	funeralComments: state.funeral.funeralComments,
});

const mapDispatchToProps = {
	getFuneralDetails,
	getFuneralComments,
};
export default connect(mapStateToProps, mapDispatchToProps)(CondolenceMessagesViewPage);
