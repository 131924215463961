import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import CSS from "./FuneralViewPage.module.scss";
import logo from "../../assets/svgs/funeral-live-logo.png";
import defaultImage from "../../assets/svgs/default_image.jpg";
import { Link } from "react-router-dom";
import NotFoundImage from "../../assets/svgs/404page.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import {
	getFuneralStreams,
	getFuneralDetails,
	postFuneralComment,
	getFuneralComments,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess
} from "../../redux/funeral/actions";
import { getFuneralHome } from "../../redux/funeralHome/actions";
import "../../components/ModalPopup.scss";
import { useTranslation } from 'react-i18next';
import VideoStream from "./VideoStream";
import VideoStreamLiveStream from './VideoStreamLiveStream';
import {Helmet} from "react-helmet";

const FuneralViewPage = (props) => {
	const {
		match: {
			params: { id: funeralId },
		},
	} = props;
	const { funeralDetails, funeralStreams, funeralComments, funeralHomeInfo, visitorDetails, postFuneralComment, postFuneralReply, getFuneralErrorCode } =
		props;
	const { t, i18n } = useTranslation();
	useEffect(() => {
		props.getFuneralDetails(funeralId, true);
		props.getFuneralComments(funeralId);
		props.getFuneralStreams(funeralId, true);
	}, []);
	useEffect(() => {
		if(funeralDetails.funeralHomeId){
			props.getFuneralHome(funeralDetails.funeralHomeId, true);
		}
	}, [funeralDetails.funeralHomeId]);
	const [selectedOption, setSelectedOption] = useState(
		() => {
			const savedLanguage = localStorage.getItem("language");
			i18n.changeLanguage(savedLanguage);
			return savedLanguage;
		}
	);
	const [dropdownOpen, setDropdown] = useState(false);
	const toggleDropdown = () => setDropdown(!dropdownOpen);
	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		if(event.target.value === "en"){
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
		}
		else if(event.target.value === "fr"){
			i18n.changeLanguage("fr");
			localStorage.setItem("language", "fr");
		}
	};
	const openFuneralLive = () => {
		window.open("https://funerallive.ca/");
	};
	const convertDateToDateOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("YYYY-MM-DD");
		}
		return "";
	};
	const convertDateToTimeOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("h:mm A");
		}
		return "";
	};

	if (getFuneralErrorCode === 400) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					background: `url(${NotFoundImage}) no-repeat center center fixed`,
					backgroundSize: 'cover',
				}}
			/>
		);
	}
	
	return (
		<div>
			{funeralHomeInfo && (
				<div className={CSS.navWrapper}>
					<div className={CSS.logoWrapper}>
						<img src={logo} alt="Logo" onClick={openFuneralLive} />
					</div>
					<div className={CSS.navTitles}>
						{funeralHomeInfo.flowerShopLink ? 
							<Link
								to={{ pathname: funeralHomeInfo.flowerShopLink }}
								target="_blank">
								{t('navigation.viewer_send_flowers')}
							</Link>: ""}
							<Link to={{ pathname: funeralHomeInfo.baseUrl }}
								target="_blank">{funeralHomeInfo.name}</Link>
							<select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
								<option value="en">En</option>
								<option value="fr">Fr</option>
							</select>
					</div>
					<div className={CSS.burgerMenu}>
						{dropdownOpen ? (
							<CloseIcon onClick={toggleDropdown} />
						) : (
							<MenuIcon onClick={toggleDropdown} />
						)}
						{dropdownOpen && (
							<div className={CSS.dropdownMenu}>
								{funeralHomeInfo.flowerShopLink ?
								<Link
									to={{
										pathname:
											funeralHomeInfo.flowerShopLink,
									}}
									target="_blank">
									{t('navigation.viewer_send_flowers')}
								</Link>: ""}
								<Link to={{ pathname: funeralHomeInfo.baseUrl }}
							target="_blank">{funeralHomeInfo.name}</Link>
							</div>
						)}
						<select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
							<option value="en">En</option>
							<option value="fr">Fr</option>
						</select>
					</div>
				</div>
			)}
			{funeralDetails && (
				<div className={CSS.mainBanner}>
					<Helmet>
						<meta property="og:title" content={`${funeralDetails.firstName}${" "}${funeralDetails.lastName}`}/>
						<meta property="og:type" content="website" />
						<meta property="og:url" content={`https://app.funerallive.ca/funerals/${funeralDetails?.funeralUrl}`} />
						<meta property="og:image" content={funeralDetails.deceasedImageUrl ? funeralDetails.deceasedImageUrl : defaultImage}/>
						<meta property="og:image:type" content="image/png"/>
						<meta property="og:image:height" content="300"/>
						<meta property="og:image:width" content="300"/>
						<meta property="og:image:alt" content={`${funeralDetails.firstName}${" "}${funeralDetails.lastName}`} />
						<meta name="description" content={`${funeralDetails.firstName}${" "}${funeralDetails.lastName} - Funeral Live`}/>
						<meta name="twitter:card" content="summary large"/>
					</Helmet>
					<div className={CSS.userInfoWrappper}>
						<div className={CSS.sectionContainer}>
							<div>
								<div>
									<div className={CSS.profileImg}>
										<img
											src={funeralDetails.deceasedImageUrl ? funeralDetails.deceasedImageUrl : defaultImage}
											alt="Profile"
										/>
									</div>
								</div>
							</div>
							<div>
								<div>
									<div className={CSS.userInfo}>
										<div className={CSS.userName}>
											{funeralDetails.firstName}{" "}
											{funeralDetails.lastName}
										</div>
										<div className={CSS.divider}>
											{/* <img
												src={seperator}
												alt="seperator"
											/> */}
											<div className={CSS.miniCircle} style={{marginRight: "16px"}}></div>
											<div className={CSS.medCircle} style={{marginRight: "16px"}}></div>
											<div className={CSS.maxCircle} style={{marginRight: "16px"}}></div>
											<div className={CSS.medCircle} style={{marginRight: "16px"}}></div>
											<div className={CSS.miniCircle}></div>
										</div>
										{!funeralDetails.hideDateOfDeath &&
											<div className={CSS.birth_death_dates}>
												{funeralDetails.dateOfBirth} -{" "}
												{funeralDetails.dateOfDeath}
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{funeralStreams && funeralHomeInfo && (
				<section className={CSS.venueInformation}>
					<div className={CSS.sectionContainer}>
						<div>
							<div>
								<div className={CSS.venueLogo}>
									{funeralHomeInfo.logoUrl ?
									<img
										src={funeralHomeInfo.logoUrl}
										alt="Logo"
									/>: ""}
								</div>
							</div>
						</div>
						<div>
							<div>
								<div className={CSS.eventTiminings}>
									<div className={CSS.eventMainHeader}>
										{t('pages.deceased.web_stream_details')}
									</div>
									<div className={CSS.eventList}>
										{funeralStreams.map((stream) => (
											<div
												key={stream.id}
												className={CSS.eventItem}>
												<div
													className={CSS.eventHeader}>
													{stream.name}
												</div>
												<div className={CSS.eventTime}>
													{convertDateToDateOnly(
														stream.streamStart
													)}
													,{" "}
													{convertDateToTimeOnly(
														stream.streamStart
													)}
													-
													{convertDateToTimeOnly(
														stream.streamEnd
													)}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{(funeralDetails.streamProvider === 'dacast_provider' || funeralDetails.streamProvider === 'livestream_provider' || funeralDetails.streamProvider === null || funeralDetails.streamProvider === 'self_managed_provider') && (<VideoStreamLiveStream funeralDetails={funeralDetails} funeralHomeInfo={funeralHomeInfo} funeralId={funeralId} funeralComments={funeralComments} visitorDetails = {visitorDetails} />)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralStreams: state.funeral.funeralStreams,
	funeralDetails: state.funeral.funeralDetails,
	funeralComments: state.funeral.funeralComments,
	funeralHomeInfo: state.funeralHome.funeralHomeViewing,
	visitorDetails: state.funeral.visitorData,
	commentDetails: state.funeral.commentData,
	getFuneralErrorCode: state.funeral.getFuneralErrorCode
});

const mapDispatchToProps = {
	getFuneralStreams,
	getFuneralDetails,
	getFuneralComments,
	getFuneralHome,
	postFuneralComment,
	postFuneralReply,
	postVisitorDataSuccess,
	postFuneralCommentSuccess
};
export default connect(mapStateToProps, mapDispatchToProps)(FuneralViewPage);
