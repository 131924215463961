import { 
  GET_FUNERAL_HOMES_SUCCESS,
  CREATE_FUNERAL_HOME_SUCCESS,
  CREATE_FUNERAL_HOME_FAILURE,
  GET_FUNERAL_HOME_SUCCESS,
  PRESIGN_IMAGE_SUCCESS,
  POST_TEST_STREAM_SUCCESS,
  POST_TEST_STREAM_FAILURE,
  GET_TEST_STREAM_SUCCESS,
  POST_REDEEM_POINTS_SUCCESS,
  UPDATE_FUNERAL_HOME_SUCCESS,
} from './actions';

const initialState = {
  funeralHomes: [],
  funeralHome: { error: false, success: false, image: null },
  updatedHome: {},
  funeralHomeViewing: {},
  testStreamViewing: {},
  newStream: {},
  error: {},
  streamData: {},
  points: {}
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case GET_FUNERAL_HOMES_SUCCESS:
      return { ...state, funeralHomes: action.funeralHomes }
    case GET_FUNERAL_HOME_SUCCESS:
      return { ...state, funeralHomeViewing: action.funeralHome }
    case PRESIGN_IMAGE_SUCCESS:
      return { ...state, funeralHome: { ...state.funeralHome, image: action.blobSignedId } }
    case CREATE_FUNERAL_HOME_SUCCESS:
      return { ...state, funeralHome: { error: false, success: true, ...action.funeralHome } }
    case CREATE_FUNERAL_HOME_FAILURE:
      return { ...state, funeralHome: { ...state.funeralHome, success: false, error: true, message: action.message } }
    case UPDATE_FUNERAL_HOME_SUCCESS:
      return { ...state, updatedHome: { error: false, success: true, ...action.funeralHome }}
    case POST_TEST_STREAM_SUCCESS:
      return { ...state, newStream: action.newStream, error: {}}
    case POST_TEST_STREAM_FAILURE:
        return { ...state, error: action.error, newStream:{}}
    case POST_REDEEM_POINTS_SUCCESS:
      return { ...state, points: action.newStream}
    case GET_TEST_STREAM_SUCCESS:
      return { ...state, streamData: action.streams}
    default:
      return state
  }
}

export default reducer;
