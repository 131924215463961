import React, { useEffect } from "react";
import {
	useTable,
	usePagination,
	useSortBy,
	useExpanded,
	useGlobalFilter,
	useFilters,
} from "react-table";
import { Table, Dropdown, Icon } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import GlobalFilter from "./GlobalFilter";
import CSS from "./Table.module.css";
import { connect } from "react-redux";

const ReactTable = ({
	columns,
	data,
	pageCount: controlledPageCount,
	SubComponent,
	dateFilterValue,
	selectedFuneralHomes,
	filterName,
	user,
	xyzGlobalFilter,
	clearFilter,
	fetchData,
	pageIndex: controlledPageIndex
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		setPageSize,
		pageCount,
		state: { pageIndex, pageSize, globalFilter, filters, sortBy },
		setGlobalFilter,
		setFilter,
		setAllFilters
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: controlledPageIndex, hiddenColumns: ["funeralHomeId"] },
			manualPagination: true,
			manualSortBy: true,
			manualGlobalFilter: true,
			autoResetPage: true,
			pageCount: controlledPageCount,
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);
	useEffect(() => {
		fetchData && fetchData({ pageIndex, pageSize, sortBy, globalFilter });
	}, [pageIndex, pageSize, sortBy, globalFilter]);
	useEffect(() => {
		if(clearFilter){
			setAllFilters([]);
		}
	}, [clearFilter]);
	useEffect(() => {
		if(selectedFuneralHomes.length>0){
			setFilter("funeralHomeId", selectedFuneralHomes);
		}
		else{
			setAllFilters([]);
		}
	}, [selectedFuneralHomes]);
	const { t } = useTranslation();
	// console.log(globalFilter)
	return (
		<>
			<div>
			<GlobalFilter
				globalFilter={globalFilter}
				setGlobalFilter={setGlobalFilter}
        filterName={filterName}
				xyzGlobalFilter={xyzGlobalFilter}
			/>
			<Table className={CSS.table} structured {...getTableProps()} style={{border : "none"}}>
				<Table.Header >
					{headerGroups.map((headerGroup) => (
						<Table.Row className={CSS.headerRow} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Table.HeaderCell
									className={CSS.headerCell}
									{...column.getHeaderProps(
									{
										...column.getSortByToggleProps(),
										style: { minWidth: column.minWidth, width: column.width, borderRadius: column.borderRadius },
									},
									)}>
									{column.render("Header")}
									<span>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<Icon name="caret down" />
											) : (
												<Icon name="caret up" />
											)
										) : (
											""
										)}
									</span>
								</Table.HeaderCell>
							))}
						</Table.Row>
					))}
				</Table.Header>
				<Table.Body {...getTableBodyProps()}>
					{page.length === 0 && 
						(filters.length > 0 || globalFilter) && (
							<Table.Row>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell
									style={{
										padding: "50px 0 50px 0",
										fontStyle: "italic",
									}}>
										{/* {data.length>0 ?  */}
										{t("pages.admin_create_org.table.filter_no_results")}
										{/* <div style={{position: "fixed", left: "35%", top: "25%", zIndex: "1000"}}><Spinner /></div>
										} */}
								</Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
						)}
					{page.map((row, i) => {
						prepareRow(row);
						const rowProps = row.getRowProps();
						return (
							<React.Fragment key={rowProps.key}>
								<Table.Row {...rowProps}>
									{row.cells.map((cell) => {
										return (
											<Table.Cell
												{...cell.getCellProps()}>
												{cell.render("Cell")}
											</Table.Cell>
										);
									})}
								</Table.Row>
								{row.isExpanded &&
									SubComponent({ row, rowProps })}
							</React.Fragment>
						);
					})}
				</Table.Body>
			</Table>
			{/* 
				Pagination can be built however you'd like. 
				This is just a very basic UI implementation:
			*/}
			<div className={CSS.pagination} style={{borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
				<div className={CSS.pageDropdown}>
				{t('pages.admin_events_table.footer_rows')}:
					<Dropdown
						value={pageSize}
						options={[
							{ key: 0, text: "1", value: 1 },
							{ key: 1, text: "10", value: 10 },
							{ key: 2, text: "20", value: 20 },
							{ key: 3, text: "30", value: 30 },
							{ key: 4, text: "40", value: 40 },
							{ key: 5, text: "50", value: 50 },
						]}
						onChange={(e, data) => {
							setPageSize(Number(data.value));
						}}
					/>
				</div>
				<div className={CSS.pageMover}>
					<Icon
						name="arrow left"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					/>{" "}
					<span>
					{t("pages.admin_create_org.table.page")}{" "}
						<strong>
							{pageIndex + 1} {t('pages.admin_events_table.footer_pages')} {pageCount}
						</strong>{" "}
					</span>{" "}
					<Icon
						name="arrow right"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					/>
				</div>
			</div></div>
		</>
	);
};

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
});
export default connect(mapStateToProps)(ReactTable);
