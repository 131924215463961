import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import logo from '../../assets/svgs/funeral-live-logo.png';
import { useTranslation } from 'react-i18next';
import { resetPassword, getCurrentUser } from '../../redux/user/actions';
import CSS from './EnterPasswordResetPage.module.scss';

const RegistrationPage = (props) => {
	const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [dropdownOpen, setDropdown] = useState(false);
	const toggleDropdown = () => setDropdown(!dropdownOpen);
	const [selectedOption, setSelectedOption] = useState(
    () => {
			const savedLanguage = localStorage.getItem("language");
			i18n.changeLanguage(savedLanguage);
			return savedLanguage;
		}
  );
  const token = props.match.params.token;
  const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		if(event.target.value === "en"){
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
		}
		else if(event.target.value === "fr"){
			i18n.changeLanguage("fr");
			localStorage.setItem("language", "fr");
		}
	};
  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.getCurrentUser();
    }
  }, []);
  const handleResetPassword = async () => {
    const result = await props.resetPassword(token, password);
      if (result.status === 200) {
        if (props.user.loggedIn) {
          localStorage.removeItem('token');
        }
        props.history.push('/');
      }
  }
  const handleContact = () => {
    window.open("https://funerallive.ca/contact-us/");
  };
  const openFuneralLive = () => {
    window.open("https://funerallive.ca/");
  };
  return (
    <div>
      <section className={CSS.navWrapper}>
        <div className={CSS.logoWrapper}>
          <img src={logo} alt="Logo" onClick={openFuneralLive}/>
        </div>
        <div className={CSS.navTitles}>
          <Link to="/" activeStyle={{ color: '#167434' }}>
            {t('pages.sign_in.sign_in_title')}
          </Link>
          <Link to="/#" onClick={handleContact}>{t('pages.sign_in.contact')}</Link>
        </div>
        <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
          <option value="en">En</option>
          <option value="fr">Fr</option>
        </select>
        <div className={CSS.burgerMenu}>
          {dropdownOpen ? (
            <CloseIcon onClick={toggleDropdown} />
          ) : (
            <MenuIcon onClick={toggleDropdown} />
          )}
          {dropdownOpen && (
            <div className={CSS.dropdownMenu}>
              <Link to="/" activeStyle={{ color: '#167434' }}>
              `{t('pages.sign_in.sign_in_title')}
              </Link>
              <Link to="/#" onClick={handleContact}>`{t('pages.sign_in.contact')}</Link>
            </div>
          )}
          <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
            <option value="en">En</option>
            <option value="fr">Fr</option>
          </select>
        </div>
      </section>
      <section className={CSS.loginForm}>
        <div className={CSS.sectionContainer}>
        <div className={CSS.formWrappper}>
						<span className={CSS.formHeading}>
            {t('pages.sign_in.complete_registration')}
						</span>
						<div className={CSS.formElements}>
            <div className={CSS.inputWrapperRow}>
                <div className={CSS.inputWrapper}>
                  <label className={CSS.inputHeader}>
                    {t('pages.sign_in.password')}
                  </label>
                  <input
                    className={CSS.inputElement}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    ></input>
                </div>
              </div>
              <div className={CSS.inputWrapperRow}>
                <div className={CSS.inputWrapper}>
                  <label className={CSS.inputHeader}>
                  {t('pages.sign_in.password_confirmation')}
                  </label>
                  <input
                    className={CSS.inputElement}
                    type="password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    ></input>
                </div>
              </div>
						</div>
						<div>
            {/* {props.user.error && 
            <div className={CSS.userMsg}>
              <div className={CSS.userMessageContainer}>
                {t('pages.sign_in.password_error')}
              </div>
            </div>
          } */}
							<div className={CSS.submitWrapper}>
                <Link to="/" className={CSS.linkText}>
                  {t('pages.sign_in.sign_in_title')}
                </Link>
								<button className={`${CSS.actionBtn} ${CSS.warning}`} type="button" disabled={(!password || !passwordConfirmation) || (password !== passwordConfirmation)} onClick={handleResetPassword}>
                  {t('pages.sign_in.reset_password')}
								</button>
							</div>
						</div>
					</div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user.currentUser,
});

const mapDispatchToProps = {
  resetPassword,
  getCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
