import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from 'react-i18next';
const StripeTermsAndPrivacy = () => {
	const goToStripe = () => {
        window.open("https://stripe.com/en-ca");
    };
	const { t } = useTranslation();
	return (
		<div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
			<LockIcon
				style={{ color: "#167434", fontSize: 21, marginRight: "6px" }}
			/>
			<div
				style={{
					fontFamily: "Bitter",
					fontStyle: "normal",
					fontWeight: 500,
					fontSize: "16px",
					lineHeight: "16px",
					textAlign: "center",
					color: "#001A20",
					marginRight: "8px",
				}}>
				{t('pages.checkout_form.Secure_payments_desc')}
			</div>
			<button
				style={{
					padding: "8px 16px",
					backgroundColor: "white",
					borderRadius: "4px",
                    cursor: "pointer"
				}}
				onClick={goToStripe}>
					{t('pages.checkout_form.powered_by')}
 					<b> stripe</b>
			</button>
		</div>
	);
};

export default StripeTermsAndPrivacy;
