import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
	getFunerals
} from "../../redux/funeral/actions";
import CSS from "./FuneralsPage.module.scss";
import profileImage from '../../assets/svgs/default_image.jpg';
import { useTranslation } from 'react-i18next';
const UpcomingEvents = ({ funerals, funeralHomes, tablePageSize, tableSortBy, getFunerals, STATE,
	setGlobalFilter, setSelectedFuneralHomes, setSelectedDate, isClearFilter, setIsClearFilter}) => {
    let orient = "#00667E";
	let organicBlack = "#001A20";
	const { t } = useTranslation();
	const [buttonColor, setButtonColor] = useState({});
	const [btnSelect, setBtnSelect] = useState({});
	useEffect(() => {
	
	}, [buttonColor, btnSelect]);
	useEffect(() => {
		if(!STATE || isClearFilter){
			setButtonColor({});
			setBtnSelect({});
			setIsClearFilter(false)
		}
	}, [STATE, isClearFilter]);
	const getFuneralHomeName = (id) => {
        return funeralHomes?.map(home => {
            if(home.id === id){
                return <div key={home.id} style={{fontFamily: 'Bitter, serif', fontStyle: 'normal', fontWeight: 300,fontSize: 14, color: '#001A20'}}>
                            {home.name}
                        </div>
            }
        })
    };
	const getSelectedUpcomingEvent = (e,id) => {
		e.preventDefault();
		const currentColor = buttonColor[id] || orient;
		const newColor = currentColor === orient ? organicBlack : orient;
		setButtonColor({ buttonColor, [id]: newColor });
		const btnOpen = btnSelect[id] || false;
		setBtnSelect({ btnSelect, [id]: !btnOpen });
		const funeralFilter={
			filterName: "Id",
			filterValue: id
		}
		if(!btnOpen){
			getFunerals(1, tablePageSize, tableSortBy , funeralFilter, [], '', '');
			setSelectedDate('');
			setSelectedFuneralHomes([]);
			setGlobalFilter(prevState => ({
					...prevState,
					filterValue: ""
			}))
		}
		else{
			getFunerals(1, tablePageSize, tableSortBy , {}, [], '', '');
		}
	}
	return (
		<div style={{ marginTop: 16, marginBottom: 16 }}>
			<div
				style={{
					fontFamily: "Inter, sans-serif",
					fontStyle: "normal",
					fontWeight: 700,
					fontSize: 14,
					textTransform: "uppercase",
					color: "#00667E",
					marginBottom: 16,
				}}>
				{t('pages.deceased.upcoming_events')}
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}>
				{funerals?.slice(0, 3).map((funeral) => (
					<div
						key={funeral.id}
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							background: "#FFFFFF",
							width: "33.3%",
							border: buttonColor[funeral.id] === organicBlack ? "1px solid #00667E" : "1px solid #C4D5D9",
							boxShadow:
								"0px 0px 4px 1px rgba(0, 102, 126, 0.15)",
							borderRadius: 8,
							padding: 16,
							marginBottom: 8,
							marginRight: "24px"
						}}>
						<div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<img style={{borderRadius: "50%", height: "32px", width: "32px", marginBottom: "4px", marginRight: "8px"}} 
								src= {funeral.deceasedImageUrl ? funeral.deceasedImageUrl : profileImage} alt="profile"/>
								<div
									style={{
										fontFamily: "Exo, sans-serif",
										fontStyle: "normal",
										fontWeight: 500,
										fontSize: 16,
										color: "#167434",
										marginBottom: 8,
									}}>
									{funeral.lastName}
									{", "}
									{funeral.firstName}
								</div>
							</div>
							<div>
								{getFuneralHomeName(funeral.funeralHomeId)}
							</div>
							<div
								style={{
									marginBottom: 8,
									fontFamily: "Inter, sans-serif",
									fontStyle: "normal",
									fontWeight: 500,
									fontSize: 14,
									textDecorationLine: "underline",
								}}>
								<Link
									to={`/funerals/${funeral.funeralUrl}`}
									target="_blank"
									style={{ color: "#00667E" }}>
									{funeral.funeralUrl}
								</Link>
							</div>
						</div>
						<div>
							<VisibilityOutlinedIcon
								className={buttonColor[funeral.id] === organicBlack ? CSS.organicBlackButton : CSS.orientButton}
								style={{ color: "white", padding: 4 }}
								cursor="pointer"
								onClick={(e) => getSelectedUpcomingEvent(e, funeral.id)}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
	getFunerals
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingEvents);
