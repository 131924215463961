import { Row, Col } from 'antd';
import React from 'react';
import {
  col,
  weekDays,
  weekDayName,
  weekDates,
  lightHighlighter,
  timeCol1,
  timeCol,
  timeString
} from '../styles';
import { isTodaysDate } from '../../utils';
function WeekHeader(props) {
  return (
    <div style={{ display: "flex", alignItems: 'stretch', position: 'sticky', top: '0px', zIndex: 9 }}>
      {props.weekDays.map(day => (
        <Col
          key={day.dateStamp}
          style={
            isTodaysDate(day.dateStamp)
              ? { ...col, ...weekDays, ...lightHighlighter }
              : { ...col, ...weekDays }
          }
        >
          <p style={weekDayName}>{day.weekDayName}</p>
          <p style={weekDates}>{day.date}</p>
        </Col>
      ))}
      <Col style={timeCol}>
        <span style={timeString}>
          EST
        </span>
        <span style={timeString}>
          GMT-5
        </span>
      </Col>
    </div>
  );
}

export default WeekHeader;