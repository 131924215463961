import axios from "../../utils/axios";
import camelcaseKeys from "camelcase-keys-recursive";
import snakeCaseKeys from "snakecase-keys";

export const POST_CREDITS_PAYMENT = "POST_CREDITS_PAYMENT";
export const POST_CREDITS_PAYMENT_SUCCESS = "POST_CREDITS_PAYMENT_SUCCESS";
export const POST_CREDITS_PAYMENT_FAILURE = "POST_CREDITS_PAYMENT_FAILURE";

const GET_CREDIT_CARDS_REQUEST = "GET_CREDIT_CARDS_REQUEST";
export const GET_CREDIT_CARDS_SUCCESS = "GET_CREDIT_CARDS_SUCCESS";
export const GET_CREDIT_CARDS_FAILURE = "GET_CREDIT_CARDS_FAILURE";

export const POST_CREDIT_CARD = "POST_CREDIT_CARD";
export const POST_CREDIT_CARD_SUCCESS = "POST_CREDIT_CARD_SUCCESS";
export const POST_CREDIT_CARD_FAILURE = "POST_CREDIT_CARD_FAILURE";

export const UPDATE_EXISTING_CARD = "UPDATE_EXISTING_CARD";
export const UPDATE_EXISTING_CARD_SUCCESS = "UPDATE_EXISTING_CARD_SUCCESS";
export const UPDATE_EXISTING_CARD_FAILURE = "UPDATE_EXISTING_CARD_FAILURE";

export const POST_EXISTING_CREDITCARD_PAYMENT = "POST_EXISTING_CREDITCARD_PAYMENT";
export const POST_EXISTING_CREDITCARD_PAYMENT_SUCCESS = "POST_EXISTING_CREDITCARD_PAYMENT_SUCCESS";
export const POST_EXISTING_CREDITCARD_PAYMENT_FAILURE = "POST_EXISTING_CREDITCARD_PAYMENT_FAILURE";

const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_FAILURE = 'DELETE_CREDIT_CARD_FAILURE';

export const POST_FUNERAL_VIDEO_PAYMENT = "POST_FUNERAL_VIDEO_PAYMENT";
export const POST_FUNERAL_VIDEO_PAYMENT_SUCCESS = "POST_FUNERAL_VIDEO_PAYMENT_SUCCESS";
export const POST_FUNERAL_VIDEO_PAYMENT_FAILURE = "POST_FUNERAL_VIDEO_PAYMENT_FAILURE";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const POST_REQUEST_DEMO = "POST_REQUEST_DEMO";
export const POST_REQUEST_DEMO_SUCCESS = "POST_REQUEST_DEMO_SUCCESS";
export const POST_REQUEST_DEMO_FAILURE = "POST_REQUEST_DEMO_FAILURE";

export const LOADING_ACTION = "LOADING_ACTION";

export const INITIAL_ACTION = "INITIAL_ACTION";
export const INITIAL_BILLING_ACTION = "INITIAL_BILLING_ACTION";

export const postCreditsPayment = (payload, homeId) => {
	return (dispatch) => {
		dispatch({ type: POST_CREDITS_PAYMENT });
		return axios
			.post(
				`api/funeral_homes/${homeId}/buy_credits`,
				snakeCaseKeys(payload),
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((response) => {
				const paymentResponse = camelcaseKeys(response.data);
				dispatch(postCreditsPaymentSuccess(paymentResponse));
				return response;
			})
			.catch((error) => {
				dispatch(postCreditsPaymentFailure(error));
			});
	};
};
const postCreditsPaymentSuccess = (payment) => {
	return {
		type: POST_CREDITS_PAYMENT_SUCCESS,
		payment,
	};
};
const postCreditsPaymentFailure = (error) => {
	return {
		type: POST_CREDITS_PAYMENT_FAILURE,
		error,
	};
};

export const getCreditCards = (id, useInternalApiKey) => {
	const token = useInternalApiKey
		? process.env.REACT_APP_DECEASED_PAGE_TOKEN || "abc"
		: localStorage.getItem("token");
	return (dispatch) => {
		dispatch({ type: GET_CREDIT_CARDS_REQUEST });
		return axios
			.get(`api/funeral_homes/${id}/credit_cards`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				const creditCardInfo = camelcaseKeys(response.data);
				dispatch(loadingAction(false))
				dispatch(getCreditCardsSuccess(creditCardInfo));
			})
			.catch((error) => {
				dispatch(loadingAction(false));
				dispatch(getCreditCardsFailure(error));
			});
	};
};
const getCreditCardsSuccess = (cardInfo) => {
	return {
		type: GET_CREDIT_CARDS_SUCCESS,
		cardInfo,
	};
};
const getCreditCardsFailure = (error) => {
	return {
		type: GET_CREDIT_CARDS_FAILURE,
		error,
	};
};

export const postCreditCard = ((payload, homeId, useInternalApiKey) => {
	const token = useInternalApiKey
		? process.env.REACT_APP_DECEASED_PAGE_TOKEN || "abc"
		: localStorage.getItem("token");
	return (dispatch) => {
		dispatch({ type: POST_CREDIT_CARD });
		return axios
			.post(`api/funeral_homes/${homeId}/credit_cards`, 
			snakeCaseKeys(payload),
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				const creditCardInfo = camelcaseKeys(response.data);
				dispatch(postCreditCardSuccess(creditCardInfo));
			})
			.catch((error) => {
				dispatch(postCreditCardFailure(error));
			});
	};
});
const postCreditCardSuccess = (payment) => {
	return {
		type: POST_CREDIT_CARD_SUCCESS,
		payment,
	};
};
const postCreditCardFailure = (error) => {
	return {
		type: POST_CREDIT_CARD_FAILURE,
		error,
	};
};

export const updateCreditCard = ((payload, homeId, creitCardId, useInternalApiKey) => {
	const token = useInternalApiKey
		? process.env.REACT_APP_DECEASED_PAGE_TOKEN || "abc"
		: localStorage.getItem("token");
	return (dispatch) => {
		dispatch({ type: UPDATE_EXISTING_CARD });
		return axios
			.patch(`api/funeral_homes/${homeId}/credit_cards/${creitCardId}`, 
			snakeCaseKeys(payload),
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				const creditCardInfo = camelcaseKeys(response.data);
				dispatch(updateCreditCardSuccess(creditCardInfo));
			})
			.catch((error) => {
				dispatch(updateCreditCardFailure(error));
			});
	};
});
const updateCreditCardSuccess = (payment) => {
	return {
		type: UPDATE_EXISTING_CARD_SUCCESS,
		payment,
	};
};
const updateCreditCardFailure = (error) => {
	return {
		type: UPDATE_EXISTING_CARD_FAILURE,
		error,
	};
};

export const postExistingCredicardPayment = (payload, homeId, useInternalApiKey) => {
	const token = useInternalApiKey
		? process.env.REACT_APP_DECEASED_PAGE_TOKEN || "abc"
		: localStorage.getItem("token");
	return (dispatch) => {
		dispatch({ type: POST_EXISTING_CREDITCARD_PAYMENT });
		return axios
			.post(
				`api/funeral_homes/${homeId}/buy_credits`,
				snakeCaseKeys(payload),
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				const paymentResponse = camelcaseKeys(response.data);
				dispatch(postExistingCredicardPaymentSuccess(paymentResponse));
				return response;
			})
			.catch((error) => {
				dispatch(postExistingCredicardPaymentFailure(error))});
	};
};
const postExistingCredicardPaymentSuccess = (payment) => {
	return {
		type: POST_EXISTING_CREDITCARD_PAYMENT_SUCCESS,
		payment,
	};
};
const postExistingCredicardPaymentFailure = (error) => {
	return {
		type: POST_EXISTING_CREDITCARD_PAYMENT_FAILURE,
		error,
	};
};

export const deleteCreditCard = (funeralHomeId, cardId, useInternalApiKey) => {
	const token = useInternalApiKey
		? process.env.REACT_APP_DECEASED_PAGE_TOKEN || "abc"
		: localStorage.getItem("token");
	return dispatch => {
	  dispatch({ type: DELETE_CREDIT_CARD });
	  return axios.delete(`api/funeral_homes/${funeralHomeId}/credit_cards/${cardId}`, {
		headers: {
		  "Authorization": `Bearer ${token}`
		}
	  })
	  .then(response => {
		dispatch(deleteCreditCardSuccess(funeralHomeId));
		return response;
	  })
	  .catch(error => {
		if( error.response ){
			dispatch(loadingAction(false))
		  	dispatch(deleteCreditCardFailure(error.response.data.message));
		} else {
			dispatch(loadingAction(false))
		  	dispatch(deleteCreditCardFailure("something went wrong"));
		}
		return error;
	  });
	}
  };
  const deleteCreditCardSuccess = (funeralHomeId) => {
	return {
	  type: DELETE_CREDIT_CARD_SUCCESS,
	  funeralHomeId,
	}
  };
  const deleteCreditCardFailure = (message) => {
	return {
	  type: DELETE_CREDIT_CARD_FAILURE,
	  message,
	}
  };

  export const postFuneralVideoPayment = (payload) => {
	return (dispatch) => {
		dispatch({ type: POST_FUNERAL_VIDEO_PAYMENT });
		return axios
			.post(`api/checkout/buy_funeral`, snakeCaseKeys(payload),
				{ 
					headers: { "Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`},
				}
			)
			.then((response) => {
				const paymentResponse = camelcaseKeys(response.data);
				dispatch(postFuneralVideoPaymentSuccess(paymentResponse));
				return response;
			})
			.catch((error) => {
				dispatch(postFuneralVideoPaymentFailure(error));
			});
	};
};
const postFuneralVideoPaymentSuccess = (payment) => {
	return {
		type: POST_FUNERAL_VIDEO_PAYMENT_SUCCESS,
		payment,
	};
};
const postFuneralVideoPaymentFailure = (error) => {
	return {
		type: POST_FUNERAL_VIDEO_PAYMENT_FAILURE,
		error,
	};
};

export const getTransactions = (page, id, useInternalApiKey) => {
	const token = useInternalApiKey
		? process.env.REACT_APP_DECEASED_PAGE_TOKEN || "abc"
		: localStorage.getItem("token");
	return (dispatch) => {
		dispatch({ type: GET_TRANSACTIONS_REQUEST });
		return axios
			.get(`api/funeral_homes/${id}/credit_transactions?page=${page}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(async (response) => {
				const transactionsInfo = camelcaseKeys(response.data.credit_transactions);
				dispatch(loadingAction(false));
				await dispatch(initialBillingState());
				dispatch(getTransactionsSuccess(transactionsInfo));
			})
			.catch((error) => {
				dispatch(loadingAction(false));
				dispatch(getTransactionsFailure(error));
			});
	};
};
const getTransactionsSuccess = (transactionInfo) => {
	return {
		type: GET_TRANSACTIONS_SUCCESS,
		transactionInfo,
	};
};
const getTransactionsFailure = (error) => {
	return {
		type: GET_TRANSACTIONS_FAILURE,
		error,
	};
};

export const postRequestDemo = (payload) => {
	return (dispatch) => {
		dispatch({ type: POST_REQUEST_DEMO });
		return axios
			.post(
				`api/funeral_homes/request_demo`,
				snakeCaseKeys(payload),
				{
					headers: {
						"Authorization": `Bearer abc`
					}
				}
			)
			.then((response) => {
				const demoResponse = camelcaseKeys(response.data);
				dispatch(postRequestDemoSuccess(demoResponse));
				return response;
			})
			.catch((error) => {
				dispatch(postRequestDemoFailure(error));
			});
	};
};
const postRequestDemoSuccess = (demoRes) => {
	return {
		type: POST_REQUEST_DEMO_SUCCESS,
		demoRes,
	};
};
const postRequestDemoFailure = (error) => {
	return {
		type: POST_REQUEST_DEMO_FAILURE,
		error,
	};
};

export function loadingAction(value){
	return{
		type: LOADING_ACTION,
		payload: value,
	}
}

export function initialStateVal(){
	return{
		type: INITIAL_ACTION,
	}
}

export function initialBillingState(){
	return{
		type: INITIAL_BILLING_ACTION,
	}
}