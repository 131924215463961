import React from 'react';
import loader from "../assets/svgs/FL-loader-js.svg";
import useWindowDimensions from "../hooks/useWindowDimensions";

function Spinner({spinnerSize, value, funeralSpinner, invitedUsr}) {
	const { height, minimumWidth } = useWindowDimensions();
    return (
        <>
			<object type="image/svg+xml" data={loader} style={{position: (value==="ADD_CARD" || funeralSpinner || invitedUsr) ? "absolute" : "", 
            height: (spinnerSize || invitedUsr) ? "200px" : minimumWidth<528 ? "300px" : "400px", zIndex: 100, left: funeralSpinner ? "35%" : invitedUsr ? "25%" : "", top: funeralSpinner ? "30%" : invitedUsr ? "25%" : "" }}>svg-animation</object>
        </>
    )
}

export default Spinner;
