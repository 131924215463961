import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { getFuneralVisitors, getFuneralDownloadInformation, sendDownloadRequest } from "../../redux/funeralInsights/actions";

const FuneralVisitors = ({ visitors }) => {
  if (visitors.length === 0) {
    <p>No funeral visitors yet!</p>
  }
  return visitors.map(visitor => {
    return <p key={visitor.id}>{visitor.email} at {visitor.createdAt}</p>
  });
}

const DownloadRequests = ({ downloadRequests }) => {
  const downloadRequestList = downloadRequests || [];
  if (downloadRequestList.length === 0) {
    <p>No funeral download requests yet!</p>
  }

  return downloadRequestList.map(downloadRequest => {
    return (
      <p style={{ color: downloadRequest.paymentSuccessful ? 'green' : 'red' }} key={downloadRequest.id}>
        {downloadRequest.email} {downloadRequest.paymentSuccessful ? 'succesfully paid' : 'did not Pay'} at {downloadRequest.createdAt}
      </p>
    )
  });
}

const CronJobs = ({ cronJobs }) => {
  const cronjobList = cronJobs || [];
  if (cronjobList.length === 0) {
    <p>No funeral cron jobs created yet!</p>
  }

  return cronjobList.map(cronJob => {
    return (
      <div key={cronJob.id}>
        <ReactJson src={cronJob} />
      </div>
    )
  });
}

function FuneralManagementConsole({ 
  getFuneralVisitors,
  getFuneralDownloadInformation,
  visitors,
  downloadRequests,
  cronJobs,
  sendDownloadRequest
}) {
  const [downloadRequestEmail, setDownloadRequestEmail] = useState('');
  const { id } = useParams();
  useEffect(()=> {
		getFuneralVisitors(id);
    getFuneralDownloadInformation(id);
	},[]);

  return (
    <div>
      <header>
        <h1>Funeral Management Console for Funeral {id}</h1>
      </header>
      <main>
        <h3>Manage funeral and get insights.</h3>
        <h4> Funeral Visitors </h4>
        <div>
          <FuneralVisitors visitors={visitors} />
        </div>
        <h4> Download Requests </h4>
        <div>
          <DownloadRequests downloadRequests={downloadRequests} />
          <div>
            <label>
							Download and Send to Email
						</label>
            <input
              type="email"
              value={downloadRequestEmail}
              onChange={(e) => setDownloadRequestEmail(e.target.value)}
            />
            <button onClick={
                () => {
                  sendDownloadRequest(id, downloadRequestEmail);
                  getFuneralVisitors(id);
                  getFuneralDownloadInformation(id);
                  setDownloadRequestEmail('');
                }
              }
            >Send</button>
          </div>
        </div>
        <h4> Cron Jobs </h4>
        <div>
          <CronJobs cronJobs={cronJobs} />
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  visitors: state.funeralInsights.visitors,
  downloadRequests: state.funeralInsights.downloadRequests,
  cronJobs: state.funeralInsights.cronJobs
});

const mapDispatchToProps = {
  getFuneralVisitors,
  getFuneralDownloadInformation,
  sendDownloadRequest
};
export default connect(mapStateToProps, mapDispatchToProps)(FuneralManagementConsole);
