import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import logo from '../../assets/svgs/funeral-live-logo.png';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { resetUser } from '../../redux/user/actions';
import CSS from './PasswordResetPage.module.scss';
import { useTranslation } from 'react-i18next';

const PasswordResetPage = (props) => {
  const { t, i18n } = useTranslation();
  const {userReset} = props;
  const [email, setEmail] = useState("");
  const [dropdownOpen, setDropdown] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
    () => {
			const savedLanguage = localStorage.getItem("language");
			i18n.changeLanguage(savedLanguage);
			return savedLanguage;
		}
  );
	const toggleDropdown = () => setDropdown(!dropdownOpen);
  const handleResetPassword = () =>
  {
    props.resetUser(email);
  }
  const handleContact = () => {
    window.open("https://funerallive.ca/contact-us/");
  };
  const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		if(event.target.value === "en"){
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
		}
		else if(event.target.value === "fr"){
			i18n.changeLanguage("fr");
			localStorage.setItem("language", "fr");
		}
	};
  const openFuneralLive = () => {
    window.open("https://funerallive.ca/");
  };
  return (
    <div>
      <section className={CSS.navWrapper}>
        <div className={CSS.logoWrapper}>
          <img src={logo} alt="Logo" onClick={openFuneralLive}/>
        </div>
        <div className={CSS.navTitles}>
          <Link to="/resetPassword">
            {t('pages.sign_in.forgot_password')}
          </Link>
          <Link to="/#" onClick={handleContact}>{t('pages.sign_in.unregistered_email_2')}</Link>
          <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
            <option value="en">En</option>
            <option value="fr">Fr</option>
          </select>
        </div>
        <div className={CSS.burgerMenu}>
          {dropdownOpen ? (
            <CloseIcon onClick={toggleDropdown} />
          ) : (
            <MenuIcon onClick={toggleDropdown} />
          )}
          {dropdownOpen && (
            <div className={CSS.dropdownMenu}>
              <Link to="/resetPassword">
              {t('pages.sign_in.forgot_password')}
              </Link>
              <Link to="/#" onClick={handleContact}>{t('pages.sign_in.unregistered_email_2')}</Link>
            </div>
          )}
          <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
            <option value="en">En</option>
            <option value="fr">Fr</option>
          </select>
        </div>
      </section>
      <section className={CSS.loginForm}>
        <div className={CSS.sectionContainer}>
        <div className={CSS.formWrappper}>
						<span className={CSS.formHeading}>
              {t('pages.sign_in.forgot_password')}
						</span>
            <span className={CSS.subHeading}>
            {t('pages.sign_in.remind_password')}
            </span>
						<div className={CSS.formElements}>
							<div className={CSS.inputWrapperRow}>
								<div className={CSS.inputWrapper}>
									<label className={CSS.inputHeader}>
                  {t('pages.sign_in.email')}
									</label>
									<input
										className={CSS.inputElement}
										type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}></input>
								</div>
							</div>
						</div>
            {userReset.error && 
            <div className={`${CSS.userMsg} ${CSS.negative}`}>
              <div className={CSS.userMessageContainer}>{t('pages.sign_in.unregistered_email_1')}&nbsp;
              <Link to="/#" onClick={handleContact} className={`${CSS.linkText} ${CSS.noPadding}`}>{t('pages.sign_in.unregistered_email_2')}
              </Link> {t('pages.sign_in.unregistered_email_3')}
              </div>
            </div>
          }
          {userReset.success && 
            <div className={`${CSS.userMsg} ${CSS.positive}`}>
              <div className={CSS.userMessageContainer}>{t('pages.sign_in.reset_success')}</div>
            </div>
          }
						<div>
							<div className={CSS.submitWrapper}>
                <Link to="/" className={CSS.linkText}>
                {t('pages.sign_in.sign_in_title')}
                </Link>
								<button className={`${CSS.actionBtn} ${CSS.warning}`} type="button" disabled={!email} onClick={handleResetPassword}>
                {t('pages.sign_in.reset_password')}
								</button>
							</div>
						</div>
					</div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => ({
  userReset: state.user.userReset
});

const mapDispatchToProps = {
  resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
