import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NavHeader from "../header/Header";
import CSS1 from "../orgManagement/OrganizationManagementView.module.scss";
import { getFuneralHomes } from "../../redux/funeralHome/actions";
import "../../components/ModalPopup.scss";


const FuneralHomeAdminPage = (props) => {
  const history = useHistory();
    useEffect(() => {
      props.getFuneralHomes();
    }, []); 

    if (props.user.user?.role !== 'super_admin') {
        return (
          <div>
              <NavHeader />
              <section className={CSS1.sectionWrapper} style={{padding: "24px 50px"}}>
                  Unauthorized page
              </section>
          </div>
        );
    }
    
	return (
		<div>
			<NavHeader />
        <section className={CSS1.sectionWrapper} style={{padding: "24px 50px"}}>
          <span className={CSS1.pageTitle}>
            Admin Page For Funeral Homes
          </span>
          <div className={`${CSS1.segment} ${CSS1.tabsContainer}`}>
            {props.funeralHomes.map((funeralHome) => (
              <div>
                <span>{funeralHome.name}</span>
                <span
                  style={{
                    marginLeft: "50px",
                    color: "blue",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`funeralhome/${funeralHome.id}/admin`)}
                >
                  Go to admin page</span>
              </div>
            ))}
          </div>
        </section>
		</div>
	);
};

const mapStateToProps = (state) => ({
  funeralHomes: state.funeralHome.funeralHomes,
  user: state.user.currentUser,
});

const mapDispatchToProps = {
	getFuneralHomes,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FuneralHomeAdminPage);
