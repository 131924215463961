import axios from '../../utils/axios';
import camelcaseKeys from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";

const ORG_LIST_REQUEST = 'ORG_LIST_REQUEST';
export const ORG_LIST_REQUEST_SUCCESS = 'ORG_LIST_REQUEST_SUCCESS';
export const ORG_LIST_REQUEST_FAILURE = 'ORG_LIST_REQUEST_FAILURE';

const GET_ORG_REQUEST = 'GET_ORG_REQUEST';
export const GET_ORG_REQUEST_SUCCESS = 'GET_ORG_REQUEST_SUCCESS';
export const GET_ORG_REQUEST_FAILURE = 'GET_ORG_REQUEST_FAILURE';

const ADD_ORG_REQUEST = 'ADD_ORG_REQUEST';
export const ADD_ORG_REQUEST_SUCCESS = 'ADD_ORG_REQUEST_SUCCESS';
export const ADD_ORG_REQUEST_FAILURE = 'ADD_ORG_REQUEST_FAILURE';

const UPDATE_ORG_REQUEST = 'UPDATE_ORG_REQUEST';
export const UPDATE_ORG_REQUEST_SUCCESS = 'UPDATE_ORG_REQUEST_SUCCESS';
export const UPDATE_ORG_REQUEST_FAILURE = 'UPDATE_ORG_REQUEST_FAILURE';

const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';

export const getOrganizations = () => {
  return dispatch => {
    dispatch({ type: ORG_LIST_REQUEST });
    return axios.get('api/organizations', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const organizations = camelcaseKeys(response.data).organizations;
      dispatch(getOrgsSuccessful(organizations));
      return response;
    })
    .catch(error => {
        dispatch(getOrgsFailed(error));
      return error;
    });
  }
};

const getOrgsSuccessful = (organizations)=>{
  return {
    type: ORG_LIST_REQUEST_SUCCESS,
    organizations: organizations
  }
}

const getOrgsFailed = (error)=>{
  return {
    type: ORG_LIST_REQUEST_FAILURE,
    error
  }
}

export const getOrganization = (id) => {
  return dispatch => {
    dispatch({ type: GET_ORG_REQUEST });
    return axios.get(`api/organizations/${id}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const organization = camelcaseKeys(response.data).organization;
      dispatch(getOrgSuccessful(organization));
      return response;
    })
    .catch(error => {
        dispatch(getOrgFailed(error));
      return error;
    });
  }
};
const getOrgSuccessful = (organization)=>{
  return {
    type: GET_ORG_REQUEST_SUCCESS,
    organization: organization
  }
}

const getOrgFailed = (error)=>{
  return {
    type: GET_ORG_REQUEST_FAILURE,
    error
  }
}

export const createOrgReq = (payload) => {
	return (dispatch) => {
		dispatch({ type: ADD_ORG_REQUEST });
		return axios
			.post('api/organizations', snakeCaseKeys(payload), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const orgData = camelcaseKeys(response.data);
				dispatch(createOrgDataSuccess(orgData));
        return orgData;
			})
			.catch((error) => dispatch(createOrgDataFailure(error)));
	};
};

const createOrgDataSuccess = (createOrgResponse) => {
	return {
		type: ADD_ORG_REQUEST_SUCCESS,
		createOrgResponse,
	};
};

const createOrgDataFailure = (error) => {
	return {
		type: ADD_ORG_REQUEST_FAILURE,
		error,
	};
};

export const updateOrgReq = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_ORG_REQUEST });
		return axios
			.put(`api/organization/${id}`, snakeCaseKeys(payload), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				const orgData = camelcaseKeys(response.data);
				dispatch(updateOrgDataSuccess(orgData));
        return orgData;
			})
			.catch((error) => dispatch(updateOrgDataFailure(error)));
	};
};

const updateOrgDataSuccess = (createOrgResponse) => {
	return {
		type: UPDATE_ORG_REQUEST_SUCCESS,
		createOrgResponse,
	};
};

const updateOrgDataFailure = (error) => {
	return {
		type: UPDATE_ORG_REQUEST_FAILURE,
		error,
	};
};

export const deleteOrganization = (orgId) => {
  return dispatch => {
    dispatch({ type: DELETE_ORGANIZATION_REQUEST });
    return axios.delete(`api/organizations/${orgId}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      dispatch(deleteOrganizationSuccess(orgId));
      return response;
    })
    .catch(error => {
      if( error.response ){
        dispatch(deleteOrganizationFailure(error.response.data.message));
      } else {
        dispatch(deleteOrganizationFailure("something went wrong"));
      }
      return error;
    });
  }
};
const deleteOrganizationSuccess = (orgId) => {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    orgId,
  }
};

const deleteOrganizationFailure = (message) => {
  return {
    type: DELETE_ORGANIZATION_FAILURE,
    message,
  }
};
