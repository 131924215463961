import React, { useState, useEffect } from "react";
import CSS from "./Header.module.scss";
import { NavLink as Link } from "react-router-dom";
import logo from "../../assets/svgs/funeral-live-logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { getCurrentUser } from "../../redux/user/actions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";
import { getOrganizations } from "../../redux/organization/actions";
import "../userManagement/userManagement.css";

const Header = (props) => {
	const { t, i18n } = useTranslation();
	const [dropdownOpen, setDropdown] = useState(false);
	const [buttonBgColor, setButtonBgColor] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
		() => {
			const savedLanguage = localStorage.getItem("language");
			i18n.changeLanguage(savedLanguage);
			return savedLanguage;
		}
	);
	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		if(event.target.value === "en"){
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
		}
		else if(event.target.value === "fr"){
			i18n.changeLanguage("fr");
			localStorage.setItem("language", "fr");
		}
	};
	const toggleDropdown = () => setDropdown(!dropdownOpen);
	const openFuneralLive = () => {
		window.open("https://funerallive.ca/");
	};
	useEffect(() => {
		if (localStorage.getItem("token")) {
			props.getCurrentUser();
		}
		if (!props.skipOrganizations) {
			props.getOrganizations();
		}
	}, []);
	useEffect(() => {}, [props.organizations]);
	useEffect(() => {}, [buttonBgColor]);
	const history = useHistory();
	const handlePopupClose = () => {
		setButtonBgColor(false);
	};
	const handlePopupOpen = () => {
		setButtonBgColor(true);
	};
	const handleLoginLogout = () => {
		if (props.user.loggedIn) {
			localStorage.removeItem("token");
			history.go("/");
		} else {
			history.push("/");
		}
	};
	return (
		<div className={CSS.navWrapper}>
			<div className={CSS.logoWrapper}>
				<img src={logo} alt="Logo" onClick={openFuneralLive} />
			</div>
			{!props.simpleView && (
				<>
					<div className={CSS.navTitles}>
						{props.Landing === "landing" ? 
							<>
								<Link to="/funerals" activeStyle={{ color: "#167434" }}>
								{t("navigation.home")}
								</Link>
								<Link to="/about" activeStyle={{ color: "#167434" }}>
								{t("navigation.about")}
								</Link>
								<Link to="https://funerallive.ca/" target="_blank" activeStyle={{ color: "#167434" }}>
								{t("navigation.contact_us")}
								</Link>
							</>
						:
							<>
								<Link to="/funerals" activeStyle={{ color: "#167434" }}>
									{t("navigation.admin_events")}
								</Link>
								<Link
									to="/calenderView"
									activeStyle={{ color: "#167434" }}>
									{t("navigation.admin_calendar")}
								</Link>
								<Popup
									style={{
										zIndex: "1001",
										border: "1px solid #C4D5D9",
										borderRadius: 4,
									}}
									size="huge"
									position="bottom right"
									trigger={
										<Button
											className="btn"
											style={{
												border: buttonBgColor
													? "1px solid #00667E"
													: "none",
												boxShadow: buttonBgColor
													? "0px 0px 4px 1px rgba(0, 102, 126, 0.15)"
													: "none",
												backgroundColor: "white",
												color: "#00667E",
												maxWidth: "220px",
												fontFamily: "Bitter, serif",
												fontStyle: "normal",
												fontWeight: 400,
												fontSize: 16,
												padding: "8px 16px",
											}}
											// content={"Account"}
											// icon= {buttonBgColor ? "angle up" : "angle down"}
										>
											{t("navigation.account")}{"    "}
											<Icon
												name={
													buttonBgColor
														? "angle up"
														: "angle down"
												}
											/>
										</Button>
									}
									content={
										<div>
											{props.user?.user?.role === "super_admin" &&
											props.user?.user?.role === "admin" ? (
												<div
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 14,
														color: "#667679",
														boxShadow:
															"inset 0px -1px 0px #C4C4C4",
													}}>
													FuneralLive
												</div>
											) : (
												<div
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 14,
														color: "#667679",
														boxShadow:
															"inset 0px -1px 0px #C4C4C4",
													}}>
													{props.organizations.length > 0 &&
														props.organizations[0].name}
												</div>
											)}
											<div>
												<Link
													to="/orgmanagement"
													activeStyle={{ color: "#167434" }}
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 16,
														color: "#001A20",
													}}>
													{t("navigation.admin_orgs")}
												</Link>
											</div>
											<div>
												<Link
													to="/usermanagement"
													activeStyle={{ color: "#167434" }}
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 16,
														color: "#001A20",
													}}>
													{t("navigation.admin_users")}
												</Link>
											</div>
											<div>
												<Link
													to="/streamcredits"
													activeStyle={{ color: "#167434" }}
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 16,
														color: "#001A20",
													}}>
													{t(
														"navigation.admin_stream_credits"
													)}
												</Link>
											</div>
											<div>
												<Link
													to="/billing"
													activeStyle={{ color: "#167434" }}
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 16,
														color: "#001A20",
													}}>
													{t(
														"navigation.admin_billing"
													)}
												</Link>
											</div>
											<div>
												{props.user?.user?.role ===
													"super_admin" && (
													<Link
														to="/configmanagement"
														activeStyle={{
															color: "#167434",
														}}
														style={{
															fontFamily: "Bitter, serif",
															fontStyle: "normal",
															fontWeight: 300,
															fontSize: 16,
															color: "#001A20",
														}}>
														{t(
															"navigation.admin_config_management"
														)}
													</Link>
												)}
											</div>
											<div>
												{props.user?.user?.role ===
													"super_admin" && (
													<Link
														to="/funeralhomeadmin"
														activeStyle={{
															color: "#167434",
														}}
														style={{
															fontFamily: "Bitter, serif",
															fontStyle: "normal",
															fontWeight: 300,
															fontSize: 16,
															color: "#001A20",
														}}>
														{t(
															"navigation.admin_funeral_homes"
														)}
													</Link>
												)}
											</div>
											<div>
												<Link
													to="/#"
													onClick={handleLoginLogout}
													style={{
														fontFamily: "Bitter, serif",
														fontStyle: "normal",
														fontWeight: 300,
														fontSize: 16,
														color: "#001A20",
													}}>
													{t("navigation.admin_logout")}
												</Link>
											</div>
										</div>
									}
									on="click"
									onClose={handlePopupClose}
									onOpen={handlePopupOpen}
								/>
							</>
						}
						<select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
							<option value="en">En</option>
							<option value="fr">Fr</option>
						</select>
					</div>
					<div className={CSS.burgerMenu}>
						{dropdownOpen ? (
							<CloseIcon onClick={toggleDropdown} style={{cursor: "pointer"}}/>
						) : (
							<MenuIcon onClick={toggleDropdown} style={{cursor: "pointer"}}/>
						)}
						{dropdownOpen && (
							<div className={CSS.dropdownMenu}>
								{props.Landing === "landing" ? 
									<>
										<Link to="/funerals" activeStyle={{ color: "#167434" }}>
										{t("navigation.home")}
										</Link>
										<Link to="/about" activeStyle={{ color: "#167434" }}>
										{t("navigation.about")}
										</Link>
										<Link to="/funerals" activeStyle={{ color: "#167434" }}>
										{t("navigation.contact_us")}
										</Link>
									</>
								:
									<>
										<Link
											to="/funerals"
											activeStyle={{ color: "#167434" }}>
											{t("navigation.admin_events")}
										</Link>
										<Link
											to="/calenderView"
											activeStyle={{ color: "#167434" }}>
											{t("navigation.admin_calendar")}
										</Link>
										<Link
											to="/orgmanagement"
											activeStyle={{ color: "#167434" }}>
											{t("navigation.admin_orgs")}
										</Link>
										<Link
											to="/usermanagement"
											activeStyle={{ color: "#167434" }}>
											{t("navigation.admin_users")}
										</Link>
										<Link
											to="/streamcredits"
											activeStyle={{ color: "#167434" }}>
											{t(
												"navigation.admin_stream_credits"
											)}
										</Link>
										<Link
											to="/billing"
											activeStyle={{ color: "#167434" }}>
											{t(
												"navigation.admin_billing"
											)}
										</Link>
										{props.user?.user?.role === "super_admin" && (
											<Link
												to="/configmanagement"
												activeStyle={{ color: "#167434" }}>
												{t(
													"navigation.admin_config_management"
												)}
											</Link>
										)}
										{props.user?.user?.role === "super_admin" && (
											<Link
												to="/funeralhomeadmin"
												activeStyle={{ color: "#167434" }}>
												{t(
													"navigation.admin_funeral_homes"
												)}
											</Link>
										)}
										<Link to="/#" onClick={handleLoginLogout}>
											{t("navigation.admin_logout")}
										</Link>
									</> 
								}
							</div>
						)}
						<select id="dropdown" value={selectedOption} onChange={handleOptionChange}>
							<option value="en">En</option>
							<option value="fr">Fr</option>
						</select>
					</div>
				</>
			)}
		</div>
	);
};
const mapStateToProps = (state) => ({
	user: state.user.currentUser,
	organizations: state.organization.organizations,
});

const mapDispatchToProps = {
	getCurrentUser,
	getOrganizations,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
