import { FUNERAL_VISITORS_SUCCESS, DOWNLOAD_REQUESTS_SUCCESS } from './actions';

const initialState = {
  visitors: [],
  downloadRequests: [],
  cronJobs: []
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case FUNERAL_VISITORS_SUCCESS:
      return { ...state, visitors: action.visitors };
    case DOWNLOAD_REQUESTS_SUCCESS:
      return { ...state, downloadRequests: action.downloadRequests, cronJobs: action.cronJobs };
    default:
      return state;
  }
}

export default reducer;
