import React from 'react';
import CSS from "./PurchaseAndPolicy.module.scss";
import Header from '../header/Header';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Policy = () => {
    const { t } = useTranslation();
  return (
    <>
        <Header Landing="landing"/>
        <div className={CSS.mainBody}>
            <div className={CSS.header}>
            {t('pages.policy.policy_header')}
            </div>
            <div className={CSS.headerHint}>
            {t('pages.policy.policy_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.personal_info_header')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.personal_info_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.personal_info_usage')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.personal_info_usage_hint')}
                <div className={CSS.subHeaderHintPoints}>
                {t('pages.policy.personal_info_usage_hint1')}
                </div>
                <div className={CSS.subHeaderHintPoints}>
                {t('pages.policy.personal_info_usage_hint2')}
                </div>
                <div className={CSS.subHeaderHintPoints}>
                {t('pages.policy.personal_info_usage_hint3')}
                </div>
                <div className={CSS.subHeaderHintPoints}>
                {t('pages.policy.personal_info_usage_hint4')}
                </div>
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.personal_disclosure')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.personal_disclosure_hint')}
                <div className={CSS.subHeaderHintPoints}>
                {t('pages.policy.personal_disclosure_hint1')}
                </div>
                <div className={CSS.subHeaderHintPoints}>
                {t('pages.policy.personal_disclosure_hint2')}
                </div>
                {t('pages.policy.personal_disclosure_hint3')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.storage_header')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.storage_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.retention_header')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.retention_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.access_header')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.access_hint')}{" "}
                <Link to='#' onClick={() => window.location = 'mailto:solutions@victoriville.co'} 
                className={CSS.emailTo}>solutions@victoriville</Link>.
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.updates_header')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.updates_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.policy.contact_header')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.policy.contact_hint')} {" "}
                <Link to='#' onClick={() => window.location = 'mailto:solutions@victoriville.co'} 
                className={CSS.emailTo}>solutions@victoriville</Link>.
            </div>
        </div>
    </>
  )
}

export default Policy;