import React, { useState } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/svgs/funeral-live-logo.png';
import CSS from './RegistrationPage.module.scss';
import { registerUser } from '../../redux/user/actions';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from "react-password-checklist";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
const RegistrationPage = (props) => {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [selectedOption, setSelectedOption] = useState(
    () => {
			const savedLanguage = localStorage.getItem("language");
			i18n.changeLanguage(savedLanguage);
			return savedLanguage;
		}
  );
  const [visible, setVisible] = useState(false);
  const [confirmationVisible, setConformationVisible] = useState(false);
  const [valid, setValid] = useState(false)
  const token = props.match.params.token;
  const handleRegister = async () => {
    const result = await props.registerUser(token, password);
    if (result.status === 200) {
      props.history.push('/');
    }
  }
  const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		if(event.target.value === "en"){
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
		}
		else if(event.target.value === "fr"){
			i18n.changeLanguage("fr");
			localStorage.setItem("language", "fr");
		}
	};
  const openFuneralLive = () => {
    window.open("https://funerallive.ca/");
  };
  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const toggleConfirmationVisibility = () => {
    setConformationVisible(!confirmationVisible)
  } 
  return (
    <div>
    <section className={CSS.navWrapper}>
      <div className={CSS.logoWrapper}>
        <img src={logo} alt="Logo" onClick={openFuneralLive}/>
      </div>
      <select id="dropdown" value={selectedOption} onChange={(event) => handleOptionChange(event)}>
        <option value="en">En</option>
        <option value="fr">Fr</option>
      </select>
    </section>
    <section className={CSS.loginForm}>
      <div className={CSS.sectionContainer}>
      <div className={CSS.formWrappper}>
          <span className={CSS.formHeading}>
          {t('pages.sign_in.registration')}
					</span>
          <div className={CSS.formElements}>
            <div className={CSS.inputWrapperRow}>
              <div className={CSS.inputWrapper}>
                <TextField
                  className={`${CSS.inputEle} ${CSS.size_l}`}
                  type={visible ? 'text' : 'password'}
                  variant="outlined"
                  placeholder={t('pages.sign_in.password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleVisibility}>
                          {visible ? <VisibilityIcon style={{color: "#00667E"}}/> : <VisibilityOffIcon style={{color: "#00667E"}}/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className={CSS.inputWrapperRow}>
              <div className={CSS.inputWrapper}>
              <TextField
                  className={`${CSS.inputEle} ${CSS.size_l}`}
                  type={confirmationVisible ? 'text' : 'password'}
                  variant="outlined"
                  placeholder={t('pages.sign_in.password_confirmation')}
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleConfirmationVisibility}>
                          {confirmationVisible ? <VisibilityIcon style={{color: "#00667E"}}/> : <VisibilityOffIcon style={{color: "#00667E"}}/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className={CSS.inputWrapperRow}>
              <PasswordChecklist
                  rules={["minLength","specialChar",
                          "number","capital","lowercase","match"]}
                  minLength={8}
                  value={password}
                  valueAgain={passwordConfirmation}
                  messages={{
                    minLength: t('pages.sign_in.password_length'),
                    specialChar: t('pages.sign_in.password_special_char'),
                    number: t('pages.sign_in.passpord_With_number'),
                    capital: t('pages.sign_in.password_with_capital'),
                    lowercase: t('pages.sign_in.password_with_lowercase'),
                    match: t('pages.sign_in.match_password'),
                  }}
                  onChange={(isValid) => setValid(isValid)}
              />
            </div>
          </div>
          <div>
            <div className={CSS.submitWrapper}>
              <button className={`${CSS.actionBtn}  ${CSS.warning} 
                ${valid ? "" : CSS.disabled}`} type="button" 
                onClick={handleRegister}>
                {t('pages.sign_in.register')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
