import {GET_ONSITE_SUCCESS, PRESIGN_IMAGE_SUCCESS, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAILURE, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAILURE, REMOVE_DECEASED_IMAGE_SUCCESS, INITIAL_ACTION} from './actions';
const initialState = {
    onsiteRes : [],
    uploadedImage: null,
    createEventResponse: {},
    updateEventResponse: {},
    createEventErrorMsg: ""
  }

  function reducer(state = initialState, action) {
    switch(action.type) {
      case GET_ONSITE_SUCCESS:
        return { ...state, onsiteRes: action.onsiteResponse }
      case PRESIGN_IMAGE_SUCCESS:
        return { ...state, uploadedImage: action.blobSignedId }
      case CREATE_EVENT_FAILURE:
        return { ...state, createEventResponse: { createEventErrorMsg: action.message } }
      case CREATE_EVENT_SUCCESS:
        return { ...state, uploadedImage: null, createEventResponse: action.createEventRes }
      case UPDATE_EVENT_FAILURE:
        return { ...state, updateEventResponse: { createEventErrorMsg: action.message } }
      case UPDATE_EVENT_SUCCESS:
        return { ...state, uploadedImage: null, updateEventResponse: action.updateEventRes}
      case REMOVE_DECEASED_IMAGE_SUCCESS:
        return { ...state, uploadedImage: null, updateEventResponse: { error: false, success: true, ...action.updateEventRes }}
      case INITIAL_ACTION:
        return { ...state, createEventResponse: {}, updateEventResponse: {} }
      default:
        return state
    }
}  

export default reducer;