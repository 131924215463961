import { LOGIN_USER_REQUEST_SUCCESS, LOGIN_USER_REQUEST_FAILURE, UPDATE_USER_REQUEST,
  LOGOUT_USER, GET_USER_SUCCESS, INVITE_USER_SUCCESS, INVITE_USER_FAILURE, RESEND_USER_INVITE_REQUEST, 
  RESET_USER_SUCCESS, RESET_USER_FAILURE, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE, INITIAL_ACTION, INVITE_USER_REQUEST,
  RESEND_USER_INVITE_SUCCESS, RESEND_USER_INVITE_FAILURE, LOADING_ACTION
} from './actions';

const setUser = () => {
  const token = localStorage.getItem("token"); 
  if (token) {
    return {
      token: token,
      loggedIn: true,
      error: null,
      user: null,
    }
  }

  return {
    token: null,
    loggedIn: false,
    error: null,
    user: null
  };
}
const AJAX_STATE = {      
  IDEAL: "IDEAL",
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
}

const initialState = {
  currentUser: setUser(),
  userInviteResponse: AJAX_STATE.IDEAL,
  resendUserInviteResponse: AJAX_STATE.IDEAL,
  loader: true,
  userReset: { error: false, success: false },
  userInviteErrorMsg: ""
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case LOGIN_USER_REQUEST_SUCCESS:
      return { ...state, currentUser: { ...state.currentUser, token: action.token, loggedIn: true } }
    case LOGIN_USER_REQUEST_FAILURE:
      return { ...state, currentUser: { token: null, error: action.error, loggedIn: false } }
    case GET_USER_SUCCESS: 
      return { ...state, currentUser: { ...state.currentUser, user: action.user } };
    case INVITE_USER_REQUEST:
      return { ...state, userInviteResponse: AJAX_STATE.REQUEST }
    case INVITE_USER_SUCCESS:
      return { ...state, userInviteResponse: AJAX_STATE.SUCCESS }
    case INVITE_USER_FAILURE:
        return { ...state, userInviteResponse: AJAX_STATE.ERROR }
    case RESEND_USER_INVITE_REQUEST:
      return { ...state, resendUserInviteResponse: AJAX_STATE.REQUEST}
    case RESEND_USER_INVITE_SUCCESS:
      return { ...state, resendUserInviteResponse: AJAX_STATE.SUCCESS }
    case RESEND_USER_INVITE_FAILURE:
        return { ...state, resendUserInviteResponse: AJAX_STATE.ERROR }
    case UPDATE_USER_REQUEST:
      return { ...state, userInviteResponse: AJAX_STATE.REQUEST }
    case UPDATE_USER_SUCCESS:
      return { ...state, userInviteResponse: AJAX_STATE.SUCCESS }
    case UPDATE_USER_FAILURE:
        return { ...state, userInviteResponse: AJAX_STATE.ERROR }
    case LOGOUT_USER:
      return {
        token: null,
        loggedIn: false,
        error: null,
        user: null
      };
    case RESET_USER_SUCCESS:
      return { ...state, userReset: { error: false, success: true } };
    case RESET_USER_FAILURE:
      return { ...state, userReset: { error: true, success: false, message: action.message } };
    case LOADING_ACTION:
      return { ...state, loader: action.payload }
    case INITIAL_ACTION:
      return { ...state, userInviteResponse: AJAX_STATE.IDEAL, resendUserInviteResponse: AJAX_STATE.IDEAL}
    default:
      return state
  }
}

export default reducer;
