import Pretender from 'pretender';

import userPretender from '../redux/user/pretender';
import funeralPretender from '../redux/funeral/pretender';
import funeralHomePretender from '../redux/funeralHome/pretender';
import createEventPretender from '../redux/createEvent/pretender';
import organizationPretender from '../redux/organization/pretender';

export const init = () => {
  const server = new Pretender(
    userPretender,
    funeralPretender,
    funeralHomePretender,
    createEventPretender,
    organizationPretender
  );

  server.handledRequest = function handledRequest(verb, path, request) {
    console.info(`[Pretender] ${verb} ${path}`, request);
  };
};

export default init;
