import React from 'react';
import CSS from "./PurchaseAndPolicy.module.scss";
import Header from '../header/Header';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PurchaseAndReturn = () => {
    const { t } = useTranslation();
  return (
    <>
        <Header Landing="landing"/>
        <div className={CSS.mainBody}>
            <div className={CSS.header}>
            {t('pages.purchase.purchase_header')}
            </div>
            <div className={CSS.headerHint}>
            {t('pages.purchase.purchase_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.purchase.purchase_policy_header')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_payment_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_payment_hint')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_delivery_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_delivery_hint')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_availability_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_availability_hint')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_pricing_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_pricing_hint')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_license_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_license_hint')}
            </div>
            <div className={CSS.subHeader}>
            {t('pages.purchase.purchase_return_policy_header')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_refund_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_refund_hint')}
                <div style={{marginLeft: "24px"}}>
                    a. {t('pages.purchase.purchase_refund_hint1')}
                </div>
                <div style={{marginLeft: "24px"}}>
                    b. {t('pages.purchase.purchase_refund_hint2')}
                </div>
                {t('pages.purchase.purchase_refund_hint3')}
            </div>
            <div className={CSS.subSubHeader}>
            {t('pages.purchase.purchase_technical_suheader')}
            </div>
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.purchase_technical_hint1')}
            </div>
            <br />
            <div className={CSS.subHeaderHint}>
            {t('pages.purchase.thank_you_note')} {" "}
                <Link to='#' onClick={() => window.location = 'mailto:solutions@victoriville.co'} 
                className={CSS.emailTo}>solutions@victoriville</Link>.
            </div>
        </div>
    </>
  )
}

export default PurchaseAndReturn;