import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import Header from "../header/Header";
import CSS from "./BillingPage.module.scss";
import { getFuneralHomes } from "../../redux/funeralHome/actions";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import {getCreditCards, getTransactions, deleteCreditCard, loadingAction} from '../../redux/payment/actions';
import VISACARDICON from '../../assets/svgs/visa.png';
import MASTERCARDICON from '../../assets/svgs/masterCard.png';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import moment from "moment";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
import TransactionImage from '../../assets/svgs/transactions-empty.svg';
import WalletImage from '../../assets/svgs/wallet-empty.svg';
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import "../../components/ModalPopup.scss";
import CheckoutFormData from "../../components/CheckoutFormData";
import { useTranslation } from 'react-i18next';
let pageNumber = 1;
const BillingPage = ({ funeralHomes, getFuneralHomes, getCreditCards, creditCards, getTransactions, 
	transactionsResponse, deleteCreditCard, loadingAction, showLoading }) => {
	const [tabIndex, setTabIndex] = useState("0");
	const [funeralHomeId, setFuneralHomeId] = useState("");
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
    const [editCardId, setEditCardId] = useState("");
	const [transactions, setTransactions] = useState("");
	let funeralSpinner = true;
	const { t } = useTranslation();
	useEffect(async() => {
		await getFuneralHomes();
	}, []);
    useEffect(async() => {
		if(funeralHomes?.length>0){
			loadingAction(true);
            await getCreditCards(funeralHomes[0]?.id, false);
            await getTransactions(pageNumber, funeralHomes[0]?.id, false);
			setFuneralHomeId(funeralHomes[0].id);
        }
    }, [funeralHomes]);
	useEffect(() => {
		setTransactions(transactionsResponse.flat());
	}, [transactionsResponse]);
	useEffect(() => {
	}, [funeralHomeId, open, editOpen, editCardId, transactions]);
	const handleClose = () => {
		setOpen(false);
	};
	const handleEditClose = () => {
		setEditOpen(false);
	};
    const HandleTabIndexChange = (evt, index) => {
		setTabIndex(index + "");
	};
    const getAllCards = async (e,homeid) => {
        e.preventDefault();
		loadingAction(true);
        await getCreditCards(homeid, false);
		await getTransactions(pageNumber, homeid, false);
    }
	const handleDeleteCard = async (e,cardid) => {
        e.preventDefault();
		loadingAction(true);
        await deleteCreditCard(funeralHomeId, cardid, false);
        await getCreditCards(funeralHomeId,false);
    }
	const convertDateToDateOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("DD-MMM-YYYY");
		}
		return "";
	};
	const addCardModal = () => {
		return(
			<div className={"modalPopup2Wrapper"} style={{width: "432px"}}>
				<div className={"modalPopupHeaderWrapper"}>
					<div className={"modalPopupTitle"} style={{paddingRight: "16px", wordBreak: "break-word"}}>
						{t('pages.billing.add_card')}
					</div>
					<div className={"modalPopupCloseBtn"}>
						<CloseIcon
							className={"modalPopupCloseIcon"}
							onClick={handleClose}
						/>
					</div>
				</div>
				<div className={"modalPopupBody"} style={{padding: "0px 24px 16px", overflow: "hidden"}}>
					<CheckoutFormData pageState="Add" homeId={funeralHomeId} modalState="Modal" setOpen={setOpen} setEditOpen={setEditOpen} />
				</div>
			</div>
		)
	};
	const editCardModal = () => {
		return(
			<div className={"modalPopup2Wrapper"} style={{width: "432px"}}>
				<div className={"modalPopupHeaderWrapper"}>
					<div className={"modalPopupTitle"} style={{paddingRight: "16px", wordBreak: "break-word"}}>
					{t('pages.billing.edit_card')}
					</div>
					<div className={"modalPopupCloseBtn"}>
						<CloseIcon
							className={"modalPopupCloseIcon"}
							onClick={handleEditClose}
						/>
					</div>
				</div>
				<div className={"modalPopupBody"} style={{padding: "0px 24px 16px", overflow: "hidden"}}>
					<CheckoutFormData pageState="Edit" homeId={funeralHomeId} modalState="Modal" cardsAvailable={creditCards?.creditCards}
						editCardId={editCardId} setEditOpen={setEditOpen} setOpen={setOpen} />
				</div>
			</div>
		)
	};
	const loadMore = async (e) => {
		e.preventDefault();
		await getTransactions(pageNumber, funeralHomeId, false);
	}
	return (
		<div>
			<Header />
			<div className={CSS.mainBody}>
				<div className={CSS.pageHeader}
					style={{ marginTop: 48, marginBottom: 24 }}>
					{t('pages.billing.billing_title')}
				</div>
				<section className={CSS.sectionWrapper}>
					<div className={`${CSS.segment} ${CSS.noPadding} ${CSS.tabsContainer}`}>
						<TabContext value={tabIndex}>
							<TabList
								onChange={HandleTabIndexChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto"
								aria-label="scrollable auto tabs example">
								{funeralHomes &&
									funeralHomes.map((home, index) => (
										<Tab
											key={home.id}
											label={home.name}
											value={index + ""}
                                            onClick={(e) => {
												setFuneralHomeId(home.id);
												pageNumber=1;
												getAllCards(e,home.id);
											}
											}
										/>
									))}
							</TabList>
							<TabPanel
								value={tabIndex}>
									<div className={CSS.billing}>
										{showLoading ?
											<Spinner funeralSpinner={funeralSpinner}/>
										:
											<>
												<div className={CSS.wallet}>
													<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom : "1px solid #C4D5D9" }}>
														<div className={CSS.walletHeader}>
														{t('pages.billing.wallet_title')}
														</div>
														<button className={`${CSS.actionBtn}  ${CSS.primary}` } onClick={(e) => {setOpen(true)}}
														style={{display : "flex"}}>
															{t('pages.billing.add_card')}
														</button>
													</div>
													{creditCards?.creditCards?.length>0 ?
														<div className={CSS.cards}>
															{creditCards?.creditCards?.map((card) => {
																return(
																	<div className={CSS.availableCreditCards} key={card.id}>
																		<div className={CSS.eachCard}>
																			<img src={card.brand==="Visa"? VISACARDICON : MASTERCARDICON} height={40} width={62}/>
																		</div>
																		<div className={CSS.editDelete}>
																			<div className={CSS.last4}>
																				{card.brand}{" ...."}{card.last4}
																			</div>
																			<button className={`${CSS.actionBtn}  ${CSS.clean}` } onClick={(e) => {setEditOpen(true); setEditCardId(card.id)}}>
																			{t('pages.billing.edit_card')}
																			</button>
																			<div className={CSS.icons}>
																				{/* <EditIcon className={CSS.EditIcon} onClick={(e) => handleEditCard(e,card.id)}/> */}
																				<button className={`${CSS.actionBtn}  ${CSS.primary}` } onClick={(e) => {handleDeleteCard(e,card.id)}}
																				style={{display : "flex"}}>
																					{t('pages.billing.remove')}
																					<DeleteForeverIcon style={{fontSize : "16px", marginLeft : "12px"}}/>
																				</button>
																			</div>
																		</div>
																	</div>
																)
															})
															}
														</div>
														: 
														<div className={CSS.noCards}>
															<div className={CSS.addCardsHint}>
															{t('pages.billing.add_card_to_wallet')}
															</div>
															<img src={WalletImage} alt="wallet" style={{margin: "auto", display: "block"}}/>
														</div>
													}
												</div>
												<div className={CSS.transactions}>
													<div className={CSS.transactionsHeader} style={{borderBottom : transactionsResponse.length>0 ? "1px solid #C4D5D9" : ""}}>
														Transactions
													</div>
													{transactions?.length>0 ?
														<div className={CSS.transaction}>
															{transactions?.map((transaction) => {
																return(
																	<div className={CSS.availableTransactions} key={transaction.id}>
																		<div className={CSS.transactionName}>
																			{transaction?.transactionType==="use_credits" &&
																				<div className={CSS.availableTransactionsHeader1}>
																					{t('pages.billing.spent')}
																				</div> }
																			{transaction?.transactionType==="redeem_points" &&
																				<div className={CSS.availableTransactionsHeader2}>
																					Redeemed
																				</div> }
																			{transaction?.transactionType==="buy_credits" &&
																				<div className={CSS.availableTransactionsHeader3}>
																					Purchased
																				</div> }
																			<div className={CSS.availableTransactionsData}>
																				<div style={{display: "flex", alignItems: "center"}}>
																					<div className={CSS.availableTransactionAmount}>
																						{transaction.creditAmount} {" credits"}
																					</div>
																					{transaction?.transactionType==="use_credits" &&
																						<Link to={`/funerals/${transaction?.metadata?.funeralUrl}`} target="_blank" className={CSS.eventLink}>
																							{transaction?.metadata?.funeralUrl}
																						</Link>
																					}
																				</div>
																				<div style={{display: "flex", alignItems: "center"}}>
																					{transaction?.transactionType==="use_credits" &&
																						<div className={CSS.paidBy}>
																							Spent by:
																						</div> 
																					}
																					{transaction?.transactionType==="redeem_points" &&
																						<div className={CSS.paidBy}>
																							Redeemed by:
																						</div> 
																					}
																					{transaction?.transactionType==="buy_credits" &&
																						<div className={CSS.paidBy}>
																							Purchased by:
																						</div> 
																					}
																					<div className={CSS.paidByName}>
																						{transaction?.metadata?.userEmail}
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className={CSS.transactionInfo}>
																		{transaction?.transactionType === "buy_credits" &&
																			<div className={CSS.transactionInfoAmount}>
																				<div className={CSS.transactionInfoHeader}>
																					PAID
																				</div>
																				<div className={CSS.transactionInfoValue}>
																					${(transaction?.metadata?.amountPaid)/100}
																				</div>
																			</div> }
																			<div className={CSS.transactionInfoAmount}>
																				<div className={CSS.transactionInfoHeader}>
																					DATE
																				</div>
																				<div className={CSS.transactionInfoValue}>
																					{convertDateToDateOnly(transaction?.createdAt)}
																				</div>
																			</div>
																		</div>
																	</div>
																)
															})}
														</div>
														:
														<div className={CSS.noTransactions}>
															<div className={CSS.noTransactionsHint}>
															{t('pages.billing.no_transactions')}...
															</div>
															<img src={TransactionImage} alt="transaction" style={{margin: "auto", display: "block"}}/>
														</div>
													}
												</div>
												{transactions?.length === 10 &&
													<div className={CSS.loadMore} onClick={(e) => {
															pageNumber=pageNumber+1
															loadMore(e);
														}}>
														{t('pages.billing.load_more')}
													</div>
												}
											</>
										}
									</div>
							</TabPanel>
						</TabContext>
					</div>
				</section>
			</div>
			<Fragment>
				<Modal open={open} onClose={handleClose}>
					{addCardModal()}
				</Modal>
			</Fragment>
			<Fragment>
				<Modal open={editOpen} onClose={handleEditClose}>
					{editCardModal()}
				</Modal>
			</Fragment>
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralHomes: state.funeralHome.funeralHomes,
	creditCards: state.payment.availableCreditCards,
	transactionsResponse: state.payment.transactionsRes,
	showLoading: state.payment.loader,
});

const mapDispatchToProps = {
	getFuneralHomes,
    getCreditCards,
	getTransactions,
	deleteCreditCard,
	loadingAction
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
