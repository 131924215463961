import React, { useState, useEffect } from "react";
import CSS from "../genericStyles/checkoutForm.module.scss";
import MaskedInput from "react-text-mask";
import {
	AMERICANEXPRESS,
	OTHERCARDS,
	EXPIRYDATE,
	CVC,
	CARDARR,
	CARDICON,
} from "../utils/constant";
import {
	stripeCardNumberValidation,
	stripeCardExpirValidation,
	textWithSpacesOnly,
	minLength,
} from "../utils/validation";
import { countryList } from "../utils/countryList";
import { connect } from "react-redux";
import { postCreditsPayment, getCreditCards, loadingAction, postCreditCard, 
	initialStateVal, updateCreditCard, postFuneralVideoPayment } from "../redux/payment/actions";
import "../pages/userManagement/userManagement.css";
import Spinner from "./Spinner";
import PaymentSuccess from "./PaymentSuccess";
import { useHistory } from "react-router-dom";
import StripeTermsAndPrivacy from "./StripeTermsAndPrivacy";
import { useTranslation } from 'react-i18next';
const reducer = (state, action) => {
	switch (action.type) {
		case "card":
			return { ...state, card: action.data };
		case "expiry":
			return { ...state, expiry: action.data };
		case "securityCode":
			return { ...state, securityCode: action.data };
		case "cardHolder":
			return { ...state, cardHolder: action.data };
		case "postal":
			return { ...state, postal: action.data };
		case "cleanState":
			return { ...action.data };
		default:
			return state;
	}
};
function findDebitCardType(cardNumber) {
	const regexPattern = {
		MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
		VISA: /^4[0-9]{2,}$/,
		AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
		DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
		DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
		JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
	};
	for (const card in regexPattern) {
		if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]))
			return card;
	}
	return "";
}
function CheckoutFormData({
	data,
	postCreditsPayment,
	homeId,
	pageState,
	setPageState,
	initialStateVal,
	paymentRes,
	postCreditCard,
	getCreditCards,
	cardsAvailable,
	editCardId,
	updateCreditCard,
	updateCardResponse,
	addCreditCardres,
	STATE,
	userPayload,
	downloadVideores,
	postFuneralVideoPayment,
	modalState,
	setEditOpen,
	setOpen
}) {
	const history = useHistory();
	const { t } = useTranslation();
	const [nameOnTheCard, setNameOnTheCard] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [error, setError] = React.useState({});
	const [cardType, setCardType] = React.useState();
	const [validForm, setValidForm] = useState("");
	const [check, setCheck] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [nameQuery, setNameQuery] = useState("");
	const [state, dispatch] = React.useReducer(reducer, {
		card: "",
		expiry: "",
		securityCode: "",
		cardHolder: "",
	});
	const [selectedCountry, setSelectedCountry] = useState("");
	useEffect(() => {
		if (
			Object.values(error).every((x) => x === undefined || x === "") &&
			postalCode &&
			cardType &&
			nameOnTheCard &&
			selectedCountry &&
			state.card &&
			state.expiry &&
			state.securityCode
		) {
			setValidForm(true);
			return true;
		} else {
			setValidForm(false);
			return false;
		}
	}, [error, postalCode, cardType, nameOnTheCard, selectedCountry]);
	useEffect(async() => {
		if(addCreditCardres === "SUCCESS"){
			if(modalState!=="Modal"){
				setPageState("");
			}
			else{
				setEditOpen(false);
				setOpen(false);
			}
			await initialStateVal();
			await getCreditCards(homeId,false);
		}
	}, [addCreditCardres]);
	useEffect(async() => {
		if(updateCardResponse === "SUCCESS"){
			if(modalState!=="Modal"){
				setPageState("");
			}
			else{
				setEditOpen(false);
				setOpen(false);
			}
			await initialStateVal();
			await getCreditCards(homeId,false);
		}
	}, [updateCardResponse]);
	useEffect(async () => {
		if(downloadVideores === "SUCCESS"){
			await initialStateVal();
			history.push({
				pathname:
					"/paymentSuccess",
				state: {
					payload: userPayload
				},
			});
		}
	}, [downloadVideores]);
	useEffect(() => {
	}, [paymentRes, pageState, nameQuery]);
	useEffect(() => {
		if(pageState === "Edit"){
			cardsAvailable.map((card) => {
				if(card.id === editCardId){
					const expireYear = card.expYear.toString();
					state.card="**** **** **** " + card.last4;
					setPostalCode(card.addressZip);
					setNameOnTheCard(card.name);
					setSelectedCountry(card.addressCountry);
					setCardType(card.brand.toUpperCase())
					state.expiry="0"+card.expMonth+"/"+expireYear.substring(2,4)
				}
			})
		}
	}, []);
	const handleNameOnTheCard = (e) => {
		setNameOnTheCard(e.target.value);
	};
	const handlePostalCode = (e) => {
		setPostalCode(e.target.value);
	};
	const handleInputData = (e) => {
		dispatch({ type: e.target.name, data: e.target.value });
	};
	const handleBlur = (e) => {
		handleValidations(e.target.name, e.target.value);
	};
	const handleCheckBox = () => {
		setCheck(!check);
	};
	const handleValidations = (type, value) => {
		let errorText;
		switch (type) {
			case "card":
				setCardType(findDebitCardType(value));
				errorText =
					value === ""
						? t('pages.checkout_form.card_number_error')
						: stripeCardNumberValidation(value, t);
				setError({ ...error, cardError: errorText });
				break;
			case "cardHolder":
				errorText =
					value === "" ? t('pages.checkout_form.required') : textWithSpacesOnly(value, t);
				setError({ ...error, cardHodlerError: errorText });
				break;
			case "expiry":
				errorText =
					value === ""
						? t('pages.checkout_form.enter_expiry_error')
						: stripeCardExpirValidation(value, t);
				setError({ ...error, expiryError: errorText });
				break;
			case "securityCode":
				errorText =
					value === ""
						? t('pages.checkout_form.enter_cvc_error')
						: minLength(3)(value, t);
				setError({ ...error, securityCodeError: errorText });
				break;
			case "nameOnCard":
				errorText = value === "" ? t('pages.checkout_form.enter_name_error') : "";
				setError({ ...error, nameOnTheCardError: errorText });
				break;
			case "postal":
				errorText = value === "" ? t('pages.checkout_form.enter_postal_error') : "";
				setError({ ...error, postalError: errorText });
				break;
			default:
				break;
		}
	};
	const addNewCard = async (e) => {
		e.preventDefault();
		setValidForm(false);
		if(pageState === "Add"){
			const payload = {
				credit_card: {
					number: state.card.replace(/\s/g, ""),
					exp_month: state.expiry.substring(0, 2),
					exp_year: "20" + state.expiry.substring(3, 5),
					cvc: state.securityCode,
					name: nameOnTheCard,
					address_country: selectedCountry,
					address_zip: postalCode,
				},
			}
			await postCreditCard(payload, homeId, false);
		}
		else{
			const payload = {
				credit_card: {
					exp_month: state.expiry.substring(0, 2),
					exp_year: "20" + state.expiry.substring(3, 5),
					name: nameOnTheCard,
					address_country: selectedCountry,
					address_zip: postalCode,
				},
			}
			await updateCreditCard(payload, homeId, editCardId, false);
		}
	}
	const creditsStripePayment = async (e) => {
		e.preventDefault();
		setValidForm(false);
		if(STATE){
			const payload={
				buyer_information: {
					funeral_id: userPayload.funeral_id,
					email: userPayload.email,
					first_name: userPayload.first_name,
					last_name: userPayload.last_name,
					telephone_number: userPayload.telephone_number
				},
				credit_card: {
					number: state.card.replace(/\s/g, ""),
					exp_month: state.expiry.substring(0, 2),
					exp_year: "20" + state.expiry.substring(3, 5),
					cvc: state.securityCode,
					name: nameOnTheCard,
					address_country: selectedCountry,
					address_zip: postalCode,
				}
			}
			await postFuneralVideoPayment(payload);
		}
		else{
			const payload = {
				credit_card: {
					number: state.card.replace(/\s/g, ""),
					exp_month: state.expiry.substring(0, 2),
					exp_year: "20" + state.expiry.substring(3, 5),
					cvc: state.securityCode,
					name: nameOnTheCard,
					address_country: selectedCountry,
					address_zip: postalCode,
				},
				credits: data.credits,
				amount: data.total,
				save_credit_card: check,
			};
			const result = await postCreditsPayment(payload, homeId);
		}
	};
	return (
		<div className={CSS.CheckoutFormData}>
			{(addCreditCardres === "REQUEST" || updateCardResponse === "REQUEST" || downloadVideores === "REQUEST")&&
				<Spinner value="ADD_CARD"/>
			}
			{paymentRes === "IDEAL" || addCreditCardres === "ERROR"|| 
			updateCardResponse === "ERROR" || paymentRes === "ERROR" || downloadVideores==="ERROR"?
				<>
					{STATE &&
						<div className={CSS.checkoutFormHeader}>
							{t('pages.checkout_form.pay_with_card_title')}
						</div>
					}
					<div className={CSS.paymentHint} style={{ marginTop: 16, marginBottom: 16 }}>
						{t('pages.checkout_form.pay_with_card_hint')}
					</div>
					<div className={CSS.elementWrapper}>
						<label className={CSS.elementLabel}>
							{t('pages.checkout_form.card_number')}
						</label>
						<MaskedInput
							className={
								(error &&
								error.cardError &&
								error.cardError.length > 1) || addCreditCardres === "ERROR"
								|| updateCardResponse === "ERROR" || paymentRes === "ERROR" || downloadVideores==="ERROR"
									? CSS.inputBoxError : pageState === "Edit" ? CSS.inputBoxEdit
									: CSS.inputBox
							}
							mask={
								["37", "34"].includes(state && state.card.split("").splice(0, 2).join(""))
									? AMERICANEXPRESS : OTHERCARDS}
							guide={false}
							placeholderChar={"\u2000"}
							placeholder= {t('pages.checkout_form.card_number')}
							name="card"
							required
							disabled={pageState === "Edit"}
							value={state.card}
							onChange={handleInputData}
							onBlur={handleBlur}
						/>
						{(!error || !error.cardError) &&
							CARDARR.includes(cardType) && (
								<img style={{float: "right", position: "relative", top: "-32px", paddingRight: "8px"}}
									src={CARDICON[cardType]}
									alt="card"
									width="62px"
									height="30px"
								/>
						)}
						{error &&
							error.cardError &&
							error.cardError.length > 1 && (
								<div style={{
										fontFamily: "Bitter, serif",
										fontStyle: "normal",
										fontWeight: 300,
										fontSize: 12,
										color: "#AE0000",
									}}>
									{error.cardError}
								</div>
							)}
					</div>
					<div className={CSS.expiryCvc}>
						<div className={CSS.elementWrapper}>
							<label className={CSS.elementLabel}>
							{t('pages.checkout_form.expiry_date')}
							</label>
							<MaskedInput
								className={
									(error &&
									error.expiryError &&
									error.expiryError.length > 1) || addCreditCardres === "ERROR"
									|| updateCardResponse === "ERROR" || paymentRes === "ERROR"|| downloadVideores==="ERROR"
										? CSS.inputBoxError
										: CSS.inputBox
								}
								mask={EXPIRYDATE}
								guide={false}
								name="expiry"
								required
								placeholderChar={"\u2000"}
								placeholder="Expiry Date (MM/YY)"
								value={state.expiry}
								onChange={handleInputData}
								onBlur={handleBlur}
							/>
							{error &&
								error.expiryError &&
								error.expiryError.length > 1 && (
									<div style={{
											fontFamily: "Bitter, serif",
											fontStyle: "normal",
											fontWeight: 300,
											fontSize: 12,
											color: "#AE0000",
										}}>
										{error.expiryError}
									</div>
								)}
						</div>
						<div className={CSS.elementWrapper} style={{ marginLeft: 8 }}>
							<label className={CSS.elementLabel}>CVC</label>
							<MaskedInput
								className={
									(error &&
									error.securityCodeError &&
									error.securityCodeError.length > 1) || addCreditCardres === "ERROR"
									|| updateCardResponse === "ERROR" || paymentRes === "ERROR"|| downloadVideores==="ERROR"
										? CSS.inputBoxError
										: CSS.inputBox
								}
								mask={CVC}
								guide={false}
								name="securityCode"
								required
								placeholderChar={"\u2000"}
								placeholder={t('pages.checkout_form.security_code')}
								value={state.securityCode}
								onChange={handleInputData}
								onBlur={handleBlur}
							/>
							{error &&
								error.securityCodeError &&
								error.securityCodeError.length > 1 && (
									<div
										style={{
											fontFamily: "Bitter, serif",
											fontStyle: "normal",
											fontWeight: 300,
											fontSize: 12,
											color: "#AE0000",
										}}>
										{error.securityCodeError}
									</div>
								)}
						</div>
					</div>
					{addCreditCardres === "ERROR" || updateCardResponse === "ERROR" ? 
						<div style={{color: "#AE0000", fontFamily: 'Bitter,serif', fontStyle: 'normal', fontWeight: 300,
							fontSize: '14px', lineHeight: '21px'}}>
							{addCreditCardres === "ERROR"? t('pages.checkout_form.add_card_error') : t('pages.checkout_form.update_card_error')}
							{t('pages.checkout_form.different_payment_method_hint')}
						</div>
					: ""}
					{paymentRes === "ERROR" || downloadVideores==="ERROR" ?
						<div style={{color: "#AE0000", fontFamily: 'Bitter,serif', fontStyle: 'normal', fontWeight: 300,
							fontSize: '14px', lineHeight: '21px'}}>
							{t('pages.checkout_form.authenticate_payment_error')} 
							{t('pages.checkout_form.different_payment_method_hint')}
						</div> : ""
					}
					<div className={CSS.elementWrapper}>
						<label className={CSS.elementLabel}>
							{t('pages.checkout_form.name_on_card')} 
						</label>
						<input name="nameOnCard"
							className={
								error &&
								error.nameOnTheCardError &&
								error.nameOnTheCardError.length > 1
									? CSS.inputBoxError
									: CSS.inputBox
							}
							value={nameOnTheCard}
							onChange={(e) => {handleNameOnTheCard(e)}}
							onBlur={handleBlur}
						/>
						{error &&
							error.nameOnTheCardError &&
							error.nameOnTheCardError.length > 1 && (
								<div style={{
										fontFamily: "Bitter, serif",
										fontStyle: "normal",
										fontWeight: 300,
										fontSize: 12,
										color: "#AE0000",
									}}>
									{error.nameOnTheCardError}
								</div>
							)}
					</div>
					<div className={CSS.elementWrapper}>
						<label className={CSS.elementLabel}>{t('pages.checkout_form.country')} </label>
						<div className="dropdown" style={{ width: "100%" }}>
							<div className="control" onClick={() => setDropdownOpen((prev) => !prev)}>
								<input type="text" className="selectedValue" value={selectedCountry||nameQuery} 
									onChange={(event) => {
										setSelectedCountry("");
										setNameQuery(event.target.value);
									}}/>
								<div className={`arrow ${ dropdownOpen ? "open" : null }`} />
							</div>
							<div className={`options ${ dropdownOpen ? "open" : null }`}>
								{countryList?.
									filter((val) => {
										if (nameQuery === "") {
											return val;
										} else if (
											val
												.toLocaleLowerCase()
												.includes(
													nameQuery.toLocaleLowerCase()
												)
										) {
											return val;
										}
									}).map((country) => (
										<div className="sub-option" key={country} onClick={() =>{setDropdownOpen((prev) => !prev);
										setSelectedCountry(country)}}>
											{country}
										</div>
								))}
							</div>
						</div>
					</div>
					<div className={CSS.elementWrapper}>
						<label className={CSS.elementLabel}>
						{t('pages.checkout_form.postal_code')} 
						</label>
						<input name="postal"
							className={
								error &&
								error.postalError &&
								error.postalError.length > 1
									? CSS.inputBoxError
									: CSS.inputBox
							}
							value={postalCode}
							onChange={(e) => {
								handlePostalCode(e);
							}}
							onBlur={handleBlur}
						/>
						{error &&
							error.postalError &&
							error.postalError.length > 1 && (
								<div
									style={{
										fontFamily: "Bitter, serif",
										fontStyle: "normal",
										fontWeight: 300,
										fontSize: 12,
										color: "#AE0000",
									}}>
									{error.postalError}
								</div>
							)}
					</div>
					{pageState!=="Add" && pageState!=="Edit" && STATE!==true?
						<div className={CSS.elementWrapper} style={{ display: "flex", alignItems: "center" }}>
							<input
								className={CSS.checkIcon}
								type="checkbox"
								value={check}
								name="checkbox"
								onChange={handleCheckBox}
							/>
							<label className={CSS.checkBoxLabel}>
							{t('pages.checkout_form.save_info_hint')} 
							</label>
						</div>
					:""}
					{pageState==="Add" ?
						<button className={`${CSS.orderButton} ${ validForm ? "" : CSS.disabled }`} onClick={(e) => addNewCard(e)}>
							{t('pages.checkout_form.add_btn')} 
						</button>
					: pageState==="Edit"?
						<button className={`${CSS.orderButton} ${ validForm ? "" : CSS.disabled }`} onClick={(e) => addNewCard(e)}>
							{t('pages.checkout_form.save_btn')} 
						</button>
					:
						<button className={`${CSS.orderButton} ${ validForm ? "" : CSS.disabled }`} onClick={(e) => creditsStripePayment(e)}>
							{t('pages.checkout_form.pay_btn')} 
						</button>
					}
					<StripeTermsAndPrivacy />
				</>
			: paymentRes === "REQUEST" ?
				<Spinner style={{position: 'absolute', height: '100px', width: '100px', top: '50%', left: '50%'}}/>
			: paymentRes === "SUCCESS" ?
				<PaymentSuccess />
			: ""
		}
		</div>
	);
}

const mapStateToProps = (state) => ({
	creditCards: state.payment.availableCreditCards,
	showLoading: state.payment.loader,
	paymentRes: state.payment.creditPaymentRes,
	updateCardResponse: state.payment.updateCardRes,
	addCreditCardres: state.payment.creditcardRes,
	downloadVideores: state.payment.funeralVideoPayment
});

const mapDispatchToProps = {
	postCreditsPayment,
	getCreditCards,
	loadingAction,
	postCreditCard,
	initialStateVal,
	updateCreditCard,
	postFuneralVideoPayment
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFormData);