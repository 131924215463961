import React, {useState, useEffect} from "react";
import CSS from "./StreamingCredits.module.scss";
import Header from "../header/Header";
import StreamCreditCard from "./StreamingCreditCard";
import ErrorIcon from "@mui/icons-material/Error";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import { getFuneralHomes, postRedeemPoints } from "../../redux/funeralHome/actions";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

const StreamingCredits = ({funeralHomes,getFuneralHomes,postRedeemPoints}) => {
	const [tabIndex, setTabIndex] = useState("0");
	const [redeemPoints, setRedeemPoints] = useState(0);
	const { t, i18n } = useTranslation();
	useEffect(() => {
	  getFuneralHomes();
	}, []);
	useEffect(() => {
	}, [redeemPoints]);
	const HandleTabIndexChange = (evt, index) => {
		setTabIndex(index + "");
	};
	const decrementRedeemPoints = () => {
		setRedeemPoints(redeemPoints-4);
	}
	const incrementRedeemPoints = () => {
		setRedeemPoints(redeemPoints+4);
	}
	const addRedeemPoints = async (id) => {
		await postRedeemPoints(id, redeemPoints);
		setRedeemPoints(0);
		await getFuneralHomes();
	}
	return (
		<div>
			<Header />
			<div className={CSS.mainBody}>
				<div
					className={CSS.pageHeader}
					style={{ marginTop: 48, marginBottom: 24 }}>
					{t('pages.streaming_credits.title')}
				</div>
				<section className={CSS.sectionWrapper}>
					<div
						className={`${CSS.segment} ${CSS.noPadding} ${CSS.tabsContainer}`}>
						<TabContext value={tabIndex}>
							<TabList
								onChange={HandleTabIndexChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto"
								aria-label="scrollable auto tabs example"> 
								{funeralHomes &&
								funeralHomes.map(
									(home, index) => (
										<Tab
											key={home.id}
											label={home.name}
											value={index + ""}
										/>
									)
								)}
							</TabList>
							{funeralHomes &&
								funeralHomes.map(
									(home, index) => (
										<TabPanel
											key={home.id}
											value={index + ""}>
												<div className={CSS.availablePoints}>
													<div className={CSS.currentPoints}>
														<div className={CSS.currentPointsHint1}>
															<div className={CSS.preHint}>					
																{t('pages.streaming_credits.current_credits')}
															</div>
															<div className={CSS.postHint}>
																{ home.credits ? home.credits : 0 }
																{t('pages.streaming_credits.credits')}
															</div>
														</div>
														<div className={CSS.currentPointsHint2}>
														{t('pages.streaming_credits.points_hint')} 👇🏽
														</div>
													</div>
													<div className={CSS.claimablePoints}>
														<div className={CSS.acclaimedPoints}>⭐️ { home.points } {t('pages.streaming_credits.accumalated_points')}</div>
														<div className={CSS.creditIncr}>
															<div className={CSS.acclaimedPointsHint}>{t('pages.streaming_credits.redeem_hint')}</div>
															<div className={CSS.incrementBtns}>
																<button style={{borderTopRightRadius: "0px", borderBottomRightRadius: "0px"}}
																	className={`${CSS.actionBtn} ${CSS.primary} 
																	${redeemPoints>=4 ? "" : CSS.disabled}`} onClick={()=>{decrementRedeemPoints()}}>
																		-
																</button>
																<input className={CSS.creditInput} value={redeemPoints} disabled></input>
																<button  style={{borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px"}}
																	className={`${CSS.actionBtn} ${CSS.primary}
																	${(home.points-redeemPoints)>=4 ? "" : CSS.disabled}`} onClick={()=>{incrementRedeemPoints()}}>
																		+
																</button>
															</div>
															<button className={`${CSS.actionBtn} ${CSS.warning} 
																${(home.points !==0 && redeemPoints !==0) ? "" : CSS.disabled}`} onClick={()=>{addRedeemPoints(home.id)}}>
																{t('pages.streaming_credits.redeem_title')}
															</button>
														</div>
														<div
															className={CSS.claimablePointsTooltip}
															style={{ display: "flex", alignItems: "center" }}>
															<div className={CSS.tooltip}>
																<ErrorIcon
																	style={{
																		color: "#00667E",
																		transform: "rotate(180deg)",
																		marginTop: 4,
																		marginRight: 4,
																		cursor: "pointer"
																	}}
																/>
																<span className={CSS.tooltiptext}>
																{t('pages.streaming_credits.tooltip')}
																</span>
															</div>
															<div>{t('pages.streaming_credits.points_for_download')}</div>
														</div>
													</div>
													<div></div>
												</div>
												<div className={CSS.creditPackages}>
													<div className={CSS.creditHeader}>
													{t('pages.streaming_credits.credits_title')}
													</div>
													<div className={CSS.creditHint} style={{ marginBottom: 8 }}>
													{t('pages.streaming_credits.bundles_hint')}
													</div>
												</div>
												<StreamCreditCard funeralHomeId={home.id}/>
										</TabPanel>
									)
								)}
						</TabContext>
					</div>
				</section>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralHomes: state.funeralHome.funeralHomes,
});

const mapDispatchToProps = {
	getFuneralHomes,
	postRedeemPoints
};
export default connect(mapStateToProps, mapDispatchToProps)(StreamingCredits);
