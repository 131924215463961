import CSS from "./CreateEventPage.module.scss";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import BackupIcon from "@material-ui/icons/Backup";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CSwitch from "../../components/CSwitch";
import { useHistory } from "react-router-dom";
import EventSuccessPage from "./EventSuccessPage";
import { connect } from "react-redux";
import { getOnsiteData,postImageWithSignedUrl,createEventReq, updateEventReq, removeDeceasedImage } from "../../redux/createEvent/actions";
import { getOrganizations } from "../../redux/organization/actions";
import { getFuneralHome, getFuneralHomes } from "../../redux/funeralHome/actions";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import "../../components/ModalPopup.scss";
import {fileChecksum} from "../../utils/checksum";
import createId from '../../utils/uuid';
import {
	getFuneralStreams,
	getFuneralDetails,
} from "../../redux/funeral/actions";
import "../userManagement/userManagement.css";
import Header from "../header/Header";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { fr } from "date-fns/locale";
import { remove } from "lodash";

const useStyles = makeStyles((theme) => ({
	selectRoot: {
		'&:focus':{
		backgroundColor:'white'
		},
	},
	selected:{
		background: 'linear-gradient(left, #00667E 0%, #00667E 5%, #E1EAEC 5%, #E1EAEC 100%)',
	},
	rootMenuItem: {
	  "&$selected": {
		backgroundColor: "white",
		  "&:hover": {
			backgroundColor: "white"
		   }
		},
	  '&:hover':{
		backgroundColor:'#C4D5D9'
	  },
	},
	icon: {
		fill: '#00667E',
	 },
  }));
const helperTextStyles = makeStyles((theme) => ({
	root: {
	},
	error: {
	  "&.MuiFormHelperText-root.Mui-error": {
		color: "#AE0000",
		position: "absolute",
		marginTop: "32px",
		fontSize: "14px",
		fontFamily: "Bitter,serif",
		fontWeight: "300",
		fontStyle: "normal",
		lineHeight: "21px"
	  },	
	}
  }));

const CreateEventPage = (props) => {
	const {
		match: {
			params: { id ,state },
		},
	} = props;
	const helperTestClasses = helperTextStyles();
	const { 
		onsiteLocationList, 
		getOnsiteData,
		uploadedImage, 
		createEventReq,
		updateEventReq,
		removeDeceasedImage,
		funeralStreams,
		funeralHomes,
		funeralDetails,
		organizations,
		funeralHomeInfo,
		createEventResponse,
		updateEventResponse,
		createEventResponseFailure,
		updateEventResponseFailure,
		getOrganizations, 
		getFuneralHomes, user } = props;
	const classes = useStyles();
    useEffect(()=>{
		if(state!=="edit"){
			getOrganizations();
			getFuneralHomes();
		}
		createEventResponse.funeralUrl=null;
    },[]);
	let funeralId = 0, funeral_home_id = 0;
	if(state === "edit"){
		funeralId = id;
	}else{
		funeral_home_id = id;
	}
	useEffect(() => {
		if(funeralId){
			props.getFuneralStreams(funeralId);
			props.getFuneralDetails(funeralId);
		}
	}, []);
	useEffect(()=>{
		if(funeralId){
			fillForms();
		}
	},[funeralStreams,funeralDetails,funeralHomeInfo]);
	useEffect(() => {
		if(state==="edit" && funeralDetails.funeralHomeId){
			props.getFuneralHome(funeralDetails.funeralHomeId);
			props.getOnsiteData(funeralDetails.funeralHomeId);
		}
	}, [funeralDetails.funeralHomeId]);
	useEffect(() => {
	  if(funeralHomes.length === 1 && (user.user?.role==="editor" || user.user?.role==="editor_restricted")){
			setFuneralHomeName(funeralHomes[0].name);
			setHomeId(funeralHomes[0].id);
			if (funeralHomes[0].id && state !=="edit") {
				getOnsiteData(funeralHomes[0].id);
			}
	  }
	}, [funeralHomes]);
	
	const { t } = useTranslation();
	const filter = createFilterOptions();

	const defaultCropSettings = {
		unit: "px",
		aspect: 1,
		minWidth: 50,
		width: 100,
		top: 0,
		left: 0,
		keepSelection: false,
	};
	const [cropSettings, setCropSettings] = useState(defaultCropSettings);
	const streamingStaticList = [
		{title: t('components.stream_name_options.name_hint')},
		{title: t('components.stream_name_options.type_service')},
		{title: t('components.stream_name_options.type_visitation')},
		{title: t('components.stream_name_options.type_viewing')},
		{title: t('components.stream_name_options.type_celebration')},
	];
	const streamingLocationList = [
		{
			id: 1,
			label: t('components.stream_location_options.stream_onsite'),
		},
		{
			id: 2,
			label: t('components.stream_location_options.stream_offsite'),
		},
	];
	const newDate = null;
	const newTime = null;
	const [isSuccess, setSucess] = useState(false);
	const [homeId, setHomeId] = useState(null);
	const [funeralHomeName, setFuneralHomeName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [authNeeded, setAuthNeeded] = useState(false);
	const [streamEventList, updateStreamEventList] = useState([]);
	const [selectedDateOFBirth, setSelectedDateOFBirth] = useState(newDate);
	const [selectedDateOFDeath, setSelectedDateOFDeath] = useState(newDate);
	const [streamName, setStreamName] = useState(null);
	const [seletedStreamDate, setSeletedStreamDate] = useState(newDate);
	const [streamStartTime, setStreamStartTime] = useState(newTime);
	const [streamEndTime, setStreamEndTime] = useState(newTime);
	const [streamLocation, setStreamLocation] = useState("");
	const [onSiteLocationName, setOnSiteLocationName] = useState("");
	const [streamLocationName, setStreamLocationName] = useState("");
	const [streamValid, setStreamValid] = useState(false);
	const [cmpltFormValild, setCmpltFormValild] = useState(false);
	const [selectedImage, setSelectedImage] = useState(undefined);
	const [croppedImage, setCroppedImage] = useState({ blob: "", url: "#" });
	const [emailsList, setEmailsList] = useState("");
	const [password, setPassword] = useState("");
	const [bypassGuestLogin, setBypassGuestLogin] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [nameQuery, setNameQuery] = useState("");
	const [deceasedUrl, setDeceasedUrl] = useState(null);
	const [eventError, setEventError] = useState("")
	const [errorStates, setErrorStates] = useState({
		firstName: "",
		lastName: "",
		selectedDateOFBirth: "",
		selectedDateOFDeath: "",
		streamName: "",
		seletedStreamDate: "",
		streamStartTime: "",
		streamEndTime: "",
		streamLocation: "",
		onSiteLocationName: "",
		streamLocationName: "",
		streamValid: "",
		emailsList: "",
		password: "",
	});
	let fileUrl;
	const defaultImguploadData = {
		fileDroped: false,
		fileUploading: false,
		fileUploadedPercent: 0,
		fileUploaded: false,
		invalidFile: false,
		invalidFileMsg: "",
		savedImage: undefined,
		savedImageUrl: "#",
		showCropOption: false,
		uploadedImageType: "",
		uploadedImageName: "",
	};
	const [imageRef, setImageRef] = useState(undefined);
	const [dropFile, setDroppedFile] = useState(defaultImguploadData);
	const history = useHistory();
	const [browserLang, setBrowserLang] = useState("");
    useEffect(() => {
		var userLang = navigator.language || navigator.userLanguage;
		setBrowserLang(userLang);
	}, []);
	useEffect(() => {
		
	}, [browserLang]);
	useEffect(() => {
		
	}, [eventError]);
	useEffect(() => {
		
	}, [cmpltFormValild]);
	useEffect(() => {
		if(state!=="edit"){
			updateStreamEventList(streamEventList.filter(event => event.locationType!==1));
		}
	}, [homeId]);
	useEffect(() => {
	}, [createEventResponse, updateEventResponse]);
	const fillForms = () => {
		if(funeralDetails){
			funeral_home_id = funeralDetails.funeralHomeId;
			setFirstName(funeralDetails.firstName);
			if(state==="edit"){
				setFuneralHomeName(funeralHomeInfo.name);
			}
			setLastName(funeralDetails.lastName);
			setAuthNeeded((funeralDetails.password || funeralDetails.passwordDigest) ? true : false);
			setPassword(funeralDetails.password);
			setBypassGuestLogin(funeralDetails.bypassGuestLogin);
			setEmailsList(getProperEmails());
			setSelectedDateOFBirth(moment(funeralDetails.dateOfBirth).toDate());
			setSelectedDateOFDeath(moment(funeralDetails.dateOfDeath).toDate());
			if(funeralDetails.deceasedImageUrl){ // image
				setDroppedFile(prev=>{
					return {
						...prev,
						fileUploaded: true,
						savedImage: funeralDetails.deceasedImageUrl,
					}
				})
			}
		}
		if(funeralStreams && funeralStreams.length > 0){
			const streams = funeralStreams.map((stream)=>{
				return {
					id: stream.id,
					name: stream.name,
					date: moment(stream.streamStart).toDate().getTime(),
					streamStart: moment(stream.streamStart).toDate().getTime(),
					streamEnd: moment(stream.streamEnd).toDate().getTime(),
					locationType: stream.location === "On-Site" ? 1 : 2,
					room_id: stream.roomId,
					location: stream.streamLocationName, //needs to be changed
				}
			});
			updateStreamEventList(streams);
		}

	}
	const getProperEmails = () => {
		if(funeralDetails && funeralDetails.clientEmails && funeralDetails.clientEmails.emails){
			let emails = funeralDetails.clientEmails.emails;
			if(typeof(emails) == "string"){
				try{
					emails = JSON.parse(emails);
					return emails.join(",");
				}catch(e){
					return emails;
				}
			}else if(typeof(emails) == "object" && emails.length){
				return emails.join(",");
			}
		}
		return "";
	}
	const convertDateToDateOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("YYYY-MM-DD");
		}
		return "";
	};

	const convertDateToTimeOnly = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("hh:mm A");
		}
		return "";
	};
	const handleFirstName = (e) => {
		setFirstName(e.target.value);
	};
	const handleLastName = (e) => {
		setLastName(e.target.value);
	};
	const handleDobChange = (date) => {
		if(checkValidDate(selectedDateOFDeath) && checkValidDate(date) && date.getTime() >= selectedDateOFDeath.getTime()){
			setErrorStates(prev=>{
				return {
					...prev,
					selectedDateOFBirth: t('pages.create_event.dob_validation'),
					selectedDateOFDeath: "",
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					selectedDateOFBirth: "",
					selectedDateOFDeath: "",
				}
			})
		}
		setSelectedDateOFBirth(date);
	};
	const handleDodChange = (date) => {
		if(checkValidDate(selectedDateOFBirth) && checkValidDate(date) && selectedDateOFBirth.getTime() >= date.getTime()){
			setErrorStates(prev=>{
				return {
					...prev,
					selectedDateOFBirth: "",
					selectedDateOFDeath: t('pages.create_event.dod_validation')
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					selectedDateOFBirth: "",
					selectedDateOFDeath: "",
				}
			})
		}
		setSelectedDateOFDeath(date);
	};
	const checkValidDate = (date) =>
		date &&
		date.toString &&
		date.toString() !== "Invalid Date" &&
		date.getTime &&
		!isNaN(date.getTime());
	const handleStreamDateChange = (date) => {
		setSeletedStreamDate(date);
	};
	const handleStreamStartTime = (time) => {
		if(checkValidDate(streamEndTime) && checkValidDate(time) && time.getTime() >= streamEndTime.getTime()){
			setErrorStates(prev=>{
				return {
					...prev,
					streamStartTime: t('pages.create_event.start_time_validation'),
					streamEndTime: "",
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					streamStartTime: "",
					streamEndTime: "",
				}
			})
		}
		setStreamStartTime(time);
	};
	const handleStreamEndTime = (time) => {
		if(checkValidDate(streamStartTime) && checkValidDate(time) && streamStartTime.getTime() >=  time.getTime()){
			setErrorStates(prev=>{
				return {
					...prev,
					streamStartTime: "",
					streamEndTime: t('pages.create_event.end_time_validation'),
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					streamStartTime: "",
					streamEndTime: "",
				}
			})
		}
		setStreamEndTime(time);
	};
	const handleStreamLocationChange = (e) => {
		setStreamLocation(e.target.value);
	};
	const handleStreamLocationNameChange = (e) => {
		setStreamLocationName(e.target.value);
	};
	const handleOnSiteLocationNameChange = (e) => {
		setOnSiteLocationName(e.target.value);
	};
	useEffect(() => {
		checkStreamValid();
	}, [
		streamName,
		seletedStreamDate,
		streamStartTime,
		streamEndTime,
		streamLocation,
		streamLocationName,
		onSiteLocationName,
	]);
	const checkStreamValid = () => {
		if (
			streamName &&
			checkValidDate(seletedStreamDate) &&
			checkValidDate(streamStartTime) &&
			checkValidDate(streamEndTime) &&
			streamLocation &&
			((streamLocation === 1 && onSiteLocationName) ||
				(streamLocation === 2 && streamLocationName))
		) {
			if (streamStartTime < streamEndTime) {
				setStreamValid(true);
				return true;
			} else {
				setStreamValid(false);
				return false;
			}
		} else {
			setStreamValid(false);
			return false;
		}
	};
	useEffect(() => {
		checkDetailsValid();
	}, [funeralHomeName, firstName, lastName, selectedDateOFBirth, selectedDateOFDeath, authNeeded, streamEventList, emailsList, password, errorStates]);
	const checkDetailsValid = () => {
		if (
			funeralHomeName&&
			firstName && firstName.trim() &&
			lastName && lastName.trim() &&
			checkValidDate(selectedDateOFBirth) &&
			checkValidDate(selectedDateOFDeath) &&
			streamEventList.length > 0 &&
			emailsList && emailsList.trim() &&
			(!authNeeded || (authNeeded && password && password.trim()))
		) {
			if(selectedDateOFBirth<selectedDateOFDeath && Object.values(errorStates).every(x => x === null || x === '')){
					setCmpltFormValild(true);
					return true;
			}else{
				setCmpltFormValild(false);
				return false;
			}
		} else {
			setCmpltFormValild(false);
			return false;
		}
	};
	const getLocationName = (id) => {
		let found = streamingLocationList.find((x) => {
			return x.id === id;
		});
		return found ? found.label : "";
	};
	const getOnsiteLocationName = (id) => {
		let found = onsiteLocationList.find((x) => x.id === id);
		return found ? found.name : "";
	};
	const resetStreamData = () => {
		setStreamName("");
		setSeletedStreamDate(null);
		setStreamStartTime(null);
		setStreamEndTime(null);
		setStreamLocation("");
		setStreamLocationName("");
	};
	const resetEventData = () => {
		setFuneralHomeName("");
		setFirstName("");
		setLastName("");
		setSelectedDateOFBirth(null);
		setSelectedDateOFDeath(null);
	};
	const handleEmailList = (e) =>{
		setEmailsList(e.target.value);
	}
	const isEmailsValid = (emailList) => {
		const resultEmail = emailList.map(element => {
			return element.trim();
		  });
		for(let i=0; i<resultEmail.length; i+=1){
			const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
			if(!regEx.test(resultEmail[i])){
				setErrorStates(prev=>{
					return {
						...prev,
						emailsList: t('pages.create_event.email_validation')
					}
				})
			}else{
				setErrorStates(prev=>{
					return {
						...prev,
						emailsList: ""
					}
				})
			}
		}
		return true;
	}
	
	const authToggle = (event) => {
		setAuthNeeded(event.target.checked);
		if (event.target.checked) {
			// setBypassGuestLogin(false);
		} else {
			setPassword('');
		}
	};
	const handleBypassGuestLoginToggle = (event) => {
		setBypassGuestLogin(event.target.checked);
		// if (event.target.checked) {
		// 	setAuthNeeded(false);
		// 	setPassword('');
		// }
	}
	useEffect(() => {
		setDeceasedUrl(createEventResponse.funeralUrl);
		if(deceasedUrl&&state!=="edit"){
			history.push({
				pathname:
					"/EventSuccess",
				state: {
					detail: deceasedUrl,
				},
			});
		}
	}, [createEventResponse,deceasedUrl]);
	useEffect(() => {
		if(password  && password.length<8 && password.length>0){
			setErrorStates(prev=>{
				return {
					...prev,
					password: t('pages.create_event.password_validation')
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					password: ""
				}
			})
	}
		const emailList = emailsList.split(',');
		// const email = emailList.trim();
		isEmailsValid(emailList);
	}, [password, emailsList])
	const handlePassword =(e) =>{
		setPassword(e.target.value);	
	}	
	const validateAddStream = (e) => {
		e.preventDefault();
		if (checkStreamValid()) {
			addStreamingEvent();
		}
		resetStreamData();
	};
	const cancelUpdate = (e) => {
		history.push("/funerals");
	}
	const validateCreateEvent = async (e) => {
		e.preventDefault();
		if(checkDetailsValid()){
			const finalPayload = {
				funeral: {
					first_name: firstName.trim(),
					last_name: lastName.trim(),
					date_of_birth: moment(selectedDateOFBirth).format("YYYY-MM-DD"),
					date_of_death: moment(selectedDateOFDeath).format("YYYY-MM-DD"),
					client_emails: emailsList.trim(),
					deceased_image: uploadedImage,
					bypass_guest_login: bypassGuestLogin,
					streams: streamEventList.map(x=>{
						const streamData = {
							name: x.name.trim(),
							date:  moment(x.date).format("YYYY-MM-DD"),
							stream_start: moment(x.streamStart).format("HH:mm"),
							stream_end: moment(x.streamEnd).format("HH:mm"),
							location: x.locationType === 1? "On-Site": "Off-Site"
						}
						if(x.locationType === 1){
							streamData.room_id = x.room_id
							streamData.location_name = null;
						}else{
							streamData.room_id = null;
							streamData.location_name = x.location;
							//when location type off-ste
						}
						return streamData;
					})
				},
			}
			if(authNeeded){
				finalPayload.funeral.password = password.trim();
			} else {
				finalPayload.funeral.password = null;
			}
			if(state === "edit"){
				finalPayload.funeral.funeralId = funeralId;
				finalPayload.funeral_home_id = funeralDetails.funeralHomeId;
				await updateEventReq(finalPayload ,id, false);
				if(!Object.keys(updateEventResponseFailure).length === 0){
					setEventError(t('pages.create_event.create_error'))
					setCmpltFormValild(true);
				}
				else{
					resetEventData();
					history.push("/funerals");
				}
			}
			else{
				finalPayload.funeral_home_id = homeId;
				await createEventReq(finalPayload, false);
				if(createEventResponseFailure){
					setEventError(`${t('pages.create_event.create_error')}. ${createEventResponseFailure}`);
					setCmpltFormValild(true);
				}
				else{
					resetEventData();
				}	
			}
	}
	};
	const prepareStringForStream = (event) => {
		const streamData =
			event.name +
			" " +
			convertDateToDateOnly(event.date) +
			" from " +
			convertDateToTimeOnly(event.streamStart) +
			" to " +
			convertDateToTimeOnly(event.streamEnd) +
			" • " +
			getLocationName(event.locationType) +
			(event.location ? " • " + event.location : "");
		return streamData;
	};
	const addStreamingEvent = () => {
		const streamData = {
			id: createId(),
			name: streamName.title,
			date: seletedStreamDate.getTime(),
			streamStart: streamStartTime.getTime(),
			streamEnd: streamEndTime.getTime(),
			locationType: streamLocation,
			room_id: onSiteLocationName,
			location:
				streamLocation === 1
					? getOnsiteLocationName(onSiteLocationName)
					: streamLocationName,
		};
		updateStreamEventList((previous) => {
			const newList = [...previous, streamData];
			return newList;
		});
	};
	const deleteStream = (id) => {
		// const index = parseInt(event.target.getAttribute("index"));
		let index = streamEventList.findIndex( el => el.id === id )
		updateStreamEventList((previous) => {
			previous.splice(index, 1);
			return [...previous];
		});
		checkDetailsValid();
	};

	const onDrop = (acceptedFiles) => {
		if (acceptedFiles && acceptedFiles.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const new_img = new Image();

				new_img.onload = function () {
					var width = new_img.naturalWidth,
						heigth = new_img.naturalHeight;
					if (width > 50 && heigth > 50) {
						//size validation for images
						setDroppedFile((previousValue) => {
							return {
								...previousValue,
								fileUploadedPercent: 100,
								showCropOption: true,
								uploadedImageType: acceptedFiles[0].type,
								uploadedImageName: acceptedFiles[0].name,
							};
						});
						setSelectedImage(reader.result);
					} else {
						setDroppedFile((previousValue) => {
							return {
								...previousValue,
								fileDroped: false,
								fileUploading: false,
								invalidFile: true,
								invalidFileMsg: "",
							};
						});
					}
				};
				new_img.src = reader.result;
			});
			reader.readAsDataURL(acceptedFiles[0]);
			setDroppedFile((previousValue) => {
				return {
					...previousValue,
					fileDroped: true,
					fileUploading: true,
					fileUploadedPercent: 10,
					invalidFile: false,
				};
			});
		}
	};
	const onDropRejected = (rejectedFiles) => {
		if (rejectedFiles && rejectedFiles.length > 0) {
			let file = rejectedFiles[0];
			let error = file.errors[0];
			if (error.code === "file-invalid-type") {
				setDroppedFile((previousValue) => {
					return {
						...previousValue,
						invalidFile: true,
						invalidFileMsg: "",
					};
				});
			} else if (error.code === "file-too-large") {
				setDroppedFile((previousValue) => {
					return {
						...previousValue,
						invalidFile: true,
						invalidFileMsg:
						t('pages.create_event.file_validation'),
					};
				});
			} else if (error.code === "file-too-small") {
				setDroppedFile((previousValue) => {
					return {
						...previousValue,
						invalidFile: true,
						invalidFileMsg: "",
					};
				});
			}
		}
	};
	const saveCroppedImage = async () => {
		const checksum = await fileChecksum(croppedImage.blob);
		await props.postImageWithSignedUrl(croppedImage.blob, checksum);
		setDroppedFile((previousValue) => {
			return {
				...previousValue,
				fileUploaded: true,
				savedImage: croppedImage.blob, //need to be send in ajax call
				savedImageUrl: croppedImage.url,
				showCropOption: false,
			};
		});
		setCropSettings(defaultCropSettings);
	};
	const removeUploadedFile = async (closingFlag, e) => {
		setDroppedFile(defaultImguploadData);
		setCropSettings(defaultCropSettings);
		if (state === "edit" && !closingFlag) {
			await removeDeceasedImage(funeralId);
			await props.getFuneralDetails(funeralId);
		}
	};
	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		onDropRejected,
		accept: 'image/jpeg, image/png',
		maxSize: "25000000",
		noClick: true,
		multiple: false,
	});
	const onCropChange = (crop) => {
		setCropSettings(crop);
	};
	const onImageLoaded = (image) => {
		setImageRef(image);
		const width = Math.min(Math.max(image.width, image.height), 350);
		setCropSettings((prev) => {
			return { ...prev, width: width };
		});
		return false;
	};
	const closeImageCrop = () => {
		const closingFlag = true;
		removeUploadedFile(closingFlag);
	};
	const onCropComplete = (crop) => {
		setCroppedImage((prev) => {
			return {
				...prev,
				blob: undefined,
				url: "#",
			};
		});
		makeClientCrop(crop);
	};
	const makeClientCrop = (crop) => {
		if (imageRef && crop.width && crop.height) {
			getCroppedImg(
				imageRef,
				crop,
				dropFile.uploadedImageName,
				dropFile.uploadedImageType
			).then((img) => {
				setCroppedImage((prev) => {
					return {
						...prev,
						blob: img.blob,
						url: img.fileUrl,
					};
				});
			});
		}
	};
	const getCroppedImg = (image, crop, fileName, fileType) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty");
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				fileUrl = window.URL.createObjectURL(blob);
				resolve({ blob, fileUrl });
			}, fileType);
		});
	};
	return (
		<div>
			<Header />
			{!isSuccess && (
				<Fragment>
					<section className={CSS.topHeader}>
						<div className={CSS.sectionContianer}>
							<div className={CSS.pageTitle}>
								{state === "edit" ? t('pages.edit_event.title'): t('pages.create_event.create_event_title')}
							</div>
						</div>
					</section>
					<section className={CSS.formWrapper}>
						<div className={CSS.sectionContianer}>
							<div className={CSS.elementRows}>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
											{t('pages.create_event.create_event_home_label')}
										</label>
										<div className="dropdown">
											<div
												className="control"
												onClick={() => setDropdownOpen((prev) => !prev)}>
												<input
													type="text"
													disabled = {state==="edit" || (user.user && user.user?.role==="editor") || (user.user && user.user?.role==="editor_restricted")}
													className={state === "edit" ? "editValue" : "selectedValue"}
													value={funeralHomeName || nameQuery}
													onChange={(event) => {
														setFuneralHomeName("");
														setNameQuery(event.target.value);
													}}
												/>
												{state!== "edit"?
												<div
													className={`arrow ${
														dropdownOpen ? "open" : null
													}`}
												/>: ""}
											</div>
											{state !== "edit" ?
											<div
												className={`options ${
													dropdownOpen ? "open" : null
												}`}>
												{user.user?.role === "admin" || user.user?.role === "super_admin"?
												organizations
													.filter((val) => {
														if (nameQuery === "" || nameQuery.length<3) {
															return val;
														} else if (
															val.name
																.toLocaleLowerCase()
																.includes(
																	nameQuery.toLocaleLowerCase()
																)
														) {
															return val;
														}
													})
													.map((org) => (
														<div
															key={org.id}
															className="option">
															<div disabled className="first-option">{org.name}</div>
															{org.funeralHomes.map((hom) =>(
																<div className="sub-option" key={hom.id}
																onClick={() => {
																	setHomeId(hom.id);
																	setFuneralHomeName(hom.name);
																	// setOrganizationName(org.name);
																	setDropdownOpen(false);
																	if (hom.id && state !=="edit") {
																		getOnsiteData(hom.id);
																	}
																}}>
																	{hom.name}
																</div>
															))}
														</div>
													)): 
													organizations[0]?.funeralHomes?.map((home) => (
														<div className="sub-option" key={home.id}
														onClick={() => {
															setHomeId(home.id);
															setFuneralHomeName(home.name);
															setDropdownOpen(false);
															if (home.id && state !=="edit") {
																getOnsiteData(home.id);
															}
														}}>
															{home.name}
														</div>
													))}
											</div> : ""}
										</div>
									</div>
								</div>
							</div>			
						</div>
					</section>
					<section className={CSS.formWrapper}>
						<div className={CSS.sectionContianer}>
							<span className={CSS.formTitlte}>
							{t('pages.create_event.deceased_title')}
							</span>
							<div className={CSS.elementRows}>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.deceased_fname_label')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}
											variant="outlined"
											value={firstName}
											onChange={(e) => {
												handleFirstName(e);
											}}
										/>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.deceased_lname_label')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}									
											variant="outlined"
											value={lastName}
											onChange={(e) => {
												handleLastName(e);
											}}
										/>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.deceased_dob_label')}
										</label>
										<MuiPickersUtilsProvider
											locale={browserLang === "fr" ? fr : ""}
											utils={DateFnsUtils}
											className={CSS.datePicker}>
											<div
												className={errorStates.selectedDateOFBirth ? `${CSS.inputEleError} ${CSS.size_sm}` : `${CSS.inputEle} ${CSS.size_sm}`}>
												<KeyboardDatePicker
													variant="inline"
													autoOk
													inputVariant="outlined"
													format="MM/dd/yyyy"
													id="date-picker-inline"
													disableFuture="false"
													value={selectedDateOFBirth}
													FormHelperTextProps={{ classes: helperTestClasses }}
													onChange={handleDobChange}
													keyboardIcon={
														<CalendarTodayIcon
															className={
																CSS.calenderIcon
															}
														/>
													}
													KeyboardButtonProps={{
														"aria-label":
															"change date",
													}}
												/>
												<div className={CSS.errorText}>{errorStates.selectedDateOFBirth}</div>
											</div>
										</MuiPickersUtilsProvider>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.deceased_dod_label')}
										</label>
										<MuiPickersUtilsProvider
											locale={browserLang === "fr" ? fr : ""}
											utils={DateFnsUtils}
											className={CSS.datePicker}>
											<div
												className={errorStates.selectedDateOFDeath ? `${CSS.inputEleError} ${CSS.size_sm}` : `${CSS.inputEle} ${CSS.size_sm}`}>
												<KeyboardDatePicker
													disableFuture="true"
													variant="inline"
													autoOk
													inputVariant="outlined"
													format="MM/dd/yyyy"
													FormHelperTextProps={{ classes: helperTestClasses }}
													value={selectedDateOFDeath}
													onChange={handleDodChange}
													id="date-picker-inline"
													keyboardIcon={
														<CalendarTodayIcon
															className={
																CSS.calenderIcon
															}
														/>
													}
													KeyboardButtonProps={{
														"aria-label":
															"change date",
													}}
												/>
												<div className={CSS.errorText}>{errorStates.selectedDateOFDeath}</div>
											</div>
										</MuiPickersUtilsProvider>
									</div>
								</div>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper} style={{ width: "100%" }}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.deceased_photo_label')}
										</label>
										{dropFile.fileUploaded || (
											<div className={CSS.fileDropzoneWrapper}>
												<div {...getRootProps()} className={CSS.fileDropzone}>
													<input {...getInputProps()}/>
													<div className={
															CSS.dropzoneContext + " " + (dropFile.fileUploading
																? CSS.fileUploading
																: "")}>
														<div className={CSS.descriptiveWrapper}>
															<BackupIcon className={CSS.uploadIcon}/>
															<div className={CSS.feedbackText}>
																{dropFile.invalidFile ||
																dropFile.largeSizeFile ? (
																	<span style={{color: "#AE0000",}}>
																		{dropFile.invalidFileMsg
																			? dropFile.invalidFileMsg
																			: "File type is not supported. Try another file"}
																	</span>
																) : (
																	<span>
																		{dropFile.fileUploading
																			? t('pages.create_event.deceased_photo_uploading')
																			: t('pages.create_event.deceased_photo_dnd')}
																	</span>
																)}
															</div>
														</div>
														<div className={CSS.progressBarWrapper}>
															<div className={CSS.progressIndicator}
																style={{width:dropFile.fileUploadedPercent +"%",}}></div>
														</div>
														<div type="button" onClick={open}
															className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}>
															{t('pages.create_event.deceased_photo_upload')}
														</div>
													</div>
												</div>
												<div className={CSS.dropzoneHint}>
													{t('pages.create_event.deceased_photo_hint')}
												</div>
											</div>
										)}
										{dropFile.fileUploaded && (
											<div className={CSS.thumbnailWrapper}>
												<div className={CSS.thumbnailContainer}>
													{dropFile.savedImageUrl &&
														<img src={(state==="edit" && funeralDetails.deceasedImageUrl)? funeralDetails.deceasedImageUrl : dropFile.savedImageUrl}
															className={CSS.thumbnail}
															alt="prof"
														/>
													}
												</div>
												<div className={`${CSS.actionBtn}  ${CSS.primary} ${CSS.removeImgBtn}`}
													onClick={(e) => removeUploadedFile(false, e)}>
													{t('pages.create_event.deceased_photo_remove')}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className={CSS.formWrapper}>
						<div className={CSS.sectionContianer}>
							<span className={CSS.formTitlte}>
							{t('pages.create_event.stream_title')}
							</span>
							<div className={CSS.elementRows}>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_name_label')}
										</label>
										<Autocomplete
											value={streamName}
											onChange={(event, newValue) => {
												if (typeof newValue === 'string') {
												setStreamName({
													title: newValue,
												});
												} else if (newValue && newValue.inputValue) {
												setStreamName({
													title: newValue.inputValue,
												});
												} else {
												setStreamName(newValue);
												}
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params);
												if (params.inputValue !== '') {
												filtered.push({
													inputValue: params.inputValue,
													title: `Create "${params.inputValue}"`,
												});
												}

												return filtered;
											}}
											disableClearable
											disableListWrap
											forcePopupIcon
											handleHomeEndKeys
											id="free-solo-with-text-demo"
											popupIcon={
												<ExpandMoreOutlinedIcon className={CSS.timeIcon}/>
											}
											options={streamingStaticList}
											getOptionLabel={(option) => {
												if (typeof option === 'string') {
												return option;
												}
												if (option.inputValue) {
												return option.inputValue;
												}
												return option.title;
											}}
											renderOption={(option) => option.title}									
											freeSolo
											getOptionDisabled={(option) =>
												option ===
												streamingStaticList[0]
											}
											renderInput={(params) => (
												<TextField {...params}
												className={`${CSS.inputEle} ${CSS.size_m}`}
												variant="outlined" />
											)}
											/>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_date_label')}
										</label>
										<MuiPickersUtilsProvider
											locale={browserLang === "fr" ? fr : ""}
											utils={DateFnsUtils}
											className={CSS.datePicker}>
											<div
												className={`${CSS.inputEle} ${CSS.size_sm}`}>
												<KeyboardDatePicker
													variant="inline"
													format="MM/dd/yyyy"
													autoOk
													allowKeyboardControl={false}
													inputVariant="outlined"
													helperText={""}
													value={seletedStreamDate}
													onChange={
														handleStreamDateChange
													}
													disablePast="false"
													keyboardIcon={
														<CalendarTodayIcon
															className={
																CSS.calenderIcon
															}
														/>
													}
													KeyboardButtonProps={{
														"aria-label":
															"change date",
													}}
												/>
											</div>
										</MuiPickersUtilsProvider>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_start_label')}
										</label>
										<MuiPickersUtilsProvider
											utils={DateFnsUtils}
											className={CSS.datePicker}>
											<div
												className={errorStates.streamStartTime ? `${CSS.inputEleError} ${CSS.size_sm}` : `${CSS.inputEle} ${CSS.size_sm}`}>
												<KeyboardTimePicker
													variant="inline"
													autoOk
													allowKeyboardControl={false}
													minutesStep={15}
													inputVariant="outlined"
													FormHelperTextProps={{ classes: helperTestClasses }}
													value={streamStartTime}
													onChange={
														handleStreamStartTime
													}
													keyboardIcon={
														<AccessTimeIcon
															className={
																CSS.timeIcon
															}
														/>
													}
												/>
												<div className={CSS.errorText}>{errorStates.streamStartTime}</div>
											</div>
										</MuiPickersUtilsProvider>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_end_label')}
										</label>
										<MuiPickersUtilsProvider
											utils={DateFnsUtils}
											className={CSS.datePicker}>
											<div
												className={errorStates.streamEndTime ? `${CSS.inputEleError} ${CSS.size_sm}` : `${CSS.inputEle} ${CSS.size_sm}`}>
												<KeyboardTimePicker
													variant="inline"
													autoOk
													allowKeyboardControl={false}
													minutesStep={15}
													inputVariant="outlined"
													FormHelperTextProps={{ classes: helperTestClasses }}
													InputProps={{ readOnly: !streamStartTime }}
													value={streamEndTime}
													onChange={
														handleStreamEndTime
													}
													keyboardIcon={
														streamStartTime ?
														<AccessTimeIcon
															className={
																CSS.timeIcon
															}
														/> : ""
													}
												/>
												<div className={CSS.errorText}>{errorStates.streamEndTime}</div>
											</div>
										</MuiPickersUtilsProvider>
									</div>
								</div>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_location_label')}
										</label>
										<FormControl
											variant="outlined"
											className={`${CSS.inputEle} ${CSS.size_sm}`}>
											<Select
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												value={streamLocation}
												onChange={
													handleStreamLocationChange
												}
												MenuProps={{
													// style: { maxWidth: 0, maxHeight: 300, position: 'absolute'},
													disableScrollLock: true,
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "left"
													  },
													transformOrigin: {
													vertical: "top",
													horizontal: "left"
													},
													getContentAnchorEl: null,
													MenuListProps: {
														disablePadding: true
													}
												  }}
												// disabled = {onsiteLocationList.length === 0}
												classes={{ root: classes.selectRoot }}										  
												IconComponent={ExpandMoreOutlinedIcon}
												inputProps={{
													classes: {
														root: classes.border,
														icon: classes.icon,
													},
												}}
												>
												{streamingLocationList.map(
													(siteLocation) => {
														return (
															<MenuItem
																key={siteLocation.id}
																classes={{ selected: classes.selected, root: classes.rootMenuItem }}
																value={siteLocation.id}>
																{
																	siteLocation.label
																}
															</MenuItem>
														);
													}
												)}
											</Select>
										</FormControl>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.location_name')}
										</label>
										{streamLocation === 1 ? (
											<FormControl
												variant="outlined"
												className={`${CSS.inputEle} ${CSS.size_m}`}>
												<Select
													labelId="demo-simple-select-outlined-label"
													id="demo-simple-select-outlined"
													value={onSiteLocationName}
													onChange={
														handleOnSiteLocationNameChange
													}
													classes={{ root: classes.selectRoot }}										  
													MenuProps={{
														disableScrollLock: true,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left"
														  },
														getContentAnchorEl: null,
														MenuListProps: {
															disablePadding: true
														}
													  }}
													inputProps={{
														classes: {
															root: classes.border,
															icon: classes.icon,
														},
													}}	
													IconComponent={
														ExpandMoreOutlinedIcon
													}>
													{homeId || (state === "edit") ? onsiteLocationList.map(
														(siteLocation) => {
															return (
																<MenuItem
																	key={
																		siteLocation.id
																	}
																	classes={{ selected: classes.selected, root: classes.rootMenuItem }}
																	value={
																		siteLocation.id
																	}>
																	{
																		siteLocation.name
																	}
																</MenuItem>
															);
														}
													): <MenuItem disabled>Please select a funeral home</MenuItem>}
												</Select>
											</FormControl>
										) : (
											<TextField
												className={`${CSS.inputEle} ${CSS.size_m}`}
												variant="outlined"
												value={streamLocationName}
												onChange={(e) => {
													handleStreamLocationNameChange(e);
												}}
											/>
										)}
									</div>
									<div className={CSS.elementWrapper}>
										<div
											className={`${CSS.actionBtn} ${CSS.primary} ${
												streamValid ? "" : CSS.disabled
											}`}
											onClick={validateAddStream}>
											{t('pages.create_event.stream_add_button')}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className={CSS.streamingLists}>
						<div className={CSS.sectionContianer}>
							<div className={CSS.streamingListWrapper}>
								{streamEventList.map((x, index) => {
									return (
										<div
											className={CSS.streamEvent}
											key={x.id}>
											<div className={CSS.streamingInfo}>
												<div
													className={
														CSS.streamHeader
													}>
													{t('pages.create_event.stream')} {index + 1}{" "}
												</div>
												<div
													className={
														CSS.streamDetails
													}>
													{prepareStringForStream(x)}
												</div>
											</div>
											<div
												className={CSS.deleteStreamIcon}
												onClick={() => deleteStream(x.id)}
												index={index}>
												<DeleteForeverIcon />
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</section>
					<section className={CSS.formWrapper} style={{marginTop : "0px"}}>
						<div className={CSS.sectionContianer}>
							<div className={CSS.elementRows}>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper} style={{marginTop : "0px"}}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_email_list_label')}
										</label>
										<TextField
											className={errorStates.emailsList? `${CSS.inputEleError} ${CSS.size_lg}` : `${CSS.inputEle} ${CSS.size_lg}`}
											variant="outlined"
											value={emailsList}
											onChange={(e) => handleEmailList(e)}
										/>
											{!errorStates.emailsList?
											<div className={CSS.inputHint}>
											{t('pages.create_event.stream_email_list_hint')}
											</div>
											:<div className={CSS.inputHint} style={{color: "#AE0000"}}>{errorStates.emailsList}</div>}
									</div>
									<div
										className={`${CSS.elementWrapper} ${CSS.radioWrapper}`} style={{marginTop : "0px"}}>
										<label className={CSS.elementLabel}>
											Bypass Guest Form
										</label>
										<div className={CSS.cSwitch}>
											<CSwitch
												checked={bypassGuestLogin}
												onChange={handleBypassGuestLoginToggle}
											/>
											<span className={CSS.cSwitchLabel}>
												{bypassGuestLogin ? t('components.stream_password_toggle.password_on') : t('components.stream_password_toggle.password_off')}
											</span>
										</div>
									</div>
									<div
										className={`${CSS.elementWrapper} ${CSS.radioWrapper}`} style={{marginTop : "0px"}}>
										<label className={CSS.elementLabel}>
										{t('pages.create_event.stream_password_label')}
										</label>
										<div className={CSS.cSwitch}>
											<CSwitch
												checked={authNeeded}
												onChange={authToggle}
											/>
											<span className={CSS.cSwitchLabel}>
												{authNeeded ? t('components.stream_password_toggle.password_on') : t('components.stream_password_toggle.password_off')}
											</span>
										</div>
									</div>
									{authNeeded && (
										<div className={CSS.elementWrapper} style={{marginTop : "0px"}}>
											<label className={CSS.elementLabel}>
											{t('pages.create_event.stream_create_password_label')}
											</label>
											<TextField
												className={errorStates.password? `${CSS.inputEleError} ${CSS.size_m}` :`${CSS.inputEle} ${CSS.size_m}`}
												variant="outlined"
												type="password"
												value={password}
												onChange={(e) =>handlePassword(e)}
											/>
												{!errorStates.password?
													<div className={CSS.inputHint}>
													{t('pages.create_event.stream_create_password_hint')}
													</div>:
													<div className={CSS.inputHint} style={{color: "#AE0000"}}>{errorStates.password}</div>}
										</div>
									)}
								</div>
							</div>
						</div>
					</section>
					<section className={CSS.formWrapper}>
						<div className={CSS.sectionContianer}>
							{eventError && 
								<div className={CSS.eventErr}>{eventError}</div>
							}
							<div className={CSS.submitBtnWrapper}>
								{state === "edit" &&
									<button className={`${CSS.actionBtn}  ${CSS.primary}` } onClick={cancelUpdate}>
										{t('pages.edit_event.cancel')}
									</button>
								}
								<button
									className={`${CSS.actionBtn}  ${CSS.warning} ${
										cmpltFormValild ? "" : CSS.disabled
									}`}
									onClick={validateCreateEvent}>
									{state === "edit" ?t('pages.edit_event.save'): t('pages.create_event.create_event_title')}
								</button>
							</div>
						</div>
					</section>
					<Modal open={dropFile.showCropOption}>
						<div className={"modalPopup2Wrapper"} style={{width: "600px"}}>
							<div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
								<div className={"modalPopupTitle"}>
								{t('pages.create_event.deceased_crop_title')}
								</div>
								<div className={"modalPopupCloseBtn"}>
									<CloseIcon
										className={"modalPopupCloseIcon"}
										onClick={closeImageCrop}
									/>
								</div>
							</div>
							<div className={"modalPopupBody"} style={{padding: "0px 24px 24px"}}>
								<div className={CSS.cropImageHint}>
								{t('pages.create_event.deceased_crop_message')}
								</div>
								<div className={CSS.cropImageWrapper}>
									<div className={CSS.cropImageContainer}>
										<ReactCrop
											src={selectedImage}
											crop={cropSettings}
											ruleOfThirds
											onImageLoaded={onImageLoaded}
											onComplete={onCropComplete}
											onChange={onCropChange}
											imageStyle={{
												maxWidth: "350px",
												maxHeight: "350px",
											}}
										/>
									</div>
									<div className={CSS.croppedImageView}>
										<div
											className={
												CSS.croppedImageWrappper
											}>
											{croppedImage.blob && (
												<img
													src={croppedImage.url}
													className={CSS.croppedImage}
													alt="profile"
												/>
											)}
										</div>
										<div
											className={`${CSS.actionBtn} ${CSS.warning} ${
												croppedImage.blob
													? ""
													: CSS.disabled
											}`}
											onClick={saveCroppedImage}>
											{t('pages.create_event.deceased_crop_save')}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</Fragment>
			)}
			{isSuccess && (
				<Fragment>
					<EventSuccessPage />
				</Fragment>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralStreams: state.funeral.funeralStreams,
	funeralHomes: state.funeralHome.funeralHomes,
	funeralDetails: state.funeral.funeralDetails,
	onsiteLocationList: state.createEvent.onsiteRes,
	uploadedImage: state.createEvent.uploadedImage,
	organizations: state.organization.organizations,
	createEventResponse: state.createEvent.createEventResponse,
	updateEventResponse: state.createEvent.updateEventResponse,
	funeralHomeInfo: state.funeralHome.funeralHomeViewing,
	user: state.user.currentUser,
	createEventResponseFailure: state.createEvent.createEventResponseFailure,
	updateEventResponseFailure: state.createEvent.updateEventResponseFailure,
});

const mapDispatchToProps = {
	getFuneralStreams,
	getFuneralDetails,
	getOnsiteData,
	postImageWithSignedUrl,
	createEventReq,
	updateEventReq,
	removeDeceasedImage,
	getOrganizations,
	getFuneralHomes,
	getFuneralHome,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateEventPage);
