import React, { useEffect, useState } from 'react';
import { Progress } from 'semantic-ui-react'
import { saveAs } from 'file-saver';
import axios from '../../utils/axios';
import Header from "../header/Header";
import CSS from "./VideoRequestPage.module.scss";
import { useTranslation } from 'react-i18next';

const ClipDownloadPage = (props) => {
  const [savedFileName, setSavedFileName] = useState('');
  const [downloadStatus, setDownloadStatus] = useState('progress');
  const [downloadPercent, setDownloadPercent] = useState(0);

  const {
		match: {
			params: { download_clip_id: clipId },
		},
	} = props;
	const { t } = useTranslation();
  const getDownloadUrl = async () => {    
    const response = await axios.get(`api/clip_download/${clipId}`);
    const data = response.data;
    setSavedFileName(data.file_name);
    return { url: data.url, fileName: data.file_name };
  }

  useEffect(async () => {
    const downloadData = await getDownloadUrl();
    if (downloadData.url === '') {
      setDownloadStatus('error');
      return;
    }

    const response = await axios({
      url: downloadData.url,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setDownloadPercent(percentCompleted);
      }
    });

    if (response.data) {
      saveAs(response.data, downloadData.fileName);
      setSavedFileName(downloadData.fileName);
      setDownloadStatus('success');
      setDownloadPercent(100);
    } else {
      setDownloadStatus('error');
    }
  }, []);

  return (
      <div>
        <Header simpleView skipOrganizations />
        <div className={CSS.sectionWrapper}>
            <section className={CSS.formWrapper}>
                <span className={CSS.formTitlte}>{t('pages.request_video.clip_downloading')}</span>
                <div>
                    <span className={CSS.formHint}>{t('pages.request_video.stay_on_page')}</span>
                </div>
            </section>
            <section className={CSS.formWrapper}>
              {downloadStatus === 'progress' && <Progress percent={downloadPercent} progress />}
              {downloadStatus === 'success' &&  <Progress percent={100} success>{t('pages.request_video.check_downloads')}{" "} {savedFileName}</Progress>}
              {downloadStatus === 'error' && <Progress percent={downloadPercent} error>{t('pages.request_video.error_downloading')}</Progress>}
            </section>
        </div>
      </div>
  )
}

export default ClipDownloadPage;
