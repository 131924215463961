import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CSS from "./CheckoutPage.module.scss";
import Header from "../header/Header";
import StripeCheckoutForm from "../../components/StripeCheckoutForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from '../../assets/svgs/favicon.ico';
import { useHistory } from "react-router-dom";
import paymentImage from '../../assets/svgs/payment-image.svg';
import { useTranslation } from 'react-i18next';
const CheckoutPage = () => {
	const location = useLocation();
    const history = useHistory();
	const { t } = useTranslation();
	useEffect(() => {
	}, [location]);
	return(
            <div>
                <Header />
                <div className={CSS.paymentForm}>
                    <div className={CSS.productData}>
                        <div className={CSS.toBack}>
                            <div>
                                <ArrowBackIcon 
                                    style={{
                                        color: "#00667E",
                                        marginRight: 12,
                                        marginTop: 4,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => history.push('/streamcredits')}
                                />
                            </div>
                            <div className={CSS.logo}>
                                <img src={logo} alt="logo" />
                            </div>
                            <div className={CSS.backText}>
                            {t('pages.streaming_credits.back')}
                            </div>
                        </div>
                        <div className={CSS.creditName} style={{marginTop: 24}}>
							<div className={CSS.creditNumber}>
								{location.state.creditData.credits}
							</div>
							<div
								className={CSS.creditNumberHint}
								style={{ marginLeft: 4 }}>
								{" "}
								{location.state.creditData.credits === 1 ? t('pages.streaming_credits.credit') : t('pages.streaming_credits.credits')}
							</div>
						</div>
                        <div className={CSS.creditHint} style={{marginTop: 4}}>
                        {t('pages.streaming_credits.get')} {location.state.creditData.credits} {t('pages.streaming_credits.stream_for')} {"$"} {location.state.creditData.price} {" "}{t('pages.streaming_credits.per_stream')}
						</div>
                        <div className={CSS.creditPrice} style={{marginTop: 16}}>
							{"$"}
							{location.state.creditData.total}
						</div>
                        <div className={CSS.creditPriceHint} style={{marginTop: 4}}>
                        {t('pages.streaming_credits.upon_payment')} {location.state.creditData.credits} {t('pages.streaming_credits.credits')}.
                        </div>
                        <div className={CSS.paymentImg}>
                            <img src={paymentImage} alt=""/>
                        </div>
                        <div className={CSS.stripeDocLinks}>
                            
                        </div>
                    </div>
                    <div className={CSS.checkoutForm}>
                        <StripeCheckoutForm data={location.state.creditData}/>
                    </div>
                </div>
            </div>
        )
};

export default CheckoutPage;
