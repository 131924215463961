import React, {Fragment, useEffect, useState} from 'react';
import Header from "../header/Header";
import CSS from "./OrganizationManagementPage.module.scss";
import PageviewIcon from '@material-ui/icons/Pageview';
import { connect } from "react-redux";
import { getOrganizations } from "../../redux/organization/actions";
import CSS1 from "../../components/Table.module.css";
import {
	Icon,
	Table,
	Segment,
	Input,
	Dropdown
} from "semantic-ui-react";
import {
	useTable,
	usePagination,
	useSortBy,
	useExpanded,
	useGlobalFilter,
	useFilters, 
} from "react-table";
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const OrganizationManagementPage = ({organizations, getOrganizations, user, history}) => {
	useEffect(()=>{
		getOrganizations();
	},[])
	useEffect(()=>{
	},[organizations]);
	const { t } = useTranslation();
	const columns = [
		{
			Header: t('pages.admin_create_org.table.org_column'),
			accessor: (org)=>{
				return (<div className={CSS.topHeaderData}>{org.name}</div>);
			},
			minWidth:180,
			width:180,
			borderRadius: 0,
			sortType: (rowA, rowB) => {
				if (
					rowA.original.name.toLocaleLowerCase() <
					rowB.original.name.toLocaleLowerCase()
				)
					return -1;
				if (
					rowA.original.name.toLocaleLowerCase() >
					rowB.original.name.toLocaleLowerCase()
				)
					return 1;
				return 0;
			},
		},
		{
			Header: t('pages.admin_create_org.table.homes_column'),
			accessor: (org)=>{
				return  (org.funeralHomes && org.funeralHomes.length > 0 && org.funeralHomes.map(home=>{
					return (<div key={home.id} className={CSS.topHeaderData}>{home.name}</div>)
				}))
			},
			minWidth:180,
			width:180,
			disableSortBy: true,
			disableGlobalFilter: true,
		},	
		{
			Header: t('pages.admin_create_org.table.email_column'),
			accessor: (org)=>{
				return  (org.funeralHomes && org.funeralHomes.length > 0 && org.funeralHomes.map(home=>{
					return (<div key={home.id} className={CSS.topHeaderData}>{home.contactEmail}</div>)
				}))
			},
			minWidth:180,
			width:180,
			disableSortBy: true,
			disableGlobalFilter: true,
		},
		{
			Header: t('pages.admin_create_org.table.users_column'),
			accessor: (org)=>{
				return  (org.funeralHomes && org.funeralHomes.length > 0 && org.funeralHomes.map(home=>{
					return (<div key={home.id} className={CSS.topHeaderData}>{home.users.length}</div>)
				}))
			},
			width:60,
		},
		{
			id: 'editOrg',
			Header: () => {return <ArrowDropDownCircleOutlinedIcon/>},
			accessor: (org) => {
				return (<PageviewIcon className = {CSS.pageViewIc} onClick={() => history.push(`/orgmanagement/${org.id}/view`)}/>)
			},
			width:40,
			borderRadius: 0,
			disableSortBy: true,
			disableGlobalFilter: true,
			// style:{
			// 	textAlign: 'right'
			// }
		},
	];
    return (
        <div>
			<Header/>
            <div className={CSS.bodyWrapper}>
				<section className={CSS.formWrapper}>
					<span className={CSS.pageTitlte}>
					{t('pages.admin_create_org.orgManagement_title')}
					</span>
					<Segment style={{padding: "0px", border: "1px solid #C4D5D9", borderRadius: "8px", overflow: "hidden"}}>
						{organizations && <OrgTable data={organizations} columns={columns} user={user}></OrgTable> }
					</Segment>
				</section>
			</div>
        </div>
    )
}

const OrgFilter = ({globalFilter, setGlobalFilter}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(globalFilter);
	const [filterData,setFilterData] = useState({
		filterValue: "",
	});
	const onChange = (value) => {
		setFilterData(prev => {
			const newFilter = {
				...prev,
				filterValue: value
			}
			setGlobalFilter(newFilter);
			return newFilter;
		})
	}

	return 	(<Input
			className={CSS.filterWrapper}
			iconPosition="left"
			placeholder={t('pages.events_calendar.search_placeholder')}>
				<Icon name="search" style={{color: "#00667E"}}/>
				<input
					className={CSS.filterInput}
					value={value || ""}
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
				/>
			</Input>)
}
const OrgTable = ({columns,data,user}) => {
	const history = useHistory();
	const [hoveredRow, setHoveredRow] = useState(null);
	useEffect(() => {
	}, [hoveredRow]);
	const { t } = useTranslation();
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		setPageSize,
		state,
		setGlobalFilter,
		setFilter,
	} = useTable(
		{
			columns,
			data,
			globalFilter: (rows, columnIds, globalFilterData) => {
				return rows.filter((row) => {
				const event = row.original;
         		let {filterValue} = globalFilterData;
				if(!(filterValue && filterValue.trim())){
					return true;
				}
				filterValue = filterValue.trim().toLowerCase();
				return (
					event.name
						.toLowerCase()
						.includes(filterValue) ||
					`${event.name.toLowerCase()}`.includes(
						filterValue
					)
				);
				});
			},
			initialState: { pageIndex: 0},
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);
	return (
		<Fragment>
			<div className={CSS.orgTableHeader} style={{margin: "16px 16px 0px 16px"}}> 
				<OrgFilter globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}/>
				{user.user && (user.user?.role === "admin" || user.user?.role === "super_admin") &&
				<button className={`${CSS.actionBtn} ${CSS.warning}`} onClick={() => history.push('/organization/add')}>{t('pages.admin_create_org.add_org.addOrg_title')}</button>}
			</div>
			<Table className={CSS.table} structured {...getTableProps()} style={{border : "none"}}>
				<Table.Header>
				{headerGroups.map((headerGroup) => (
					<Table.Row {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<Table.HeaderCell
								className={CSS1.headerCell}
								{...column.getHeaderProps({
									...column.getSortByToggleProps(),
									style: {minWidth: column.minWidth, width: column.width, borderRadius: column.borderRadius}
								})}>
								{column.render("Header")}
								<span>
									{column.isSorted ? (
										column.isSortedDesc ? (
											<Icon name="caret down" />
										) : (
											<Icon name="caret up" />
										)
									) : (
										""
									)}
								</span>
							</Table.HeaderCell>
						))}
					</Table.Row>
				))}
			</Table.Header>
				<Table.Body {...getTableBodyProps()}>
					{page.length === 0 &&
						(state.filters.length > 0 || state.globalFilter) && (
							<Table.Row>
								<Table.Cell></Table.Cell>
								<Table.Cell style={{
										padding: "50px 0 50px 125px",
										fontStyle: "italic",
									}}>{t("pages.admin_create_org.table.filter_no_results")}</Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
						)}
					{page.map((row, i) => {
						prepareRow(row);
						const rowProps = row.getRowProps();
						return (
							<React.Fragment key={rowProps.key}>
								<Table.Row {...rowProps}
								onMouseEnter={()=> setHoveredRow(rowProps.key)}
								onMouseLeave={() => setHoveredRow(null)}
								style={{ background: rowProps.key === hoveredRow ? '#D6FFD6' : 'white', }}>
									{row.cells.map((cell) => {
										return (
											<Table.Cell
												{...cell.getCellProps()}>
												{cell.render("Cell")}
											</Table.Cell>
										);
									})}
								</Table.Row>
							</React.Fragment>
						);
					})}
				</Table.Body>
			</Table>
			<div className={CSS.pagination} style={{borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
				<div className={CSS.pageDropdown}>
					{t('pages.admin_events_table.footer_rows')}:
					<Dropdown
						value={state.pageSize}
						options={[
							{ key: 1, text: "10", value: 10 },
							{ key: 2, text: "20", value: 20 },
							{ key: 3, text: "30", value: 30 },
							{ key: 4, text: "40", value: 40 },
							{ key: 5, text: "50", value: 50 },
						]}
						onChange={(e, data) => {
							setPageSize(Number(data.value));
						}}
					/>
				</div>
				<div className={CSS.pageMover}>
					<Icon
						name="arrow left"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					/>{" "}
					<span>
					{t("pages.admin_create_org.table.page")}{" "}
						<strong>
							{state.pageIndex + 1} {t('pages.admin_events_table.footer_pages')} {pageOptions.length}
						</strong>{" "}
					</span>{" "}
					<Icon
						name="arrow right"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					/>
				</div>
			</div>
		</Fragment>	
	)
}

const mapStateToProps = (state) => ({
	organizations: state.organization.organizations,
	user: state.user.currentUser,
});

const mapDispatchToProps = {
	getOrganizations,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationManagementPage);
