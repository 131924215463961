import axios from '../../utils/axios';
import camelcaseKeys from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";

const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_REQUEST_SUCCESS = 'LOGIN_USER_REQUEST_SUCCESS';
export const LOGIN_USER_REQUEST_FAILURE = 'LOGIN_USER_REQUEST_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

export const RESEND_USER_INVITE_REQUEST = 'RESEND_USER_INVITE_REQUEST';
export const RESEND_USER_INVITE_SUCCESS = 'RESEND_USER_INVITE_SUCCESS';
export const RESEND_USER_INVITE_FAILURE = 'RESEND_USER_INVITE_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const RESET_USER_REQUEST = 'RESET_USER_REQUEST';
export const RESET_USER_SUCCESS = 'RESET_USER_SUCCESS';
export const RESET_USER_FAILURE = 'RESET_USER_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const INITIAL_ACTION = "INITIAL_ACTION";

export const LOADING_ACTION = "LOADING_ACTION";

export const loginUser = (email, password) => {
  return dispatch => {
    dispatch({ type: LOGIN_USER_REQUEST });
    return axios.post('api/users/login', {
      user: {
        email,
        password
      }
    })
    .then(response => {
      const token = camelcaseKeys(response.data).authToken;
      localStorage.setItem("token", token);
      dispatch(loginUserSuccess(token));
      dispatch(getCurrentUser());
      return response;
    })
    .catch(error => {
      localStorage.removeItem('token');
      if (error.response && error.response.status === 401) {
        dispatch(loginUserFailure({ message: "Wrong email or password" }));
      } else {
        dispatch(loginUserFailure(error));
      }
      return error;
    });
  }
};

export const inviteUser = (payload) => {
  return dispatch => {
    dispatch({ type: INVITE_USER_REQUEST });
    
    return axios.post('api/users/invite_user', snakeCaseKeys(payload), 
    {
      headers: {
        Authorization : `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const userInvite = camelcaseKeys(response.data).userInvite;
      dispatch(inviteUserSuccess(userInvite));
    })
    .catch(error => {
      if (error.response) {
        dispatch(inviteUserFailure(error.response.data.message));
      } else {
        dispatch(inviteUserFailure("Something went wrong"));
      }
    }
    );
  }
};

const inviteUserSuccess = (userInvite) => {
  return {
    type: INVITE_USER_SUCCESS,
    userInvite,
  }
};

const inviteUserFailure = (message) => {
  return {
    type: INVITE_USER_FAILURE,
    message,
  }
};

export const resendUserInvite = (id) => {
  return dispatch => {
    dispatch({ type: RESEND_USER_INVITE_REQUEST });
    return axios.post(`api/user_invites/${id}`, null, 
    {
      headers: {
        Authorization : `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
        const userInvite = camelcaseKeys(response.data).userInvite;
        dispatch(resendUserInviteSuccess(userInvite));
    })
    .catch(error => {
      if (error.response) {
        dispatch(resendUserInviteFailure(error.response.data.message));
      } else {
        dispatch(resendUserInviteFailure("Something went wrong"));
      }
    }
    );
  }
};

const resendUserInviteSuccess = (resendUserInvite) => {
  return {
    type: RESEND_USER_INVITE_SUCCESS,
    resendUserInvite,
  }
};

const resendUserInviteFailure = (message) => {
  return {
    type: RESEND_USER_INVITE_FAILURE,
    message,
  }
};

export const updateUser = (payload, id) => {
  return dispatch => {
    dispatch({ type: UPDATE_USER_REQUEST });
    
    return axios.put(`api/users/${id}`, snakeCaseKeys(payload), 
    {
      headers: {
        Authorization : `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const userInvite = camelcaseKeys(response.data);
      dispatch(updateUserSuccess(userInvite));
    })
    .catch(error => {
      if (error.response) {
        dispatch(updateUserFailure(error.response.data.message));
      } else {
        dispatch(updateUserFailure("Something went wrong"));
      }
    }
    );
  }
};

const updateUserSuccess = (userUpdate) => {
  return { 
    type: UPDATE_USER_SUCCESS,
    userUpdate,
  }
};

const updateUserFailure = (message) => {
  return {
    type: UPDATE_USER_FAILURE,
    message,
  }
};

export const userDelete = (id) => {
  return dispatch => {
    dispatch({ type: DELETE_USER_REQUEST });
    
    return axios.delete(`api/users/${id}`, 
    {
      headers: {
        Authorization : `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      dispatch(deleteUserSuccess(id));
    })
    .catch(error => {
      if (error.response) {
        dispatch(deleteUserFailure(error.response.data.message));
      } else {
        dispatch(deleteUserFailure("Something went wrong"));
      }
    }
    );
  }
};

const deleteUserSuccess = (userID) => {
  return { 
    type: DELETE_USER_SUCCESS,
    userID,
  }
};

const deleteUserFailure = (message) => {
  return {
    type: DELETE_USER_FAILURE,
    message,
  }
};

export const registerUser = (token, password) => {
  return dispatch => {
    dispatch({ type: REGISTER_USER_REQUEST });

    const data = snakeCaseKeys({ 
      token,
      password
    });
    
    return axios.post('api/users/finish_registration', data, 
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const user = camelcaseKeys(response.data).user;
      dispatch(registerUserSuccess(user));
      return response;
    })
    .catch(error => {
      if (error.response) {
        dispatch(registerUserFailure(error.response.data.message));
      } else {
        dispatch(registerUserFailure("Something went wrong"));
      }
      return error;
    }
    );
  }
};

const registerUserSuccess = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    user,
  }
};

const registerUserFailure = (message) => {
  return {
    type: REGISTER_USER_FAILURE,
    message,
  }
};

export const getCurrentUser = () => {
  return dispatch => {
    dispatch({ type: GET_USER_REQUEST });
    
    return axios.get('api/users/get_current_user', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      const user = camelcaseKeys(response.data).currentUser;
      dispatch(getCurrentUserSuccess(user));
    })
    .catch(error => {
      dispatch(getCurrentUserFailure(error));
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
    });
  }
};

const getCurrentUserSuccess = (user) => {
  return {
    type: GET_USER_SUCCESS,
    user,
  }
};

const getCurrentUserFailure = (error) => {
  return {
    type: GET_USER_FAILURE,
    error,
  }
};

const loginUserSuccess = (token) => {
  return {
    type: LOGIN_USER_REQUEST_SUCCESS,
    token,
  }
};

const loginUserFailure = (error) => {
  return {
    type: LOGIN_USER_REQUEST_FAILURE,
    error,
  }
};

export const resetUser = (email) => {
  return dispatch => {
    dispatch({ type: RESET_USER_REQUEST });

    const data = snakeCaseKeys({ 
      email,
    });
    
    return axios.post('api/users/reset_password', data, 
    {
      headers: {}
    })
    .then(response => {
      dispatch(resetUserSuccess());
      return response;
    })
    .catch(error => {
      if (error.response) {
        dispatch(resetUserFailure(error.response.data.message));
      } else {
        dispatch(resetUserFailure("Something went wrong"));
      }
      return error;
    }
    );
  }
};

const resetUserSuccess = () => {
  return {
    type: RESET_USER_SUCCESS,
  }
};

const resetUserFailure = (message) => {
  return {
    type: RESET_USER_FAILURE,
    message,
  }
};

export const resetPassword = (token, password) => {
  return dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const data = snakeCaseKeys({ 
      token,
      password
    });
    
    return axios.post('api/users/finish_reset_password', data, 
    {
      headers: {}
    })
    .then(response => {
      const user = camelcaseKeys(response.data).user;
      dispatch(resetPasswordSuccess(user));
      return response;
    })
    .catch(error => {
      if (error.response) {
        dispatch(resetPasswordFailure(error.response.data.message));
      } else {
        dispatch(resetPasswordFailure("Something went wrong"));
      }
      return error;
    });
  }
};

const resetPasswordSuccess = (user) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user,
  }
};

const resetPasswordFailure = (message) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    message,
  }
};

export function initialStateVal(){
	return{
		type: INITIAL_ACTION,
	}
}

export function loadingAction(value){
	return{
		type: LOADING_ACTION,
		payload: value,
	}
}