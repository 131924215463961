import React, { useState, useEffect } from "react";
import { Icon, Input, Button, Popup } from "semantic-ui-react";
import moment from "moment";
import "../userManagement/userManagement.css";
import { useTranslation } from "react-i18next";

const SearchPopup = ({funerals}) => {
	const [searchValue, setSearchValue] = useState("");
	const [searchEvents, setSearchEvents] = useState([]);
	const [hoveredRow, setHoveredRow] = useState(false);
	const [buttonBgColor, setButtonBgColor] = useState(false)
    useEffect(() => {

	}, [searchEvents, buttonBgColor]);
    useEffect(() => {
		var x = [];
		if (searchValue.length > 2) {
			funerals.map(funeral => {
				if (funeral.lastName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
					x.push(funeral)
				}
			})
		}
		const y = x.map((f) => f.streams.map(s => s));
		const z = y.flat();
		setSearchEvents(z);
	}, [searchValue]);
	const { t } = useTranslation();
	const handlePopupClose = () => {
		setButtonBgColor(false)
	  }
	  const handlePopupOpen = () => {
		setButtonBgColor(true)
	  }
    const deceasedEvent = (e) => {
		setSearchValue(e.target.value);
	}
    const deceasedLastName = (id) => {
		return funerals.map(funeral => {
			if (funeral.id === id) {
				return funeral.lastName;
			}
		})
	}
	const convertDateToOnlyDay = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("ddd");
		}
		return "";
	};
	const convertDateToOnlyMonth = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("MMM");
		}
		return "";
	};
	const convertDateToOnlyDate = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("DD");
		}
		return "";
	};
	const convertDateToOnlyTime = (unformattedDate) => {
		if (unformattedDate) {
			return moment(unformattedDate).format("h:mm A");
		}
		return "";
	};
	return (
		<Popup
			style={{ zIndex: "10" }}
			size="small"
			position="bottom right"
			trigger={
				<Button
					className="btn"
					style={{
						border: buttonBgColor ? "1px solid #00667E" : "none",
						boxShadow: buttonBgColor ? "0px 0px 4px 1px rgba(0, 102, 126, 0.15)" : "none",
						backgroundColor: "white",
						color: "#00667E",
						maxWidth: "220px",
						fontFamily: 'Bitter, serif', 
						fontStyle: 'normal', 
						fontWeight: 400, 
						fontSize: 16,
						padding: '8px 8px',
						marginRight: 16
					}}
					icon="search"
				/>
			}
			content={
				<div>
					<div
						style={{
							fontFamily: "Bitter,serif",
							fontStyle: "normal",
							fontWeight: 500,
							fontSize: 16,
							color: "#001A20",
						}}>
						{t("pages.events_calendar.search_placeholder")}
					</div>
					<Input iconPosition="left" placeholder={t("pages.events_calendar.search_placeholder")}>
						<Icon name="search" style={{ color: "#00667E" }} />
						<input
							style={{
								borderRadius: 18,
								border: "1px solid #001A20",
								height: 32,
								width: 250,
							}}
							value={searchValue}
							onChange={(e) => {
								deceasedEvent(e);
							}}
						/>
					</Input>
					{searchEvents.length > 0 && (
						<div>
							{searchEvents.map((eve) => {
								return (
									<div key={eve.id} onMouseEnter={()=> setHoveredRow(eve.id)}
									onMouseLeave={() => setHoveredRow(false)}
                                    style={{ backgroundColor : hoveredRow === eve.id ? "#E1EAEC" : "white", borderRadius:4, padding: "4px 8px", marginTop: 16 }}>
										<div
											style={{
												fontFamily: "Bitter, serif",
												fontStyle: "normal",
												fontWeight: 500,
												fontSize: 16,
												color: "#000000",
											}}>
											{deceasedLastName(eve.funeralId)}
											{" - "} {eve.name}
										</div>
										<div
											style={{
												fontFamily: "Bitter, serif",
												fontStyle: "normal",
												fontWeight: 300,
												fontSize: 14,
												color: "#001A20",
												marginTop: 4,
											}}>
											{convertDateToOnlyDay(eve.streamStart)}{" "}
											{convertDateToOnlyMonth(eve.streamStart)}{". "}
											{convertDateToOnlyDate(eve.streamStart)}{", "}
											{convertDateToOnlyTime(eve.streamStart)}{" - "}
											{convertDateToOnlyTime(eve.streamEnd)}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			}
			on="click"
			onClose={handlePopupClose}
            onOpen={handlePopupOpen}
		/>
	);
};

export default SearchPopup;
