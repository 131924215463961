import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { fileChecksum } from "../../utils/checksum";
import createId from '../../utils/uuid';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import {
	createFuneralHome,
	postImageWithSignedUrl,
	updateFuneralHome
} from "../../redux/funeralHome/actions";
import Header from "../header/Header";
import BackupIcon from "@material-ui/icons/Backup";
import Modal from "@material-ui/core/Modal";
import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import CloseIcon from "@material-ui/icons/Close";
import CSS from "./OrganizationManagementAddAndEditPage.module.scss";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PlacesAutocomplete, {
	geocodeByPlaceId,
} from "react-places-autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getOrganization, updateOrgReq, createOrgReq, deleteOrganization } from "../../redux/organization/actions";
import { deleteFuneralHome, postTestStreamData, getFuneralTestStream, getFuneralHomes, removeLogo } from '../../redux/funeralHome/actions';
import validator from 'validator';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const LIVESTREAM_PROVIDER = "livestream_provider";
const AWS_PROVIDER = "aws_provider";
const SELF_MANAGED_PROVIDER = "self_managed_provider";
const DACAST_PROVIDER = "dacast_provider";

const STREAM_PROVIDERS = [
	{
    value: DACAST_PROVIDER,
    label: 'Dacast',
  },
  {
    value: LIVESTREAM_PROVIDER,
    label: 'Livestream',
  },
  {
    value: AWS_PROVIDER,
    label: 'AWS',
  },
  {
    value: SELF_MANAGED_PROVIDER,
    label: 'Self Managed (OBS or other stream providers)',
  },
];

const useStyles = makeStyles((theme) => ({
	selectRoot: {
		'&:focus':{
		backgroundColor:'white'
		}
	},
	selected:{
		background: 'linear-gradient(left, #00667E 0%, #00667E 2%, #E1EAEC 2%, #E1EAEC 100%)',
	},
	rootMenuItem: {
	  "&$selected": {
		backgroundColor: "white",
		  "&:hover": {
			backgroundColor: "white"
		   }
		},
	  '&:hover':{
		backgroundColor:'#C4D5D9'
	  },
	},
	icon: {
		fill: '#00667E',
	 },
  }));

const FuneralCreatePage = (props) => {
	const {
		match: {
			params: { id: orgId },
		},
	} = props;
	const classes = useStyles();
	const { t } = useTranslation();
    const location = useLocation();
    const {organization, funeralHomes, getOrganization, getFuneralHomes, createOrgReq, updateFuneralHome, createFuneralHome, deleteOrganization, deleteFuneralHome, history, user, removeLogo} = props;
	const [pageState, setPageState] = useState(orgId ? "edit" : "add");
	const { uploadedImage } = props;
	const [organizationName, setOrganizationName] = useState("");
	const [funeralHomeName, setFuneralHomeName] = useState("");
	const [funeralContactEmail, setFuneralContactEmail] = useState("");
	const [funeralPhoneContact, setFuneralPhoneContact] = useState("");
	const [funeralSiteUrl, setFuneralSiteUrl] = useState("");
	const [flowerShopLink, setFlowerShopLink] = useState("");
	const [roomName, setRoomName] = useState("");
	const [streamProvider, setStreamProvider] = useState("");
	const [awsChannelId, setAwsChannelId] = useState("");
	const [awsEndpointId, setAwsEndpointId] = useState("");
	const [awsCloudUrl, setAwsCloudUrl] = useState("");
	const [cameraIp, setCameraIp] = useState("");
	const [rtmpUrl, setRtmpUrl] = useState("");
	const [ec2Host, setEc2Host] = useState("");
	const [roomValid, setRoomValid] = useState(false);
	const [roomEventList, updateRoomEventList] = useState([]);
	const [mailingAddressData, setMailingAddressData] = useState({
		mailingAddress: "",
		mailingAddressAptUnit: "",
		mailingAddressCity: "",
		mailingAddressProvince: "",
		mailingAddressPostalCode: "",
		mailingAddressCountry: "",
	});
	const [billingAddressData, setBillingAddressData] = useState({
		billingAddress: "",
		billingAddressAptUnit: "",
		billingAddressCity: "",
		billingAddressProvince: "",
		billingAddressPostalCode: "",
		billingAddressCountry: "",
	});
	const [check, setCheck] = useState(false);
	const defaultCropSettings = {
		unit: "px",
		aspect: 1,
		minWidth: 50,
		width: 100,
		top: 0,
		left: 0,
		keepSelection: false,
	};
	const [cropSettings, setCropSettings] = useState(defaultCropSettings);
	const [croppedImage, setCroppedImage] = useState({ blob: "", url: "#" });
	let fileUrl;
	const defaultImguploadData = {
		fileDroped: false,
		fileUploading: false,
		fileUploadedPercent: 0,
		fileUploaded: false,
		invalidFile: false,
		invalidFileMsg: "",
		savedImage: undefined,
		savedImageUrl: "#",
		showCropOption: false,
		uploadedImageType: "",
		uploadedImageName: "",
	};
	const [imageRef, setImageRef] = useState(undefined);
	const [dropFile, setDroppedFile] = useState(defaultImguploadData);
	const [selectedImage, setSelectedImage] = useState(undefined);
	const [cmpltFormValild, setCmpltFormValild] = useState(false);
	const [homeFormValid, setHomeFormValid] = useState(false);
	const [expandedElement, setExpandedElement] =  useState({
		id:0,
		edit: false
	});
	const [showForm, setShowForm] = useState(!orgId);
	const [homeList, setHomeList] = useState([]);
    const [currentOrgData, setCurrentOrgData] = useState(null);
	const [deleteConfirmationModal,setDeleteConfirmationModal ] = useState({
        state: "close",
    });
	const [errorStates, setErrorStates] = useState({
		organizationName: "",
		funeralHomeName: "",
		funeralContactEmail: "",
		funeralPhoneContact: "",
		funeralSiteUrl: "",
		funeralLiveStreamEmail: "",
		flowerShopLink: "",
	});
	const [logoUrl, setLogoUrl] = useState("");
	const [doneValid, setDoneValid] = useState(false);
	const [logoHomeId, setLogoHomeId] = useState("");
	useEffect(() => {
		if(orgId && homeList.length>0){
			// editHome(location.state.detail)
		}
	}, [location, logoUrl, logoHomeId, check]);
	useEffect(()=>{
		if(orgId){
			getOrganization(orgId);
			getFuneralHomes();
		}
    },[]);
	useEffect(()=>{
        if(organization && orgId){
			if(user.user?.role === "admin" || user.user?.role === "super_admin"){
				setOrganizationName(organization.name);
				setHomeList([...organization.funeralHomes]);
				setCurrentOrgData({...organization});
			}
			else if(user.user?.role === "editor" || user.user?.role === "editor_restricted"){
				setOrganizationName(organization.name);
            	setHomeList([...funeralHomes]);
			}
        }
    },[organization, funeralHomes]);

	const handleOrganizationName = (e) => {
		setOrganizationName(e.target.value);
	};
	const handleFuneralHomeName = (e) => {
		setFuneralHomeName(e.target.value);
	};
	useEffect(() => {
		if(validator.isEmail(funeralContactEmail) || !funeralContactEmail){
			setErrorStates(prev=>{
				return {
					...prev,
					funeralContactEmail:""
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					funeralContactEmail: t("pages.admin_create_org.add_org.email_validation")
				}
			})
		}
		if (validator.isURL(funeralSiteUrl) || !funeralSiteUrl) {
			setErrorStates(prev=>{
				return {
					...prev,
					funeralSiteUrl:""
				}
			})
		  } else {
			setErrorStates(prev=>{
				return {
					...prev,
					funeralSiteUrl: t("pages.admin_create_org.add_org.url_validation")
				}
			})
		  }
		  if (validator.isURL(flowerShopLink) || !flowerShopLink) {
			setErrorStates(prev=>{
				return {
					...prev,
					flowerShopLink:""
				}
			})
		  } else {
			setErrorStates(prev=>{
				return {
					...prev,
					flowerShopLink: t("pages.admin_create_org.add_org.url_validation")
				}
			})
		  }
	}, [funeralContactEmail, funeralSiteUrl, flowerShopLink]);
	const handleFuneralContactEmail = (e) => {
		setFuneralContactEmail(e.target.value);
	};
	const handleFuneralPhoneContact = (e) => {
		setFuneralPhoneContact(e.target.value);
	};
	const handleFuneralSiteUrl = (e) => {
		setFuneralSiteUrl(e.target.value);
	};
	const handleFlowerShopLink = (e) => {
		setFlowerShopLink(e.target.value);
	};
	const handleStreamProviderChange = (e) => {
		setStreamProvider(e.target.value);
	};
	const handleRoomName = (e) => {
		setRoomName(e.target.value);
	};
	useEffect(() => {
		checkRoomValid();
	}, [
		roomName,
		awsChannelId,
		awsEndpointId,
		awsCloudUrl,
		cameraIp,
		rtmpUrl,
		ec2Host
	]);
	const checkRoomValid = () => {
		if (STREAM_PROVIDERS.find(provider => provider.value === streamProvider)) {
			const roomValid = roomName;
			setRoomValid(roomValid);
		}
	};
	const validateRoomDetails = (e) => {
		e.preventDefault();
		if(roomValid) {
			addRoomData();
		}
		resetRoomData();
	};
	const addRoomData = () => {
		const roomData = {
			uuid: createId(),
			name: roomName,
			channel: awsChannelId,
			endpoint: awsEndpointId,
			cloudUrl: awsCloudUrl,
			ip: cameraIp,
			rtmp: rtmpUrl,
			instance: ec2Host,
			provider: streamProvider
		};
		updateRoomEventList((previous) => {
			const newList = [...previous, roomData];
			return newList;
		});
	};
	const resetRoomData = () => {
		setRoomName("");
		setAwsChannelId("");
		setAwsEndpointId("");
		setAwsCloudUrl("");
		setEc2Host("");
		setRtmpUrl("");
		setCameraIp("");
	};
	const prepareStringForRoom = (event) => {
		if(event.channel && event.endpoint){
			const funeralRoomdata =
			event.name +
			" • " + 
			event.provider
		return funeralRoomdata;
		}
		else{
			const funeralRoomdata =
			event.name +
			" • " + 
			event.provider
		return funeralRoomdata;
		}
	};
	const deleteStream = (event) => {
		const index = parseInt(event.target.getAttribute("index"));
		updateRoomEventList((previous) => {
			previous.splice(index, 1);
			return [...previous];
		});
		checkDetailsValid();
	};

	//mailing address
	const handleSelectMailingData = async (value, placeId) => {
		const places = await geocodeByPlaceId(placeId);
		const placeDetails = places[0];
		let address1 = "",
			postcode = "";
		for (const component of placeDetails.address_components) {
			const componentType = component.types[0];
			switch (componentType) {
				case "street_number": {
					address1 = `${component.long_name} ${address1}`;
					break;
				}
				case "route": {
					address1 += component.short_name;
					break;
				}
				case "postal_code": {
					postcode = `${component.long_name}${postcode}`;
					break;
				}
				case "postal_code_suffix": {
					postcode = `${postcode}-${component.long_name}`;
					break;
				}
				case "locality":
					setMailingAddressData((prev) => {
						return {
							...prev,
							mailingAddressCity: component.long_name,
						};
					});
					break;

				case "administrative_area_level_1": {
					setMailingAddressData((prev) => {
						return {
							...prev,
							mailingAddressProvince: component.long_name,
						};
					});
					break;
				}
				case "country":
					setMailingAddressData((prev) => {
						return {
							...prev,
							mailingAddressCountry: component.long_name,
						};
					});
					break;
				default: 
					break;
			}
			if (address1) {
				// setMailingAddressData((prev)=>{
				// 	return {
				// 		...prev,
				// 		mailingAddressCountry: component.long_name
				// 	}
				// });
			}
			if (postcode) {
				setMailingAddressData((prev) => {
					return {
						...prev,
						mailingAddressPostalCode: postcode,
					};
				});
			}
			setMailingAddressData((prev) => {
				return {
					...prev,
					mailingAddress: placeDetails.formatted_address,
				};
			});
		}
	};

	const handleSelectBillingData = async (value, placeId) => {
		const places = await geocodeByPlaceId(placeId);
		const placeDetails = places[0];
		let address1 = "",
			postcode = "";
		for (const component of placeDetails.address_components) {
			const componentType = component.types[0];
			switch (componentType) {
				case "street_number": {
					address1 = `${component.long_name} ${address1}`;
					break;
				}
				case "route": {
					address1 += component.short_name;
					break;
				}
				case "postal_code": {
					postcode = `${component.long_name}${postcode}`;
					break;
				}
				case "postal_code_suffix": {
					postcode = `${postcode}-${component.long_name}`;
					break;
				}
				case "locality":
					setBillingAddressData((prev) => {
						return {
							...prev,
							billingAddressCity: component.long_name,
						};
					});
					break;

				case "administrative_area_level_1": {
					setBillingAddressData((prev) => {
						return {
							...prev,
							billingAddressProvince: component.long_name,
						};
					});
					break;
				}
				case "country":
					setBillingAddressData((prev) => {
						return {
							...prev,
							billingAddressCountry: component.long_name,
						};
					});
					break;
				default: 
					break;
			}
			if (address1) {
				// setMailingAddressData((prev)=>{
				// 	return {
				// 		...prev,
				// 		mailingAddressCountry: component.long_name
				// 	}
				// });
			}
			if (postcode) {
				setBillingAddressData((prev) => {
					return {
						...prev,
						billingAddressPostalCode: postcode,
					};
				});
			}
			setBillingAddressData((prev) => {
				return {
					...prev,
					billingAddress: placeDetails.formatted_address,
				};
			});
		}
	};

	const handleAddress = async (value) => {
		setMailingAddressData((prevState) => ({
			...prevState,
			mailingAddress: value,
		}));
	};
	const handleBillingAddress = async (value) => {
		setBillingAddressData((prevState) => ({
			...prevState,
			billingAddress: value,
		}));
	};
	const handleApartmentNumber = (e) => {
		setMailingAddressData((prevState) => ({
			...prevState,
			mailingAddressAptUnit: e.target.value,
		}));
	};
	const handleCity = (e) => {
		setMailingAddressData((prevState) => ({
			...prevState,
			mailingAddressCity: e.target.value,
		}));
	};
	const handleProvince = (e) => {
		setMailingAddressData((prevState) => ({
			...prevState,
			mailingAddressProvince: e.target.value,
		}));
	};
	const handlePostalCode = (e) => {
		setMailingAddressData((prevState) => ({
			...prevState,
			mailingAddressPostalCode: e.target.value,
		}));
	};
	const handleCountryName = (e) => {
		setMailingAddressData((prevState) => ({
			...prevState,
			mailingAddressCountry: e.target.value,
		}));
	};

	//billing address
	const handleBillingAptunit = (e) => {
		setBillingAddressData((prevState) => ({
			...prevState,
			billingAddressAptUnit: e.target.value,
		}));
	};
	const handleBillingCity = (e) => {
		setBillingAddressData((prevState) => ({
			...prevState,
			billingAddressCity: e.target.value,
		}));
	};
	const handleBillingProvince = (e) => {
		setBillingAddressData((prevState) => ({
			...prevState,
			billingAddressProvince: e.target.value,
		}));
	};
	const handleBillingPostalCode = (e) => {
		setBillingAddressData((prevState) => ({
			...prevState,
			billingAddressPostalCode: e.target.value,
		}));
	};
	const handleBillingCountry = (e) => {
		setBillingAddressData((prevState) => ({
			...prevState,
			billingAddressCountry: e.target.value,
		}));
	};

	const handleCheckBox = () => {
		setCheck(!check);
	};

	const onDrop = (acceptedFiles) => {
		if (acceptedFiles && acceptedFiles.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const new_img = new Image();

				new_img.onload = function () {
					var width = new_img.naturalWidth,
						heigth = new_img.naturalHeight;
					if (width > 50 && heigth > 50) {
						//size validation for images
						setDroppedFile((previousValue) => {
							return {
								...previousValue,
								fileUploadedPercent: 100,
								showCropOption: true,
								uploadedImageType: acceptedFiles[0].type,
								uploadedImageName: acceptedFiles[0].name,
							};
						});
						setSelectedImage(reader.result);
					} else {
						setDroppedFile((previousValue) => {
							return {
								...previousValue,
								fileDroped: false,
								fileUploading: false,
								invalidFile: true,
								invalidFileMsg: "",
							};
						});
					}
				};
				new_img.src = reader.result;
			});
			reader.readAsDataURL(acceptedFiles[0]);
			setDroppedFile((previousValue) => {
				return {
					...previousValue,
					fileDroped: true,
					fileUploading: true,
					fileUploadedPercent: 10,
					invalidFile: false,
				};
			});
		}
	};
	const onDropRejected = (rejectedFiles) => {
		if (rejectedFiles && rejectedFiles.length > 0) {
			let file = rejectedFiles[0];
			let error = file.errors[0];
			if (error.code === "file-invalid-type") {
				setDroppedFile((previousValue) => {
					return {
						...previousValue,
						invalidFile: true,
						invalidFileMsg: "",
					};
				});
			} else if (error.code === "file-too-large") {
				setDroppedFile((previousValue) => {
					return {
						...previousValue,
						invalidFile: true,
						invalidFileMsg:
						t('pages.create_event.file_validation'),
					};
				});
			} else if (error.code === "file-too-small") {
				setDroppedFile((previousValue) => {
					return {
						...previousValue,
						invalidFile: true,
						invalidFileMsg: "",
					};
				});
			}
		}
	};
	const saveCroppedImage = async () => {
		const checksum = await fileChecksum(croppedImage.blob);
		await props.postImageWithSignedUrl(croppedImage.blob, checksum);
		setDroppedFile((previousValue) => {
			return {
				...previousValue,
				fileUploaded: true,
				savedImage: croppedImage.blob, //need to be send in ajax call
				savedImageUrl: croppedImage.url,
				showCropOption: false,
			};
		});
		setCropSettings(defaultCropSettings);
	};
	const removeUploadedFile = async () => {
		setDroppedFile(defaultImguploadData);
		setCropSettings(defaultCropSettings);
		if (pageState === "edit") {
			await removeLogo(logoHomeId);
			await getOrganization(orgId);
			await getFuneralHomes();
		}
	};
	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		onDropRejected,
		accept: "image/jpeg, image/png",
		maxSize: "5242880",
		noClick: true,
		multiple: false,
	});
	const onCropChange = (crop) => {
		setCropSettings(crop);
	};
	const onImageLoaded = (image) => {
		setImageRef(image);
		const width = Math.min(Math.max(image.width, image.height), 350);
		setCropSettings((prev) => {
			return { ...prev, width: width };
		});
		return false;
	};
	const closeImageCrop = () => {
		removeUploadedFile();
	};
	const onCropComplete = (crop) => {
		setCroppedImage((prev) => {
			return {
				...prev,
				blob: undefined,
				url: "#",
			};
		});
		makeClientCrop(crop);
	};
	const makeClientCrop = (crop) => {
		if (imageRef && crop.width && crop.height) {
			getCroppedImg(
				imageRef,
				crop,
				dropFile.uploadedImageName,
				dropFile.uploadedImageType
			).then((img) => {
				setCroppedImage((prev) => {
					return {
						...prev,
						blob: img.blob,
						url: img.fileUrl,
					};
				});
			});
		}
	};
	const getCroppedImg = (image, crop, fileName, fileType) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty");
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				fileUrl = window.URL.createObjectURL(blob);
				resolve({ blob, fileUrl });
			}, fileType);
		});
	};
	useEffect(() => {
		checkSaveHomeValid();
	}, [
		funeralHomeName,
		funeralPhoneContact,
		funeralSiteUrl,
		funeralContactEmail,
		flowerShopLink,
		mailingAddressData,
		billingAddressData,
		check,
		organizationName,
		roomEventList,
		errorStates
	]);
	const checkSaveHomeValid = () => {
		if (
			funeralHomeName &&
			funeralPhoneContact &&
			funeralSiteUrl &&
			funeralContactEmail &&
			organizationName &&
			roomEventList.length>0 &&
			Object.values(errorStates).every(x => x === null || x === '') &&
			mailingAddressData.mailingAddressCity &&
			mailingAddressData.mailingAddressCountry &&
			mailingAddressData.mailingAddress &&
			mailingAddressData.mailingAddressPostalCode &&
			mailingAddressData.mailingAddressProvince &&
			(check ||
				(!check &&
					billingAddressData.billingAddress &&
					billingAddressData.billingAddressCity &&
					billingAddressData.billingAddressCountry &&
					billingAddressData.billingAddressProvince &&
					billingAddressData.billingAddressPostalCode
					))
		) {
			setHomeFormValid(true);
			return true;
		} else {
			setHomeFormValid(false);
			return false;
		}
	};
	const validateSaveHome = (e) => {
		e.preventDefault();
		if (checkSaveHomeValid()) {
			addFuneralHomeData();
			resetHomeData();
		}
	};
	const resetHomeData = () => {
		setFuneralHomeName("");
		setFuneralPhoneContact("");
		setFuneralSiteUrl("");
		setFuneralContactEmail("");
		setFlowerShopLink("");
		setMailingAddressData(previous=>{
			Object.keys(previous).map((x) => {
				previous[x] = ""
			});
			return previous;
		});
		updateRoomEventList([]);
		setBillingAddressData(previous=>{
			Object.keys(previous).map((x) => {
				previous[x] = ""
			});
			return previous;
		});
		setCheck(false);
	};
	const prepareStringForStream = (event) => {
		const funeralHomedata =
			event.name +
			" " +
			event.contactEmail +
			" • " +
			event.phone +
			" • " +
			event.baseUrl
			// event.streamEmail;
		return funeralHomedata;
	};
	const addFuneralHomeData = () => {
		const funeralHomedata = {
			id: createId(),
			name: funeralHomeName.trim(),
			phone: funeralPhoneContact.trim(),
			contactEmail: funeralContactEmail.trim(),
			flowerShopLink: flowerShopLink.trim(),
			logo: uploadedImage,
			baseUrl: funeralSiteUrl.trim(),
			rooms: roomEventList.map(x=>{
				const roomData = {
					name: x.name.trim(),
					awsChannelId:  x.channel.trim(),
					awsEndpointId: x.endpoint.trim(),
					awsCloudfrontUrl: x.cloudUrl.trim(),
					cameraIp: x.ip.trim(),
					rtmpUrl: x.rtmp.trim(),
					ec2Host: x.instance.trim(),
					streamProvider: x.provider.trim()
				}
				return roomData;
			}),
			mailingAddress: {
				city: mailingAddressData.mailingAddressCity.trim(),
				street: mailingAddressData.mailingAddress.split(',')[0].trim(),
				country: mailingAddressData.mailingAddressCountry.trim(),
				province: mailingAddressData.mailingAddressProvince.trim(),
				postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
				unit: mailingAddressData.mailingAddressAptUnit.trim()
			},
			check: check
		};
		if(check){
			funeralHomedata.billingAddress = {
				city: mailingAddressData.mailingAddressCity.trim(),
				street: mailingAddressData.mailingAddress.split(',')[0].trim(),
				country: mailingAddressData.mailingAddressCountry.trim(),
				province: mailingAddressData.mailingAddressProvince.trim(),
				postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
				unit: mailingAddressData.mailingAddressAptUnit.trim()
			}
		}else{
			funeralHomedata.billingAddress = {
				city: billingAddressData.billingAddressCity.trim(),
				street: billingAddressData.billingAddress.split(',')[0].trim(),
				country: billingAddressData.billingAddressCountry.trim(),
				province: billingAddressData.billingAddressProvince.trim(),
				postalCode: billingAddressData.billingAddressPostalCode.trim(),
				unit: billingAddressData.billingAddressAptUnit.trim()
			}
		}
		setHomeList((previous) => {
			const newList = [...previous, funeralHomedata];
			return newList;
		});
		setDroppedFile(defaultImguploadData);
		setCropSettings(defaultCropSettings);
	};
	const removeFuneralHome = (homeId) => {
        const home = homeList.find(x => x.id === homeId);
        if(home){
            setDeleteConfirmationModal({
                state: "open",
                homeId: home.id,
                name: home.name,
                deleteOrg: homeList.length === 1 && pageState === "edit"
            });
        }
    }
	const deleteConfirmation = async(homeId) => {
		if(pageState === "edit"){
			deleteFuneralHome(homeId).then(()=> {
				if(currentOrgData.funeralHomes.length === 1){
					deleteOrganization(orgId);
					history.push(`/orgmanagement`);
				}else{
					const index = homeList.findIndex(x=>x.id === homeId);
					setHomeList((previous) => {
						previous.splice(index, 1);
						return [...previous];
					});
				} 
			});
		}
		setDeleteConfirmationModal({
            state: 'close'
        });
	}
	const discardEdited = (id) => {
		setExpandedElement({
			id: 0,
			edit: false
		});
	}
	const saveEdited = async (id) => {
		if (checkSaveHomeValid()) {
				const newList = homeList.find(x=>x.id === id);
				newList.name= funeralHomeName.trim();
				newList.phone = funeralPhoneContact.trim();
				newList.contactEmail = funeralContactEmail.trim();
				newList.flowerShopLink = flowerShopLink.trim();
				newList.baseUrl = funeralSiteUrl.trim();
				newList.logo=uploadedImage;
				newList.mailingAddress = {
					city: mailingAddressData.mailingAddressCity.trim(),
					street: mailingAddressData.mailingAddress.trim(),
					country: mailingAddressData.mailingAddressCountry.trim(),
					province: mailingAddressData.mailingAddressProvince.trim(),
					postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
					unit: mailingAddressData.mailingAddressAptUnit.trim()
				};
				newList.rooms = roomEventList.map((x)=>{
					const roomData = {
						name: x.name,
						awsChannelId:  x.channel,
						awsEndpointId: x.endpoint,
						awsCloudfrontUrl: x.cloudUrl,
						cameraIp: x.ip,
						rtmpUrl: x.rtmp,
						ec2Host: x.instance,
						streamProvider: x.provider
					}
					return roomData;
				});
				newList.check = check;
				if(check){
					newList.billingAddress = {
						city: mailingAddressData.mailingAddressCity.trim(),
						street: mailingAddressData.mailingAddress.trim(),
						country: mailingAddressData.mailingAddressCountry.trim(),
						province: mailingAddressData.mailingAddressProvince.trim(),
						postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
						unit: mailingAddressData.mailingAddressAptUnit.trim()
					}
				}else{
					newList.billingAddress = {
						city: billingAddressData.billingAddressCity.trim(),
						street: billingAddressData.billingAddress.trim(),
						country: billingAddressData.billingAddressCountry.trim(),
						province: billingAddressData.billingAddressProvince.trim(),
						postalCode: billingAddressData.billingAddressPostalCode.trim(),
						unit: billingAddressData.billingAddressAptUnit.trim()
					}
				}
				const homePayload = {
					funeralHome: {
						name: newList.name.trim(),
						mailingAddress: {
							city: newList.mailingAddress.city.trim(),
							street: newList.mailingAddress.street.split(',')[0].trim(),
							country: newList.mailingAddress.country.trim(),
							province: newList.mailingAddress.province.trim(),
							postalCode: newList.mailingAddress.postalCode.trim(),
							unit: newList.mailingAddress.unit.trim()
						},
						rooms: newList.rooms.map(x=>{
							const roomData = {
								name: x.name,
								awsChannelId:  x.awsChannelId,
								awsEndpointId: x.awsEndpointId,
								awsCloudfrontUrl: x.awsCloudfrontUrl,
								cameraIp: x.cameraIp,
								rtmpUrl: x.rtmpUrl,
								ec2Host: x.ec2Host,
								streamProvider: x.streamProvider
							}
							return roomData;
						}),
						phone: newList.phone.trim(),
						contactEmail: newList.contactEmail.trim(),
						flowerShopLink: newList.flowerShopLink.trim(),
						baseUrl: newList.baseUrl.trim(),
						check: newList.check,
						logo: newList.logo
					},
					organizationId: orgId
				};
				if(check){
					homePayload.funeralHome.billingAddress = {
						city: newList.mailingAddress.city.trim(),
						street: newList.mailingAddress.street.split(',')[0].trim(),
						country: newList.mailingAddress.country.trim(),
						province: newList.mailingAddress.province.trim(),
						postalCode: newList.mailingAddress.postalCode.trim(),
						unit: newList.mailingAddress.unit.trim()
					}
				}else{
					homePayload.funeralHome.billingAddress = {
						city: newList.billingAddress.city.trim(),
						street: newList.billingAddress.street.split(',')[0].trim(),
						country: newList.billingAddress.country.trim(),
						province: newList.billingAddress.province.trim(),
						postalCode: newList.billingAddress.postalCode.trim(),
						unit: newList.billingAddress.unit.trim()
					}
				}
				if(pageState === "edit"){
					await updateFuneralHome(homePayload, id);
					await getOrganization(orgId);
					await getFuneralHomes();
				}
				setExpandedElement({
					id: 0,
					edit: false
				});
				resetHomeData();
		}
	}
	const setHomeDataToForm = (id) => {
		const homeDetails = homeList.find(x=>x.id === id);
		setFuneralHomeName(homeDetails.name);
		setFuneralContactEmail(homeDetails.contactEmail);
		setFuneralPhoneContact(homeDetails.phone);
		setFuneralSiteUrl(homeDetails.baseUrl);
		if(homeDetails.logoUrl){ // image
			setLogoUrl(homeDetails.logoUrl);
			setDroppedFile(prev=>{
				return {
					...prev,
					fileUploaded: true,
					savedImageUrl: homeDetails.logoUrl,
				}
			})
		}
		else{
			setLogoUrl("");
			setDroppedFile(defaultImguploadData);
			setCropSettings(defaultCropSettings);
		}
		setFlowerShopLink(homeDetails.flowerShopLink);
		if(homeDetails.rooms && homeDetails.rooms.length > 0){
			const rooms = homeDetails.rooms.map((room)=>{
				return {
					uuid: room.id,
					name: room.name,
					channel: room.awsChannelId,
					endpoint: room.awsEndpointId,
					ip: room.cameraIp,
					instance: room.ec2Host,
					provider: room.streamProvider
				}
			});
			updateRoomEventList(rooms);
			}
		setMailingAddressData({
			mailingAddressCity: homeDetails.mailingAddress.city,
			mailingAddress: homeDetails.mailingAddress.street,
			mailingAddressCountry: homeDetails.mailingAddress.country,
			mailingAddressProvince: homeDetails.mailingAddress.province,
			mailingAddressPostalCode: homeDetails.mailingAddress.postalCode,
			mailingAddressAptUnit: homeDetails.mailingAddress.unit
		});
		if(!homeDetails.check){
			setBillingAddressData({
				billingAddressCity: homeDetails.billingAddress.city,
				billingAddress: homeDetails.billingAddress.street,
				billingAddressCountry: homeDetails.billingAddress.country,
				billingAddressProvince: homeDetails.billingAddress.province,
				billingAddressPostalCode: homeDetails.billingAddress.postalCode,
				billingAddressAptUnit: homeDetails.billingAddress.unit
			});
		}
	}
	const deleteHome = (id) => {
		const index = homeList.findIndex(x=>x.id === id);
		setHomeList((previous) => {
			previous.splice(index, 1);
			return [...previous];
		});
		checkDetailsValid();
	};
	useEffect(() => {
		checkDoneValid();
	}, [homeList, organizationName]);
	const checkDoneValid =() =>{
		if(organizationName && homeList.length>0){
			setDoneValid(true);
			return true;
		}
		else{
			setDoneValid(false);
			return false;
		}
	}
	useEffect(() => {
		checkDetailsValid();
	}, [
		homeList,
		funeralHomeName,
		funeralPhoneContact,
		funeralSiteUrl,
		funeralContactEmail,
		flowerShopLink,
		mailingAddressData,
		billingAddressData,
		check,
		organizationName,
		roomEventList,
		errorStates
	]);
	const checkDetailsValid = () => {
		if (
			(organizationName &&
			homeList.length>0 && funeralHomeName &&
			funeralPhoneContact &&
			funeralSiteUrl &&
			funeralContactEmail &&
			organizationName &&
			roomEventList.length>0 &&
			Object.values(errorStates).every(x => x === null || x === '') &&
			mailingAddressData.mailingAddressCity &&
			mailingAddressData.mailingAddressCountry &&
			mailingAddressData.mailingAddress &&
			mailingAddressData.mailingAddressPostalCode &&
			mailingAddressData.mailingAddressProvince &&
			(check ||
				(!check &&
					billingAddressData.billingAddress &&
					billingAddressData.billingAddressCity &&
					billingAddressData.billingAddressCountry &&
					billingAddressData.billingAddressProvince &&
					billingAddressData.billingAddressPostalCode
					))) || (funeralHomeName &&
				funeralPhoneContact &&
				funeralSiteUrl &&
				funeralContactEmail &&
				organizationName &&
				roomEventList.length>0 &&
				Object.values(errorStates).every(x => x === null || x === '') &&
				mailingAddressData.mailingAddressCity &&
				mailingAddressData.mailingAddressCountry &&
				mailingAddressData.mailingAddress &&
				mailingAddressData.mailingAddressPostalCode &&
				mailingAddressData.mailingAddressProvince &&
				(check ||
					(!check &&
						billingAddressData.billingAddress &&
						billingAddressData.billingAddressCity &&
						billingAddressData.billingAddressCountry &&
						billingAddressData.billingAddressProvince &&
						billingAddressData.billingAddressPostalCode
						)))
		) {
			setCmpltFormValild(true);
			return true;
		} else {
			setCmpltFormValild(false);
			return false;
		}
	};
	const collapseHome = () => {
		resetHomeData();
		setExpandedElement({
			id: 0,
			edit: false
		});
		setShowForm(false);
	}
	const editHome = (id) => {
		setHomeDataToForm(id);
		setExpandedElement({
			id: id,
			edit: true
		});
	}
	const addNewHome = (e) => {
		e.preventDefault();
		if(checkSaveHomeValid()) {
			const homePayload = {
				funeralHome: {
					name: funeralHomeName,
					mailingAddress: {
						city: mailingAddressData.mailingAddressCity.trim(),
						street: mailingAddressData.mailingAddress.split(',')[0].trim(),
						country: mailingAddressData.mailingAddressCountry.trim(),
						province: mailingAddressData.mailingAddressProvince.trim(),
						postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
						unit: mailingAddressData.mailingAddressAptUnit.trim()
					},
					logo: uploadedImage,
					rooms: roomEventList.map(x=>{
						const roomData = {
							name: x.name.trim(),
							awsChannelId:  x.channel.trim(),
							awsEndpointId: x.endpoint.trim(),
							awsCloudfrontUrl: x.cloudUrl.trim(),
							cameraIp: x.ip.trim(),
							rtmpUrl: x.rtmp.trim(),
							ec2Host: x.instance.trim(),
							streamProvider: x.provider.trim(),
						}
						return roomData;
					}),
					phone: funeralPhoneContact,
					contactEmail: funeralContactEmail,
					flowerShopLink: flowerShopLink,
					baseUrl: funeralSiteUrl,
				},
				organizationId: orgId
			};
			if(check){
				homePayload.funeralHome.billingAddress = {
					city: mailingAddressData.mailingAddressCity.trim(),
					street: mailingAddressData.mailingAddress.split(',')[0].trim(),
					country: mailingAddressData.mailingAddressCountry.trim(),
					province: mailingAddressData.mailingAddressProvince.trim(),
					postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
					unit: mailingAddressData.mailingAddressAptUnit.trim()
				}
			}else{
				homePayload.funeralHome.billingAddress = {
					city: billingAddressData.billingAddressCity.trim(),
					street: billingAddressData.billingAddress.split(',')[0].trim(),
					country: billingAddressData.billingAddressCountry.trim(),
					province: billingAddressData.billingAddressProvince.trim(),
					postalCode: billingAddressData.billingAddressPostalCode.trim(),
					unit: billingAddressData.billingAddressAptUnit.trim()
				}
			}
			createFuneralHome(homePayload)
			setHomeList((previous) => {
				const newList = [...previous, homePayload.funeralHome];
				return newList;
			});
			history.push('/orgmanagement')
		}
		setShowForm(false);
	}
	const validateCreateOnboard = async (e) => {
		e.preventDefault();
		if (checkDetailsValid()) {
			if(funeralHomeName &&
				funeralPhoneContact &&
				funeralSiteUrl &&
				funeralContactEmail &&
				roomEventList.length>0 &&
				Object.values(errorStates).every(x => x === null || x === '') &&
				mailingAddressData.mailingAddressCity &&
				mailingAddressData.mailingAddressCountry &&
				mailingAddressData.mailingAddress &&
				mailingAddressData.mailingAddressPostalCode &&
				mailingAddressData.mailingAddressProvince &&
				(check || (!check && 
						billingAddressData.billingAddress &&
						billingAddressData.billingAddressCity &&
						billingAddressData.billingAddressCountry &&
						billingAddressData.billingAddressProvince &&
						billingAddressData.billingAddressPostalCode)))
				{
					const funeralHomedata = {
						name: funeralHomeName.trim(),
						phone: funeralPhoneContact.trim(),
						contactEmail: funeralContactEmail.trim(),
						flowerShopLink: flowerShopLink.trim(),
						logo: uploadedImage,
						baseUrl: funeralSiteUrl.trim(),
						rooms: roomEventList.map(x=>{
							const roomData = {
								name: x.name.trim(),
								awsChannelId:  x.channel.trim(),
								awsEndpointId: x.endpoint.trim(),
								awsCloudfrontUrl: x.cloudUrl.trim(),
								cameraIp: x.ip.trim(),
								rtmpUrl: x.rtmp.trim(),
								ec2Host: x.instance.trim(),
								streamProvider: x.provider.trim(),
							}
							return roomData;
						}),
						mailingAddress: {
							city: mailingAddressData.mailingAddressCity.trim(),
							street: mailingAddressData.mailingAddress.split(',')[0].trim(),
							country: mailingAddressData.mailingAddressCountry.trim(),
							province: mailingAddressData.mailingAddressProvince.trim(),
							postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
							unit: mailingAddressData.mailingAddressAptUnit.trim()
						},
						check: check
					};
					if(check){
						funeralHomedata.billingAddress = {
							city: mailingAddressData.mailingAddressCity.trim(),
							street: mailingAddressData.mailingAddress.split(',')[0].trim(),
							country: mailingAddressData.mailingAddressCountry.trim(),
							province: mailingAddressData.mailingAddressProvince.trim(),
							postalCode: mailingAddressData.mailingAddressPostalCode.trim(),
							unit: mailingAddressData.mailingAddressAptUnit.trim()
						}
					}else{
						funeralHomedata.billingAddress = {
							city: billingAddressData.billingAddressCity.trim(),
							street: billingAddressData.billingAddress.split(',')[0].trim(),
							country: billingAddressData.billingAddressCountry.trim(),
							province: billingAddressData.billingAddressProvince.trim(),
							postalCode: billingAddressData.billingAddressPostalCode.trim(),
							unit: billingAddressData.billingAddressAptUnit.trim()
						}
					}
					if(homeList.length>0){
						const org = {
							organization: {
								name: organizationName,
								funeralHomes: [...homeList,funeralHomedata]
							}
						}
						await createOrgReq(org);
						history.push('/orgmanagement');
					}
					else{
						const org = {
							organization: {
								name: organizationName,
								funeralHomes: [funeralHomedata]
							}
						}
						await createOrgReq(org);
						history.push('/orgmanagement');
					}
			}	
			else{
				homeList.map((list)=>delete list.id);
					const org = {
						organization: {
							name: organizationName,
							funeralHomes: [...homeList]
						}
				}
				await createOrgReq(org);
				history.push('/orgmanagement');
			}
		}
	};

	const updateOrganization = async (e) =>{
		e.preventDefault();
		if (checkDoneValid() && !expandedElement.edit) {
			history.push(`/orgmanagement/${orgId}/view`)
		}
	}

	const funeralHomeForm = (edit) =>{
		return <Fragment>
			<div className={`${!edit || expandedElement.edit ? '' : CSS.disableForm}`}>
				<section className={CSS.formWrapper}>
					<span className={CSS.formTitlte}>{t('pages.admin_create_org.add_org.home_info_title')}</span>
					<div className={CSS.elementRows}>
						<div className={CSS.formElementsRow}>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.home_name')}
								</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_l}`}
									variant="outlined"
									value={funeralHomeName}
									onChange={(e) => {
										handleFuneralHomeName(e);
									}}
								/>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.home_email')}
								</label>
								<TextField
									className={errorStates.funeralContactEmail? `${CSS.inputEleError} ${CSS.size_l}` :`${CSS.inputEle} ${CSS.size_l}`}
									variant="outlined"
									value={funeralContactEmail}
									onChange={(e) => {
										handleFuneralContactEmail(e);
									}}
								/>
								<div className={CSS.errorText}>{errorStates.funeralContactEmail}</div>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.home_phone')}
								</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_m}`}
									variant="outlined"
									value={funeralPhoneContact}
									onChange={(e) => {
										handleFuneralPhoneContact(e);
									}}
								/>
							</div>
						</div>
						<div className={CSS.formElementsRow}>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.home_url')}
								</label>
								<TextField
									className={errorStates.funeralSiteUrl? `${CSS.inputEleError} ${CSS.size_m}` :`${CSS.inputEle} ${CSS.size_m}`}
									variant="outlined"
									value={funeralSiteUrl}
									onChange={(e) => {
										handleFuneralSiteUrl(e);
									}}
								/>
								<div className={CSS.errorText}>{errorStates.funeralSiteUrl}</div>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.home_flower_link')}
								</label>
								<TextField
									className={errorStates.flowerShopLink? `${CSS.inputEleError} ${CSS.size_l}` :`${CSS.inputEle} ${CSS.size_l}`}
									variant="outlined"
									value={flowerShopLink}
									onChange={(e) => {
										handleFlowerShopLink(e);
									}}
								/>
								<div className={CSS.errorText}>{errorStates.flowerShopLink}</div>
							</div>
						</div>
					</div>
				</section>
				{user.user && (user.user?.role === "admin" || user.user?.role === "super_admin") ?
					<section className={CSS.formWrapper}>
						<span className={CSS.formTitlte}>{t('pages.admin_create_org.add_org.rooms_title')}</span>
						<div className={CSS.elementRows}>
							<div className={CSS.formElementsRow}>
								<div className={CSS.elementWrapper}>
									<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.room_stream_provider')}
									</label>
									<FormControl
										variant="outlined"
										className={`${CSS.inputEle} ${CSS.size_l}`}>
										<Select
											variant="outlined"
											value={streamProvider}
											onChange={handleStreamProviderChange}
											MenuProps={{
												disableScrollLock: true,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left"
												},
												getContentAnchorEl: null,
												MenuListProps: {
													disablePadding: true
												}
											}}
											classes={{ root: classes.selectRoot }}										  
											IconComponent={ExpandMoreOutlinedIcon}
											inputProps={{
												classes: {
													root: classes.border,
													icon: classes.icon,
												},
											}}
										>
											{STREAM_PROVIDERS.map((option) => (
												<MenuItem key={option.value} value={option.value} classes={{ selected: classes.selected, root: classes.rootMenuItem }}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
						</div>
						<div className={CSS.elementRows}>
							<div className={CSS.formElementsRow}>
								<div className={CSS.elementWrapper}>
									<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.room_name')}
									</label>
									<TextField
										className={`${CSS.inputEle} ${CSS.size_l}`}
										variant="outlined"
										value={roomName}
										onChange={(e) => {
											handleRoomName(e);
										}}
									/>
								</div>
							</div>
							{STREAM_PROVIDERS.find(provider => provider.value === streamProvider) && <div className={CSS.formElementsRow}>
								<div className={CSS.btnWrapper}>
									<div className={`${CSS.actionBtn} ${CSS.warning} ${roomValid ? "" : CSS.disabled}`}
										onClick={validateRoomDetails}>
										{t('pages.admin_create_org.add_org.create_room_button')}
									</div>
								</div>
							</div>}
						</div>
					</section>: ""}
				<section className={CSS.streamingLists}>
					<div className={CSS.sectionContianer}>
						<div className={CSS.streamingListWrapper}>
						{roomEventList.map((x, index) => {
							return (
								<div className={CSS.streamEvent} key={x.uuid}>
									<div className={CSS.streamingInfo}>
										<div className={CSS.streamDetails}>{prepareStringForRoom(x)}</div>
									</div>
									{user.user && (user.user?.role === "admin" || user.user?.role === "super_admin") ?
										<div style={{paddingLeft: "12px"}}>
											<DeleteForeverIcon className={CSS.deleteStreamIcon} onClick={deleteStream} index={index}/>
										</div>: ""}
								</div>
							);
						})}
						</div>
					</div>
				</section>
				<section className={CSS.formWrapper}>
					<span className={CSS.formTitlte}>{t('pages.admin_create_org.add_org.mailing_title')}</span>
					<div className={CSS.elementRows}>
						<div className={CSS.formElementsRow}>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
								{t('pages.admin_create_org.add_org.mailing_address')}
								</label>
								<PlacesAutocomplete
									value={mailingAddressData.mailingAddress}
									onChange={handleAddress}
									onSelect={handleSelectMailingData}
								>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
									<div style={{paddingRight: "10px"}}>
										<input
										{...getInputProps({
											placeholder: t('pages.admin_create_org.add_org.search_places_placeholder'),
											className: CSS.inputPlaces,
										})}
										/>
										<div style={{position: "absolute", zIndex: "10000" ,  border: "1px solid #C4C4C4", display: "block"}}>
										{loading && <div>Loading...</div>}
										{suggestions.map(suggestion => {
											const className = suggestion.active
											? 'suggestion-item--active'
											: 'suggestion-item';
											const style = suggestion.active
											? { backgroundColor: '#fafafa', cursor: 'pointer' }
											: { backgroundColor: '#ffffff', cursor: 'pointer' };
											return (
											<div
												{...getSuggestionItemProps(suggestion, {
												className,
												style,
												})}
												key={suggestion.placeId}
											>
												<LocationOnIcon /><span>{suggestion.description}</span>
											</div>
											);
										})}
										</div>
									</div>
									)}
								</PlacesAutocomplete>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
								{t('pages.admin_create_org.add_org.mailing_unit')}
								</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_m}`}
									variant="outlined"
									value={
										mailingAddressData.mailingAddressAptUnit
									}
									onChange={(e) => {
										handleApartmentNumber(e);
									}}
								/>
							</div>
						</div>
						<div className={CSS.formElementsRow}>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>{t('pages.admin_create_org.add_org.mailing_city')}</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_m}`}
									variant="outlined"
									value={
										mailingAddressData.mailingAddressCity
									}
									onChange={(e) => {
										handleCity(e);
									}}
								/>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
								{t('pages.admin_create_org.add_org.mailing_province')}
								</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_m}`}
									variant="outlined"
									value={
										mailingAddressData.mailingAddressProvince
									}
									onChange={(e) => {
										handleProvince(e);
									}}
								/>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
								{t('pages.admin_create_org.add_org.mailing_postcode')}
								</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_m}`}
									variant="outlined"
									value={
										mailingAddressData.mailingAddressPostalCode
									}
									onChange={(e) => {
										handlePostalCode(e);
									}}
								/>
							</div>
							<div className={CSS.elementWrapper}>
								<label className={CSS.elementLabel}>
								{t('pages.admin_create_org.add_org.mailing_country')}
								</label>
								<TextField
									className={`${CSS.inputEle} ${CSS.size_m}`}
									value={
										mailingAddressData.mailingAddressCountry
									}
									variant="outlined"
									onChange={(e) => {
										handleCountryName(e);
									}}
								/>
							</div>
						</div>
					</div>
				</section>
				<section className={CSS.formWrapper}>
					<span className={CSS.formTitlte}>{t('pages.admin_create_org.add_org.billing_title')}</span>
					<div className={CSS.elementRows}>
						<div className={CSS.formElementsRow}>
							<div className={CSS.elementWrapper}>
								<div style={{display: "flex", alignItems: "center"}}>
									<FormControlLabel
										control={<Checkbox checked={check} id="selectSameAddress"
											onChange={handleCheckBox}
											style={{
												color: "#00667E",
											}}
											classes={{ root: classes.checkbox, checked: classes.checkedbox }}
											className={classes.root} />}
										label={<span style={{ fontSize: '16px', fontWeight: "300", fontFamily: "Bitter,serif", wordWrap: "break-word", color: "#001A20", lineHeight: "16px" }}>{t('pages.admin_create_org.add_org.billing_checkbox')}</span>} />
									{/* <input
										className={CSS.checkIcon}
										type="checkbox"
										value={check}
										name="checkbox"
										onChange={handleCheckBox}
									/> */}
									{/* <label className={CSS.checkBoxLabel}>
									{t('pages.admin_create_org.add_org.billing_checkbox')}
									</label> */}
								</div>
							</div>
						</div>
						{!check && (
							<div>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.admin_create_org.add_org.billing_address')}
										</label>
										<PlacesAutocomplete
											value={billingAddressData.billingAddress}
											onChange={handleBillingAddress}
											onSelect={handleSelectBillingData}
										>
											{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											<div style={{paddingRight: "10px", border:"none"}}>
												<input
												{...getInputProps({
													placeholder: t('pages.admin_create_org.add_org.search_places_placeholder'),
													className: CSS.inputPlaces,
												})}
												/>
												<div style={{position: "absolute", zIndex: "10000" ,  border: "1px solid #C4C4C4", display: "block"}}>
												{loading && <div>Loading...</div>}
												{suggestions.map(suggestion => {
													const className = suggestion.active
													? 'suggestion-item--active'
													: 'suggestion-item';
													const style = suggestion.active
													? { backgroundColor: '#fafafa', cursor: 'pointer' }
													: { backgroundColor: '#ffffff', cursor: 'pointer' };
													return (
													<div
														{...getSuggestionItemProps(suggestion, {
														className,
														style,
														})}
														key={suggestion.placeId}
													>
														<LocationOnIcon /> <span>{suggestion.description}</span>
													</div>
													);
												})}
												</div>
											</div>
											)}
										</PlacesAutocomplete>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.admin_create_org.add_org.billing_unit')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}
											variant="outlined"
											value={
												billingAddressData.billingAddressAptUnit
											}
											onChange={(e) => {
												handleBillingAptunit(e);
											}}
										/>
									</div>
								</div>
								<div className={CSS.formElementsRow}>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.admin_create_org.add_org.billing_city')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}
											variant="outlined"
											value={
												billingAddressData.billingAddressCity
											}
											onChange={(e) => {
												handleBillingCity(e);
											}}
										/>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.admin_create_org.add_org.billing_province')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}
											variant="outlined"
											value={
												billingAddressData.billingAddressProvince
											}
											onChange={(e) => {
												handleBillingProvince(e);
											}}
										/>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.admin_create_org.add_org.billing_postcode')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}
											variant="outlined"
											value={
												billingAddressData.billingAddressPostalCode
											}
											onChange={(e) => {
												handleBillingPostalCode(e);
											}}
										/>
									</div>
									<div className={CSS.elementWrapper}>
										<label className={CSS.elementLabel}>
										{t('pages.admin_create_org.add_org.billing_country')}
										</label>
										<TextField
											className={`${CSS.inputEle} ${CSS.size_m}`}
											variant="outlined"
											value={
												billingAddressData.billingAddressCountry
											}
											onChange={(e) => {
												handleBillingCountry(e);
											}}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</section>
				<section className={CSS.formWrapper}>
					<div className={CSS.formElementsRow}>
						<div
							className={CSS.elementWrapper}
							style={{ width: "100%" }}>
							<label className={CSS.elementLabel}>
							{t('pages.admin_create_org.add_org.org_logo_upload')}
							</label>
							{dropFile.fileUploaded || (
								<div className={CSS.fileDropzoneWrapper}>
									<div
										{...getRootProps()}
										className={CSS.fileDropzone}>
										<input {...getInputProps()} />
										<div
											className={
												CSS.dropzoneContext +
												" " +
												(dropFile.fileUploading
													? CSS.fileUploading
													: "")
											}>
											<div
												className={
													CSS.descriptiveWrapper
												}>
												<BackupIcon
													className={CSS.uploadIcon}
												/>
												<div
													className={
														CSS.feedbackText
													}>
													{dropFile.invalidFile ||
													dropFile.largeSizeFile ? (
														<span
															style={{
																color: "#AE0000",
															}}>
															{dropFile.invalidFileMsg
																? dropFile.invalidFileMsg
																: "File type is not supported. Try another file"}
														</span>
													) : (
														<span>
															{dropFile.fileUploading
																? t('pages.create_event.deceased_photo_uploading')
																: t('pages.admin_create_org.add_org.org_logo_drag')}
														</span>
													)}
												</div>
											</div>
											<div
												className={
													CSS.progressBarWrapper
												}>
												<div
													className={
														CSS.progressIndicator
													}
													style={{
														width:
															dropFile.fileUploadedPercent +
															"%",
													}}></div>
											</div>

											<div type="button"
												onClick={open}
												className={`${CSS.actionBtn} ${CSS.primary} ${CSS.browseBtn}`}>
												{t('pages.admin_create_org.add_org.org_logo_browse')}
											</div>
										</div>
									</div>
									<div className={CSS.dropzoneHint}>
									{t('pages.admin_create_org.add_org.org_logo_specs')}
									</div>
								</div>
							)}
							{dropFile.fileUploaded && (
								<div className={CSS.thumbnailWrapper}>
									<div className={CSS.thumbnailContainer}>
										{dropFile.savedImageUrl && (
											<img
												src={(pageState === "edit" && logoUrl) ? logoUrl : dropFile.savedImageUrl}
												className={CSS.thumbnail}
												alt="profile"
											/>
										)}
									</div>
									<div
										className={`${CSS.actionBtn} ${CSS.primary} ${CSS.removeImgBtn}`}
										onClick={removeUploadedFile}>
										{t('pages.create_event.deceased_photo_remove')}
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
				{edit &&  expandedElement.edit &&
						<section className={CSS.formWrapper}>
							<div className={CSS.submitBtnWrapper}>
								<div
									className={`${CSS.actionBtn} ${CSS.warning} ${
										cmpltFormValild ? "" : CSS.disabled
									}`}
									onClick={()=>{saveEdited(expandedElement.id)}}>
									{t("pages.admin_create_org.add_org.save_changes")}
								</div>
								<div
									className={`${CSS.actionBtn} ${CSS.primary}`} style={{backgroundColor: "#C4D5D9"}}
									onClick={()=>{discardEdited(expandedElement.id)}}>
									{t("pages.admin_create_org.add_org.discard_changes")}
								</div>
							</div>
						</section>
					}
			</div>
		</Fragment>
	}
	return (
		<div>
			<Fragment>
				<Header />
				<div className={CSS.sectionWrapper}>
					<section className={CSS.formWrapper}>
						<span className={CSS.pageTitlte}>
						<a href="/orgmanagement" className={CSS.moveTo}> {t('pages.admin_create_org.orgManagement_title')} </a> {">"} {pageState === "edit"? t('pages.admin_create_org.view_org.viewOrg_title') : t('pages.admin_create_org.add_org.addOrg_title')}
						</span>
						<div className={CSS.elementRows}>
							<div className={CSS.formElementsRow}>
								<div className={CSS.elementWrapper}>
									<label className={CSS.elementLabel}>
									{t('pages.admin_create_org.add_org.org_name')}
									</label>
									<TextField
										className={pageState === "edit" ? `${CSS.inputEleEdit} ${CSS.size_l}` : `${CSS.inputEle} ${CSS.size_l}`}
										variant="outlined"
										disabled = {pageState === "edit"}
										value={organizationName}
										onChange={(e) => {
											handleOrganizationName(e);
										}}
									/>
								</div>
							</div>
						</div>
					</section>
					<section className={CSS.formWrapper}>
						<div className={CSS.sectionContianer}>
							<div className={CSS.homeListWrapper}>
								{homeList.map((x) => {
									return (
										<Fragment key={x.id}>
											<div className={CSS.homeEvent} key={x.id}>
												{pageState==="edit" &&
												(expandedElement.id === x.id ? <ExpandLessIcon className={CSS.expIcon} onClick={e=>collapseHome()}/>: 
												<ExpandMoreIcon className={CSS.expIcon}  onClick={e=>{setLogoHomeId(x.id); editHome(x.id)}}/> )}
													<div className={CSS.homeInfo}>
														<div className={CSS.homeDetails}>
															{prepareStringForStream(x)}
														</div>
													</div>
												{((user.user && user.user?.role === "super_admin") || (user.user && user.user?.role === "admin")) &&
												pageState === "edit" ?
													<DeleteForeverIcon className={CSS.deleteStreamIcon} style={{marginLeft: "auto", marginRight: "0px"}} onClick={()=>removeFuneralHome(x.id)}/>
													: (user.user && user.user?.role !== "editor_restricted" && user.user?.role !== "editor") && <DeleteForeverIcon className={CSS.deleteStreamIcon} style={{marginLeft: "auto", marginRight: "0px"}} onClick={()=>deleteHome(x.id)}/>
												}
											</div>
											{expandedElement.id === x.id && funeralHomeForm(true)}
										</Fragment>
									);
								})}
							</div>
						</div>
					</section>
					{!expandedElement.id && showForm && funeralHomeForm(false)}
					{!expandedElement.id && showForm && pageState === "add" &&
						<section className={CSS.formWrapper}>
							<div className={CSS.submitBtnWrapper}>
								<div className={`${CSS.actionBtn} ${CSS.primary} ${homeFormValid ? "" : CSS.disabled}`}
									onClick={validateSaveHome}>
									{t('pages.admin_create_org.add_org.org_save_add')}
								</div>
								<div className={`${CSS.actionBtn} ${CSS.warning} ${cmpltFormValild ? "" : CSS.disabled}`}
									onClick={validateCreateOnboard}>
									{t('pages.admin_create_org.add_org.org_create')}
								</div>
							</div>
						</section>
					}
					{ !showForm && pageState === "edit" && 
						<section className={CSS.formWrapper}>
							<div className={CSS.submitBtnWrapper}>
							{((user.user && user.user?.role === "super_admin") || (user.user && user.user?.role === "admin")) &&
								<div className={`${CSS.actionBtn} ${CSS.primary}`}
									onClick={() => {
										setExpandedElement({
											id: 0,
											edit: false
										});
										setCheck(false);
										setLogoUrl("");
										setDroppedFile(defaultImguploadData);
										setCropSettings(defaultCropSettings);
										resetHomeData();
										setShowForm(true)}}>
									{t("pages.admin_create_org.add_org.add_funeral_home")}
								</div>}
								<div
									className={`${CSS.actionBtn} ${CSS.warning} ${!expandedElement.edit ? "" : CSS.disabled}`}
									onClick={updateOrganization}>
									{t('pages.admin_create_org.add_org.done')}
								</div>
							</div>
						</section>
					}
					{ showForm && pageState === "edit" && 
						<section className={CSS.formWrapper}>
							<div className={CSS.submitBtnWrapper}>
								<div className={`${CSS.actionBtn} ${CSS.warning} ${homeFormValid ? "" : CSS.disabled}`}
									onClick={addNewHome}>
									{t("pages.admin_create_org.view_org.delete_org_modal.save_home")}
								</div>
								<div className={`${CSS.actionBtn} ${CSS.primary}`}
									onClick={() => {setShowForm(false); resetHomeData(); setCheck(false); setDroppedFile(defaultImguploadData)}}>
									{t("pages.admin_create_org.view_org.delete_org_modal.cancel")}
								</div>
							</div>
						</section>
					}
				</div>
				<Modal open={dropFile.showCropOption}>
					<div className={"modalPopup2Wrapper"} style={{width: "600px"}}>
						<div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
							<div className={"modalPopupTitle"}>
							{t('pages.create_event.deceased_crop_title')}
							</div>
							<div className={"modalPopupCloseBtn"}>
								<CloseIcon
									className={"modalPopupCloseIcon"}
									onClick={closeImageCrop}
								/>
							</div>
						</div>
						<div className={"modalPopupBody"} style={{padding: "0px 24px 24px"}}>
							<div className={CSS.cropImageHint}>
							{t('pages.create_event.deceased_crop_message')}
							</div>
							<div className={CSS.cropImageWrapper}>
								<div className={CSS.cropImageContainer}>
									<ReactCrop
										src={selectedImage}
										crop={cropSettings}
										ruleOfThirds
										onImageLoaded={onImageLoaded}
										onComplete={onCropComplete}
										onChange={onCropChange}
										imageStyle={{
											maxWidth: "350px",
											maxHeight: "350px",
										}}
									/>
								</div>
								<div className={CSS.croppedImageView}>
									<div className={CSS.croppedImageWrappper}>
										{croppedImage.blob && (
											<img
												src={croppedImage.url}
												className={CSS.croppedImage}
												alt="profile"
											/>
										)}
									</div>
									<div className={`${CSS.actionBtn} ${CSS.warning} ${croppedImage.blob? "": CSS.disabled}`}
										onClick={saveCroppedImage}>
										{t('pages.create_event.deceased_crop_save')}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</Fragment>
			<Modal
            open={deleteConfirmationModal.state !== 'close'}>
                <div className={"modalPopup2Wrapper"}>
                    <div className={"modalPopupHeaderWrapper"} style={{padding: "16px 24px"}}>
                        <div className={"modalPopupTitle"}>
						{t('pages.admin_create_org.view_org.delete_org_modal.title')} {deleteConfirmationModal.name}
                        </div>
                        <div className={"modalPopupCloseBtn"}>
                            <CloseIcon
                            className={"modalPopupCloseIcon"}
                            onClick={()=>{ setDeleteConfirmationModal({
                                state: 'close'
                            })}}
                            />
                        </div>
                    </div>
                    <div className={"modalPopupBody"} style={{padding: "0px 24px 16px"}}>
                        {deleteConfirmationModal.deleteOrg ?
                            <div className={"modalBody"}>
                                {t('pages.admin_create_org.view_org.delete_org_modal.body1')} <span style={{fontWeight: 'bold'}}>{organizationName}</span>. {t('pages.admin_create_org.view_org.delete_org_modal.body2')}
                                <div><span style={{color: '#AE0000'}}>{t('pages.admin_create_org.view_org.delete_org_modal.body_warn')}</span></div>
                            </div>
                            :
                            <div className={"modalBody"}>
                                {t('pages.admin_create_org.view_org.delete_home_modal.body1')} 
								<div><span style={{color: '#AE0000'}}>{t('pages.admin_create_org.view_org.delete_home_modal.body_warn')}</span></div>
                            </div>
                        }
                        <div style={{marginTop: 16, display:'flex',justifyContent: 'space-between'}}>
                            <button  className={`${CSS.actionBtn} ${CSS.primary}`} onClick={()=>{ setDeleteConfirmationModal({
                                state: 'close'
                            })}}>{t('pages.admin_create_org.view_org.delete_home_modal.cancel')}</button>                     
                            <button  className={`${CSS.actionBtn} ${CSS.error}`}  onClick={()=> { deleteConfirmation(deleteConfirmationModal.homeId) }}>{deleteConfirmationModal.deleteOrg ? t('pages.admin_create_org.view_org.delete_home_modal.delete_org') : t('pages.admin_create_org.view_org.delete_home_modal.delete')}</button>
                        </div>
                    </div>
                </div>
            </Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	funeralHome: state.funeralHome.funeralHome,
	funeralHomes: state.funeralHome.funeralHomes,
	uploadedImage: state.createEvent.uploadedImage,
	organization: state.organization.organization,
	user: state.user.currentUser,
	streamDataSuccess: state.funeralHome.newStream,
	streamDataFailure: state.funeralHome.error,
	finalStreamData: state.funeralHome.streamData
});

const mapDispatchToProps = {
	createFuneralHome,
	postImageWithSignedUrl,
	getOrganization,
	getFuneralHomes,
	createOrgReq,
	updateFuneralHome,
	updateOrgReq,
	deleteFuneralHome,
    deleteOrganization,
	postTestStreamData,
	getFuneralTestStream,
	removeLogo
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralCreatePage);
